export enum DialogTypes {
	none = "none",
	setupParts = "setupParts",
	addPart = "addPart",
	removePart = "removePart",
	configurationSummary = "configurationSummary",
	warnBeforeExit = "warnBeforeExit",
	saveConfiguration = "saveConfiguration",
	savedConfiguration = "savedConfiguration",
	conflictWhileAddPart = "conflictWhileAddPart",
	exportConfiguration = 'exportConfiguration',
	shareConfiguration = 'shareConfiguration',
	removeConflictingPart = 'removeConflictingPart',
	deleteConfiguraion = 'deleteConfiguraion',
	sendConfiguration = "sendConfiguration",
	sendConfigurationToDealer = "sendConfigurationToDealer",
	userGuidance = "userGuidance",
	languageSelection = "languageSelection"
}

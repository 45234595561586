import React from "react";
import { Attribute, AttributeValues, Part, PartAction } from "./PartsList";
import Action from "../../redux/reducers/models/action";
import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { FullImage } from "../configuration/ConfigurationView";
import Close from "../../assets/icons/close";
import NoPartPreviewIcon from "@material-ui/icons/VisibilityOffOutlined";
import { StateManagementProps } from "../../utils/addReduxProps";
import formatter from "../../utils/formatter";
import Minus3D from "../../assets/icons/minus3D";
import Plus3D from "../../assets/icons/plus3D";
import { Collapse } from "@material-ui/core";
import ArrowHead from "../../assets/icons/arrowHead";
import { togglePart } from "../../utils/partsHelpers";
import { debug } from "../../utils/helpers";

export interface PartDetailDialogProps {
  selectedPart: Part;
  closeDialog: () => void;
  applyParts: (parts: string[]) => Action;
  setImageTimestamp: () => Action;
  setConfigurationImages: (images: FullImage[]) => Action;
  appliedParts: Part[];
  userInactive?: boolean;
}

export interface CollapseState {
  name: string;
  open: boolean;
}
type PartDetailAllProps = PartDetailDialogProps & WithNamespaces & StateManagementProps;

export const PartDetailDialog: React.FunctionComponent<PartDetailAllProps> = (props) => {
  const { t, selectedPart, closeDialog, appliedParts, applicationSettings, configurationState, userInactive, is3DMode } = props;
  const setCollapseState = () => {
    let stateArray: CollapseState[] | null = [{ name: "Features", open: true }];
    if (selectedPart.Attributes) {
      selectedPart.Attributes.forEach((attribute: Attribute, index: number) => {
        if (stateArray) {
          stateArray.push({ name: attribute.Description, open: true });
        } else {
          stateArray = [{ name: attribute.Description, open: true }];
        }
      });
    }
    return stateArray;
  };

  const setScrollableState = () => {
    const container = document.getElementById("part-detail-texts");
    const content = document.getElementById("part-detail-content");
    let containerHeight = container ? container.offsetHeight : 0;
    let contentHeight = content ? content.offsetHeight : 0;
    return containerHeight < contentHeight;
  };

  const allImages = [selectedPart.Images && selectedPart.Images.Main ? selectedPart.Images.Main : "", ...(selectedPart.Images && selectedPart.Images.Other ? selectedPart.Images.Other : [])];
  const isIncluded = appliedParts.includes(selectedPart);
  const [imageUrl, setImageUrl] = useState(selectedPart.Images && selectedPart.Images.Main ? selectedPart.Images.Main : "");
  const [expandedStates, setExpandedStates] = useState<CollapseState[]>(() => setCollapseState());
  const [lastExpanded, setLastExpanded] = useState(0);
  const [scrollableContent, setScrollableContent] = useState(() => setScrollableState());

  const partImageStyle: React.CSSProperties = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    border: applicationSettings.Brand === "HQV" ? "1px solid #f2f2f2" : "",
  };

  const handleResize = () => {
    setScrollableContent(() => setScrollableState());
  };

  const handleExpandClick = (event: any, state: CollapseState) => {
    setExpandedStates((x) => {
      let v = x;
      if (v) {
        const index = v.findIndex((c) => c.name === state.name);
        if (index > -1) {
          v[index] = { name: state.name, open: !state.open };
        }
      }

      return v;
    });

    setLastExpanded(lastExpanded + 1);
  };

  const handleBrowserBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    closeDialog();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("popstate", handleBrowserBack, false);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, []);

  useEffect(() => {
    if (configurationState.noConflictingParts.PartId !== "" && !configurationState.noConflictingParts.DryRun) {
      closeDialog();
    }
  }, [configurationState.noConflictingParts]);

  useEffect(() => {
    
    if (configurationState.removeNoConflictingParts.PartId !== "" && !configurationState.removeNoConflictingParts.DryRun) {
      closeDialog();
    }
  }, [configurationState.removeNoConflictingParts]);

  const getCheckedAttributesArray = (array: Attribute[]) => {
    return array;
  };

  useEffect(() => {
    if (userInactive) {
      closeDialog();
    }
  }, [userInactive]);

  const renderBackButton3D = () => {
    return (
      <div className="back-button-parts-details" title={t("navigation.goBack")} onClick={closeDialog}>
        <span>
          <ArrowHead />
        </span>
        {t("navigation.goBack")}
      </div>
    );
  };

  const renderAttributes = () => {
    let DescriptionAttribute: Attribute = {
      ClassificationCategory: "DESC_PP",
      Description: "Features",
      Key: "DESC_POWERPARTS",
      Values: [{ Key: "desc", Description: selectedPart.Description }],
    };
    let currentArributesArray = [DescriptionAttribute];
    if (selectedPart.Attributes) {
      let checkedAttributesArray = getCheckedAttributesArray(selectedPart.Attributes);
      currentArributesArray = currentArributesArray.concat(checkedAttributesArray);
    }

    return (
      <>
        {currentArributesArray.map((attribute: Attribute, index: number) => {
          return (
            <>
              {attribute.Description && attribute.Description !== "" && (
                <div key={`attribute-${index}`} className={`attribute-container ${currentArributesArray!.length !== index + 1 ? "border-bottom" : ""}`}>
                  <div className="attribute-description" onClick={(e) => handleExpandClick(e, expandedStates[index])}>
                    {attribute.Description}
                    <span className={expandedStates[index].open ? "open" : "closed"}>
                      <ArrowHead />
                    </span>
                  </div>

                  <Collapse in={expandedStates[index].open} className={`attribute-value-container ${expandedStates[index].open ? "open" : "closed"}`}>
                    {attribute.Values.map((value: AttributeValues, indexValue: number) => {
                      return <div key={`attribute-value-${indexValue}`} className="attribute-value" dangerouslySetInnerHTML={{ __html: value.Description }}></div>;
                    })}
                  </Collapse>
                </div>
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <div id="part-detail-dialog-container" className={`part-detail-dialog-container details-3d`}>
      <div className="part-detail-dialog-window-wrapper">
        <div className="close-dialog-mobile show-mobile">
          <button className="primary" title={t("navigation.goBack")} onClick={closeDialog}>
            <Close />
          </button>
        </div>
        <div className="close-dialog">
          {renderBackButton3D()}
          {!selectedPart.CanBeRendered && !is3DMode && (
            <div className="nv-part">
              <NoPartPreviewIcon fontSize="inherit" className="not-visualized-icon" titleAccess={t("configuration.parts.notVisualized")} />
              {t("configuration.parts.notVisualized")}
            </div>
          )}
          <div className="mobile-category-title">{selectedPart.CategoryName}</div>
        </div>
        <div className="detail-content">
          <Grid item className="part-images-wrapper">
            {/* main image */}
            <div id="main-dialog-image-wrapper" style={partImageStyle}></div>
            {/* other images */}
            {allImages.length > 1 && (
              <Grid item className="other-images-wrapper desktop-other-images">
                {allImages.map((url: string, idx: number) => {
                  const selected = url === imageUrl ? "selected" : "";
                  const partSmallImage: React.CSSProperties = {
                    backgroundImage: `url(${url})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    width: "100%",
                    backgroundColor: "#fff",
                    border: applicationSettings.Brand === "HQV" ? "1px solid #f2f2f2" : "",
                  };
                  return (
                    <div key={"otherimg-" + idx} onClick={() => setImageUrl(url)} className={`other-dialog-image-wrapper ${selected}`}>
                      <div className="other-dialog-image" style={partSmallImage}></div>
                    </div>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <div className="part-main-desc">
            <div className="add-powerpart-in-detail-3d">
              <div className="header-part">
                <h2 id="part-detail-title-3d">{selectedPart.Name}</h2>
                <div id="part-id-title-3d" className="d-none-mobile">
                  Ref: {selectedPart.PartId}
                </div>
              </div>
            </div>
            <div className="part-detail-price-container">
              <div className={`parts-price ${(selectedPart.Price && selectedPart.Price.Value === 0) || !selectedPart.Price ? "no-price" : ""}`}>{formatter.currency(selectedPart.Price)}</div>
              <div className="button-wrapper">
                <div id="part-id-title-3d" className="show-mobile">
                  Ref: {selectedPart.PartId}
                </div>
                <div className="pp-button-wrapper">
                  <button onClick={() => togglePart(selectedPart, appliedParts)} className={isIncluded ? "remove" : "add"}>
                    <span className="button-text">{isIncluded ? t("configuration.parts.remove") : t("configuration.parts.add")}</span>

                    {isIncluded ? <Minus3D identifyer={`remove-part-details-${selectedPart.PartId}`} /> : <Plus3D identifyer={`add-part-details-${selectedPart.PartId}`} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {allImages.length > 1 && (
            <Grid item className="other-images-wrapper mobile-other-images">
              {allImages.map((url: string, idx: number) => {
                const selected = url === imageUrl ? "selected" : "";
                const partSmallImage: React.CSSProperties = {
                  backgroundImage: `url(${url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: applicationSettings.Brand === "HQV" ? "1px solid #f2f2f2" : "",
                };
                return (
                  <div key={"otherimage-" + idx} onClick={() => setImageUrl(url)} className={`other-dialog-image-wrapper ${selected}`}>
                    <div className="other-dialog-image" style={partSmallImage}></div>
                  </div>
                );
              })}
            </Grid>
          )}

          <div id="part-detail-texts" className={`part-detail-texts ${scrollableContent ? "scrollable" : ""}`}>
            <div id="part-detail-content">{renderAttributes()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(PartDetailDialog);

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const MapPin: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 40 52" fill="none" className="icon-map-pin">
      <title>Pin Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1735 43.0165L20.8615 42.2021C20.8897 42.1679 20.9485 42.0986 21.035 41.9966C22.8238 39.8886 36.4579 23.8213 36.4579 15.7702C36.4579 7.10772 29.1181 0 20.1734 0C11.2284 0 3.88889 7.10798 3.88889 15.7702C3.88889 24.2107 18.7972 41.4617 19.4853 42.2021L20.1735 43.0165ZM20.1735 1.85099C28.1243 1.85099 34.5467 8.07011 34.5467 15.7703C34.5467 22.5818 23.155 36.5751 20.1735 40.203C17.1918 36.575 5.80034 22.5818 5.80034 15.7703C5.80034 8.07051 12.2228 1.85099 20.1735 1.85099ZM25.9025 41.4269L25.9025 41.4269L25.902 41.4269H25.9025ZM25.9025 41.4269C25.3312 42.0673 24.7599 42.7076 24.1887 43.2676C33.0447 43.6679 37.8062 45.4287 37.8062 46.1489C37.8062 47.0294 31.6166 49.1101 20.0935 49.1101C8.57034 49.1101 2.38074 47.0293 2.38074 46.1489C2.38074 45.4287 7.14224 43.6679 15.9983 43.2676C15.7324 43.0067 15.4871 42.7287 15.2337 42.4414L15.2336 42.4414C14.9425 42.1114 14.6406 41.7692 14.2845 41.4269C7.61855 41.907 0 43.1877 0 46.1489C0 50.0709 13.1419 51.1111 19.9983 51.1111C26.8548 51.1111 39.9965 50.0704 39.9965 46.1489C40.1865 43.1878 32.5683 41.907 25.9025 41.4269ZM27.4444 15.6024C27.4444 19.4891 24.1724 22.7486 20 22.7486C15.8276 22.7486 12.5556 19.4891 12.5556 15.6024C12.5556 11.7156 15.8276 8.45616 20 8.45616C24.1724 8.45616 27.4444 11.7156 27.4444 15.6024ZM29.4444 15.6024C29.4444 20.6537 25.216 24.7486 20 24.7486C14.784 24.7486 10.5556 20.6537 10.5556 15.6024C10.5556 10.5511 14.784 6.45616 20 6.45616C25.216 6.45616 29.4444 10.5511 29.4444 15.6024Z"
      />
    </SvgIcon>
  );
};

export default MapPin;

export interface FormControlState<V = any> {
	value: V;
	pristine: boolean;
	validators: Validators;
	required: boolean;
	name: string;
}

export type Validator = (value: FormControlState) => boolean;

export type Validators = (DefaultValidators | string)[];

export type ValidatorType = { [key: string]: Validator };

export function createControl(name: string, value: any, validators: Validators = [], required = false): FormControlState {
	return { name, value, validators, required, pristine: true };
}

export enum DefaultValidators {
	notEmpty = 'notEmpty',
	email = 'email',
}

type SetStateType = React.Dispatch<React.SetStateAction<any>>;

const defaultValidators: ValidatorType = {
	[DefaultValidators.notEmpty]: (control: FormControlState<string>) => {
		return !!control.value.trim().length;
	},
	[DefaultValidators.email]: (control: FormControlState<string>) => {
		// eslint-disable-next-line
		const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		return !!control.value.match(pattern);
	},
}

export class FormManager {
	private validators: ValidatorType = { ...defaultValidators };
	private controls: { [key: string]: FormControlState } = {};
	private controlSetters: { [key: string]: SetStateType } = {};
	private touched = false;

	constructor(customValidators: ValidatorType = {}) {
		this.validators = { ...this.validators, ...customValidators };
	}

	public addValidator(validator: ValidatorType) {
		this.validators = { ...this.validators, ...validator };
	}

	public addAllControls(controls: Array<[FormControlState, SetStateType]>) {
		controls.forEach(
			([control, setControl]: [FormControlState, SetStateType]) => {
				this.addControl(control);
				this.addControlSetter({ [control.name]: setControl });
			}
		);
	};

	public get formIsValid(): boolean {
		return Object.keys(this.controls).every((name: string) => {
			const control = this.controls[name];
			if (!control.required || control.validators.length === 0) {
				return true;
			}
			return this._controlIsValid(control);
		});
	}

	public touchControls(controls: FormControlState[] = []) {
		// touch only selected otherwise all controls
		const controlNames = !controls.length ? Object.keys(this.controls) : controls.map(control => control.name);
		controlNames.forEach((name) => this.controlSetters[name]({ ...this.controls[name], pristine: false }));
		this.touched = true;
	}

	public controlIsValid(control: FormControlState): boolean {
		return this._controlIsValid(this.controls[control.name]);

	}

	public getHashValues<T = any>(): T {
		return Object
			.keys(this.controls)
			.reduce((acc, formName) => {
				return {
					...acc,
					[formName]: this.controls[formName].value,
				}
			}, {} as T);

	}

	public get formIsTouched() {
		return this.touched;
	}

	public reset() {
		this.touched = false;
	}

	private addControlSetter(controlSetter: { [key: string]: SetStateType }) {
		this.controlSetters = {
			...this.controlSetters,
			...controlSetter
		};
	}

	private addControl(control: FormControlState) {
		this.controls = {
			...this.controls,
			[control.name]: control
		};
	}

	private _controlIsValid(control: FormControlState): boolean {
		return control.validators.every((validatorKey: string) => {
			if (!(this.validators as Object).hasOwnProperty(validatorKey)) {
				throw new Error(`A validator with key: ${validatorKey} does not exist in FormManager validators!`);
			}
			return this.validators[validatorKey](control);
		});
	}
}

import React from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import Tick from "../../assets/icons/tick";
import { ColorVarations } from "../../models/vehicle";
import Dot from "../../assets/icons/dot";

export interface ColorSelectionProps {
  ColorArray?: ColorVarations[];
  changeBikeColor?: (VariationCode: string, ColorCode: string) => void;
}

const ColorSelection: React.FunctionComponent<StateManagementProps & WithNamespaces & ColorSelectionProps> = (props) => {
  const { t, ColorArray, changeBikeColor, currentVariation, is3DMode } = props;

  if(ColorArray && changeBikeColor) {
    return (
      <>
        {ColorArray.length > 1 && (
          <div className={`color-selection-container ${is3DMode && "colors-3D"}`}>
            <div className="color-selection-title">{t("configuration.colorSwitcherHeading")}</div>
  
            <div className={`sum-${ColorArray.length} color-selection`}>
              {ColorArray.map((color, index) => (
                <span
                  key={color.Code}
                  className={`color-${color.ColorCode} color-selector ${currentVariation === color.Code && "active"}`}
                  onClick={() => changeBikeColor(color.Code, color.ColorCode)}
                >
                  {currentVariation === color.Code && <Dot />}
                </span>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  return null;

};

export default connect(mapStateToProps, mapDispatchToProps)(ColorSelection);

import React, { useState } from "react";
import { useEffect } from "react";
import AddCustomColorIcon from "../../../assets/icons/xbow/add_custom_color";
import { ColorOption } from "../../../models/vehicle";
import HSLAColorSlider from "./colorSlider";
import XBowColorFinishSelector from "./xBowColorFinishSelection";
import XBowCustomColorHexCode from "./XBowCustomColorHexCode";

export interface XBowColorSelectorProps {
  selectedColor: ColorOption;
  colorSelectionCategoriesModel: string;
  predefinedColors: ColorOption[];
  setPredefinedColor: (color: ColorOption) => void;
  customColors: ColorOption[];
  addCustomColor: (color: ColorOption) => void;
  setCustomColor: (color: ColorOption) => void;
  colorFinish: string;
  updateColorFinish: () => void;
  isMobile: boolean;
  closeColorSliderOnBackButton: boolean;
  setCloseColorSliderOnBackButton: (isColorSliderOpen: boolean) => void;
  setIsCustomColor: (isCustomColor: boolean) => void;
}

const XBowColorSelector: React.FunctionComponent<XBowColorSelectorProps> = (props) => {
  const {
    closeColorSliderOnBackButton,
    setCloseColorSliderOnBackButton,
    isMobile,
    selectedColor,
    colorSelectionCategoriesModel,
    predefinedColors,
    setPredefinedColor,
    customColors,
    addCustomColor,
    setCustomColor,
    colorFinish,
    updateColorFinish,
    setIsCustomColor,
  } = props;

  const [currentSelectedColor, setCurrentSelectedColor] = useState(selectedColor);
  const [isCustomColorSelected, setCustomColorSelected] = useState(selectedColor.RenderColorCode.includes("CUSTOM_COLOR"));
  const [hideColorSquaresForMobile, setHideColorSquaresForMobile] = useState(false);
  const [hideCustomColorSelectionSliderForMobile, setHideCustomColorSelectionSliderForMobile] = useState(true);

  const displayCustomColorSelection = colorSelectionCategoriesModel !== "Rims";
  const displayMatteGlossySection = colorSelectionCategoriesModel === "Body";
  const isSuspensionAndLogo = colorSelectionCategoriesModel === "Suspension and Logo";
  const displayRimSize = colorSelectionCategoriesModel === "Rims";

  useEffect(() => {
    setCurrentSelectedColor(selectedColor);
    setCustomColorSelected(() => {
      const isCustomColor = selectedColor.RenderColorCode.includes("CUSTOM_COLOR");
      if (isMobile) {
        if (isCustomColor) {
          setCloseColorSliderOnBackButton(true);
        }
      }
      setIsCustomColor(isCustomColor);
      return isCustomColor;
    });
  }, [selectedColor]);

  useEffect(() => {
    if (closeColorSliderOnBackButton) {
      setHideColorSquaresForMobile(true);
      setHideCustomColorSelectionSliderForMobile(false);
    } else {
      setHideColorSquaresForMobile(false);
      setHideCustomColorSelectionSliderForMobile(true);
    }
  }, [closeColorSliderOnBackButton]);

  const setCustomColorInColorSelector = (color: ColorOption) => {
    setCustomColor(color);
  };

  const renderPredefinedColor = (predefinedColor: ColorOption, index: number) => {
    return (
      <div
        className={`color-square-container ${predefinedColor.ColorName === currentSelectedColor.ColorName && predefinedColor.RimSize === currentSelectedColor.RimSize ? "active" : ""}`}
        key={`pedefined-color-${index}${predefinedColor.RimSize ? "-" + predefinedColor : ""}`}
      >
        <div
          title={predefinedColor.ColorName}
          className={`color-square ${isSuspensionAndLogo ? "isSuspensionAndLogo" : ""} ${predefinedColor.FrontendColorCode.includes("png") ? "carbon-fiber" : ""} ${isMobile ? "mobile-square" : ""}`}
          style={
            predefinedColor.FrontendColorCode.includes("png")
              ? {
                  background: `url(/images/3d/` + predefinedColor.FrontendColorCode + `)`,
                  backgroundSize: "cover",
                  transform: "rotate(90deg)",
                }
              : { backgroundColor: predefinedColor.FrontendColorCode }
          }
          onClick={() => {
            setPredefinedColor(predefinedColor);
          }}
        ></div>
      </div>
    );
  };

  const getColorTitle = () => {
    return `/ ${currentSelectedColor.ColorName}`;
  };

  return (
    <div className="color-selection">
      <div className="color-squares-section">
        <div className={`color-squares ${hideColorSquaresForMobile ? "hide" : ""}`}>
          {displayRimSize && <div className="color-heading">19/20'' &#40;5-Bolt&#41;</div>}
          {predefinedColors.map((predefinedColor, index) => (!displayRimSize || predefinedColor.RimSize === 19) && <>{renderPredefinedColor(predefinedColor, index)}</>)}

          {displayCustomColorSelection && (
            <>
              {customColors.map((customColor, index) => (
                <div className={`color-square-container ${customColor.ColorName === currentSelectedColor.ColorName ? "active" : ""}`} key={`customColor-color-${index}`}>
                  <div title={customColor.FrontendColorCode} className={`color-square ${isMobile ? "mobile-square" : ""}`} style={{ backgroundColor: customColor.FrontendColorCode }} onClick={() => setCustomColor(customColor)}></div>
                </div>
              ))}

              {customColors.length <= 2 && (
                <div className="color-square-container">
                  <div
                    className={`color-square add-custom-color ${isMobile ? "mobile-square" : ""}`}
                    onClick={() => {
                      const newColor: ColorOption = {
                        ColorName: `Individual Color ${customColors.length + 1}`,
                        FrontendColorCode: "#785DB2",
                        RenderColorCode: "CUSTOM_COLOR",
                        IsMatte: false,
                      };
                      addCustomColor(newColor);
                      setCustomColor(newColor);
                    }}
                  >
                    <AddCustomColorIcon />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {displayRimSize && (
          <div className={`color-squares ${hideColorSquaresForMobile ? "hide" : ""}`}>
            <div className="color-heading">20/21'' &#40;Center Lock&#41;</div>
            {predefinedColors.map((predefinedColor, index) => predefinedColor.RimSize === 20 && renderPredefinedColor(predefinedColor, index))}
          </div>
        )}

        {isMobile && !hideCustomColorSelectionSliderForMobile && (
          <div className="color-slider-container">
            <HSLAColorSlider selectedColor={currentSelectedColor} setSelectedColor={setCustomColorInColorSelector} isMobile={isMobile} />
            <div className={`color-slider-mobile-square-container`}>
              <div className="color-slider-mobile-square" style={{ backgroundColor: currentSelectedColor.FrontendColorCode }}></div>
            </div>
          </div>
        )}

        <div className="color-name-container">
          <span className="color-name">
            <p className="text">{getColorTitle()}</p>
          </span>
          {isCustomColorSelected && isMobile && <XBowCustomColorHexCode hexCode={currentSelectedColor.FrontendColorCode} display={true} isMobile={isMobile} />}
        </div>
      </div>

      {!isMobile && (
        <>
          <div className="horizontal-line"></div>

          <div className="custom-color-selection-section">
            {isCustomColorSelected && (
              <>
                <div className="display-section">
                  <XBowColorFinishSelector colorFinish={colorFinish} updateColorFinish={updateColorFinish} display={displayMatteGlossySection} isMobile={isMobile} />
                  <XBowCustomColorHexCode hexCode={currentSelectedColor.FrontendColorCode} display={true} isMobile={isMobile} />
                </div>

                <div className="color-slider-container">
                  <HSLAColorSlider selectedColor={currentSelectedColor} setSelectedColor={setCustomColorInColorSelector} isMobile={isMobile} />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default XBowColorSelector;

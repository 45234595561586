import { SHOW_TOAST, HIDE_TOAST, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import Action from './models/action';

export interface ToastMessageState {
	visible: boolean;
	message: string
}

export const initialState: ToastMessageState = {
	visible: false,
	message: '',
};

export default function ToastMessageReducer(state: ToastMessageState = initialState, action: Action): ToastMessageState {
	switch (action.type) {
		case SHOW_TOAST: {
			const message: string = action.payload;
			return { visible: true, message };
		}
		case HIDE_TOAST: {
			return { ...state, visible: false };
		}
		case EXIT_VEHICLE_CONFIGURATION: {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
}
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Hand: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 63.074 65.813">
      <title>Hand</title>
      <g id="hand" data-name="Gruppe 1600" transform="translate(-10.661 -0.016)">
        <path
          id="Pfad_1016"
          data-name="Pfad 1016"
          d="M17.517,13.725a1.377,1.377,0,0,1-.971-.4L11.062,7.84a1.372,1.372,0,0,1,0-1.939L16.547.417a1.371,1.371,0,0,1,1.939,1.939L13.972,6.87l4.514,4.514a1.372,1.372,0,0,1,0,1.939A1.358,1.358,0,0,1,17.517,13.725Z"
          transform="translate(0)"
        />
        <path
          id="Pfad_1017"
          data-name="Pfad 1017"
          d="M438.7,13.725a1.37,1.37,0,0,1-.971-2.339l4.517-4.517-4.514-4.514A1.371,1.371,0,0,1,439.671.417L445.155,5.9a1.372,1.372,0,0,1,0,1.939l-5.485,5.485A1.377,1.377,0,0,1,438.7,13.725Z"
          transform="translate(-371.821)"
        />
        <path id="Pfad_1018" data-name="Pfad 1018" d="M369.825,45.409H353.371a1.371,1.371,0,1,1,0-2.742h16.454a1.371,1.371,0,0,1,0,2.742Z" transform="translate(-297.462 -37.168)" />
        <path id="Pfad_1019" data-name="Pfad 1019" d="M28.492,45.409H12.038a1.371,1.371,0,0,1,0-2.742H28.492a1.371,1.371,0,1,1,0,2.742Z" transform="translate(-0.005 -37.168)" />
        <g id="icon-hand">
          <path
            id="icon-hand-path"
            data-name="Pfad 1020"
            d="M98.056,121.588H91.631a12.259,12.259,0,0,1-8.726-3.614L63.374,98.443a4.116,4.116,0,0,1,0-5.816,7.189,7.189,0,0,1,9.348-.664l4.766,3.573V72.227a5.483,5.483,0,0,1,8.534-4.558,5.486,5.486,0,0,1,10.662,1.815V83.934a5.44,5.44,0,0,1,2.742-.738,5.5,5.5,0,0,1,5.177,3.669,5.484,5.484,0,0,1,8.534,4.558v15.083A15.1,15.1,0,0,1,98.056,121.588ZM68.439,93.282a4.406,4.406,0,0,0-3.123,1.283,1.372,1.372,0,0,0,0,1.939l19.531,19.531a9.525,9.525,0,0,0,6.784,2.811h6.425a12.355,12.355,0,0,0,12.34-12.34V91.423a2.742,2.742,0,0,0-5.484,0v1.371a1.371,1.371,0,1,1-2.742,0V88.681a2.742,2.742,0,1,0-5.484,0v4.113a1.371,1.371,0,0,1-2.742,0V69.485a2.742,2.742,0,1,0-5.484,0V92.794a1.371,1.371,0,0,1-2.742,0V72.227a2.742,2.742,0,1,0-5.484,0V98.279a1.371,1.371,0,0,1-2.194,1.1L71.08,94.157A4.4,4.4,0,0,0,68.439,93.282Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Hand;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const User: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 75 90" fill="none" className="icon-user">
      <title>User Icon</title>
      <path d="M37.5 48C43.7519 48 49.6487 45.487 54.097 40.9239C58.5484 36.3608 61 30.3193 61 23.9106C61 10.7259 50.4555 0 37.5 0C31.2481 0 25.3513 2.51304 20.903 7.0761C16.4547 11.6392 14 17.6807 14 24.0894C14 37.2741 24.5414 48 37.5 48ZM37.5 5.62001C47.4339 5.62001 55.5175 13.9063 55.5175 24.0894C55.5175 34.2725 47.4339 42.5588 37.5 42.5588C27.5661 42.5588 19.4825 34.2725 19.4825 24.0894C19.4825 13.9063 27.5661 5.62001 37.5 5.62001Z" />
      <path d="M72.1436 76.7417C70.2917 72.1302 67.6143 68.0548 64.1898 64.6302C57.3313 57.7748 47.852 54 37.5 54C27.148 54 17.6718 57.7748 10.8102 64.6302C7.38261 68.0548 4.70829 72.1302 2.85637 76.7417C1.20532 80.8512 0.24797 85.3017 0 90H5.54009C6.07684 81.9174 9.24395 74.5289 14.5894 68.9876C20.5187 62.8388 28.6547 59.4545 37.5 59.4545C46.3453 59.4545 54.4813 62.8388 60.4106 68.9876C65.7561 74.5289 68.92 81.9174 69.4568 90H75C74.752 85.3017 73.7947 80.8543 72.1436 76.7417Z" />
    </SvgIcon>
  );
};

export default User;

import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { Product } from "../setups/SetupsList";
import { DialogProps } from "./dialogRouter";
import FadeInLetters from "../shared/FadeInLetters";

export const SetupPartsDialog: React.FunctionComponent<DialogProps> = ({ data, title, hideDialog }) => {
  return (
    <Dialog open={true} onClose={hideDialog} aria-labelledby="setup-parts-modal">
      <DialogTitle id="setup-parts-dialog-title">
        <Grid container justify="space-between" direction="row">
          <h2 className="setups-headline underline pathaway-font uppercase">
            <FadeInLetters text={title} />
          </h2>
          <IconButton className="dialog-hide-icon" onClick={hideDialog}>
            <Clear />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List>
          {data.map((part: Product, idx: number) => (
            <ListItem className="setup-part" key={`part-${idx}`}>
              {part.Name.toUpperCase()}
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SetupPartsDialog;

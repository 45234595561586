import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const GG_Logo: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 477.63 59.41">
      <title>GasGas Logo</title>
    <path d="M134.39,59.41h22.42L140.25,0h-23L69.89,59.41H93l7.56-10.64H131.7ZM110,35.44l13.53-19,4.81,19Z"/>
    <path d="M225.06,22.85H188.59a3.42,3.42,0,0,1-3.24-4.47l.37-1.15a6,6,0,0,1,5.72-4.15h46.7l-.09-3.87c0-6-5.25-9.21-9.92-9.21H186.34c-5.16,0-15.62,3.33-17.78,10.94l-3.37,11.54c-2.84,8.46,2.79,13,8.42,13h37.31a3.7,3.7,0,0,1,3.54,4.85l-.41,1.26a6.75,6.75,0,0,1-6.25,4.73H159.5l-.3,5.54c-.09,3.81,3.83,7.59,8.5,7.59h46.67c4.31,0,10.72-3.19,13.79-6.78s6.39-15.05,6.43-20.5A9.44,9.44,0,0,0,225.06,22.85Z"/>
    <path d="M26.78,19.13a8.73,8.73,0,0,1,8.31-6H82.52l-.1-3.87c0-6-5.24-9.21-9.91-9.21H30.77C23,0,12.57,5.41,10.43,12L.68,42.35c-2.14,6.58.62,17.06,10.5,17.06h46c9.17,0,15.09-8.2,17.23-14.78L81.3,22.85H40.09L35.84,36.18H58.71L57,41.27c-.89,2.74-4.52,5-7.37,5H24.39a4.73,4.73,0,0,1-4.5-6.17Z"/>
    <path d="M373.88,59.41H396.3L379.73,0h-23L309.38,59.41h23.07L340,48.77h31.19Zm-24.41-24,13.52-19,4.82,19Z"/>
    <path d="M464.55,22.85H428.08a3.42,3.42,0,0,1-3.24-4.47l.37-1.15a6,6,0,0,1,5.72-4.15h46.7l-.1-3.87c0-6-5.24-9.21-9.91-9.21H425.83C420.67,0,410.2,3.33,408,10.94l-3.37,11.54c-2.84,8.46,2.8,13,8.43,13h37.31A3.7,3.7,0,0,1,454,40.29l-.41,1.26a6.75,6.75,0,0,1-6.26,4.73H399l-.3,5.54c-.1,3.81,3.83,7.59,8.5,7.59h46.67c4.31,0,10.72-3.19,13.79-6.78s6.39-15.05,6.43-20.5A9.44,9.44,0,0,0,464.55,22.85Z"/>
    <path d="M266.26,19.13a8.75,8.75,0,0,1,8.31-6H322l-.09-3.87c0-6-5.24-9.21-9.91-9.21H270.25c-7.74,0-18.19,5.41-20.33,12l-9.75,30.36c-2.14,6.58.62,17.06,10.5,17.06h45.94c9.18,0,15.1-8.2,17.24-14.78l6.94-21.78H279.58l-4.25,13.33H298.2l-1.71,5.09c-.89,2.74-4.53,5-7.37,5H263.88a4.73,4.73,0,0,1-4.5-6.17Z"/>
    </SvgIcon>
  );
};

export default GG_Logo;

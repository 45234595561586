import React, { useEffect, useState } from "react";
import { translate, WithNamespaces } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { ShowDialogProps } from "../../../redux/actions";
import { DialogTypes } from "../../../enums/dialogTypes";
import { hashCode, handlePDFData } from "../../../utils/helpers";
import PDF from "../../../assets/icons/pdf";
import Share from "../../../assets/icons/share";
import http from "../../../services/http";
import ConfigurationSummaryHeader from "./ConfigurationSummaryHeader";
import CdcLogin from "../cdc/CdcLogin";
import CheckOk from "../../../assets/icons/check_ok";
import { Is3DProp } from "./ConfigurationSummary";
import { StateManagementProps } from "../../../utils/addReduxProps";
import { RouteComponentProps } from "react-router";

export interface ScreenshotPDFProps {
  takeScreenshotAndDownloadPDF?: (onStartedCallback?: () => void, onFinishedCallback?: () => void) => void;
}

export type ConfigurationCompletionProps = StateManagementProps & WithNamespaces & Is3DProp & RouteComponentProps<{ modelId: string }> & ScreenshotPDFProps;

export const ConfigurationCompletion: React.FunctionComponent<ConfigurationCompletionProps> = props => {
  const { t, showDialog, applicationSettings, takeScreenshotAndDownloadPDF, updatesetShowSummary, dealerInquirySent, setDealerInquirySent, history, is3DMode, selectedModel, parts, currentVariation } =
    props;

  enum PDF_STATUS {
    Initial,
    Processing,
    Done,
  }

  const [pdfStatus, setPdfStatus] = useState<PDF_STATUS>(PDF_STATUS.Initial);

  const brand = applicationSettings.Brand;

  const [loaded, setLoaded] = useState(true);
  const [shareClicked, setShareClicked] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  // use useEffect to access lifecycle methods, as componentDidMount etc. are not available on function components.
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        // Code here will run when back button fires. Note that it's after the `return` for useEffect's callback; code before the return will fire after the page mounts, code after when it is about to unmount.
        console.debug("BROWSER BACK", history.location.pathname);
        // updatesetShowSummary && updatesetShowSummary();

        if (dealerInquirySent) {
          setDealerInquirySent(false);
        }
      }
      if (history.location.pathname === "/main") {
        if (dealerInquirySent) {
          setDealerInquirySent(false);
        }
      }
    };
  }, []);

  /**
   * This function handles the pdf download for all the summary pages and the summary header
   * if the header is used set the ignoreState to true so that the following summary page is not messed up
   * TOOD needs more cleanup
   */
  const downloadPdf = (ignoreState: boolean) => {
    onStartedCallback();
    let partsHash: string = hashCode(selectedModel, currentVariation, parts) as string;
    setLoaded(false);
    http
      .downloadConfigurationSummaryPdf(partsHash)
      .then(response => {
        handlePDFData(response);
      })
      .finally(() => {
        setLoaded(true);
        !ignoreState && onFinishedCallback();
      });
  };

  const shareConfiguration = () => {
    const dialogProps: ShowDialogProps = {
      title: `${t("dialogs.shareConfiguration.title")}`,
      data: {},
      contentType: DialogTypes.shareConfiguration,
    };
    showDialog(dialogProps);
    props.onShareLinkSuccess && props.onShareLinkSuccess();
  };

  const handlePDFClick = () => {
    if (is3DMode) {
      takeScreenshotAndDownloadPDF && takeScreenshotAndDownloadPDF(onStartedCallback, onFinishedCallback);
    } else {
      downloadPdf && downloadPdf(false);
    }
  };

  const onStartedCallback = () => {
    setPdfStatus(PDF_STATUS.Processing);
  };

  const onFinishedCallback = () => {
    setPdfStatus(PDF_STATUS.Done);
  };

  return (
    <>
      <ConfigurationSummaryHeader loaded={loaded} updatesetShowSummary={updatesetShowSummary} />

      <div className="configurationSummary3dContainer flex-container column full-width">
        <div className="checkIconContainer flex-container centered">
          <CheckOk />
        </div>

        <div className="headingTextContainer flex-container centered column">
          {brand === "KTM" ? (
            <>
              <div className="headingTextOne uppercase">{t("configuration.summary.screen.title_one")}</div>
              <div className="headingTextTwo uppercase">{t("configuration.summary.screen.title_two")}</div>
            </>
          ) : (
            <div className="headingText-hqv">{t("configuration.summary.screen.title_hqv")}</div>
          )}
        </div>

        <div className="descTextContainer flex-container centered column">
          <div className={`descText ${brand === "KTM" ? "uppercase" : ""}`}>{t("configuration.summary.screen.text_desc_one")}</div>
          <div className={`descText ${brand === "KTM" ? "uppercase" : ""}`}>{t("configuration.summary.screen.text_desc_two")}</div>
        </div>

        <div
          className={`summary-ctas flex-container justify-evenly full-width 
            ${pdfStatus === PDF_STATUS.Done ? "pdfClicked" : ""}
            ${shareClicked ? "shareClicked" : ""}
            ${saveClicked ? "saveClicked" : ""}
          `}
        >
          <div className="flex-container centered column">
            {pdfStatus.valueOf() !== PDF_STATUS.Processing.valueOf() ? (
              <a className="pdf-button" onClick={handlePDFClick} target="_blank">
                <PDF />
              </a>
            ) : (
              <CircularProgress className="spinner pdfBusySummarySpinner" />
            )}

            <div className="summary-cta-text pdf">{pdfStatus === PDF_STATUS.Done ? t("configuration.summary.screen.pdfDownloaded") : t("configuration.summary.screen.pdfDownload")}</div>
          </div>

          <div className="flex-container centered column">
            <a
              className="share-button"
              onClick={() => {
                setShareClicked(true);
                shareConfiguration();
              }}
            >
              <Share />
            </a>
            <div className="summary-cta-text share">{!shareClicked ? t("configuration.summary.screen.share") : t("configuration.summary.screen.shared")}</div>
          </div>

          {applicationSettings.CdcApplicationSettings.CdcEnabled && (
            <div className="flex-container centered column">
              <CdcLogin
                {...props}
                mode="saveUserConfigurationCDC"
                showSaveIcon={true}
                onSaveIconClicked={() => {
                  !saveClicked && setSaveClicked(true);
                }}
              />
              <div className="summary-cta-text save">{!saveClicked ? t("configuration.summary.screen.save") : t("configuration.summary.screen.saved")}</div>
            </div>
          )}
        </div>

        <div className="button-container flex-container centered">
          <div
            className={`primary configuration-summary-button-3d ${brand === "KTM" ? "uppercase" : ""}`}
            onClick={() => {
              updatesetShowSummary && updatesetShowSummary();
              setDealerInquirySent(false);
              history.push("/main");
            }}
          >
            {t("configuration.summary.screen.newconfiguration")}
          </div>
        </div>
      </div>
    </>
  );
};

export default translate()(ConfigurationCompletion);

import store from "../redux/store";
import {
  getCulture as getCultureSelector,
  getMarket as getMarketSelector,
  getPreservedSaveDetailsDataSelector,
  getMotorcycleModels,
  getConfigurationWasRecentlyCreatedSelector,
  getConfigurationIsDirtySelector,
  getConfiguredPartsCount,
} from "../redux/selectors";
import { Part } from "../components/parts/PartsList";
import { hideToast, ShowDialogProps, showToast } from "../redux/actions";
import { getVehicleInformation } from "../redux/reducers/models";
import { SaveDetailsData } from "../components/dialogs/saveConfiguration";
import { Price, VehicleInformation } from "../models/vehicle";
import { DialogTypes } from "../enums/dialogTypes";
import { DispatchToProps, StateToProps } from "./addReduxProps";
import { WithNamespaces } from "react-i18next";
import { ConfigOverviewMenuProps } from "../components/configuration/ConfigurationOverviewMenu";
import { Is3DProp, ShowAllPrices, TestRideProps } from "../components/configuration/configurationSummary/ConfigurationSummary";
import { RouteComponentProps } from "react-router";
import html2canvas from "html2canvas";
import { getCopyright } from "../components/navigation/copyright";
import contentDisposition from "content-disposition";
import { saveAs } from "file-saver";

let timeoutHolder: NodeJS.Timeout;

// use for debug messages that should stay in the source code
export function debug(msg: string, params?: any) {
  // TODO: disable again log outs for production
  if (process.env.REACT_APP_ENVIRONMENT === "DEV") console.log("Debug=>", msg, params);
}

export function info(msg: string, params?: any) {
  // TODO: disable again log outs for production
  if (process.env.REACT_APP_ENVIRONMENT === "DEV") console.info("Info=>", msg, params);
}

export function error(msg: string, params?: any) {
  // TODO: disable again log outs for production
  if (process.env.REACT_APP_ENVIRONMENT === "DEV") console.error("Info=>", msg, params);
}

export function toastMessage(message: string) {
  // make sure no previous toasts are visible
  timeoutHolder && clearTimeout(timeoutHolder);
  store.dispatch(hideToast());
  store.dispatch(showToast(message));
  timeoutHolder = setTimeout(() => store.dispatch(hideToast()), 1900);
}

export function getAppliedPartsCount(): number {
  return getConfiguredPartsCount(store.getState());
}

export function getConfigurationId(): number {
  return store.getState().configurationState.configuration.ConfigurationId;
}

export function getEntryPointUrl() {
  return store.getState().configurationState.entryPointUrl;
}

export function getCulture(): string {
  return getCultureSelector(store.getState());
}

export function getMarket(): string {
  return getMarketSelector(store.getState());
}

export function getPreservedSaveDetailsData(): SaveDetailsData {
  return getPreservedSaveDetailsDataSelector(store.getState());
}

export const getConfigurationWasRecentlyCreated = () => getConfigurationWasRecentlyCreatedSelector(store.getState());

export const getConfigurationIsDirty = () => getConfigurationIsDirtySelector(store.getState());

function getPartsValue(parts: Part[]) {
  return parts.reduce((acc: number, part: Part) => {
    return acc + (part.Price === null ? 0 : part.Price.Value);
  }, 0);
}

export function getVehicleById(id: string): VehicleInformation | undefined {
  return getVehicleInformation(getMotorcycleModels(store.getState())).find((item: VehicleInformation) => item.ModelId === id);
}

export function getLocaleQuery() {
  const { culture, market, companyId } = store.getState().configurationState;
  return `?cid=${companyId}&c=${culture}&m=${market}`;
}

export function sortAscendingByProperty<T extends { [key: string]: string }>(key: keyof T) {
  return (previous: T, next: T) => {
    const Previous = previous[key].toUpperCase();
    const Next = next[key].toUpperCase();
    return Previous > Next ? 1 : Previous < Next ? -1 : 0;
  };
}

export function RGBToHSL(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  // Calculate hue
  // No difference
  if (delta === 0) h = 0;
  // Red is max
  else if (cmax === r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax === g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;
  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h: h, s: s, l: l };
}

export function isPortraitMode() {
  return window.innerHeight > window.innerWidth;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const setHexToHSLA = (hex: string) => {
  const rgb = hexToRgb(hex);
  const rgbChecked = {
    r: rgb && rgb.r ? rgb.r : 0,
    g: rgb && rgb.g ? rgb.g : 0,
    b: rgb && rgb.b ? rgb.b : 0,
  };
  const hsl = RGBToHSL(rgbChecked.r, rgbChecked.g, rgbChecked.b);

  return { h: hsl.h, s: hsl.s, l: hsl.l, a: 1 };
};

export function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function sendConfigurationToDealer(
  props:
    | (StateToProps & DispatchToProps & WithNamespaces & ConfigOverviewMenuProps)
    | (StateToProps & DispatchToProps & WithNamespaces & TestRideProps & ShowAllPrices & Is3DProp & RouteComponentProps)
) {
  const dialogProps: ShowDialogProps = {
    title: `${props.t("configuration.offer.dialogtitle")}`,
    data: { ...props },
    contentType: DialogTypes.sendConfigurationToDealer,
  };
  props.showDialog(dialogProps);
  props.onSendToDealerSuccess && props.onSendToDealerSuccess();
}

export async function generateRawScreenshot(portraitMode: boolean, mobileMode: boolean, drawOnElement: HTMLElement, video: HTMLVideoElement, brand: string | undefined): Promise<string> {
  console.log("generateRawScreenshot:::portraitMode", portraitMode);
  console.log("generateRawScreenshot:::mobileMode", mobileMode);

  if (portraitMode && mobileMode) {
    const mobileCanvas = document.createElement("canvas") as HTMLCanvasElement;
    const mobileContext = mobileCanvas.getContext("2d") as any;

    // set canvas 1080x1080 (squared)
    mobileCanvas.width = 1080;
    mobileCanvas.height = 1080;
    // move drawing entry point below bottom left corner of canvas
    mobileContext.translate(0, 1560); // this can be experimented with - it's 1920- something so that the bike is aligned in the center.
    // rotate coordinate system 270 degrees so that bike is aligned properly
    mobileContext.rotate((270 * Math.PI) / 180);
    mobileContext.drawImage(video, 0, 0);
    mobileContext.translate(0, 0); // reset coordinate system !!
    mobileContext.rotate((90 * Math.PI) / 180);

    const copyright = getCopyright(brand);

    drawOnElement.appendChild(mobileCanvas);
    const copyrightDiv = document.createElement("div");
    copyrightDiv.setAttribute("style", "color:white;position:absolute;left:16px;bottom:16px; width: 100%; text-align:center; font-size: 0.75rem; font-weight: 400;");
    copyrightDiv.textContent = "" + copyright;

    drawOnElement.setAttribute("style", "position:fixed;width:1080px;height:1080px;top:0,left:0;visibility:visible");
    drawOnElement.appendChild(copyrightDiv);

    return html2canvas(drawOnElement, { allowTaint: true }).then((canvas) => {
      // cleanup and reset properly
      drawOnElement.setAttribute("style", "position:fixed;width:1080px;height:1080px;top:0,left:0;visibility:hidden");
      drawOnElement.removeChild(copyrightDiv);
      drawOnElement.removeChild(mobileCanvas);
      return canvas.toDataURL();
    });
  } else {
    return html2canvas(video, { allowTaint: true }).then(async (canvas) => {
      info("takeScreenshot video captured");
      drawOnElement.setAttribute("style", "position:fixed;top:0;right:0;visibility:visible");

      const copyright = getCopyright(brand);
      const initialInnerHTML = drawOnElement.innerHTML;
      drawOnElement.innerHTML = "";
      canvas.setAttribute("style", "margin:-7px;"); //removes the 7px white line on bottom
      drawOnElement.appendChild(canvas);
      const copyrightDiv = document.createElement("div");
      copyrightDiv.setAttribute("style", "color:white;position:absolute;left:16px;bottom:16px; width: 100%; text-align:center; font-size: 0.75rem; font-weight: 400;");
      copyrightDiv.textContent = "" + copyright;
      drawOnElement.appendChild(copyrightDiv);

      const logoDiv = document.createElement("div");
      logoDiv.setAttribute("style", "position:fixed; top:0; right:20px;");
      logoDiv.innerHTML = initialInnerHTML;
      drawOnElement.appendChild(logoDiv);

      const promise = html2canvas(drawOnElement, { allowTaint: true }).then((canvas) => {
        const data: string = canvas.toDataURL();
        drawOnElement.innerHTML = initialInnerHTML;
        drawOnElement.setAttribute("style", "position:fixed;top:0;right:0;visibility:hidden");
        return data;
      });

      return await promise;
    });
  }
}

export function generateScreenshotForPDFGeneration(portraitMode: boolean, mobile: boolean, video: HTMLElement): Promise<string> | undefined {
  if (mobile && portraitMode) {
    const mobileCanvas = document.createElement("canvas");
    const mobileContext = mobileCanvas.getContext("2d") as any;

    // set canvas 1080x1080 (squared)
    mobileCanvas.width = 1080;
    mobileCanvas.height = 1080;
    // move drawing entry point below bottom left corner of canvas
    mobileContext.translate(0, 1560); // this can be experimented with - it's 1920- something so that the bike is aligned in the center.
    // rotate coordinate system 270 degrees so that bike is aligned properly
    mobileContext.rotate((270 * Math.PI) / 180);
    mobileContext.drawImage(video, 0, 0);

    const mobileStringifiedScreenshot = mobileCanvas.toDataURL();
    return new Promise<string>((resolve, reject) => {
      resolve(mobileStringifiedScreenshot);
    });
  } else {
    return html2canvas(video, { allowTaint: true }).then((canvas) => {
      return canvas.toDataURL();
    });
  }
}

export function getIsMobile(): boolean {
  return window.innerWidth < 1024;
}

export function getIsMobileXS(): boolean {
  return window.innerWidth < 769;
}

export function uniqBy(arr, predicate) {
  const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
}

export const hashCode = (selectedModel: VehicleInformation, currentVariation: string, parts: Part[]) => {
  var s: string = selectedModel.ModelId + currentVariation;
  parts.forEach((part: Part) => {
    s += part.Images.Main;
  });
  var h = 0,
    l = s.length,
    i = 0;

  if (l > 0) {
    while (i < l) {
      h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    }
    return h;
  }
  return s;
};

 export const hashCodeScreenShot = (s: string) => {
    var h = 0,
      l = s.length,
      i = 0;

    if (l > 0) {
      while (i < l) {
        h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
      }
      return h;
    }
    return s;
  };

export const handlePDFData = (response:any) => {
  
  var headers = response.headers;
  var headersArray = Object.entries(headers);
  var contentDispos: any;
  headersArray.forEach(([key, value]) => {
    if (key === "content-disposition") {
      contentDispos = value;
    }
  });

  var blob = response.data;
  var disposition = contentDisposition.parse(contentDispos);
  var fileName = disposition.parameters.filename ? disposition.parameters.filename : "configuration.pdf";
  saveAs(blob, fileName);
}

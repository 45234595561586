import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import Swipeable from "react-swipeable";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { FullImage } from "../configuration/ConfigurationView";
import { Grid } from "@material-ui/core";
import FullImageIcon from "../../assets/icons/show_full_image";
import { useHistory, StaticContext } from "react-router";
import { getConfigurationId } from "../../utils/helpers";
import { VehicleRenderer } from "./VehicleRenderer";
import ArrowHead from "../../assets/icons/arrowHead";

export interface VehicleImageProps {
  useDarkFont?: boolean;
  imageClassName?: string;
  arrowStyle?: object;
  pathname?: any;
  closePreview?: string;
  onlyFirstImg?: boolean;
  sideImage?: true;
  history?: History;
  changeRenderedImage?: boolean;
  resetRenderedPictureChange?: () => void;
}

export type VehicleImageAllprops = VehicleImageProps & StateManagementProps & StaticContext & WithNamespaces;

export const VehicleImage: React.FunctionComponent<VehicleImageAllprops> = (props: VehicleImageAllprops) => {
  const { applicationSettings, perspective, resetRenderedPictureChange } = props;
  const [imageSrc, setImageSrc] = useState("");

  let useHistoryValue: any = useHistory();

  let history = props.history ? props.history : useHistoryValue;

  let perspectives = [0, 1, 2];
  if (applicationSettings.Brand === "HQV") {
    perspectives = [3, 4, 5, 6];
  }

  const defaultPreview = "/preview";
  let closePreview: string;

  if (props.closePreview !== defaultPreview && props.closePreview) {
    closePreview = props.closePreview.toString();
  } else {
    closePreview = defaultPreview;
  }

  let onlyFirstImage = Boolean(props.onlyFirstImg);

  let sideImage = Boolean(props.sideImage);

  useEffect(() => {
    if (applicationSettings.Brand === "HQV" && perspective === 0) {
      props.setPerspective(6);
    }
  }, []);

  useEffect(() => {
    if (props.changeRenderedImage && resetRenderedPictureChange) {
      props.setImageTimestamp();
      resetRenderedPictureChange();
    }
    if (getImageSrc()) {
      setImageSrc("" + getImageSrc());
    }
  }, [props.configuration.FullImages, props.perspective, props.changeRenderedImage]);

  const handleSwipe = (change: number) => {
    let newPosition: number;
    const min = Math.min(...perspectives);
    const max = Math.max(...perspectives);
    const newValue = props.perspective + change;
    newPosition = newValue < min ? max : newValue > max ? min : newValue;
    props.setPerspective(newPosition);
  };

  const handleSwipeP = (newValue: number) => {
    let newPosition: number;
    const min = Math.min(...perspectives);
    const max = Math.max(...perspectives);
    newPosition = newValue < min ? max : newValue > max ? min : newValue;
    props.setPerspective(newPosition);
  };

  const getImageSrc = () => {
    let imageBase = getImageBase();
    if (imageBase) {
      let baseArray = imageBase.split("/");
      baseArray[3] = "" + getConfigurationId();
      imageBase = baseArray.join("/");
    } else {
      return undefined;
    }

    return `${imageBase}&temp=${props.imageTimestamp}`;
  };

  const getHeroImage = () => {
    let imageBase = getHeroImageBase();
    if (imageBase) {
      let baseArray = imageBase.split("/");
      baseArray[3] = "" + getConfigurationId();
      imageBase = baseArray.join("/");
    }
    return `${imageBase}&temp=${props.imageTimestamp}`;
  };

  const getImageBase = () => {
    const fullImage = props.configuration.FullImages
      ? props.configuration.FullImages.find((image: FullImage) => {
          return image.Perspective === perspective;
        })
      : undefined;

    if (fullImage) {
      return fullImage.Url;
    }
  };

  const getHeroImageBase = () => {
    const fullImage = props.configuration.FullImages
      ? props.configuration.FullImages.find((image: FullImage) => {
          return image.Perspective === (applicationSettings.Brand === "KTM" ? 2 : 6);
        })
      : undefined;
    if (fullImage) {
      return fullImage.Url;
    }
  };

  const goToPrev = () => {
    if (history) {
      history.push("/preview");
    }
  };

  const goBackToConfig = () => {
    if (history) {
      history.goBack();
    }
  };

  const renderBackgroundTitle = () => {
    if (applicationSettings.Brand === "HQV") {
      return null;
    }

    const vehicleName = props.selectedModel.Name;
    let cutVehicleName = "";
    let spacedArray = vehicleName.split(" ");
    let filteredArray = spacedArray.filter((entry) => {
      return entry !== "";
    });
    for (let i = 0; i < filteredArray.length - 1; i++) {
      cutVehicleName += filteredArray[i] + " ";
    }

    return (
      <div className="background-title">
        <span className="background-name">{cutVehicleName}</span>
      </div>
    );
  };

  return (
    <Grid item container className="full-height full-width relative">
      <Grid item className="full-width vehicle-image-wrapper">
        {!onlyFirstImage && !sideImage && props.configuration && (
          <>
            {closePreview !== defaultPreview && (
              <>
                <div onClick={() => handleSwipe(-1)} id="show-left-view" className={`${props.perspective !== 0 && props.perspective !== 6 ? "active" : ""}`}>
                  <ArrowHead />
                </div>
                <div onClick={() => handleSwipe(1)} id="show-right-view" className={`${props.perspective !== 2 && props.perspective !== 5 ? "active" : ""}`}>
                  <ArrowHead />
                </div>
              </>
            )}
            <Swipeable
              className={`full-width turnable-image-wrapper full-height flex-container centered`}
              trackMouse={!onlyFirstImage && !sideImage ? true : false}
              preventDefaultTouchmoveEvent={true}
              onSwipedLeft={() => handleSwipe(1)}
              onSwipedRight={() => handleSwipe(-1)}
            >
              <VehicleRenderer {...props} url={imageSrc} brand={"" + applicationSettings.Brand} />
            </Swipeable>
            {renderBackgroundTitle()}
          </>
        )}
        {onlyFirstImage && props.configuration && (
          <>
            <div className={`full-width turnable-image-wrapper full-height flex-container centered`}>
              <VehicleRenderer {...props} url={imageSrc} brand={"" + applicationSettings.Brand} />
            </div>
            {renderBackgroundTitle()}
          </>
        )}
        {sideImage && (
          <>
            <VehicleRenderer {...props} url={getHeroImage()} brand={"" + applicationSettings.Brand} />
          </>
        )}
      </Grid>

      {!onlyFirstImage && !sideImage && (
        <div className="image-controlls-wrapper">
          <Grid item className="show-full-image d-none-mobile">
            {closePreview === defaultPreview && (
              <a className="" onClick={goToPrev}>
                <FullImageIcon />
              </a>
            )}
            {closePreview !== defaultPreview && (
              <a className="" onClick={goBackToConfig}>
                <FullImageIcon />
              </a>
            )}
          </Grid>
          {applicationSettings.Brand === "KTM" && (
            <Grid item className="full-width centered image-slider-controller">
              <button title="Perspective Left Side" className={`slider-dots ${props.perspective === 0 ? "active" : ""}`} onClick={() => handleSwipeP(0)}></button>
              <button title="Perspective Right Side" className={`slider-dots ${props.perspective === 1 ? "active" : ""}`} onClick={() => handleSwipeP(1)}></button>
              <button title="Perspective Center" className={`slider-dots ${props.perspective === 2 ? "active" : ""}`} onClick={() => handleSwipeP(2)}></button>
            </Grid>
          )}
          {applicationSettings.Brand === "HQV" && (
            <Grid item className="full-width centered image-slider-controller">
              <button title="Perspective Right Side" className={`slider-dots ${props.perspective === 6 ? "active" : ""}`} onClick={() => handleSwipeP(6)}></button>
              <button title="Perspective Left Side" className={`slider-dots ${props.perspective === 3 ? "active" : ""}`} onClick={() => handleSwipeP(3)}></button>
              <button title="Perspective Left Side 45" className={`slider-dots ${props.perspective === 4 ? "active" : ""}`} onClick={() => handleSwipeP(4)}></button>
              <button title="Perspective Right Side 45" className={`slider-dots ${props.perspective === 5 ? "active" : ""}`} onClick={() => handleSwipeP(5)}></button>
            </Grid>
          )}
          {applicationSettings.Brand === "GG" && (
            <Grid item className="full-width centered image-slider-controller">
              <a href="#left" title="Perspective Left Side" className={`slider-dots ${props.perspective === 0 ? "active" : ""}`} onClick={() => handleSwipeP(0)}></a>
              <a href="#right" title="Perspective Right Side" className={`slider-dots ${props.perspective === 1 ? "active" : ""}`} onClick={() => handleSwipeP(1)}></a>
              <a href="#center" title="Perspective Center" className={`slider-dots ${props.perspective === 2 ? "active" : ""}`} onClick={() => handleSwipeP(2)}></a>
            </Grid>
          )}
        </div>
      )}
    </Grid>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(VehicleImage));

import React from 'react';
import { getCulture } from './helpers';
import { store } from '../redux/store';
import { getPriceAvailability } from '../redux/selectors';
import { Price } from '../models/vehicle';

export default class Formatter extends React.Component {

	static currency(price: Price, isXBow:boolean=false) {
		
		const pricesAreAvailable = getPriceAvailability(store.getState());
		let lang = document.documentElement.lang
		if (!price || !pricesAreAvailable || isXBow) return null;
		
		//Hofix if price is 0 in database
		if(price && price.Value === 0) return (lang === "de" ? "Preis nicht vorhanden" : "Price not Available");

		if (getCulture() === 'en-JP'){
			return price.Value.toLocaleString('ja-JP')  + ' ' + price.CurrencySymbol ;
		}

		return price.Value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })  + ' ' + price.CurrencySymbol ;
	}
}

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const KTM_Logo: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 98 89" fill="none">
      <title>KTM Logo</title>
      <path d="M98 0H0V88.0779H98V0Z" fill="#FF6600"/>
      <path d="M14.7012 34.2867H24.2828L26.6899 28.4039L31.1284 34.2867H51.7829L58.1589 28.3452L57.1021 34.2867H65.1924L71.862 28.4039L70.9578 34.2867H79.6822L83.2988 17.8594H74.5274L67.4234 24.1297L68.7973 17.8594H60.3899L51.2193 27.0535L55.0589 15.8045H69.0791L69.7249 12.9395H32.9367L31.8565 15.8045H45.3834L39.6767 30.2474L35.1912 24.3411L42.9528 17.8594H36.4594L28.4865 24.2354L31.1754 17.8594H21.676L14.7012 34.2867Z" fill="#1A1A18"/>
    </SvgIcon>
  );
};

export default KTM_Logo;

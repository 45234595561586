import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Indicator3DDefault: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <title>Indicator 3D Default</title>
      <path
        d="M9.42622 14.2503L9.52152 14.2774L9.54948 14.1824L9.72223 13.5952C9.91439 12.942 10.4879 12.485 11.173 12.485C12.0291 12.485 12.7209 13.1663 12.7209 14.0043C12.7209 14.7124 12.1355 15.2902 11.4105 15.2902H11.3105V15.3902V16.6126V16.7126H11.4105C12.1355 16.7126 12.7209 17.2904 12.7209 17.9985C12.7209 18.8355 12.0302 19.5178 11.173 19.5178C10.4879 19.5178 9.91439 19.0608 9.72223 18.4077L9.54948 17.8205L9.52152 17.7254L9.42622 17.7525L8.2311 18.092L8.134 18.1196L8.16249 18.2164L8.33524 18.8036C8.69021 20.0102 9.78916 20.9402 11.173 20.9402C12.8251 20.9402 14.165 19.6237 14.165 17.9985C14.165 17.2078 13.8199 16.4963 13.2708 16.0014C13.8199 15.5066 14.165 14.7951 14.165 14.0043C14.165 12.3767 12.8226 11.0626 11.173 11.0626C9.78916 11.0626 8.69021 11.9927 8.33524 13.1992L8.16249 13.7864L8.134 13.8833L8.2311 13.9108L9.42622 14.2503Z"
        fill="#A9A9A9"
        stroke="#A9A9A9"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M16.969 11.0626H16.869V11.1626V20.8402V20.9402H16.969H19.9254C22.0905 20.9402 23.8413 19.1627 23.8433 16.9761V16.976L23.8433 15.0291C23.8433 12.8399 22.0901 11.0626 19.9254 11.0626H16.969ZM22.4362 16.9749L22.4362 16.9753C22.4346 18.3803 21.3101 19.5178 19.9254 19.5178H18.2761V12.485H19.9254C21.3106 12.485 22.4362 13.6231 22.4362 15.0291L22.4362 16.9749Z"
        fill="#A9A9A9"
        stroke="#A9A9A9"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M13.6976 5.14399L11.5635 3.01718C16.3564 1.42131 21.8507 2.51755 25.6632 6.31585L25.6632 6.31587C30.623 11.2553 30.9945 19.0233 26.795 24.3977L26.4225 24.8745L26.3607 24.9536L26.44 25.0151L27.3975 25.757L27.4762 25.8181L27.5375 25.7396L27.91 25.2628C32.5411 19.3361 32.1339 10.7691 26.6622 5.31986C22.16 0.834515 15.5362 -0.2309 10.0153 2.09593L9.1865 2.44525L9.04925 2.5031L9.15475 2.60823L12.6983 6.13966L12.7689 6.20996L12.8395 6.13971L13.6976 5.2857L13.7688 5.21487L13.6976 5.14399Z"
        fill="#A9A9A9"
        stroke="#A9A9A9"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M5.57693 7.12621L5.63872 7.04708L5.55934 6.9856L4.60167 6.24394L4.52293 6.18295L4.46163 6.26145L4.08926 6.73832C-0.539803 12.6665 -0.134725 21.2336 5.33729 26.6815C9.83941 31.1651 16.4633 32.2324 21.9844 29.9054L22.8135 29.556L22.9508 29.4981L22.8453 29.393L19.2993 25.8616L19.2288 25.7913L19.1582 25.8616L18.3004 26.7159L18.2292 26.7868L18.3004 26.8576L20.4358 28.9843C15.643 30.5798 10.1487 29.4822 6.33617 25.6854L6.33616 25.6853C1.37654 20.7477 1.00634 12.9795 5.20457 7.60308L5.57693 7.12621Z"
        fill="#A9A9A9"
        stroke="#A9A9A9"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default Indicator3DDefault;

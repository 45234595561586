import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuHQV: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 24.113 17.305">
      <title>Menu Icon</title>
      <g id="menu">
        <rect width="24.113" height="1.418" />
        <rect width="24.113" height="1.418" transform="translate(0 7.943)" />
        <rect width="24.113" height="1.418" transform="translate(0 15.886)" />
      </g>
    </SvgIcon>
  );
};

export default MenuHQV;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";

import BarItemDevider from "../../../assets/icons/waitingroom/bar-item-devider";

import RotateIcon from "../../../assets/icons/waitingroom/rotate";
import ZoomIcon from "../../../assets/icons/waitingroom/zoom";
import FocusIcon from "../../../assets/icons/waitingroom/focus";

import { getIsMobile } from "../../../utils/helpers";
import useWaitingroom from "../../../lib/hooks/useWaitingroom";
import { StreamState } from "../../../lib/streaming/core";

export interface WaitingRoomStateProps {
  currentStreamState: StreamState;
}

type WaitingroomProps = StateManagementProps & WithNamespaces & WaitingRoomStateProps;

const WaitingRoom: React.FunctionComponent<WaitingroomProps> = (props) => {
  const { t, applicationSettings, isXBow, currentStreamState } = props;
  const brand = applicationSettings.Brand;

  const [isMobile, setIsMobile] = useState(() => getIsMobile());
  const streetModels = ["M-990DUKER2024","M-990DUKE2024", "M-1390SUPERDUKEREVO2024","M-1390SUPERDUKER2024"]
  const getIsStreet = () => streetModels.includes(props.selectedModel.ModelId);
  const [isStreet] = useState(getIsStreet);

  const setMobileColumnHeight = () => setCurrentColumnHeight(getMobileColumnHeight());

  const getMobileColumnHeight = () => {
    const d = document.getElementById("waiting-room-column-1");
    const height = d && d.offsetHeight ? `${d.offsetHeight}px` : "410px";
    return height;
  };

  const getContentHeight = () => {
    const h = document.getElementById("header");
    const headerHeight = h && h.offsetHeight ? h.offsetHeight : 5;
    const windowHeight = window.innerHeight;
    return windowHeight - headerHeight + "px";
  };

  const setIsMobileState = () => {
    setIsMobile(getIsMobile());
  };

  const setContainerHeight = () => {
    setContentHeight(getContentHeight);
  };

  const handleResize = () => {
    setMobileColumnHeight();
    setIsMobileState();
    setContainerHeight();
  };

  const setCurrentLoadingState = () => {
    switch (currentStreamState) {
      case StreamState.INITIALIZING:
        return "0% Loading Factory";
      case StreamState.LOAD_BIKE:
        if (isXBow) {
          return " 20% assembling car";
        }
        return "20% assembling bike";
      case StreamState.LOAD_ENV:
        return "40% Preparing Environment";
      case StreamState.LOAD_BASE:
        return "60% Flashing Base Settings";
      case StreamState.LOAD_COLORS:
        return "80% Flashing Cutsom Settings";
      case StreamState.LOAD_VIDEO_SRC:
        return "99% Starting Engine";
      default:
        return "loading";
    }
  };

  const [currentColumnHeight, setCurrentColumnHeight] = useState(getMobileColumnHeight());
  const [contentHeight, setContentHeight] = useState(() => getContentHeight());

  const { rotate, zoom, focus, loadingGif, rotateGif, zoomGif, focusGif, displayProp, showFocus, showRotate, showZoom } = useWaitingroom({ brand, isXBow, isMobile, isStreet });

  const rotateVideoRef = useRef<HTMLVideoElement>(null);
  const zoomVideoRef = useRef<HTMLVideoElement>(null);
  const focusVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (rotateVideoRef.current && zoomVideoRef.current && focusVideoRef.current) {
      if (displayProp === showRotate) {
        rotateVideoRef!.current!.play();
        zoomVideoRef!.current!.pause();
        focusVideoRef!.current!.pause();
      }

      if (displayProp === showZoom) {
        zoomVideoRef!.current!.play();
        rotateVideoRef!.current!.pause();
        focusVideoRef!.current!.pause();
      }

      if (displayProp === showFocus) {
        focusVideoRef!.current!.play();
        zoomVideoRef!.current!.pause();
        rotateVideoRef!.current!.pause();
      }
    }
  }, [displayProp]);

  const checkDisplay = useMemo(() => {
    return {
      showRotate: displayProp === showRotate,
      showZoom: displayProp === showZoom,
      showFocus: displayProp === showFocus,
    };
  }, [displayProp]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="waiting-room" style={{ height: contentHeight }}>
      <div className="waiting-room-header-container margin-left">
        <span className={`waiting-room-header ${brand === "KTM" ? "ktm-before" : ""}`}>
          <p>{t(`waitingroom.headerText.${isXBow ? "car" : "bike"}`)}</p>
        </span>
      </div>
      <div className="waiting-room-content-container">
        <div className="bar-container">
          <div className="bar">
            <div className="bar-item active">
              <RotateIcon />
            </div>
            <div className={`bar-item-devider ${checkDisplay[showZoom] || checkDisplay[showFocus] ? "active" : ""}`}>
              <BarItemDevider />
            </div>
            <div className={`bar-item ${checkDisplay[showZoom] || checkDisplay[showFocus] ? "active" : ""}`}>
              <ZoomIcon />
            </div>
            <div className={`bar-item-devider ${checkDisplay[showFocus] ? "active" : ""}`}>
              <BarItemDevider />
            </div>
            <div className={`bar-item ${checkDisplay[showFocus] ? "active" : ""}`}>
              <FocusIcon />
            </div>
          </div>
        </div>
        <div className="waiting-room-columns" style={isMobile ? { height: currentColumnHeight } : {}}>
          <div className={`waiting-room-column ${checkDisplay[showRotate] ? "active" : "wr-mb-none wr-d-op-none"}`}>
            <div className="waiting-room-column-heading-container">
              <span className={`waiting-room-column-heading first ${brand === "KTM" ? "ktm-colors uppercase" : ""}`}>
                <p>{t("waitingroom.barHeaderTextRotate")}</p>
              </span>
            </div>
            <div className="waiting-room-column-bike-video-container">
              <video ref={rotateVideoRef} className="bike-video" muted={true} autoPlay={false} playsInline={true}>
                <source src={rotate} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div className="waiting-room-column-text-container">
              <span className="waiting-room-column-text">{!isMobile ? <p>{t("waitingroom.Mouse1Text")}</p> : <p> {t("waitingroom.Finger1Text")}</p>}</span>
            </div>
            <div className="waiting-room-column-mouse-gif-container">
              <img src={rotateGif} alt="rotate_mouse_gif" className="mouse-gif" />
            </div>
          </div>
          <div className={`waiting-room-column ${checkDisplay[showZoom] ? "active" : "wr-mb-none wr-d-op-none"}`}>
            <div className="waiting-room-column-heading-container">
              <span className={`waiting-room-column-heading middle ${brand === "KTM" ? "ktm-colors uppercase" : ""}`}>
                <p>{t("waitingroom.barHeaderTextZoom")}</p>
              </span>
            </div>
            <div className="waiting-room-column-bike-video-container">
              <video ref={zoomVideoRef} className="bike-video" muted={true} autoPlay={false} playsInline={true}>
                <source src={zoom} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div className="waiting-room-column-text-container">
              <span className="waiting-room-column-text">{!isMobile ? <p>{t("waitingroom.Mouse2Text")}</p> : <p> {t("waitingroom.Finger2Text")}</p>}</span>
            </div>
            <div className="waiting-room-column-mouse-gif-container">
              <img src={zoomGif} alt="zoom_mouse_gif" className="mouse-gif" />
            </div>
          </div>
          <div className={`waiting-room-column ${checkDisplay[showFocus] ? "active" : "wr-mb-none wr-d-op-none"}`}>
            <div className="waiting-room-column-heading-container">
              <span className={`waiting-room-column-heading last ${brand === "KTM" ? "ktm-colors uppercase" : ""}`}>
                <p>{t("waitingroom.barHeaderTextFocus")}</p>
              </span>
            </div>
            <div className="waiting-room-column-bike-video-container">
              <video ref={focusVideoRef} className="bike-video" muted={true} autoPlay={false} playsInline={true}>
                <source src={focus} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div className="waiting-room-column-text-container">
              <span className="waiting-room-column-text">{!isMobile ? <p>{t("waitingroom.Mouse3Text")}</p> : <p> {t("waitingroom.Finger3Text")}</p>}</span>
            </div>
            <div className="waiting-room-column-mouse-gif-container">
              <img src={focusGif} alt="focus_mouse_gif" className="mouse-gif" />
            </div>
          </div>
        </div>
      </div>
      <div className="waiting-room-footer margin-left">
        <div className="loading-gif-container">
          <img src={loadingGif} alt="loading.." />
        </div>
        <div className="waiting-room-loading-text">
          <span>
            <p>{setCurrentLoadingState()}</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoom);

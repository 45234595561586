import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PlusIconProps } from "./plus";

const PlusHQV: React.FunctionComponent<PlusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 25.5 25.5" id={props.identifyer}>
      <title>Plus Icon</title>
      <g transform="translate(-1845 -840)">
        <path
          d="M467.324,664.263a12.75,12.75,0,1,1,12.75-12.75A12.764,12.764,0,0,1,467.324,664.263Zm0-23.964a11.214,11.214,0,1,0,11.214,11.214A11.226,11.226,0,0,0,467.324,640.3Z"
          transform="translate(1390.426 201.237)"
        />
        <g transform="translate(1852.374 847.433)">
          <path d="M540.542,117.277v5.034h-5.034v.78h5.034v5.034h.78v-5.034h5.034v-.78h-5.034v-5.034Z" transform="translate(-535.508 -117.277)" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PlusHQV;

import { useEffect, useState } from "react";
import { WithNamespaces } from "react-i18next";
import { getAppliedPartsCount, getIsMobileXS, sendConfigurationToDealer } from "../../utils/helpers";
import { StateManagementProps } from "../../utils/addReduxProps";
import React from "react";
import Finance from "../../assets/icons/finance";
import { Check, Close } from "@material-ui/icons";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { Button, withStyles } from "@material-ui/core";
import store from "../../redux/store";
import SelectedPartsList from "./selectedPartsList/SelectedPartsList";
import { noConflictingParts, removeNoConflictingParts } from "../../redux/actions";
import { AddPartPayload, RemovePartPayload } from "../../services/http/real";
import ArrowHead from "../../assets/icons/arrowHead";

export interface ConfigOverviewMenuProps {
  handleSummaryClick?: (isXBow?: boolean) => void;
  onSendToDealerSuccess?: () => void;
}

type AllProps = StateManagementProps & WithNamespaces & ConfigOverviewMenuProps;

export const ConfigurationOverviewMenu: React.FunctionComponent<AllProps> = (props) => {
  const {
    t,
    appliedParts,
    handleSummaryClick, } = props;


  let portraitMode = Boolean(window.orientation === 0);

  const [financeAvailable] = useState(false);
  const [anchorAppliedEl, setAppliedAnchorEl] = useState<null | HTMLElement>(null);
  const scrollToFinance = React.createRef<HTMLDivElement>();
  const [isMobile, setIsMobile] = useState(()=>getIsMobileXS());

  const setIsMobileState = () => {
    setIsMobile(getIsMobileXS());
  }

  useEffect(() => {
    setIsMobileState();
    window.addEventListener("resize", setIsMobileState);

    return () => {
      window.removeEventListener("resize",setIsMobileState);
    }
  }, []);

  

  const StyledMenu = withStyles({
    paper: {},
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      transitionDuration={0} //TODO: find better solution
      {...props}
    />
  ));

  function resetConflictingPartsStatus() {
    let sendPayload: AddPartPayload = {
      PartId: "",
      SelectedParts: [],
      RemoveParts: [],
      DryRun: false,
    };
    store.dispatch(noConflictingParts(sendPayload));
    let sendRemovePayload: RemovePartPayload = {
      PartId: "",
      RemoveParts: [],
      DryRun: false,
    };
    store.dispatch(removeNoConflictingParts(sendRemovePayload));
  }

  function handleClickApplied(event: React.MouseEvent<HTMLElement>) {
    resetConflictingPartsStatus();
    setAppliedAnchorEl(event.currentTarget);
  }

  const getAppliedClosed = () => {
    setAppliedAnchorEl(null);
  };

  return (
<>
      {/*Mobile Header*/}
      {(isMobile || portraitMode) && (
        <div className={`config-overview-header absolute-mobile show-mobile flex ${financeAvailable ? "finance-available" : "finance-hidden"}`}>
          <div className="config-counter-container config-options">
            <Button aria-controls="applied-parts-list" aria-haspopup="true" onClick={handleClickApplied} className="mobile-parts-button">
              <span className="config-counter-wrapper">
                <span className="config-counter">{getAppliedPartsCount()}</span>
              </span>
            </Button>
          </div>
          {financeAvailable && (
            <div
              className="config-icon-2 config-options finance-option"
              onClick={() => {
                if (scrollToFinance && scrollToFinance.current) {
                  scrollToFinance.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            >
              <Finance></Finance>
            </div>
          )}

          <div className="config-icon-3 config-options inverted gtm-summary-button-mobile" onClick={() => handleSummaryClick && handleSummaryClick()}>
            <Check />
          </div>
        </div>
      )}

      {/*Desktop Header*/}
      <div className={`config-overview-header d-none-mobile`}>
        <Button aria-controls="applied-parts-list" aria-haspopup="true" onClick={handleClickApplied} className="desktop-menu-button">
          <div className="config-counter-container config-options">
            <span className="config-counter-wrapper">
              <span className="config-counter">{getAppliedPartsCount()}</span>
            </span>
          </div>
          <div className="config-title">
            {t("configuration.overview")} <ArrowHead />
          </div>
        </Button>
        {anchorAppliedEl && (
          <StyledMenu
            id="applied-parts-list"
            anchorEl={anchorAppliedEl}
            keepMounted
            open={Boolean(anchorAppliedEl)}
            onClose={getAppliedClosed}
            className={`is2D  ${getAppliedPartsCount() <= 0 ? "no-parts" : "parts"} ${getAppliedPartsCount() >= 3 ? "fullheight" : ""}`}
          >
            <span className={`is2D show-mobile close-icon`} onClick={getAppliedClosed}>
              <Close />
            </span>
            {getAppliedPartsCount() !== 0 && <SelectedPartsList {...props} parts={appliedParts} />}
            {getAppliedPartsCount() <= 0 && <span>{t("configuration.parts.noParts")}</span>}
          </StyledMenu>
        )}
      </div>
    </>
  )
};

import { useEffect, useState } from "react";
import useInterval from "./useInterval";

import LoadingGif_KTM from "../../assets/gifs/waitingroom/ktm/gif.gif";
import LoadingGif_HQV from "../../assets/gifs/waitingroom/hqv/loading.gif";
import LoadingGif_GG from "../../assets/gifs/waitingroom/gg/loading.gif";

import RotateGif_KTM from "../../assets/gifs/waitingroom/ktm/rotation.gif";
import RotateGif_HQV from "../../assets/gifs/waitingroom/hqv/rotate.gif";
import RotateGif_GG from "../../assets/gifs/waitingroom/gg/rotate.gif";

import RotateHandGif_KTM from "../../assets/gifs/waitingroom/ktm/ktm_rotate_finger.gif";
import RotateHandGif_HQV from "../../assets/gifs/waitingroom/hqv/hqv_rotate_finger.gif";
import RotateHandGif_GG from "../../assets/gifs/waitingroom/gg/gg_rotate_finger.gif";

import ZoomGif_KTM from "../../assets/gifs/waitingroom/ktm/zooming.gif";
import ZoomGif_HQV from "../../assets/gifs/waitingroom/hqv/zoom.gif";
import ZoomGif_GG from "../../assets/gifs/waitingroom/gg/zoom.gif";

import ZoomHandGif_KTM from "../../assets/gifs/waitingroom/ktm/ktm_zoom_finger.gif";
import ZoomHandGif_HQV from "../../assets/gifs/waitingroom/hqv/hqv_zoom_finger.gif";
import ZoomHandGif_GG from "../../assets/gifs/waitingroom/gg/gg_zoom_finger.gif";

import FocusGif_KTM from "../../assets/gifs/waitingroom/ktm/focusing.gif";
import FocusGif_HQV from "../../assets/gifs/waitingroom/hqv/focus.gif";
import FocusGif_GG from "../../assets/gifs/waitingroom/gg/focus.gif";

import FocusHandGif_KTM from "../../assets/gifs/waitingroom/ktm/ktm_focus_finger.gif";
import FocusHandGif_HQV from "../../assets/gifs/waitingroom/hqv/hqv_focus_finger.gif";
import FocusHandGif_GG from "../../assets/gifs/waitingroom/gg/gg_focus_finger.gif";

interface UseWaitingroomProps {
  brand: string;
  isXBow: boolean;
  isMobile: boolean;
  isStreet: boolean;
}

interface UseWaitingroomReturns {
  rotate: string;
  zoom: string;
  focus: string;
  loadingGif: string;
  rotateGif: string;
  zoomGif: string;
  focusGif: string;
  displayProp: string;
  showRotate: string;
  showZoom: string;
  showFocus: string;
}

const useWaitingroom = (props: UseWaitingroomProps): UseWaitingroomReturns => {
  const { brand, isXBow, isMobile, isStreet } = props;

  const isKTM = () => brand === "KTM";
  const isHQV = () => brand === "HQV";

  const showRotate = "showRotate";
  const showZoom = "showZoom";
  const showFocus = "showFocus";

  const BikeRotate_KTM = "/videos/ktm/ktm_rotate.mp4";
  const BikeRotate_HQV = "/videos/hqv/hqv_rotate.mp4";
  const BikeRotate_GG = "/videos/gg/gg_rotate.mp4";

  const BikeZoom_KTM = "/videos/ktm/ktm_zoom.mp4";
  const BikeZoom_HQV = "/videos/hqv/hqv_zoom.mp4";
  const BikeZoom_GG = "/videos/gg/gg_zoom.mp4";

  const BikeFocus_KTM = "/videos/ktm/ktm_focus.mp4";
  const BikeFocus_HQV = "/videos/hqv/hqv_focus.mp4";
  const BikeFocus_GG = "/videos/gg/gg_focus.mp4";

  const XBowRotate = "/videos/xbow/xbow_rotate.mp4";
  const XBowZoom = "/videos/xbow/xbow_zoom.mp4";
  const XBowFocus = "/videos/xbow/xbow_focus.mp4";

  const StreetRotate = "/videos/street/street_rotate.mp4";
  const StreetZoom = "/videos/street/street_zoom.mp4";
  const StreetFocus = "/videos/street/street_focus.mp4";
  

  const getLoadingGif = () => (isKTM() ? LoadingGif_KTM : isHQV() ? LoadingGif_HQV : LoadingGif_GG);

  const getRotateKTMGif = () => (isMobile ? RotateHandGif_KTM : RotateGif_KTM);
  const getRotateHQVGif = () => (isMobile ? RotateHandGif_HQV : RotateGif_HQV);
  const getRotateGsGGif = () => (isMobile ? RotateHandGif_GG : RotateGif_GG);
  const getRotateGif = () => (isKTM() ? getRotateKTMGif() : isHQV() ? getRotateHQVGif() : getRotateGsGGif());

  const getZoomKTMGif = () => (isMobile ? ZoomHandGif_KTM : ZoomGif_KTM);
  const getZoomHQVGif = () => (isMobile ? ZoomHandGif_HQV : ZoomGif_HQV);
  const getZoomGsGGif = () => (isMobile ? ZoomHandGif_GG : ZoomGif_GG);
  const getZoomGif = () => (isKTM() ? getZoomKTMGif() : isHQV() ? getZoomHQVGif() : getZoomGsGGif());

  const getFocusKTMGif = () => (isMobile ? FocusHandGif_KTM : FocusGif_KTM);
  const getFocusHQVGif = () => (isMobile ? FocusHandGif_HQV : FocusGif_HQV);
  const getFocusGsGGif = () => (isMobile ? FocusHandGif_GG : FocusGif_GG);
  const getFocusGif = () => (isKTM() ? getFocusKTMGif() : isHQV() ? getFocusHQVGif() : getFocusGsGGif());

  const getRotateVideo = () => {
    if (isXBow) return XBowRotate;
    else if(isStreet) return StreetRotate;
    else if (isKTM()) return BikeRotate_KTM;
    else if (isHQV()) return BikeRotate_HQV;
    return BikeRotate_GG;
  };

  const getZoomVideo = () => {
    if (isXBow) return XBowZoom;
    else if(isStreet) return StreetZoom;
    else if (isKTM()) return BikeZoom_KTM;
    else if (isHQV()) return BikeZoom_HQV;
    return BikeZoom_GG;
  };

  const getFocusVideo = () => {
    if (isXBow) return XBowFocus;
    else if(isStreet) return StreetFocus;
    else if (isKTM()) return BikeFocus_KTM;
    else if (isHQV()) return BikeFocus_HQV;
    return BikeFocus_GG;
  };

  const [rotate, setRotate] = useState(getRotateVideo());
  const [zoom, setZoom] = useState(getZoomVideo());
  const [focus, setFocus] = useState(getFocusVideo());
  const [loadingGif, setLoadingGif] = useState(getLoadingGif());
  const [rotateGif, setRotateGif] = useState(getRotateGif());
  const [zoomGif, setZoomGif] = useState(getZoomGif());
  const [focusGif, setFocusGif] = useState(getFocusGif());
  const [displayProp, setDisplayProp] = useState(showRotate);

  const [returnValues, setReturnValues] = useState({
    rotate,
    zoom,
    focus,
    loadingGif,
    rotateGif,
    zoomGif,
    focusGif,
    displayProp,
    showRotate,
    showFocus,
    showZoom,
  });

  const setReturningValues = () => {
    const values = {
      rotate,
      zoom,
      focus,
      loadingGif,
      rotateGif,
      zoomGif,
      focusGif,
      displayProp,
      showRotate,
      showFocus,
      showZoom,
    };
    setReturnValues(values);
  };

  useEffect(() => {
    setLoadingGif(getLoadingGif());
  }, [brand]);

  useEffect(() => {
    setRotate(getRotateVideo());
    setZoom(getZoomVideo());
    setFocus(getFocusVideo());
    setLoadingGif(getLoadingGif());
  }, [brand, isXBow]);

  useEffect(() => {
    setRotateGif(getRotateGif());
    setZoomGif(getZoomGif());
    setFocusGif(getFocusGif());
  }, [brand, isMobile]);

  useInterval(() => {
    if (displayProp === showRotate) {
      setDisplayProp(showZoom);
    }
    if (displayProp === showZoom) {
      setDisplayProp(showFocus);
    }
    if (displayProp === showFocus) {
      setDisplayProp(showRotate);
    }
  }, 4000);

  useEffect(() => {
    setReturningValues();
  }, [displayProp]);

  return returnValues;
};

export default useWaitingroom;

import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Action from "../../redux/reducers/models/action";
import { StateManagementProps } from "../../utils/addReduxProps";
import Arrow from "../../assets/icons/arrow";
import Close from "../../assets/icons/close";

export const SaveConfiguration: React.FunctionComponent<DialogProps & StateManagementProps & WithNamespaces> = ({ title, hideDialog, t, applicationSettings, data }) => {
  const renderDesktopBackButton = () => {
    if (applicationSettings.Brand === "HQV") {
      return (
        <>
          <span className="d-none-mobile ">
            <Arrow />
          </span>
          {t("buttons.closeDialog")}
        </>
      );
    }
    return (
      <>
        {t("buttons.closeDialog")}
        <span className="d-none-mobile">
          <Arrow />
        </span>
      </>
    );
  };

  const renderText = () => {
    if (data && data.success) {
      return (
        <>
          <p>{t("dialogs.savedConfiguration.text.first")}</p>
          <p>
            {t("dialogs.savedConfiguration.text.second")}
            <a href="https://www.motohub.io/" target="_blank">
              {t("dialogs.savedConfiguration.text.third")}
            </a>
            {t("dialogs.savedConfiguration.text.fourth")}
          </p>
          <p>{t("dialogs.savedConfiguration.text.fifth")}</p>
        </>
      );
    }
    return <p>{t("dialogs.savedConfiguration.error.text")}</p>;
  };

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      onClose={hideDialog}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="export configuration modal"
      className="send-mail-wrapper"
      id="savedConfigurationDialog"
    >
      <Grid item className="close-send-mail-button-mobile show-mobile container">
        <button className="primary gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>
          <Close />
        </button>
      </Grid>
      <Grid item className="close-send-mail d-none-mobile container">
        <button className="primary back-link gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>
          {renderDesktopBackButton()}
        </button>
      </Grid>
      <div className="send-mail container">
        <div className="dialog-wrapper">
          <Grid id="setup-parts-dialog-title">
            <h2 className={applicationSettings.Brand === "KTM" ? "setups-headline underline uppercase" : "setups-headline underline"}>{title}</h2>
            <div className="setups-description text-center">{renderText()}</div>
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};

export default withNamespaces()(SaveConfiguration);

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MinusIconProps } from "./minus";

const MinusHQV: React.FunctionComponent<MinusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 25.5 25.5" id={props.identifyer}>
      <title>Minus Icon</title>
      <g transform="translate(0)">
        <path
          d="M467.324,664.263a12.75,12.75,0,1,1,12.75-12.75A12.765,12.765,0,0,1,467.324,664.263Zm0-23.964a11.214,11.214,0,1,0,11.214,11.214A11.226,11.226,0,0,0,467.324,640.3Z"
          transform="translate(-454.574 -638.763)"
        />
        <rect width="10.492" height="1.536" transform="translate(7.504 11.982)" />
      </g>
    </SvgIcon>
  );
};

export default MinusHQV;

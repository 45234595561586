import store from "../redux/store";
import { Part } from "../components/parts/PartsList";
import addPartAsync from "../redux/async-actions/addPart";
import removePartAsync from "../redux/async-actions/removePart";
import { AddPartPayload, RemovePartPayload } from "../services/http/real";

/*--- collect Parts ---*/

function getAllParts(): Part[] {
  return store.getState().vehicleOverviewState.Parts;
}

export function getPartById(id: string) {
  return getAllParts().find((part: Part) => part.PartId === id) as Part;
}

/*--- ADD & REMOVE PART ---*/
export function togglePart(part: Part, appliedParts: Part[]) {
    appliedParts.findIndex((entry) => entry.PartId === part.PartId) !== -1 ? removePart(part) : addPart(part);
  }
  
  export function addNonConflictingPart(payload: AddPartPayload) {
    store.dispatch(addPartAsync(payload) as any);
  }
  
  export function removeNonConflictingPart(payload: RemovePartPayload) {
    store.dispatch(removePartAsync(payload) as any);
  }
  
  export function addPart(part: Part) {
    let payload: AddPartPayload = {
      PartId: part.PartId,
      SelectedParts: [],
      RemoveParts: [],
      DryRun: true,
    };
    store.dispatch(addPartAsync(payload) as any);
  }
  
  export function removePart(part: Part) {
    let payload: RemovePartPayload = {
      PartId: part.PartId,
      RemoveParts: [],
      DryRun: true,
    };
    store.dispatch(removePartAsync(payload) as any);
  }
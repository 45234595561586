import React from 'react';
import { Route, RouteComponentProps, RouteProps, Redirect } from "react-router";
import { Store } from 'redux';
import { Store as appStore } from '../redux/reducers';
import store from '../redux/store';

export interface RestrictedRouteProps {
    hasParam?: string;
    checkStore?: (store: Store<appStore>) => boolean;
    needLogin?: boolean;
    redirect?: string;
}

const RestrictedRoute: React.FunctionComponent<RestrictedRouteProps & RouteProps> = ({ component, path, hasParam, checkStore, needLogin, redirect }) => {
    return (
        <Route path={path} render={
            (props: RouteComponentProps<any>) => {
                const { match } = props;
                function canAccessComponent(): boolean {
                    const paramOk = !hasParam || (hasParam && match.params[hasParam] && match.params[hasParam].length);
                    const storeOk = !checkStore || checkStore(store);
                    return paramOk && storeOk;
                }
                const Component = component as React.ComponentType<any>;
                return canAccessComponent() ? <Component {...props}></Component> : <Redirect to={redirect ? redirect : '/'}></Redirect>
            }
        } />
    );
}

export default RestrictedRoute;
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Finance: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 36.586 40.91">
      <title>Finance Icon</title>
      <path
        className="a"
        d="M1024.551,569.279a3.543,3.543,0,0,0-4.679-1.426l-5.494,2.6a4.476,4.476,0,0,0-3.815-2.354l-5.072-.14a5.361,5.361,0,0,1-2.305-.6l-.516-.268a9.1,9.1,0,0,0-7.228-.514v-1.844h-7.385v14.611h7.385v-2.589a2.479,2.479,0,0,1,1.8-.179l8.629,2.422.046.011a9.245,9.245,0,0,0,1.9.2,9.361,9.361,0,0,0,3.909-.853.668.668,0,0,0,.087-.048l12.511-8.092A.7.7,0,0,0,1024.551,569.279Zm-30.34,6.386v2.451h-4.926V565.964h4.926v2.574s4.114-2.348,7.961-.188l.516.268a6.774,6.774,0,0,0,2.912.752l5.073.14a3.077,3.077,0,0,1,2.924,2.451l-7.556-.208a.7.7,0,1,0-.038,1.4l8.338.23h.02a.7.7,0,0,0,.7-.682,4.464,4.464,0,0,0-.068-.91l5.636-2.663.016-.008a2.143,2.143,0,0,1,2.423.339l-11.828,7.652a7.955,7.955,0,0,1-4.863.543l-8.605-2.416c-.836-.236-2.424-.6-3.561.426Z"
        transform="translate(-988.056 -538.435)"
      />
      <path
        className="a"
        d="M1112.56,384.516a8.59,8.59,0,1,1,8.59-8.59A8.6,8.6,0,0,1,1112.56,384.516Zm0-15.775a7.185,7.185,0,1,0,7.185,7.185A7.193,7.193,0,0,0,1112.56,368.742Z"
        transform="translate(-1093.792 -358.37)"
      />
      <g transform="translate(15.502 13.544)">
        <path
          className="a"
          d="M1169.774,425.533l1.362.357a3.493,3.493,0,0,1-.463.727,1.964,1.964,0,0,1-1.507.872h-1.653a1.29,1.29,0,0,1-.938-.317c-.185-.159-.489-.449-.621-.6a1.407,1.407,0,0,1-.436-1.084v-.754h-.912v-.846h.912v-.859h-.912v-.846h.912v-.727a1.291,1.291,0,0,1,.317-.939c.159-.185.45-.489.6-.621a1.407,1.407,0,0,1,1.084-.436h1.653a1.908,1.908,0,0,1,1.507.873,4.133,4.133,0,0,1,.463.727l-1.362.357c-.251-.383-.542-.635-.648-.635h-1.586q-.079,0-.357.278c-.172.172-.278.3-.278.357v.767h2.3v.846h-2.3v.859h2.3v.846h-2.3v.793q0,.079.278.357c.172.172.3.278.357.278h1.573C1169.232,426.168,1169.523,425.917,1169.774,425.533Z"
          transform="translate(-1164.605 -419.465)"
        />
      </g>
      <rect className="a" width="1.405" height="4.214" transform="translate(12.785 3.422)" />
      <rect className="a" width="1.405" height="4.214" transform="translate(23.381 3.422)" />
      <rect className="a" width="1.405" height="7.635" transform="translate(18.083)" />
    </SvgIcon>
  );
};

export default Finance;

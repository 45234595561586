import React, { useEffect } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { Setup } from '../setups/SetupsList';
import { Part } from '../parts/PartsList';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Format from '../../utils/formatter';
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from '../../utils/addReduxProps';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ColorSelection from './colorSelection';
import { ColorVarations, Price, VehicleInformation } from '../../models/vehicle';
import { uniqBy } from '../../utils/helpers';

export interface VehicleSummaryProps {
	appliedSetup: Setup | undefined;
	appliedParts: Part[];
	model: VehicleInformation;
	totalPrice: Price;
	hasPrices: boolean;
	showDescription: boolean;
	changeBikeColor: (VariationCode:string, ColorCode:string) => void;
	variationCode: string;
	financeAvailable: boolean;
}

export const classes = (theme: Theme) => {
	return {
		priceClass: {
			color: theme.palette.primary.main,
			fontSize: '1.5em',
			display: 'inline' as "inline",
		},
		addOnCountBackground: {
			display: 'inline-block',
			background: theme.palette.primary.main,
			borderRadius: '50%',
			width: '26px',
			lineHeight: '26px',
			fontSize: '22px',
			textAlign: 'center' as "center",
			color: 'white',
			marginLeft: '1em',
			marginRight: '0.5em',
			position: 'relative' as "relative",
			top: '-2px'

		},
		addOn: {
			color: 'white',
			textTransform: 'initial' as "initial",
			fontSize: 'smaller' as "smaller",
		},
		addOnsWrapper: {
			display: 'inline-block',
		},
		count: {
			position: 'relative' as "relative",
			top: '1px',
		}
	}
}

export const VehicleSummary: React.FunctionComponent<VehicleSummaryProps & WithStyles & WithNamespaces & StateManagementProps> = props => {
	const { model, overview:{Variations}, t, configurationState, hasPrices, showDescription, applicationSettings, changeBikeColor, variationCode, currentVariation, setVehicleVariation } = props;
	const [scrollable, setScrollable] = React.useState(false);
	const [ColorArray, setColorArray] = React.useState<ColorVarations[]>([]);
	const [PriceNote, setPriceNote] = React.useState('');

	const lineHeightDesc = 34;
	const maxLines = 7;
	const maxLinesSmallerScreen = 5;
	let maxHeight = lineHeightDesc * maxLines;
	let maxHeightSmallerScreens = lineHeightDesc * maxLinesSmallerScreen;
	let DescElement: HTMLElement | null = document.getElementById('vehicle-description-wrapper');

	let lineHeightStyle: React.CSSProperties = {
	}

	let lineHeightStyleScroll: React.CSSProperties = {
		maxHeight: `${maxHeight}px`,
	}

	let lineHeightStyleSmallerScreens: React.CSSProperties = {
		maxHeight: `${maxHeightSmallerScreens}px`,
	}

	const [usedStyle, setUsedStyle] = React.useState(lineHeightStyle); 

	const handleColorSelection = (Code: string, ColorCode: string) => {
		changeBikeColor(Code, ColorCode);
	}

	const checkScrollableClass = () => {		
		let currentDescElement = document.querySelector('#vehicle-description-wrapper');
		//TODO: remove this code. Why is the dependency between finance plugin and the bike description? Shall be solved logically. 
		let financePlugin = document.querySelector('#iframe-zone');
		if (currentDescElement) {
			let height = currentDescElement.scrollHeight;
			let currentMaxLines = financePlugin ? maxLinesSmallerScreen : maxLines;
			if ((height / lineHeightDesc) > currentMaxLines) {
				if(window.innerHeight < 1000 && financePlugin) {
					setUsedStyle(lineHeightStyleSmallerScreens);
					setScrollable(true);
				}
				else {
					setUsedStyle(lineHeightStyleScroll);
					setScrollable(true);
				}
			}
			else {
				setUsedStyle(lineHeightStyle);
				setScrollable(false);
			}
		}
	}

	useEffect(() => {
		if(currentVariation === "") {
			setVehicleVariation(variationCode);
		}
	}, [currentVariation]);

	useEffect(() => {
		checkScrollableClass();
	},[DescElement]);

	useEffect(() => {
		window.addEventListener('resize', checkScrollableClass);

		setColorArray(uniqBy(Variations, (a) => a.ColorCode));
		const variation = currentVariation !== "" ? currentVariation : variationCode;
		const item = Variations.find(color => color.Code === variation);

		if(item) {
			setPriceNote(item.PriceNote)
		}

		return () => {
			window.removeEventListener('resize', checkScrollableClass);
		}
	}, [])

	if(ColorArray.length === 0) {
		return <CircularProgress className="spinner" />;
	}

	return (
		<Grid item className=''>
			{/* BIKE INFO */}
			<Grid container direction={"column"} >
				{showDescription &&
					<>
						<h3 className="d-none-mobile sub-heading">{model.Hero.Title}</h3>
						<div className="ktm-headline selected config-summary-heading">
							<h1>
								{model.Name}
							</h1>
						</div>
					</>
				}
				{!showDescription &&
					<h3 className="short-view-title">{model.Name}</h3>
				}
				{showDescription &&
					<p id="vehicle-description-wrapper" style={usedStyle} className={`vehicle-description-wrapper text overflow-auto max-height d-none-mobile d-none-tablet ${scrollable ? 'scrollable' : ''}`}>{model.Hero.Description}</p>
				}
				<ColorSelection 
					{...props} 
					ColorArray={ColorArray} 
					changeBikeColor={changeBikeColor}/>
				{/*ColorArray.length > 1 &&
					<div className="color-selection-container">
						<div className="color-selection-title">
							{t('configuration.colorSwitcherHeading')}
						</div>
						<div className={`sum-${ColorArray.length} color-selection`}>
							{ColorArray.map((color, index) => (
								<span key={color.Code} className={`color-${color.ColorCode} color-selector ${currentVariation === color.Code ? "active" : ""}`} onClick={()=>handleColorSelection(color.Code, color.ColorCode)}>{currentVariation === color.Code && <Tick/> }</span>
							))}
						</div>
					</div>
							*/}
				{hasPrices &&
					<div className={`vehicle-price-wrapper ${showDescription ? 'line-before' : ''}`}>
						<div className="vehicle-price-header">
							<div className={applicationSettings.Brand !== 'HQV' ? "uppercase" : ''}>
								{t(`configuration.summary.total.${applicationSettings.Brand}`)}
							</div>
						</div>
						<div className="vehicle-price">{Format.currency(configurationState.price)}</div>
					</div>
				}
				{hasPrices &&
				<>
					<>
						{PriceNote !== "" &&
							<div className="vehicle-price-info" dangerouslySetInnerHTML={{__html: PriceNote}}>
							</div>
						}
					</>
					<>
						{PriceNote === "" &&
							<div className="vehicle-price-info">{t('configuration.summary.priceInfo')}</div>
						}
					</>
				</>					
				}
			</Grid>
		</Grid>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withStyles(classes)(VehicleSummary)));

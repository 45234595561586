import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { CircularProgress, FormControl, MenuItem, Select } from "@material-ui/core";
import { getCulture } from "../../utils/helpers";
import ArrowHead from "../../assets/icons/arrowHead";
import store from "../../redux/store";
import Action from "../../redux/reducers/models/action";
import { SET_COUNTRY, SET_CULTURE } from "../../redux/actionTypes";
import Arrow from '../../assets/icons/arrow';
import queryString from 'query-string';
import { StateManagementProps } from "../../utils/addReduxProps";
import { RouteComponentProps } from "react-router";
import * as H from 'history';
import i18next from "i18next";
import Close from "../../assets/icons/close";

export interface CountryLanguageType {
    CountryName: string;
    CountryCode: string;
    LanguageCode: any;
    ImprintCode: string|null;
}

export interface LanguageSelectionProps {
    history: H.History;
}
export const SaveConfiguration: React.FunctionComponent<
	DialogProps & WithNamespaces & RouteComponentProps & LanguageSelectionProps & StateManagementProps
> = ({
	hideDialog,
    t,
    history,
    applicationSettings,
    countryArray
}) => {

    const [showActiveClass, setShowActiveClass] = useState<boolean>(false);
    let CountriesArray:CountryLanguageType[] = countryArray.currentCountryArray;
    const [culture, setCulture] = useState(getCulture());

    const handleChange = (event: React.ChangeEvent<{ value: string}>) => {
        let currentQueryString = history.location.search;
        let parsedQueryString = queryString.parse(currentQueryString);
        let country = (event.target.value).split('-');
        let additionalQueryString="";
        let newQueryString ="";
        if(currentQueryString && currentQueryString !== "") {
            if ('culture' in parsedQueryString && 'country' in parsedQueryString && parsedQueryString.culture) {
                parsedQueryString.culture = event.target.value;
                parsedQueryString.country = country[1]
            }
            else if("culture" in parsedQueryString){
                parsedQueryString.culture = event.target.value;
                additionalQueryString = "&country="+country[1];
            }
            else if("country" in parsedQueryString) {
                parsedQueryString.country = country[1];
                additionalQueryString = "&culture="+event.target.value;
            }
            else {
                additionalQueryString="&country="+country[1]+"&culture="+event.target.value;
            }
            newQueryString= queryString.stringify(parsedQueryString);
        }
        else {
            newQueryString = "culture="+event.target.value+"&country="+country[1];
        }
        store.dispatch<Action>({type: SET_COUNTRY, payload: country[1]});
        store.dispatch<Action>({type: SET_CULTURE, payload: event.target.value});
        i18next.changeLanguage(event.target.value as string);
        history.push(history.location.pathname+'?'+newQueryString+additionalQueryString);
        hideDialog();
        window.location.reload();
      };

    if ( CountriesArray.length === 0) {
		return <CircularProgress className="spinner" />;
    }

    if(CountriesArray.length !==0) {
        return (
            <Dialog
                disableBackdropClick={true}
                fullWidth={true}
                open={true}
                onClose={hideDialog}
                aria-labelledby="language-selection"
                className="language-selection-wrapper"
            >
                <Grid item className="close-send-mail-button-mobile show-mobile container">
                    {culture !== "" &&
                        <button className="primary" title={t("buttons.closeDialog")} onClick={hideDialog}>
                            <Close />
                        </button>
                    }
				</Grid>
                <Grid container className="back-button-area d-none-mobile" >
                    {culture !== "" && applicationSettings.Brand !== "GG" &&
                        <a href="#back" className="primary back-link" onClick={()=>hideDialog()}>
                            {applicationSettings.Brand === "HQV" &&
                                <span><Arrow /></span>
                            }
                            {t('buttons.closeDialog')}
                            {applicationSettings.Brand === "KTM" &&
                                <Arrow />
                            }
                        </a>
                    }
                    {culture !== "" && applicationSettings.Brand === "GG" &&
                        <button className="reset-button-styles primary gg-back-link" onClick={()=>hideDialog()}>
                            <Close/>
                        </button>
                    }
                </Grid>
                <Grid container className="language-selection-content">
                    <h1>{t('dialogs.languageSelection.heading')}</h1>
                    <div className="selection-wrapper">
                        <FormControl id="lang-selection-form-id" className={`language-selection-form ${showActiveClass ? 'selection-open' : ""}`} onClick={()=> setShowActiveClass(!showActiveClass)}>
                            <ArrowHead />
                            <Select
                            id="language-selection"
                            value={getCulture()}
                            onChange={handleChange}
                            IconComponent="object"
                            onClose={()=> setShowActiveClass(false)}
                            open={showActiveClass}
                            onOpen={e => {}}
                            MenuProps={{
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}
                            >
                                    {CountriesArray.map(function(country, index){
                                        return <MenuItem key={index} className={`row-${index%3} ${(getCulture() === (country.LanguageCode+'-'+country.CountryCode)) ? "selected" : ""}`}value={`${country.LanguageCode}-${country.CountryCode}`}>{country.CountryName}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
            </Dialog>
        );
    }
    return null;

};

export default withNamespaces()(SaveConfiguration);

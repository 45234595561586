import { AxiosResponse, AxiosRequestConfig } from "axios";
import http from "../../services/http";
import { Store as appState } from "../reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { TogglePartResponse } from "../../components/parts/PartsList";
import { DialogTypes } from "../../enums/dialogTypes";
import {} from "react-i18next";
import { RemovePartPayload } from "../../services/http/real";
import {
	ConflictingPartErrorResponse
} from "../../components/dialogs/conflictingParts";
import {
	applyParts,
	setConfigurationImages,
	addPrice,
	setImageTimestamp,
	showDialog,
	hideDialog,
	setConfigurationIsDirty,
	removeNoConflictingParts
} from "../actions";
import i18n from "../../i18n";
import { toastMessage } from "../../utils/helpers";
import store from "../store";

// implement custom error interface to support data types
export interface CustormAxiosError<D> {
	config: AxiosRequestConfig;
	request: any;
	response: AxiosResponse<D>;
}

export default function removePart(
	payload: RemovePartPayload | string
): ThunkAction<Promise<void>, appState, {}, any> {
	return function(dispatch: ThunkDispatch<any, any, any>) {
		return http
			.removePart(payload)
			.then(function addPartOnSuccess(
				response: AxiosResponse<TogglePartResponse>
			) {
				
				if(response.data.RulesResult) {
					dispatch(hideDialog());
					const data = response.data
					dispatch(
						showDialog({
							contentType: DialogTypes.removeConflictingPart,
							data,
							title: i18n.t("dialogs.conflictingParts.required.delete-title")
						})
					);
				}
				else if (typeof payload !== "string" && !payload.DryRun){
					dispatch(hideDialog());
					dispatch(applyParts(response.data.PartIds));
					dispatch(setConfigurationImages(response.data.FullImages));
					dispatch(addPrice(response.data.Price));
					dispatch(setImageTimestamp());
					toastMessage(i18n.t("configuration.parts.toastPartRemoved"));
					dispatch(setConfigurationIsDirty(true));
					let sendPayload:RemovePartPayload = {
						PartId : "",
						RemoveParts : [],
						DryRun : false
	
					} 
					store.dispatch(removeNoConflictingParts(sendPayload));
				}
				else if(typeof payload !== "string" && payload.DryRun) {
					let sendPayload:RemovePartPayload = {
						PartId : "",
						RemoveParts : [],
						DryRun : false
	
					} 
					sendPayload = payload;
					sendPayload.DryRun = false;
					store.dispatch(removeNoConflictingParts(sendPayload));
				}
				
			})
			.catch(function handeErrorResponse(
				error: CustormAxiosError<ConflictingPartErrorResponse>
			) {
				console.log(error);
			});
	};
}

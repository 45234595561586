import React, { useEffect, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import http from "../../../services/http";
import { WithNamespaces } from "react-i18next";
import { ShowDialogProps } from "../../../redux/actions";
import { DialogTypes } from "../../../enums/dialogTypes";
import { AxiosError, AxiosResponse } from "axios";
import { getCulture } from "../../../utils/helpers";
import { ApplicationSettings } from "../../../redux/reducers/applicationSettings";
import Save from "../../../assets/icons/save_3d_ktm";
import { store } from "../../../redux/store";

export type UserProfile = {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  photoURL: string;
  thumbnailURL: string;
};

export type UserData = {
  UID: string;
  UIDSignature: string;
  profile: UserProfile;
};

export type CDCLoginProps = {
  onUserLoggedIn?(data: UserData | undefined): void | undefined;
  mode: "saveUserConfigurationCDC" | "loadAccountInfo" | "configuratorLogin";
  showSaveIcon?: boolean;
  onSaveIconClicked?(): void;
  onSaveSuccess?(): void;
  userProfileChecked?: boolean;
  removeTabIndexToMakeCDCWorking?(): void;
  mobile?: boolean;
  checkUserLogin?: () => void;
  isSummary?: boolean;
};

export const getScrollableState = () => {
  var cdcElement = document.querySelector("#cdc-login-container_content");
  var elementContainer = document.querySelector("#cdc-login-container");
  var elementHeight = cdcElement ? cdcElement.clientHeight : 0;
  var containerHeight = elementContainer ? elementContainer.clientHeight : 0;
  return Boolean(containerHeight < elementHeight);
};

export type CDCProps = StateManagementProps & WithNamespaces & CDCLoginProps;

const CdcLogin: React.FunctionComponent<CDCProps> = (props) => {
  const {
    t,
    applicationSettings,
    mode,
    appliedParts,
    showSaveIcon,
    setConfigurationId,
    onUserLoggedIn,
    onSaveIconClicked,
    onSaveSuccess,
    userProfileChecked,
    removeTabIndexToMakeCDCWorking,
    mobile,
    checkUserLogin,
    cdcSettings,
    setCdcSettings,
  } = props;

  useEffect(() => {
    if (!cdcSettings && !showButton) {
      setShowButton(true);
    }
  }, [cdcSettings]);

  var [pluginInitialized, setpluginInitialized] = useState(false);
  var [showButton, setShowButton] = useState(false);
  var [savedStatus, setSavedStatus] = useState(true);
  var [userLoggedIn, setUserLoggedIn] = useState(false);
  /** indicator that the component was disposed so that the screenset creation can be aborted */
  const [aborted, setAborted] = useState(false);

  const brand = applicationSettings.Brand.toLowerCase();

  useEffect(() => {
    const pluginPromise = makeCancelable(loadCDCPluginScript(applicationSettings));
    if (!pluginInitialized) {
      pluginPromise.promise
        .then(() => {
          try {
            setShowButton(true);
            setpluginInitialized(true);
            if (mode === "configuratorLogin") {
              showLogin();
            }
            if (mode == "loadAccountInfo") {
              loadAccountInfo();
            }
          } catch (err) {
            console.error(err);
          }
        })
        .catch((e) => {
          setpluginInitialized(false);
          setShowButton(false);
        });
    } else {
      setShowButton(true);
      if (mode === "configuratorLogin") {
        showLogin();
      }
    }

    return () => {
      pluginPromise.cancel();
      setAborted(true);
      (window as any).gigya.accounts.hideScreenSet(brand + "-in-page");
    };
  }, []);

  
  const setCDCSettingsFunction=(open:boolean) => {
    setCdcSettings(open);
    if(!open) {
      (window as any).gigya.accounts.hideScreenSet(brand + "-in-page");
    }
  }

  const showLogin = () => {
    store.dispatch(setCdcSettings(true));
    const lang = getCulture().substr(0, 2);

    setShowButton(false);

    (window as any).gigya.accounts.showScreenSet({
      onError: function (err: any) {
        console.log(err);
        setShowButton(false);
      },
      onLoad: function () {
        setpluginInitialized(true);
      },
      onHide: function () {
        setShowButton(true);
        if (mode === "configuratorLogin" && checkUserLogin) {
          checkUserLogin();
        }
        setCDCSettingsFunction(false);
      },
      loaded: function () {
        setShowButton(true);
      },
      onAfterScreenLoad: function () {
      },
      onBeforeScreenLoad: function (event) {
        if (aborted) {
          // see gigya documentation
          return false;
        }
      },
      onAfterSubmit: function (eventObj) {
        if (eventObj.response && eventObj.response.errorCode == 0) {
          if (mode == "saveUserConfigurationCDC") {
            saveUserConfigurationCDC();
          } else {
            loadAccountInfo();
          }
          setShowButton(true);
        }
      },
      startScreen: brand + "-login",
      screenSet: brand + "-in-page",
      lang: lang,
      containerID: "cdc-login-container",
    });
  };

  const saveUserConfigurationCDC = () => {
    setShowButton(true);
    (window as any).gigya.accounts.getAccountInfo({
      callback: function (data: any) {
        (window as any).gigya.accounts.getJWT({
          callback: function (JWTdata: any) {
            setSavedStatus(false);
            http
              .saveUserConfigurationCDC(data.UID, appliedParts, `Bearer ${JWTdata.id_token}`)
              .then((response: AxiosResponse<{ ConfigurationId: number }>) => {
                setConfigurationId(response.data.ConfigurationId);
                setSavedStatus(true);
                showSavedSuccess(true);
                onSaveSuccess && onSaveSuccess();
              })
              .catch((err: AxiosError) => {
                // log error if nothing else applies
                console.error("Error during save user configuration", err);
                showSavedSuccess(false);
              });
          },
        });
      },
    });
  };

  const loadAccountInfo = () => {
    setShowButton(true);
    const gigya = (window as any).gigya;
    gigya &&
      gigya.accounts &&
      gigya.accounts.getAccountInfo &&
      gigya.accounts.getAccountInfo({
        callback: function (data: UserData) {
          if (data.profile) {
            setUserLoggedIn(true);
            if (onUserLoggedIn) onUserLoggedIn(data);
          } else {
            if (onUserLoggedIn) onUserLoggedIn(undefined);
          }
        },
      });
  };

  const logOut = () => {
    (window as any).gigya.accounts.logout();
    setUserLoggedIn(false);
    if (onUserLoggedIn) onUserLoggedIn(undefined);
  };

  // called when the plug in loaded
  const onClickButton = () => {
    if (!(pluginInitialized && showButton)) {
      return;
    }

    if ((window as any).gigya && (window as any).gigya.hasSession) {
      (window as any).gigya
        .hasSession()
        .then(function (sessionExist: any) {
          if (sessionExist) {
            setShowButton(false);
            if (mode == "saveUserConfigurationCDC") {
              saveUserConfigurationCDC();
            } else {
              loadAccountInfo();
            }

            setShowButton(true);
          } else {
            showLogin();
          }
        })
        .catch((err: any) => {
          console.log(err);
          showSavedSuccess(false);
        });
    } else {
      if (mode == "saveUserConfigurationCDC") {
        showSavedSuccess(false);
      }
    }
    onSaveIconClicked && onSaveIconClicked();
  };

  const showSavedSuccess = (success: boolean) => {
    const dialogProps: ShowDialogProps = {
      title: success ? `${t("dialogs.savedConfiguration.title")}` : `${t("dialogs.savedConfiguration.error.title")}`,
      data: { success },
      contentType: DialogTypes.savedConfiguration,
    };
    props.showDialog(dialogProps);
  };

  const loadButtonContent = () => {
    if (mode === "saveUserConfigurationCDC") {
      return (
        <div className={`${!showSaveIcon ? "flex-container centered column save-button summary-button-wrapper" : "summary-button-wrapper"}`}>
          {showSaveIcon && showButton && (
            <div className={`cdc-login ${pluginInitialized ? "" : "disabled"}`}>
              <a className={`button-text ${!savedStatus ? "saving-animation" : ""}`} onClick={onClickButton}>
                <Save />
              </a>
            </div>
          )}
          {!showSaveIcon && (
            <div className="save-configuration secondary">
              <div className={`cdc-login ${pluginInitialized ? "" : "disabled"}`}>
                {!showButton && <span className="button-text saving-animation">loading</span>}
                {showButton && mode === "saveUserConfigurationCDC" && !showSaveIcon && (
                  <span className={`button-text ${!savedStatus ? "saving-animation" : ""}`} onClick={onClickButton}>
                    {t("buttons.save")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      );
    } else if (mode === "loadAccountInfo") {
      return (
        <div className={mobile ? "mobile-buttons" : `step-action ${userProfileChecked ? "checked" : "unchecked"}`} onClick={removeTabIndexToMakeCDCWorking}>
          <div className={`cdc-login ${pluginInitialized ? "" : "disabled"}`}>
            {showButton && !userLoggedIn && (
              <span className={`button-text ${!savedStatus ? "saving-animation" : ""}`} onClick={onClickButton}>
                {t("buttons.login")}
              </span>
            )}

            {showButton && userLoggedIn && (
              <span className={`button-text ${!savedStatus ? "saving-animation" : ""}`} onClick={logOut}>
                {t("buttons.logout")}
              </span>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  if (aborted) return null;

  return <>{loadButtonContent()}</>;
};

export const isReady = (): boolean => {
  return (window as any).gigya && (window as any).gigya.isReady;
};

export const loadCDCPluginScript = (applicationSettings: ApplicationSettings) => {
  const existingScript = document.getElementById("cdc");

  return new Promise<void>((resolve, reject) => {
    if (!existingScript) {
      let url = applicationSettings.CdcApplicationSettings.Url;
      let api = applicationSettings.CdcApplicationSettings.Api;
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.charset = "UTF-8";
      script.setAttribute("id", "cdc");
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject();
        console.error("error script not loaded");
      };

      document.head.appendChild(script);
      script.src = url + api;
    } else {
      resolve();
    }
  });
};

export const makeCancelable = (promise: Promise<any>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CdcLogin);

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MinusIconProps } from "./minus";

const MinusKTM3D: React.FunctionComponent<MinusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 96 96" id={props.identifyer}>
      <title>Minus Icon</title>
      <rect x="31.01" y="41.96" width="34.41" height="10.73" />
    </SvgIcon>
  );
};

export default MinusKTM3D;

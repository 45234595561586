import { SET_PRESELECTED_VARIATION_CODE} from "../actionTypes";
import Action from './models/action';

export interface PreselectedVariationCodeState {
	code: string;
}

export const initialState: PreselectedVariationCodeState = {
	code: "",
};

export default function PreselectedVariationCodeReducer(state: PreselectedVariationCodeState = initialState, action: Action): PreselectedVariationCodeState {
	switch (action.type) {
		case SET_PRESELECTED_VARIATION_CODE: {
			return {
				...action.payload,  
			};
		}    
		default: 
			return state;
	}
}
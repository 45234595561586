import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PinCircle: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 34 34" fill="none" className="icon-pin-cycle">
      <title>PinCircle Icon</title>
      <circle cx="17" cy="17" r="14.75" stroke="white" strokeWidth="4.5" />
    </SvgIcon>
  );
};

export default PinCircle;

import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';
import translationIT from './locales/it/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

export enum Languages {
    en = 'en',
    de = 'de',
    fr = 'fr',
    es = 'es',
    it = 'it'
}

const resources: { [index in Languages]: any } = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    fr: {
        translation: translationFR,
    },
    es: {
        translation: translationES,
    },
    it: {
        translation: translationIT,
    },
};

const detectorOptions = {
    // from where user language should be detected
    // for example add: 'navigator' to order-array if querystring is no longer used later
    order: ['querystring'],
    lookupQuerystring: 'culture',
}

i18n
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        resources,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        detection: detectorOptions,
    })

export default i18n;
import React, { useEffect, useMemo, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import SoundOn from "../../../assets/icons/sound_on";
import SoundOff from "../../../assets/icons/sound_off";
import PDF from "../../../assets/icons/pdf";
import { Share } from "@material-ui/icons";
import Screenshot from "../../../assets/icons/screenshot_ktm";
import CdcLogin from "../cdc/CdcLogin";
import { getBrandLogoSvgComponent } from "../../../redux/reducers/applicationSettings";
import { CircularProgress } from "@material-ui/core";
import { StreamState } from "../../../lib/streaming/core";

export interface TechnicalDetailsProps {
  engineReady: boolean;
  loggedIn: boolean;
  soundON: boolean;
  setSoundON: (soundONOff: boolean) => void;
  sendSoundOnOff: (soundONOff: boolean) => void;
  isPDFBusy: boolean;
  takeScreenshotAndDownloadPDF: (onStartedCallback?: () => void, onFinishedCallback?: () => void) => void;
  shareConfiguration: () => void;
  takeScreenshot: () => void;
  onSaveSuccess: () => void;
  currentStreamState: StreamState;
}

type AllProps = StateManagementProps & WithNamespaces & TechnicalDetailsProps;

const TechnicalDetails3D: React.FunctionComponent<AllProps> = (props) => {
  const {
    t,
    selectedModel,
    isXBow,
    engineReady,
    loggedIn,
    setSoundON,
    sendSoundOnOff,
    soundON,
    isPDFBusy,
    applicationSettings,
    takeScreenshotAndDownloadPDF,
    shareConfiguration,
    takeScreenshot,
    onSaveSuccess,
    currentStreamState,
  } = props;

  const brandSvg = getBrandLogoSvgComponent(applicationSettings.Brand);

  const onDownloadClicked = (event) => {
    takeScreenshotAndDownloadPDF();
  };

  const renderStreamingState = () => {
    let svgClass = "";
    let streamingStatus = "";
    switch (currentStreamState) {
      case StreamState.INIT_DONE:
        svgClass = "svg-done";
        streamingStatus = "stream connected";
        break;
      case StreamState.BITRATE_DROPPED:
        svgClass = "svg-dropped";
        streamingStatus = "slow connection";
        break;
    }

    return (
      <svg width="28" height="29" xmlns="http://www.w3.org/2000/svg" className={`connection-svg ${svgClass}`}>
        <g>
          <title>{streamingStatus}</title>
          <rect id="svg_1" height="29" width="4" y="0" x="3" />
          <rect id="svg_2" height="22" width="4" y="7" x="9" />
          <rect id="svg_3" height="15" width="4" y="14" x="15" />
          <rect id="svg_4" height="8" width="4" y="21" x="21" />
          <rect transform="rotate(-45 13.6278 15.0954)" id="svg_7" height="1.48867" width="35.38871" y="14.35111" x="-4.06652"/>
        </g>
      </svg>
    );
  };

  return (
    <div className="tecnical-details-wrapper">
      <div className="tec-details button-section">
        <div id="title-3d" className="">
          <h2>
            <span className="headline no-brush">{selectedModel.Name}</span>
          </h2>
        </div>

        <div className="overview-border configview"></div>

        <div className="ctas">
          {!isXBow && (
            <>
              {engineReady && loggedIn && (
                <a
                  onClick={() => {
                    setSoundON(!soundON);
                    sendSoundOnOff(!soundON);
                  }}
                  className={`mute-button ${soundON ? "active" : "inactive"}`}
                >
                  {soundON ? <SoundOn /> : <SoundOff />}
                </a>
              )}
              <div className="cta-devider"></div>
            </>
          )}
          {!isPDFBusy ? (
            <a className="pdf-button" onClick={onDownloadClicked} target="_blank">
              <PDF />
            </a>
          ) : (
            <CircularProgress className="spinner pdfspinner" />
          )}

          <a onClick={() => shareConfiguration()}>
            <Share />
          </a>
          {applicationSettings.CdcApplicationSettings.CdcEnabled && <CdcLogin {...props} mode="saveUserConfigurationCDC" showSaveIcon={true} onSaveSuccess={onSaveSuccess} />}
          <a className="screenShot" onClick={() => takeScreenshot()}>
            <Screenshot />
          </a>
          {/*<div>{renderStreamingState()}</div>*/}
          <div
            id="screenshot-div"
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              visibility: "hidden",
            }}
          >
            <div
              className="brandlogo-in-main-header brandlogo-in-configheader-screenshot logo"
              style={{
                position: "fixed",
                top: "0px",
                right: "20px",
                padding: 0,
              }}
            >
              {brandSvg}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalDetails3D);

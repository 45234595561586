import setups, { SetupsState } from "./setups";
import parts, { PartsState } from "./parts";
import dialog, { DialogState } from "./modals";
import load from "./load";
import models from "./models";
import configuration, {
  ConfigurationState,
  initialConfigurationState,
} from "./configuration";
import vehicleOverview, {
  VehicleOverviewState,
  vehicleInitialState,
} from "./vehicleOverview";
import toast, { ToastMessageState } from "./toast";
import views, { ViewsState, viewsInitialState } from "./views";
import { LOAD_CONFIGURATION } from "../actionTypes";
import Action from "./models/action";
import { initialState as modelInitialState } from "./models";
import { initialState as dialogInitialState } from "./modals";
import { initialState as partsInitialState } from "./parts";
import { initialState as setupsInitialState } from "./setups";
import { initialState as toastInitialState } from "./toast";
import { initialState as userGuidanceInitialState } from "./userGuidance";
import currentCountry, {CurrentCountryState, initialState as currentCountryInitialState} from "./currentCountry";
import countryArray, {CountryArrayState, initialState as countryArrayInitialState} from "./countryArray";
import ApplicationSettingsReducer, {
  ApplicationSettings,
  initialState as applicationSettingsInitialState,
} from "./applicationSettings";
import userGuidance, { UserGuidanceState } from "./userGuidance";
import imageArray, { ImageArrayState, initialState as imageArrayInitialState } from "./imageArray";
import preselectedVariationCode, {PreselectedVariationCodeState, initialState as PreselectedVariationCodeStateInitialState} from "./preselectedVariationCode";
import { MotorcycleModelResponse } from "../../models/vehicle";


export interface Store {
  applicationSettings: ApplicationSettings;
  dialog: DialogState;
  configurationState: ConfigurationState;
  views: ViewsState;
  modelsResponse: MotorcycleModelResponse;
  vehicleOverviewState: VehicleOverviewState;
  parts: PartsState;
  setups: SetupsState;
  toast: ToastMessageState;
  userGuidance: UserGuidanceState;
  currentCountry: CurrentCountryState;
  countryArray: CountryArrayState;
  imageArray: ImageArrayState;
  preselectedVariationCode: PreselectedVariationCodeState;
}

export const initialReduxState: Store = {
  applicationSettings: { ...applicationSettingsInitialState },
  dialog: { ...dialogInitialState },
  configurationState: {
    ...initialConfigurationState,
  },
  views: { ...viewsInitialState },
  modelsResponse: { ...modelInitialState },
  vehicleOverviewState: { ...vehicleInitialState },
  parts: { ...partsInitialState },
  setups: { ...setupsInitialState },
  toast: { ...toastInitialState },
  userGuidance: {...userGuidanceInitialState },
  currentCountry: {...currentCountryInitialState},
  countryArray: {...countryArrayInitialState},
  imageArray: {...imageArrayInitialState},
  preselectedVariationCode:{...PreselectedVariationCodeStateInitialState}
};

export default function rootReducer(
  state: Store = initialReduxState,
  action: Action
): Store {
  if (action.type === LOAD_CONFIGURATION) {
    return load(state, action);
  }

  return {
    applicationSettings: ApplicationSettingsReducer(
      state.applicationSettings,
      action
    ),
    dialog: dialog(state.dialog, action),  
    configurationState: configuration(state.configurationState, action),
    views: views(state.views, action),
    modelsResponse: models(state.modelsResponse, action),
    vehicleOverviewState: vehicleOverview(
      state.vehicleOverviewState,
      action as Required<Action>
    ),
    parts: parts(state.parts, action, state),   
    setups: setups(state.setups, action),    
    toast: toast(state.toast, action),
    userGuidance: userGuidance(state.userGuidance, action),
    currentCountry: currentCountry(state.currentCountry,action),
    countryArray: countryArray(state.countryArray, action),
    imageArray: imageArray(state.imageArray, action),
    preselectedVariationCode: preselectedVariationCode(state.preselectedVariationCode,action)
  };
}

import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import {
	FormManager,
	FormControlState,
	createControl,
	DefaultValidators as Validators
} from "../../utils/FormManager";
import {
	getPreservedSaveDetailsData,
	getConfigurationId,
	getLocaleQuery
} from "../../utils/helpers";
import store from "../../redux/store";
import { preserveSaveDetailsData } from "../../redux/actions";
import { SaveDetailsData } from "./saveConfiguration";

const firstnameControl = createControl(
	"Firstname",
	"",
	[Validators.notEmpty],
	true
);
const lastnameControl = createControl(
	"Lastname",
	"",
	[Validators.notEmpty],
	true
);
const commentControl = createControl("Comment", "");
const emailControl = createControl("Email", "@", [Validators.email], true);
const formManager = new FormManager();

export interface ExportConfigurationValueNames {
	Firstname: string;
	Lastname: string;
	Comment: string;
	Email: string;
}

export const ExportConfiguration: React.FunctionComponent<
	DialogProps & WithNamespaces
> = ({ title, hideDialog, t }) => {
	// intialize state
	const [firstname, setFirstname] = useState<FormControlState>(
		firstnameControl
	);
	const [lastname, setLastname] = useState<FormControlState>(lastnameControl);
	const [comment, setComment] = useState<FormControlState>(commentControl);
	const [email, setEmail] = useState<FormControlState>(emailControl);

	// load form control values from redux store
	useEffect(() => {
		const {
			Firstname: preservedName,
			Lastname: preservedSurName,
			Comment: preservedComment
		} = getPreservedSaveDetailsData();

		setFirstname({ ...firstname, value: preservedName });
		setLastname({ ...lastname, value: preservedSurName });
		setComment({ ...comment, value: preservedComment });
		// eslint-disable-next-line
	}, []);

	const saveCustomerData = (values: ExportConfigurationValueNames) => {
		const { Vin, OrderNumber } = getPreservedSaveDetailsData();

		store.dispatch(
			preserveSaveDetailsData({
				...values,
				Vin,
				OrderNumber
			} as SaveDetailsData)
		);
	};

	const customerNameIsInvalid = () =>
		!formManager.controlIsValid(firstnameControl) ||
		!formManager.controlIsValid(lastnameControl);

	const getDownloadProps = () => {
		if (customerNameIsInvalid()) {
			return {};
		}

		const values = formManager.getHashValues<ExportConfigurationValueNames>();
		const firstname = encodeURIComponent(values.Firstname);
		const lastname = encodeURIComponent(values.Lastname);
		const href = `/api/posconfigurator/configuration/${getConfigurationId()}/export${getLocaleQuery()}&firstname=${firstname}&lastname=${lastname}`;
		return { href };
	};

	const saveAndClose = () => {
		saveCustomerData(
			formManager.getHashValues<ExportConfigurationValueNames>()
		);
		hideDialog();
	};

	// update controls on every render
	formManager.addAllControls([
		[firstname, setFirstname],
		[lastname, setLastname],
		[comment, setComment],
		[email, setEmail]
	]);

	const emailError =
		!email.pristine && !formManager.controlIsValid(emailControl);

	return (
		<Dialog
			open={true}
			disableBackdropClick={true}
			onClose={hideDialog}
			fullWidth={true}
			maxWidth={"sm"}
			aria-labelledby="export configuration modal"
		>
			<DialogTitle id="setup-parts-dialog-title">
				<Grid container justify="space-between" direction="row">
					<h2 className="setups-headline underline uppercase">
						title
					</h2>
					<IconButton className="dialog-hide-icon" onClick={hideDialog}>
						<Clear />
					</IconButton>
				</Grid>
			</DialogTitle>
			<form noValidate>
				<DialogContent>
					<TextField
						id="firstname"
						label={t("dialogs.exportConfiguration.name")}
						value={firstname.value}
						fullWidth={true}
						onChange={({ target: { value } }) =>
							setFirstname({ ...firstname, value })
						}
						margin="normal"
						required={true}
						error={
							!firstname.pristine &&
							!formManager.controlIsValid(firstnameControl)
						}
						helperText={t("errors.required")}
						autoFocus
					/>
					<TextField
						id="lastname"
						label={t("dialogs.exportConfiguration.surname")}
						value={lastname.value}
						fullWidth={true}
						onChange={({ target: { value } }) =>
							setLastname({ ...lastname, value })
						}
						margin="normal"
						required={true}
						error={
							!lastname.pristine && !formManager.controlIsValid(lastnameControl)
						}
						helperText={t("errors.required")}
					/>
					{/*<TextField
						id="comment"
						label={t("dialogs.exportConfiguration.comment")}
						value={comment.value}
						fullWidth={true}
						onChange={({ target: { value } }) =>
							setComment({ ...comment, value })
						}
						margin="normal"
						multiline
					/>*/}
					<TextField
						id="email"
						label={t("dialogs.exportConfiguration.sendTo")}
						value={email.value}
						fullWidth={true}
						onChange={({ target: { value } }) => setEmail({ ...email, value })}
						margin="normal"
						required={true}
						error={emailError}
						helperText={emailError ? t("errors.invalidEmail") : ""}
					/>
				</DialogContent>
				<DialogActions>
					<a {...getDownloadProps()} download>
						<button
							disabled={customerNameIsInvalid()}
							type="button"
							onClick={saveAndClose}
							className="secondary"
						>
							{t("buttons.download")}
						</button>
					</a>
					{/*
						<div className="button-spacer" />
						<button type="button" onClick={handleSend} className="primary">
							{t("buttons.send")}
						</button>
					*/}

				</DialogActions>
			</form>
		</Dialog>
	);
};

export default withNamespaces()(ExportConfiguration);

import React, { useEffect, useMemo, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import { sendMessage } from "../../../lib/streaming/core";
import { ColorOption, ColorSelectionCategoriesModel } from "../../../models/vehicle";
import { Collapse } from "@material-ui/core";
import XBowColorSelector from "./XBowColorSelectorComponent";
import { CustomColorConfigurationsPayload } from "../../../services/http/real";
import { Part, PartImages } from "../../parts/PartsList";
import ArrowHead from "../../../assets/icons/arrowHead";
import XBowColorFinishSelector from "./xBowColorFinishSelection";

export interface ColorProps {
  setSubMenuState?: (open: boolean) => void;
  sendColorData: (CustomColorConfiguration: CustomColorConfigurationsPayload) => void;
  isMobile: boolean;
  updateIsColorSelectorOpen: (open: boolean) => void;
  isColorSelectorOpen: boolean;
}

export type XBowColorsProps = ColorProps & StateManagementProps & WithNamespaces;

const XBowColors: React.FunctionComponent<XBowColorsProps> = (props) => {
  const { isColorSelectorOpen, updateIsColorSelectorOpen, overview, configurationState, appliedParts, sendColorData, isMobile } = props;

  const { BodyColor, BrakeCaliperColor, RimColor, SuspensionSpringAndLogoColor, ContrastStitchingColor } = overview.RenderSettings!.Tools.ColorSelection.PreDefined;

  const paraseFinish = (savedColorOption: string | undefined) => {
    if (savedColorOption) {
      if (savedColorOption.includes("nb") && savedColorOption.includes(matte.toLowerCase())) {
        return matte;
      }
    }
    return glossy;
  };

  const parseIncomingColors = (ColorArray: ColorOption[], savedColorOption: string | undefined) => {
    if (savedColorOption) {
      // individual color
      if (savedColorOption.indexOf("#") >= 0) {
        const parsedHexCode = savedColorOption.split(";")[0].split(" ")[2]; // TODO this also contains matt or glossy, set accordingly

        const isMatte: boolean = savedColorOption.includes("matte");

        const customColorToAdd: ColorOption = {
          ColorName: `Individual Color ${customColors.length + 1}`,
          FrontendColorCode: parsedHexCode,
          RenderColorCode: "CUSTOM_COLOR",
          IsMatte: isMatte,
        };

        // only push color if not available - otherwise re-draw messes it up
        if (customColors.findIndex((x) => x.FrontendColorCode == customColorToAdd.FrontendColorCode) <= 0) {
          customColors.push(customColorToAdd);
        }

        if (isMatte) {
          setColorFinish(matte);
        }
        return customColorToAdd;
        
      } else {
        const isPreDefinedAndMatte = savedColorOption.split(";").length > 1;
        let foundIndex = 0;
        ColorArray.forEach((colors, index) => {
          if (savedColorOption.indexOf(colors.RenderColorCode) >= 0 && colors.IsMatte === isPreDefinedAndMatte) {
            foundIndex = index;
            return;
          }
        });
        return ColorArray[foundIndex];
      }
    }
    return ColorArray[0];
  };

  /**
   * This sets the color Setting to Glossy or Matte
   */
  const matte = "Matte";
  const glossy = "Glossy";

  /**
   * The state has to be restored properly not only for the command that is being sent to the engine but also frontend wise and command-wise
   */
  const [colorFinish, setColorFinish] = useState(() => paraseFinish(configurationState.VehicleSettings.CustomColorConfiguration.SelectedBodyColor));
  const [customColors, setCustomColors] = useState<ColorOption[]>([]);
  const [selectedColorBody, setSelectedColorBody] = useState(() => parseIncomingColors(BodyColor, configurationState.VehicleSettings.CustomColorConfiguration.SelectedBodyColor));
  const [selectedColorCalipers, setSelectedColorCalipers] = useState(() => parseIncomingColors(BrakeCaliperColor, configurationState.VehicleSettings.CustomColorConfiguration.SelectedCaliperColor));
  const [selectedColorRims, setSelectedColorRims] = useState(() => parseIncomingColors(RimColor, configurationState.VehicleSettings.CustomColorConfiguration.SelectedRimsColor));
  const [selectedColorSuspensionAndLogo, setSelectedColorSuspensionAndLogo] = useState(() =>
    parseIncomingColors(SuspensionSpringAndLogoColor, configurationState.VehicleSettings.CustomColorConfiguration.SelectedSuspensionAndLogoColor)
  );
  const [selectedColorContrastStitching, setSelectedColorContrastStitching] = useState(() =>
    parseIncomingColors(ContrastStitchingColor, configurationState.VehicleSettings.CustomColorConfiguration.SelectedStitchingColor)
  );

  const initialColorCommandState = (command?: string) => {
    if (command && command !== "") {
      return command;
    }
    return "";
  };

  const [bodyColorCommand, setBodyColorCommand] = useState(() => initialColorCommandState(configurationState.VehicleSettings.CustomColorConfiguration.SelectedBodyColor));
  const [brakeCaliperColorCommand, setBrakeCaliperColorCommand] = useState(() => initialColorCommandState(configurationState.VehicleSettings.CustomColorConfiguration.SelectedCaliperColor));
  const [rimColorCommand, setRimColorCommand] = useState(() => initialColorCommandState(configurationState.VehicleSettings.CustomColorConfiguration.SelectedRimsColor));
  const [suspensionSpringAndLogoColorCommand, setsuspensionSpringAndLogoColorCommand] = useState(
    () => initialColorCommandState(configurationState.VehicleSettings.CustomColorConfiguration.SelectedSuspensionAndLogoColor)
  );
  const [contrastStitchingColorCommand, setContrastStitchingColorCommand] = useState(() => initialColorCommandState(configurationState.VehicleSettings.CustomColorConfiguration.SelectedStitchingColor));

  useEffect(() => {
    const patchedCommand = bodyColorCommand.includes("indvcol") && isMatte() ? `${bodyColorCommand};confopt ExteriorFinish matte` : bodyColorCommand;
    sendColorData({
      SelectedBodyColor: patchedCommand,
      SelectedRimsColor: rimColorCommand,
      SelectedCaliperColor: brakeCaliperColorCommand,
      SelectedStitchingColor: contrastStitchingColorCommand,
      SelectedSuspensionAndLogoColor: suspensionSpringAndLogoColorCommand,
    } as CustomColorConfigurationsPayload);
  }, [bodyColorCommand, brakeCaliperColorCommand, rimColorCommand, suspensionSpringAndLogoColorCommand, contrastStitchingColorCommand]);

  const selectedCommandMapping: { [key: string]: any } = {
    BodyColor: setBodyColorCommand,
    BrakeCaliperColor: setBrakeCaliperColorCommand,
    RimColor: setRimColorCommand,
    SuspensionSpringAndLogoColor: setsuspensionSpringAndLogoColorCommand,
    ContrastStitchingColor: setContrastStitchingColorCommand,
  };

  const predefinedColors: { [key: string]: ColorOption[] } = {
    BodyColor: BodyColor,
    BrakeCaliperColor: BrakeCaliperColor,
    RimColor: RimColor,
    SuspensionSpringAndLogoColor: SuspensionSpringAndLogoColor,
    ContrastStitchingColor: ContrastStitchingColor,
  };

  const selectedColorsMapping = useMemo(() => {
    return {
      BodyColor: selectedColorBody,
      BrakeCaliperColor: selectedColorCalipers,
      RimColor: selectedColorRims,
      SuspensionSpringAndLogoColor: selectedColorSuspensionAndLogo,
      ContrastStitchingColor: selectedColorContrastStitching,
    };
  }, [selectedColorBody, selectedColorCalipers, selectedColorRims, selectedColorSuspensionAndLogo, selectedColorContrastStitching]);

  const colorCategories = overview.RenderSettings!.Tools.ColorSelection.Categories;
  const [currentSection, setCurrentSection] = useState(colorCategories[0]);
  const [lastSelectedColorSection, setLastSelectedColorSection] = useState({} as ColorSelectionCategoriesModel);

  /**
   * this applies only to the xbow as bikes do not have color data
   */
  useEffect(() => {
    colorDataToFakeParts().forEach((x) => {
      let parts = appliedParts.filter((y) => y.PartId === x.PartId);
      if (parts.length === 0) {
        appliedParts.push(x);
      } else {
        let index = appliedParts.findIndex((y) => y.PartId === x.PartId);
        appliedParts[index] = x;
      }
    });
  }, [selectedColorBody, selectedColorCalipers, selectedColorRims, selectedColorSuspensionAndLogo, selectedColorContrastStitching, colorFinish]);

  const parseText = (color: ColorOption, withFinish: boolean = false): string => {
    if (color.RenderColorCode === "CUSTOM_COLOR") {
      return withFinish ? color.FrontendColorCode + " " + colorFinish + " (custom)" : color.FrontendColorCode + " (custom)";
    } else if (color.RimSize) {
      return color.ColorName;
    }
    return color.ColorName + " (standard)";
  };

  const colorDataToFakeParts = (): Part[] => {
    let result: Part[] = [];
    selectedColorBody &&
      result.push({
        CanBeRendered: false,
        Name: `Color Body`,
        CategoryName: "SelectedBodyColor",
        PartId: "-12345",
        Racing: true,
        Price: null,
        Description: parseText(selectedColorBody, true),
        Images: {} as PartImages,
        ApprovalInfo: "xbow",
        IsColorPart: true,
      } as Part);

    selectedColorCalipers &&
      result.push({
        CanBeRendered: false,
        Name: `Color Calipers`,
        CategoryName: "SelectedCaliperColor",
        PartId: "-12346",
        Racing: true,
        Price: null,
        Description: parseText(selectedColorCalipers),
        Images: {} as PartImages,
        ApprovalInfo: "xbow",
        IsColorPart: true,
      } as Part);

    selectedColorRims &&
      result.push({
        CanBeRendered: false,
        Name: `Color Rims`,
        CategoryName: "SelectedRimsColor",
        PartId: "-12347",
        Racing: true,
        Price: null,
        Description: parseText(selectedColorRims),
        Images: {} as PartImages,
        ApprovalInfo: "xbow",
        IsColorPart: true,
      } as Part);

    selectedColorContrastStitching &&
      result.push({
        CanBeRendered: false,
        Name: `Color Contrast Stitching`,
        CategoryName: "SelectedStitchingColor",
        PartId: "-12348",
        Racing: true,
        Price: null,
        Description: parseText(selectedColorContrastStitching),
        Images: {} as PartImages,
        ApprovalInfo: "xbow",
        IsColorPart: true,
      } as Part);

    selectedColorSuspensionAndLogo &&
      result.push({
        CanBeRendered: false,
        Name: `Color Suspension and Logo`,
        CategoryName: "SelectedSuspensionAndLogoColor",
        PartId: "-12349",
        Racing: true,
        Price: null,
        Description: parseText(selectedColorSuspensionAndLogo),
        Images: {} as PartImages,
        ApprovalInfo: "xbow",
        IsColorPart: true,
      } as Part);
    return result;
  };

  const setColorMapping: { [key: string]: any } = {
    BodyColor: setSelectedColorBody,
    BrakeCaliperColor: setSelectedColorCalipers,
    RimColor: setSelectedColorRims,
    SuspensionSpringAndLogoColor: setSelectedColorSuspensionAndLogo,
    ContrastStitchingColor: setSelectedColorContrastStitching,
  };

  const setColorForCurrentSegment = (color: ColorOption) => {
    setColorMapping[currentSection.Value](color);
  };

  /**
   * This sets the pre-defined color for the currently active category
   */
  const { Command, CommandIndividual } = overview.RenderSettings!.Tools.ColorSelection;

  const getPredefinedColors = () => predefinedColors[currentSection.Value];

  const setPredefinedColor = (color: ColorOption) => {
    updateColorSelectionCamera();
    if (currentSection.Value === "BodyColor") {
      if (color.IsMatte && !isMatte()) {
        updateColorFinish();
      }
      if (!color.IsMatte && isMatte()) {
        updateColorFinish();
      }
    }

    setColorForCurrentSegment(color);
    if (color.IsMatte && currentSection.Value === "BodyColor") {
      selectedCommandMapping[currentSection.Value](`${Command} ${currentSection.Value} ${color.RenderColorCode};confopt ExteriorFinish matte`);
    } else {
      selectedCommandMapping[currentSection.Value](`${Command} ${currentSection.Value} ${color.RenderColorCode}`);
    }
    render(Command, color.RenderColorCode, currentSection.Value);
  };

  /**
   * this funcition determines if a camera movement on color selection is necessary and does if so
   */
  const updateColorSelectionCamera = () => {
    if (currentSection.Value !== lastSelectedColorSection.Value) {
      setLastSelectedColorSection(currentSection);
      sendMessage(`confcam ${currentSection.Value}`);
    }
  };

  const isMatte = () => colorFinish === matte || colorFinish.toLowerCase() === "matte";

  /**
   * Determines the exterior finish command based on the props parameter from the db and the current se lected value which
   * can be either Glossy or Matte. Glossy is the default.
   * @param key can/should be "Glossy" or "Matte"
   * @returns command as string - like 'confopgt ExteriorFinish default'
   */
  const getColorFinishCommandValue = (key: string) => {
    const exteriorFinishCommmand = "confopt ExteriorFinish";
    const arr = overview.RenderSettings!.Tools.ColorSelection.FinishOptions.filter((x) => x.Name === key);
    if (arr && arr.length > 0) {
      return exteriorFinishCommmand + " " + arr[0].Value;
    }
    return exteriorFinishCommmand + " default";
  };

  const updateColorFinish = () => {
    const toggle = isMatte() ? glossy : matte;
    sendMessage(getColorFinishCommandValue(toggle));
    setColorFinish(toggle);
    // body only!!
    selectedCommandMapping[currentSection.Value](
      `${CommandIndividual} ${currentSection.Value} ${selectedColorBody.FrontendColorCode};${getColorFinishCommandValue(toggle)};${Command} ${currentSection.Value} ni`
    );
  };

  /**
   * This sets the individual color for the currently active category
   */
  const addCustomColor = (customColor: ColorOption) => setCustomColors((x) => [...x, customColor]);

  const setCustomColor = (customColor: ColorOption) => {
    setCustomColors((x) => {
      let v = x;
      const index = v.findIndex((c) => c.ColorName === customColor.ColorName);
      if (index > -1) {
        v[index] = customColor;
      }
      return v;
    });

    setColorForCurrentSegment(customColor);
    setCustomColorInStream(customColor);
  };

  const setCustomColorInStream = (color: ColorOption) => {
    updateColorSelectionCamera();
    selectedCommandMapping[currentSection.Value](`${CommandIndividual} ${currentSection.Value} ${color.FrontendColorCode};${Command} ${currentSection.Value} ni`);
    render(CommandIndividual, color.FrontendColorCode, currentSection.Value);
    render(Command, "ni", currentSection.Value);
  };

  const render = (command: string, renderColorCode: string, section: string) => {
    const message = `${command} ${section} ${renderColorCode}`;
    sendMessage(message); //Just a test message, please add correct message
  };

  const [selectedIndex, setSelectedIndex] = useState(""); //"" if no color-category is selected

  function handleOnSubMenuClick(currentIndex: string) {
    if (selectedIndex === currentIndex) {
      setSelectedIndex("");
      updateIsColorSelectorOpen(false);
    } else {
      setSelectedIndex(currentIndex);
      updateIsColorSelectorOpen(true);
    }
  }

  const [closeColorSliderOnBackButton, setCloseColorSliderOnBackButton] = useState(false);

  const handleMobileBackButtonClicked = () => {
    if (closeColorSliderOnBackButton) {
      setCloseColorSliderOnBackButton(false);
    } else {
      updateIsColorSelectorOpen(false);
    }
  };

  const [isCustomColor, setIsCustomColor] = useState(false);

  return (
    <>
      {colorCategories.map((colorSelectionCategoriesModel: ColorSelectionCategoriesModel, index: number) => {
        const currentIndex = index.toString();
        const activeCategory = currentIndex === selectedIndex;

        return (
          <div key={`color-category-${index}`} className="color-category-list-item-container">
            <div
              onClick={() => {
                handleOnSubMenuClick(currentIndex);
                setCurrentSection(colorCategories[index]);
              }}
              id={`color-category-list-item-${index}`}
              className={`category-item ${activeCategory ? "active" : ""} ${selectedIndex === "" ? "not-selected" : "hide-not-selected"}`}
            >
              <div className={`category-name ${activeCategory ? "active" : ""}`}>{colorSelectionCategoriesModel.Name}</div>
            </div>

            <div className="horizontal-line"></div>

            {!isMobile && (
              <Collapse in={activeCategory} timeout="auto" unmountOnExit>
                <div className="color-body">
                  <XBowColorSelector
                    selectedColor={selectedColorsMapping[colorCategories[index].Value]}
                    colorSelectionCategoriesModel={colorSelectionCategoriesModel.Name}
                    predefinedColors={getPredefinedColors()}
                    setPredefinedColor={setPredefinedColor}
                    addCustomColor={addCustomColor}
                    setCustomColor={setCustomColor}
                    customColors={customColors}
                    colorFinish={colorFinish}
                    updateColorFinish={updateColorFinish}
                    isMobile={isMobile}
                    closeColorSliderOnBackButton={closeColorSliderOnBackButton}
                    setCloseColorSliderOnBackButton={setCloseColorSliderOnBackButton}
                    setIsCustomColor={setIsCustomColor}
                  />
                </div>
              </Collapse>
            )}

            {isMobile && activeCategory && isColorSelectorOpen && (
              <div className="mobile-color-selection">
                <div className="mobile-color-selection-header">
                  <div className="mobile-color-selection-header-close" onClick={handleMobileBackButtonClicked}>
                    <ArrowHead />
                    <span className="mobile-color-selection-header-title">{colorSelectionCategoriesModel.Name}</span>
                  </div>
                  <XBowColorFinishSelector
                    colorFinish={colorFinish}
                    updateColorFinish={updateColorFinish}
                    display={colorSelectionCategoriesModel.Name === "Body" && isCustomColor}
                    isMobile={isMobile}
                  />
                </div>

                <div className="color-body">
                  <XBowColorSelector
                    selectedColor={selectedColorsMapping[colorCategories[index].Value]}
                    colorSelectionCategoriesModel={colorSelectionCategoriesModel.Name}
                    predefinedColors={getPredefinedColors()}
                    setPredefinedColor={setPredefinedColor}
                    addCustomColor={addCustomColor}
                    setCustomColor={setCustomColor}
                    customColors={customColors}
                    colorFinish={colorFinish}
                    updateColorFinish={updateColorFinish}
                    isMobile={isMobile}
                    closeColorSliderOnBackButton={closeColorSliderOnBackButton}
                    setCloseColorSliderOnBackButton={setCloseColorSliderOnBackButton}
                    setIsCustomColor={setIsCustomColor}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(XBowColors);

import React, { useEffect, useState } from "react";
import { HueSlider, SaturationSlider, LightnessSlider } from "react-slider-color-picker";
import { ColorOption } from "../../../models/vehicle";
import { hslToHex, setHexToHSLA } from "../../../utils/helpers";

export interface ColorSliderProps {
  selectedColor: ColorOption;
  setSelectedColor: (color: ColorOption) => void;
  isMobile: boolean;
}

interface HSLAColor {
  h: number;
  s: number;
  l: number;
  a: number;
}

const HSLAColorSlider: React.FunctionComponent<ColorSliderProps> = props => {
  const { selectedColor, setSelectedColor, isMobile } = props;

  const convertHexToHSLA = (hex: string): HSLAColor => setHexToHSLA(hex);
  const convertHSLAToHex = (hsl: HSLAColor): string => hslToHex(hsl.h, hsl.s, hsl.l);

  const [sliderColor, setSliderColor] = useState(convertHexToHSLA(selectedColor.FrontendColorCode));

  useEffect(() => {
    setSliderColor(convertHexToHSLA(selectedColor.FrontendColorCode));
  }, [selectedColor]);

  const handleHSLASliderColorChange = (newColor: HSLAColor) => {
    setSliderColor(newColor);

    const newColorObj: ColorOption = {
      ColorName: selectedColor.ColorName,
      FrontendColorCode: convertHSLAToHex(newColor),
      RenderColorCode: selectedColor.RenderColorCode,
      IsMatte: selectedColor.IsMatte,
    };
    setSelectedColor(newColorObj);
  };

  return (
    <div className={`slider ${isMobile ? "mobile" : ""}`}>
      <div className="colors-slider">
        <HueSlider handleChangeColor={handleHSLASliderColorChange} color={sliderColor} />
      </div>
      <div className="colors-slider">
        <LightnessSlider handleChangeColor={handleHSLASliderColorChange} color={sliderColor} />
      </div>
      <div className="colors-slider">
        <SaturationSlider handleChangeColor={handleHSLASliderColorChange} color={sliderColor} />
      </div>
    </div>
  );
};

export default HSLAColorSlider;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Send: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Send Icon</title>
      <path id="send" d="M0,15.63V80.37H96V15.63Zm78.3,8.48L48,43,18.65,24.11Zm9.23,47.78H8.47V27.62L48,53,87.53,28.32Z" />
    </SvgIcon>
  );
};

export default Send;

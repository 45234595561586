import { useEffect, useState } from "react";
import { WithNamespaces } from "react-i18next";
import { StateManagementProps } from "../../utils/addReduxProps";
import Rendering3D from "./3DRendering/rendering3D";
import CdcLogin, { loadCDCPluginScript, makeCancelable, isReady } from "./cdc/CdcLogin";
import React from "react";
import CategoryContainer from "../categories/CategoryContainer";
import { debug, generateRawScreenshot, generateScreenshotForPDFGeneration, getIsMobile, hashCodeScreenShot } from "../../utils/helpers";
import Tools, { ToolProps } from "./3Dtools/tools";
import Header, { HeaderProps } from "../shared/Header";
import WaitingRoom from "./3DRendering/waitingRoom";
import { Part } from "../parts/PartsList";
import { RouteComponentProps } from "react-router";
import { renderParts, sendMessage, socketMessagesCallbacks, StreamState } from "../../lib/streaming/core";
import http from "../../services/http";
import contentDisposition from "content-disposition";
import { saveAs } from "file-saver";
import {
  CustomColorConfigurationsPayload,
  ToolsConfigurationPayload,
  UpdateConfigurationPayload,
  sendBitrateDroppedTelemetryData,
  shareConfiguration as shareConfigurationURL,
} from "../../services/http/real";
import { updateConfiguration } from "./updateConfiguration";
import { ShowDialogProps } from "../../redux/actions";
import { DialogTypes } from "../../enums/dialogTypes";
import TechnicalDetails3D, { TechnicalDetailsProps } from "./bottomSection/technicalDetails";
import Plus3D from "../../assets/icons/plus3D";
import ConfigurationOverview3D from "./configurationOverview3D";
import { getBrandLogoSvgComponent } from "../../redux/reducers/applicationSettings";
import ConfigurationCompletion from "./configurationSummary/ConfigurationCompletion";
import { ColorVarations } from "../../models/vehicle";
import ColorSelection, { ColorSelectionProps } from "../vehicle-overview/colorSelection";
import { HEROLVL_DONE, INTROGO_COMMAND } from "../../lib/streaming/commands";

export interface Configuration3DProps {
  itemClicked?: () => void;
  showDetails?: () => void;
  setUserInactiveFunction: (inactivity: boolean) => void;
  isPortraitMode: boolean;
  userInactive: boolean;
  variationCode: string;
}

type AllConfigurationView3DProps = StateManagementProps & WithNamespaces & Configuration3DProps & RouteComponentProps<any>;

export const ConfigurationView3D: React.FunctionComponent<AllConfigurationView3DProps> = (props) => {
  const {
    t,
    selectedModel,
    configurationState,
    appliedParts,
    showDialog,
    toggleSummary,
    applicationSettings,
    overview,
    isXBow,
    setIsXBow,
    currentVariation,
    dealerInquirySent,
    setUserInactiveFunction,
    isPortraitMode,
    userInactive,
    cdcSettings,
    setCdcSettings,
    userDidTimeoutInStream,
    history,
    setUserDidTimeoutInStream,
    setVehicleVariation,
    variationCode,
    preselectedVariationCode
  } = props;

  const debugMessagesON = false; //to enable debug messages

  const [renderingCommands, setRenderingCommands] = useState<string[] | null>(null);
  const [createConfigurationState, setCreateConfigurationState] = useState({
    exists: configurationState.configuration.hasOwnProperty("ConfigurationId"),
    loading: false,
  });
  const [cdcLoaded, setCDCLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [engineReady, setEngineReady] = useState(false); //show Tools, Categories, Overview & Tec Details after loading stream
  const [catsOpen, setCatsOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [colorsOpen, setColorsOpen] = useState(false);
  const [isPDFBusy, setIsPDFBusy] = useState(false);
  const [toolsData, setToolsData] = useState<ToolsConfigurationPayload>({});
  const [colorData, setColorData] = useState<CustomColorConfigurationsPayload>({});
  const [soundON, setSoundON] = useState(false);
  const [ColorArray, setColorArray] = React.useState<ColorVarations[]>([]);
  const [bitrateDroppedEvent, setBitrateDroppedEvent] = useState({});
  const [initialColorSet, setInitialColorSet] = useState(false);

  //keeps the already rendered parts as array
  const emptyList: Part[] = [];
  const [renderedParts, setRenderedParts] = useState(emptyList);
  const [userId, setUserId] = useState(""); // the user id configuring

  const [currentStreamState, setCurrentStreamState] = useState<StreamState>(StreamState.INITIALIZING);

  // Control UI
  const [displayLoginScreen, setDisplayLoginScreen] = useState(false);
  const [displayWaitingroom, setDisplayWaitingroom] = useState(false);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [displayToolsAndPowerPartsAndBottomSection3d, setDisplayToolsAndPowerPartsAndBottomSection3d] = useState(false);
  const [isMobile, setIsMobile] = useState(() => getIsMobile());

  const setIsMobileState = () => {
    setIsMobile(getIsMobile());
  };

  useEffect(() => {
    handleBitrateDropped(bitrateDroppedEvent);
  }, [bitrateDroppedEvent]);

  useEffect(() => {
    makeCancelable(loadCDCPluginScript(applicationSettings)).promise.then(() => {
      if (isReady()) {
        // this is the fallback version if the cdc has been loaded previously. the
        setCDCLoaded(true);
      }
      if (ColorArray.length === 0) {
        setColorArray(overview.Variations);
      }
        if(preselectedVariationCode.code !== "") {
          overview.Variations.forEach(Variation => {
            if(Variation.Code === preselectedVariationCode.code) {
              changeBikeColor(Variation.Code, Variation.ColorCode);
            }
          });
        }
      
      
    });

    const onResize = () => {
      setIsMobileState();
    };
    window.addEventListener("resize", onResize);
    setIsXBow(Boolean(configurationState.configuration.VariationCode === "X3001U0"));
    setIsMobile(getIsMobile());

    //@ts-ignore
    window.onGigyaServiceReady = () => {
      // this is the callback from the CDC itselt - TOOD move to library - this is only called once when the cdc has not been loaded previously.
      const gigyaobject =
        (window as any).gigya &&
        (window as any).gigya.accounts &&
        (window as any).gigya.accounts.getAccountInfo &&
        (window as any).gigya.accounts.getAccountInfo({
          callback: (callbackObject) => {
            updateUserId(callbackObject.UID as string);
          },
        });
      setCDCLoaded(true);
    };
    // this is a fallback solution
    (window as any).gigya &&
      (window as any).gigya.accounts &&
      (window as any).gigya.accounts.getAccountInfo &&
      (window as any).gigya.accounts.getAccountInfo({
        callback: (callbackObject) => {
          updateUserId(callbackObject.UID as string);
          setCDCLoaded(true);
        },
      });

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    setDisplayLoginScreen(!loggedIn && cdcLoaded);
    setDisplayWaitingroom(loggedIn && !engineReady);
    setDisplayToolsAndPowerPartsAndBottomSection3d(loggedIn && engineReady && !dealerInquirySent);
    setDisplaySummary(dealerInquirySent);
  }, [engineReady, cdcLoaded, loggedIn, dealerInquirySent]);

  useEffect(() => {
    if (cdcLoaded) {
      checkCDCStatus();
    }
  }, [cdcLoaded]);

  useEffect(() => {
    if (userInactive) {
      setListOpen(false);
      setCatsOpen(false);
      setToolsOpen(false);
    }
  }, [userInactive]);

  enum Tiltshift {
    None = 0,
    ShiftToLeft = 1,
    ShiftToRight = 2,
  }

  const [tiltShiftState, setTiltShiftState] = useState(Tiltshift.None);

  const changeBikeColor = (VariationCode: string, ColorCode: string) => {
    //update configuration
    setVehicleVariation(VariationCode);
    const data: UpdateConfigurationPayload = {
      ColorCode: ColorCode,
      VariationCode: VariationCode,
      newVariation: VariationCode,
    };
    updateConfiguration(data);

    //load new bike
    sendMessage("herolvl " + VariationCode);
    socketMessagesCallbacks[HEROLVL_DONE] = (message: string) => {
      if (message === HEROLVL_DONE) {
        //load selected pp
        resendAppliedPartsForRendering();
      }
    };
  };

  const checkUserLogin = () => {
    cdcLoaded && checkCDCStatus();
  };

  const onStreamStateChanged = (streamState: StreamState, event?: any) => {
    if (debugMessagesON) {
      debug("onStreamStateChanged", streamState);
    }
    setCurrentStreamState(streamState);
    switch (streamState) {
      case StreamState.INIT_DONE:
        setEngineReady(true);
        break;
      case StreamState.INITIALIZING:
        setEngineReady(false);
        break;
      case StreamState.BROKEN:
        setEngineReady(false);
        break;
      case StreamState.ACCEPTING:
        setEngineReady(false);
        resendAppliedPartsForRendering();
        break;
      case StreamState.BITRATE_DROPPED:
        setBitrateDroppedEvent(event);
        break;
    }
  };

  const handleSummaryClosing = () => {
    if (debugMessagesON) debug("handleSummaryClosing");
    setDisplayToolsAndPowerPartsAndBottomSection3d(true);
    setDisplaySummary(false);
  };

  const checkCDCStatus = () => {
    if ((window as any).gigya && (window as any).gigya.hasSession) {
      (window as any).gigya
        .hasSession()
        .then(function (sessionExists: boolean) {
          setCDCLoaded(!sessionExists);
          setLoggedIn(sessionExists);
          setCdcSettings(!sessionExists);
          setDisplayLoginScreen(!sessionExists);
        })
        .catch((error: any) => {
          setCreateConfigurationState({
            exists: false,
            loading: false,
          });
        });
    }
  };

  const updateUserId = (userId: string) => {
    setUserId(userId);
  };

  function handleBitrateDropped(event?: any) {
    event && engineReady && sendBitrateDroppedTelemetryData(userId, event.currentBitrate, isMobile).then((x) => {});
  }

  function handleSummaryClick(isXBow?: boolean) {
    if (isXBow) {
      setListOpen(false);
      return;
    }
    toggleSummary(true);
    setDisplaySummary(true);
  }

  const updateRenderingCommands = (renderingCommandList: string[] | null) => {
    renderingCommandList && setRenderingCommands(renderingCommandList);
  };

  useEffect(() => {
    /** this helps to prevent the parts being initially rendered the real way when loading a configuration */
    if (engineReady) {
      sendPartsForRendering(appliedParts, renderedParts, engineReady);
    }
  }, [appliedParts]);

  useEffect(() => {
    loggedIn && updateConfiguration(props, toolsData, colorData);
  }, [toolsData, colorData]);

  /**
   * Handle tilt shifting on menu state change
   */
  useEffect(() => {
    engineReady && handleTiltShifting();
  }, [toolsOpen, catsOpen, listOpen]);

  useEffect(() => {
    engineReady && !isMobile && sendMessage("tiltsft " + tiltShiftState);
  }, [tiltShiftState]);

  useEffect(() => {
    if (currentVariation === "") {
      setVehicleVariation(variationCode);
    }
  }, [currentVariation]);

  /**
   * Resend again the full configuration
   */
  const resendAppliedPartsForRendering = () => {
    if (debugMessagesON) debug("resend appliedParts");
    setRenderedParts(emptyList); //remove all parts, because on reconnect they are not rendered
    sendPartsForRendering(appliedParts, emptyList, false);
    sendMessage("perspct default");
  };

  /**
   * Tilt Shift Commandas depending on UI Menu Open State wh
   */
  const handleTiltShifting = () => {
    toolsOpen && !catsOpen && !listOpen && setTiltShiftState(Tiltshift.ShiftToRight); // Tools Menu Open
    !toolsOpen && catsOpen && !listOpen && setTiltShiftState(Tiltshift.ShiftToLeft); // Color Selection/PowerPart Open
    !toolsOpen && !catsOpen && !listOpen && setTiltShiftState(Tiltshift.None); // Menus Closed
    listOpen && setTiltShiftState(Tiltshift.ShiftToLeft); // Menu Drop UP Open
  };

  // Send applied parts for 3d rendering
  const sendPartsForRendering = (appliedParts: Part[], renderedParts: Part[], engineReadyState: boolean) => {
    if (debugMessagesON) debug("appliedParts changed", appliedParts);
    let renderSettings = overview.RenderSettings;

    const mapToPartWithCategory = (acc, cur) => {
      const powerPart3D = renderSettings!.PowerParts.List.find((pp) => pp.Id === cur.PartId);
      if (powerPart3D) {
        const categories = powerPart3D.Category.split(",").map((p) => p.trim());
        return [
          ...acc,
          ...categories.map((c, index) => ({
            ...cur,
            Category: c,
            Value: powerPart3D.Value,
            Silent: index > 0,
          })),
        ];
      } else {
        acc.push(cur);
        return acc;
      }
    };

    // TODO fix type
    const newAddedParts: any[] = appliedParts.filter((p) => renderedParts.indexOf(p) < 0).reduce(mapToPartWithCategory, []);

    const removedParts: {
      Category?: string;
      Silent?: boolean;
      [key: string]: any;
    }[] = renderedParts
      .filter((p) => appliedParts.indexOf(p) < 0)
      .reduce(mapToPartWithCategory, [])
      .filter((p) => !newAddedParts.find((n) => n.Category == p.Category));

    const promises = removedParts.map((removedPart) => {
      if (removedPart.Category) {
        if (debugMessagesON) {
          debug("found matching part in the 3d rendering configuration", removedPart);
        }
        return renderParts(`${removedPart.Category}`, "default", removedPart.Silent!);
      } else {
        // for test only
        if (debugMessagesON) {
          debug("Cannot match applied part id with one from the 3d rendering confriguration. Send sample to 3d engine...");
        }
        sendMessage("confopt mat default");
        return Promise.resolve();
      }
    });

    Promise.all(promises).then(() => {
      newAddedParts.forEach((appliedPart) => {
        if (appliedPart.Category) {
          if (debugMessagesON) {
            debug("found matching part in the 3d rendering configuration", appliedPart);
          }
          renderParts(
            `${appliedPart.Category}`,
            `${appliedPart.Value}`,
            engineReadyState ? appliedPart.Silent : true // do not use confcam when engine is not ready - this is for loading
          );
        } else {
          // for test only
          if (debugMessagesON) {
            debug("Cannot match applied part id with one from the 3d rendering confriguration. Send sample to 3d engine...");
          }
          sendMessage("confopt mat =79012906200");
        }
      });

      //copy the current array
      setRenderedParts([...appliedParts]);
    });
  };

  /**
   * Callback from the Tools Component to update the ConfigurationView3D about the tools sate
   */
  const sendToolData = (toolsConfiguration: ToolsConfigurationPayload) => setToolsData(toolsConfiguration);

  /**
   * Callback from the Color Component to update the ConfigurationView3D about the colors sate
   */
  const sendColorData = (colorData: CustomColorConfigurationsPayload) => setColorData(colorData);

  /**
   * Callback from the Tools component to render the selected tools.
   * @returns
   */
  const sendToolOption = (message: string, lighting?:string) => sendMessage(message, lighting);

  /**
   * Calls the HTTP Rest lib to update the configuration on the backend service.
   * @returns
   */
  const update3DConfiguration = () => {}; // TODO refactor

  /**
   * Saving the Configuration was triggered explicitly (save to motohub)
   * @returns
   */
  const onSaveSuccess = () => update3DConfiguration();

  /**
   * Callback from the ConfigurationSummary component when send to dealer was done
   * @returns
   */
  const onSendToDealerSuccess = () => handleSummaryClick(false);

  /**
   * Callback from the ConfigurationSummary component when send to self was done
   * @returns
   */
  const onSendSuccess = () => update3DConfiguration();

  /**
   * Handle the various menu open state changes
   */
  const toggleToolsMenuCallback = () => {
    setToolsOpen(() => !toolsOpen);
    setCatsOpen(false);
    setListOpen(false);
  };

  const togglePowerPartsMenuCallback = () => {
    setCatsOpen(() => !catsOpen);
    setToolsOpen(false);
  };

  const toggleColorSelectionMenuCallback = () => {
    setColorsOpen(() => !colorsOpen);
    setCatsOpen(() => !catsOpen);
    setToolsOpen(false);
  };

  const toggleOverviewSelectionMenuCallback = (open?: boolean) => {
    if (open !== undefined) {
      setListOpen(() => open);
    } else {
      setListOpen(() => !listOpen);
    }
    setCatsOpen(false);
    setToolsOpen(false);
  };

  const startedCallback = (callback?: () => void) => {
    setIsPDFBusy(true);
    callback && callback();
  };

  const finishedCallback = (callback?: () => void) => {
    setIsPDFBusy(false);
    callback && callback();
  };

  /**
   * This function handles the PDF screenshot functionality - it works for mobile and desktop
   * but the mobile version needs to be different because of the portrait mode.
   * @returns
   */
  const takeScreenshotAndDownloadPDF = (onStartedCallback?: () => void, onFinishedCallback?: () => void) => {
    startedCallback(onStartedCallback);

    const video = document.getElementById("stream") as HTMLVideoElement;

    if (!video) {
      finishedCallback(onFinishedCallback);
      return;
    }

    const storePdfScreenshot = sessionStorage.getItem("pdfScreenshot");

    if (storePdfScreenshot) {
      takeScreenshotAndDownloadPDFFunction(storePdfScreenshot, onFinishedCallback);
    } else {
      const promise = generateScreenshotForPDFGeneration(isPortraitMode, isMobile, video);
      //also check for sth in session storage here
      promise &&
        promise.then((screenshot) => {
          takeScreenshotAndDownloadPDFFunction(screenshot, onFinishedCallback);
        });
    }

    update3DConfiguration();
  };

  const takeScreenshotAndDownloadPDFFunction = (screenshot: string, onFinishedCallback?: () => void) => {
    let partsHash: string = selectedModel.ModelId + currentVariation;
    appliedParts.forEach((part: Part) => {
      partsHash += part.Images.Main;
    });
    partsHash = "" + hashCodeScreenShot(partsHash);

    http
      .downloadConfigurationSummaryPdfWithScreenshot(partsHash, screenshot)
      .then((response) => {
        var headers = response.headers;
        var headersArray = Object.entries(headers);
        var contentDispos: any;
        headersArray.forEach(([key, value]) => {
          if (key === "content-disposition") {
            contentDispos = value;
          }
        });

        var blob = response.data;
        var disposition = contentDisposition.parse(contentDispos);
        var fileName = disposition.parameters.filename ? disposition.parameters.filename : "configuration.pdf";
        saveAs(blob, fileName);
      })
      .finally(() => {
        finishedCallback(onFinishedCallback);
      });
  };

  /** Make screenshot of the 3D Video and add copyright to it */
  const takeScreenshot = () => {
    const video = document.getElementById("stream") as HTMLVideoElement;
    let screenshotElement: HTMLElement;
    if (video) {
      if (isMobile && isPortraitMode) {
        screenshotElement = document.getElementById("screenshot-div-mobile-portrait-mode") as HTMLDivElement;
      } else {
        screenshotElement = document.getElementById("screenshot-div") as HTMLDivElement;
      }
      generateRawScreenshot(isPortraitMode, isMobile, screenshotElement, video, applicationSettings.Brand)!.then((base64Image) => {
        saveAs(base64Image, `screenshot-my-${applicationSettings.Brand}.png`);
      });
    }
  };

  const sendSoundOnOff = (soundON: boolean) => {
    if (soundON) {
      sendMessage("soundfx 1");
    } else {
      sendMessage("soundfx 0");
    }

    const video = document.getElementById("stream") as HTMLVideoElement;

    if (video) {
      video.muted = !soundON;
    }
  };

  const shareConfiguration = () => {
    const dialogProps: ShowDialogProps = {
      title: `${t("dialogs.shareConfiguration.title")}`,
      data: {},
      contentType: DialogTypes.shareConfiguration,
    };
    if (debugMessagesON) debug("shareConfiguration clicked");
    showDialog(dialogProps);
    update3DConfiguration();
  };

  const getReturnUrl = async () => {
    return await shareConfigurationURL().then((res) => {
      return res.data.Url;
    });
  };

  const returnToConfiguration = async () => {
    let data = await getReturnUrl();
    setTimeout(() => {
      if (process.env.NODE_ENV == "development") {
        data = data.replace("5000", "5001");
      }
      window.location.href = data;
    }, 600);
  };

  const selectedModelName = selectedModel.Name;

  const headerProps: HeaderProps = { loggedIn, cdcLoaded, engineReady, selectedModelName };

  const setToolsOpenFunc = toggleToolsMenuCallback;
  const toolProps: ToolProps = { toolsOpen, setToolsOpenFunc, sendToolOption, sendToolData };
  const technicalDetailProps: TechnicalDetailsProps = {
    engineReady,
    loggedIn,
    soundON,
    setSoundON,
    sendSoundOnOff,
    isPDFBusy,
    takeScreenshotAndDownloadPDF,
    shareConfiguration,
    takeScreenshot,
    onSaveSuccess,
    currentStreamState,
  };
  const colorSelectionProps: ColorSelectionProps = {
    ColorArray,
    changeBikeColor,
  };

  const brandSvg = getBrandLogoSvgComponent(applicationSettings.Brand);

  return (
    <>
      {!cdcSettings && loggedIn && <Header {...props} {...headerProps} displayWaitingroom={displayWaitingroom} />}
      <div
        id="screenshot-div-mobile-portrait-mode"
        style={{
          position: "fixed",
          visibility: "hidden",
          top: 0,
          left: 0,
          width: "1080px",
          height: "1080px",
        }}
      >
        <div
          className="brandlogo-in-main-header brandlogo-in-configheader-screenshot logo"
          style={{
            position: "absolute",
            top: "0px",
            right: "20px",
            padding: 0,
          }}
        >
          {brandSvg}
        </div>
      </div>

      {displayLoginScreen && cdcLoaded && <CdcLogin {...props} mode="configuratorLogin" checkUserLogin={checkUserLogin} />}

      {displayToolsAndPowerPartsAndBottomSection3d && (
        <>
          <h1 className="configuration-main-heading">{selectedModelName}</h1>
          <Tools {...props} {...toolProps} />
          <ColorSelection {...props} ColorArray={ColorArray} changeBikeColor={changeBikeColor} />
        </>
      )}

      {loggedIn && <Rendering3D {...props} onStreamStateChanged={onStreamStateChanged} setUserInactiveFunction={setUserInactiveFunction} displaySummary={displaySummary}></Rendering3D>}

      {displayToolsAndPowerPartsAndBottomSection3d && (
        <CategoryContainer
          {...props}
          catsOpen={catsOpen}
          setCatsOpenFunc={isXBow ? toggleColorSelectionMenuCallback : togglePowerPartsMenuCallback}
          is3D={true}
          updateRenderingCommands={updateRenderingCommands}
          renderingCommands={renderingCommands}
          isMobile={isMobile}
          sendColorData={sendColorData}
        ></CategoryContainer>
      )}

      {((displayToolsAndPowerPartsAndBottomSection3d && overview.RenderSettings && overview.RenderSettings.Tools && overview.RenderSettings.Tools.ColorSelection) ||
        displayToolsAndPowerPartsAndBottomSection3d) && (
        <div className={`open-mobile-pp-menu ${catsOpen ? "hide" : ""}`} onClick={() => togglePowerPartsMenuCallback()}>
          <span>{!isXBow ? t(`buttons.categories.${applicationSettings.Brand}`) : "Customization"}</span>
          <Plus3D identifyer={`pp-opener`} />
        </div>
      )}

      {displayWaitingroom && !userDidTimeoutInStream && <WaitingRoom {...props} currentStreamState={currentStreamState} />}

      {displayToolsAndPowerPartsAndBottomSection3d && (
        <>
          <div className={`bottom-section-3d  ${listOpen ? "open" : "closed"}`}>
            <div className="mobile-parts-trigger-button">Powerparts</div>
            <TechnicalDetails3D {...props} {...technicalDetailProps} {...colorSelectionProps} />

            <ConfigurationOverview3D {...props} {...technicalDetailProps} listOpen={listOpen} setOverviewOpen={toggleOverviewSelectionMenuCallback} isPortraitMode={isPortraitMode} />
          </div>
        </>
      )}

      {displaySummary && (
        <div className={`full-width full-height show-configuration-summary is3D`} style={{ position: "absolute" }}>
          <ConfigurationCompletion
            {...props}
            updatesetShowSummary={handleSummaryClosing}
            takeScreenshotAndDownloadPDF={takeScreenshotAndDownloadPDF}
            onSendToDealerSuccess={onSendToDealerSuccess}
            onSendSuccess={onSendSuccess}
            onShareLinkSuccess={shareConfiguration}
            isPortraitMode={isPortraitMode}
          />
        </div>
      )}

      {loggedIn && userDidTimeoutInStream && (
        <div className={`flex-container centered ${isMobile ? "column timeout-mobile" : "row timeout"}`}>
          <button
            className="secondary"
            onClick={() => {
              setUserDidTimeoutInStream(false);
              setUserInactiveFunction(false);
              history.push("/main");
            }}
          >
            {t(`history.header.chooseModel`)}
          </button>
          <button className="secondary" onClick={() => window.location.reload()}>
            {t(`configuration.summary.screen.newconfiguration`)}
          </button>
          <button className="secondary" onClick={() => returnToConfiguration()}>
            {t(`configuration.summary.backLink`)}
          </button>
        </div>
      )}
    </>
  );
};

import React from "react";

export interface xBowColorFinishSelectorProps {
  updateColorFinish: () => void;
  colorFinish: string;
  display: boolean;
  isMobile: boolean;
}

const XBowColorFinishSelector: React.FunctionComponent<xBowColorFinishSelectorProps> = props => {
  const { updateColorFinish, colorFinish, display, isMobile } = props;

  const checkUpdateColorFinish = (currentClick: string) => {
    if (currentClick !== colorFinish) {
      updateColorFinish();
    }
  };

  return display ? (
    <div className={`matt-glossy ${isMobile ? "mobile" : ""}`}>
      <span className={`text-container ${colorFinish === "Matte" ? "active" : ""}`} onClick={() => checkUpdateColorFinish("Matte")}>
        <p className="text uppercase">Matte</p>
      </span>
      <span className={`text-container ${colorFinish === "Glossy" ? "active" : ""}`} onClick={() => checkUpdateColorFinish("Glossy")}>
        <p className="text uppercase">Glossy</p>
      </span>
    </div>
  ) : null;
};

export default XBowColorFinishSelector;

import React, { useState } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import CategoryName from "../shared/CategoryName";
import Collapse from "@material-ui/core/Collapse";
import Parts from "../parts/PartsList";
import Arrow from "../../assets/icons/arrow";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../utils/addReduxProps";
import Minus3D from "../../assets/icons/minus3D";
import Plus3D from "../../assets/icons/plus3D";

export interface CategoryListProps {
  categories: string[];
  itemClicked?: () => void;
  showDetails?: () => void;
  setScrollPosition?: () => void;
  setSubMenuState?: (open: boolean) => void;
  resetSubMenuState?: () => void;
  is3DMode?: boolean;
  renderingCommands?: string[] | null;
  updateRenderingCommands?: (renderingCommandList) => void;
}

export type CategoryListAllProps = CategoryListProps & RouteComponentProps<any>;

export const CategoryListComponent: React.FunctionComponent<CategoryListAllProps> = props => {
  const { categories, setSubMenuState, resetSubMenuState, is3DMode } = props;

  //Index is string, to reset index state to "" because 0 is first category
  const [selectedIndex, setSelectedIndex] = useState("");

  function handleClick(currentIndex: string) {
    if (selectedIndex === currentIndex) {
      setSelectedIndex("");
      if (setSubMenuState) {
        setSubMenuState(false);
      }
    } else {
      setSelectedIndex(currentIndex);
      if (setSubMenuState) {
        setSubMenuState(true);
      }
    }
  }

  if (!categories.length) {
    return null;
  }

  if (is3DMode) {
    return (
      <>
        {categories.map((category: string, index: number) => {
          let currentIndex = index.toString();
          let activeCategory = currentIndex === selectedIndex;
          return (
            <div key={`category-${index}`} className="category-items-wrapper">
              <div
                onClick={() => {
                  handleClick(currentIndex);
                }}
                id={`category-list-item-${index}`}
                className={`category-item ${activeCategory ? "active" : ""} ${selectedIndex === "" ? "not-selected" : "hide-not-selected"}`}
              >
                <div className={`${activeCategory ? "active" : ""} category-name`}>
                  {category}
                  {activeCategory ? <Minus3D identifyer={`part-${index}`} /> : <Plus3D identifyer={`part-${index}`} />}
                </div>
              </div>
              <div className="horizontal-line"></div>
              <Collapse in={activeCategory} timeout="auto" unmountOnExit>
                <>
                  <Parts currentCategoryId={index} {...props} resetSubMenuState={resetSubMenuState} is3D={is3DMode} updateRenderingCommands={props.updateRenderingCommands} />
                  <div className="horizontal-line"></div>
                </>
              </Collapse>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <section id="category-list" className={`full-height ${selectedIndex ? "child-selected" : ""}`}>
      <ul className="category-list-component">
        {categories.map((category: string, index: number) => {
          let currentIndex = index.toString();
          let activeCategory = currentIndex === selectedIndex;
          return (
            <div key={`category-${index}`}>
              <li
                onClick={() => {
                  handleClick(currentIndex);
                }}
                id={`category-list-item-${index}`}
                className={`category-item-button ${activeCategory ? "active" : ""} ${selectedIndex === "" ? "not-selected" : "hide-not-selected"}`}
              >
                {activeCategory && (
                  <div className="show-mobile">
                    <Arrow />
                  </div>
                )}
                <ListItemText className="category-name" primary={<CategoryName text={category} />} />
              </li>
              <Collapse in={activeCategory} timeout="auto" unmountOnExit>
                <Parts currentCategoryId={index} {...props} resetSubMenuState={resetSubMenuState} />
              </Collapse>
            </div>
          );
        })}
      </ul>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListComponent);

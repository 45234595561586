import { SHOW_MODAL, HIDE_MODAL, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import Action from './models/action';
import { DialogTypes } from "../../enums/dialogTypes";

export interface DialogState<D = any> {
	isOpen: boolean;
	contentType: DialogTypes
	title: string;
	data: D;
	shareType?: string;
}

export const initialState = {
	isOpen: false,
	contentType: DialogTypes.none,
	title: '',
	data: {},
	shareType: '',
};

export default function DialogReducer(state: DialogState = initialState, action: Action): DialogState {
	switch (action.type) {
		case SHOW_MODAL: {
			const { contentType, data, title, shareType } = action.payload;
			return {
				isOpen: true,
				contentType,
				data,
				title,
				shareType,

			};
		}
		case EXIT_VEHICLE_CONFIGURATION:
		case HIDE_MODAL: {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
}

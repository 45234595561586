import React, { useEffect } from "react";
import Part, { PartProps } from "./Part";
import { connect } from "react-redux";
import { mapStateToProps, StateManagementProps, mapDispatchToProps } from "../../utils/addReduxProps";
import { RouteComponentProps } from "react-router-dom";
import { sortAscendingByProperty } from "../../utils/helpers";
import { addNonConflictingPart, removeNonConflictingPart } from "../../utils/partsHelpers";
import { FullImage } from "../configuration/ConfigurationView";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Subscription } from "rxjs";
import { TransitionState } from "../../enums/transitionStates";
import { TransititionStateValue } from "../../App";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { Price } from "../../models/vehicle";

export interface Part {
  PartId: string;
  Name: string;
  Price: Price;
  Racing: boolean;
  Description: string;
  Images: PartImages;
  CategoryName: string;
  ApprovalInfo: string;
  CanBeRendered: boolean;
  IsColorPart?: boolean; // XBOW color parts - removes add/remove button in OverviewMenu -> DropUp
  Attributes?: Attribute[];
}

export interface Attribute {
  Key: string;
  ClassificationCategory: string;
  Description: string;
  Values: AttributeValues[];
}

export interface AttributeValues {
  Key: string;
  Description: string;
}

// final don't touch
export interface TogglePartResponse {
  Price: Price;
  PartIds: string[];
  FullImages: FullImage[];
  RulesResult: RulesResultRepsonse;
}

export interface RulesResultRepsonse {
  ChoosePart: boolean;
}

export interface PartImages {
  Main: string;
  Other: string[];
}

export enum PartAction {
  Apply = "Apply",
  Remove = "Remove",
}

export interface PartListProps {
  currentCategoryId: number;
  itemClicked?: () => void;
  showDetails?: () => void;
  resetSubMenuState?: () => void;
  is3D?: boolean | undefined;
  updateRenderingCommands?: (renderingCommandList) => void;
}

export type PartListAllProps = PartListProps & RouteComponentProps & StateManagementProps & WithNamespaces;

export interface PartListState {}

export const PartList: React.FunctionComponent<PartListAllProps & PartListState> = props => {
  const { currentCategoryId, is3D } = props;
  let sub: Subscription | undefined = undefined;

  useEffect(() => {
    if (props.location.hash) {
      sub = TransititionStateValue.pipe(
        distinctUntilChanged(),
        filter((item: TransitionState) => item === TransitionState.Exit)
      ).subscribe(() => {
        setTimeout(() => {
          // @todo find better solution (investigate why refs not working ok) ??
          const el = document.getElementById(props.location.hash.substring(1));
          if (el) {
            el.scrollIntoView();
          }
        });
      });
    }
    return () => {
      sub && sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (props.configurationState.noConflictingParts.PartId !== "" && !props.configurationState.noConflictingParts.DryRun) {
      addNonConflictingPart(props.configurationState.noConflictingParts);
    }
  }, [props.configurationState.noConflictingParts]);

  useEffect(() => {
    if (props.configurationState.removeNoConflictingParts.PartId !== "" && !props.configurationState.removeNoConflictingParts.DryRun) {
      removeNonConflictingPart(props.configurationState.removeNoConflictingParts);
    }
  }, [props.configurationState.removeNoConflictingParts]);

  const showDialog = (part: Part) => {
    props.selectPart(part);
    props.togglePartDetail(true);
  };

  const {
    parts,
    selectedPart,
    appliedParts,
    categories,
    location: { hash },
  } = props;
  const categoryName = categories[+currentCategoryId];
  const categoryParts = parts.filter((part: Part) => part.CategoryName === categoryName);

  if (!categoryParts) {
    return null;
  }

  return (
    <section id="select-parts">
      <div id="parts-wrapper" className={props.is3D ? "parts-3d" : ""}>
        {categoryParts.sort(sortAscendingByProperty<Pick<Part, "Name">>("Name")).map((part: Part, index: number) => {
          let _props: PartProps = {
            isIncluded: appliedParts.findIndex(entry => entry.PartId === part.PartId) !== -1,
            part: part,
            selectedFromSummary: false,
            showDialog: showDialog,
            fade: "PartId" in selectedPart && !Object.is(part, selectedPart),
            itemClicked: props.itemClicked,
            showDetails: props.showDetails,
            resetSubMenuState: props.resetSubMenuState,
            appliedParts: props.appliedParts,
            is3D: props.is3D,
          };
          if (hash && part.PartId === hash.substring(1)) {
            _props = {
              ..._props,
              selectedFromSummary: true,
            };
          }
          return <Part key={`part-${index}`} {..._props} is3D={is3D} />;
        })}
      </div>
    </section>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PartList));

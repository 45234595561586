import { ADD_VEHICLE_OVERVIEW, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import Action from "./models/action";
import { Part } from "../../components/parts/PartsList";
import { VehicleInformation, VehicleOverviewResponse } from "../../models/vehicle";

export interface VehicleOverviewState extends VehicleOverviewResponse {
  Vehicle: VehicleInformation;
  Categories: string[];
}

const VehicleState: VehicleInformation = {
  Name: "",
  ModelId: "",
  EngineType: "",
  Hero: {
    Intro: "",
    Title: "",
    ImageUrl: "",
    Description: "",
    Price: {
      CurrencySymbol: "",
      Value: 0,
    },
  },
  RenderingEngine: "",
};

export const vehicleInitialState: VehicleOverviewState = {
  Vehicle: VehicleState,
  FastFacts: [],
  Setups: [],
  Categories: [],
  Parts: [],
  Variations: [
    {
      Code: "",
      ColorCode: "",
      PriceNote: "",
      MontageNote: null
    },
  ],
  Attributes: [
    {
      ProductCode: "",
      Attributes: [
        {
          Key: "",
          ClassificationCategory: "",
          Description: "",
          Values: [
            {
              Key: "",
              Description: "",
            },
          ],
        },
      ],
    },
  ],
  Finance: {
    FinanceData: [
      {
        Variation: "",
        Desciption: "",
        Key: "",
        Available: false,
      },
    ],
    FinanceConfiguration: {
      FinanceServiceUrl: "",
    },
  },
  RenderSettings: {
    Data: {
      Command: "",
      Value: "",
    },
    Sound: {
      Enabled: true,
      Command: "",
      Value: "",
    },
    Reset: {
      Command: "",
      Value: "",
    },
    AppSettings: {
      ENV: "",
      KEY: "",
      URL: "",
    },
    PowerParts: {
      Command: "string",
      List: [
        {
          Category: "",
          Id: "",
          Value: "",
          default: false,
        },
      ],
      Camera: "",
    },
    Tools: {
      Views: {
        Command: "",
        Animations: [{ Name: "", Value: "" }],
      },
      Cinematics: {
        Command: "",
        List: [
          {
            Name: "",
            Value: "",
          },
        ],
      },
      Environments: {
        Command: "",
        List: [
          {
            Name: "",
            Value: "",
          },
        ],
      },
      Perspectives: {
        Command: "",
        List: [
          {
            Name: "",
            Value: "",
          },
        ],
      },
      Tiltshift: {
        Command: "",
        Value: "",
      },
      Lighting: {
        CommandInitial: "",
        CommandSlider: "",
        ValueInitial: 12.0,
        ValueFrom: 12.0,
        ValueTo: 22.0,
        Options: [
          {
            Name: "",
            Value: 12.0,
          },
        ],
      },
      ColorSelection: {
        Command: "",
        CommandIndividual: "",
        PreDefined: {
          BodyColor: [],
          BrakeCaliperColor: [],
          RimColor: [],
          ContrastStitchingColor: [],
          SuspensionSpringAndLogoColor: [],
        },
        Categories: [],
        FinishOptions: [],
      },
    },
  },
};

export function getCategoryInformation(parts: Part[]): string[] {
  return parts
    .reduce((acc: string[], current: Part) => {
      return acc.includes(current.CategoryName) ? acc : [...acc, current.CategoryName];
    }, [])
    .sort();
}

export default function VehicleOverviewReducer(state: VehicleOverviewState = vehicleInitialState, action: Required<Action>): VehicleOverviewState {
  switch (action.type) {
    case ADD_VEHICLE_OVERVIEW: {
      return {
        ...action.payload,
      };
    }
    case EXIT_VEHICLE_CONFIGURATION: {
      return {
        ...vehicleInitialState,
      };
    }
    default:
      return state;
  }
}

import http from '../../services/http';
import { AxiosResponse } from 'axios';
import { VehicleOverviewState, getCategoryInformation } from '../reducers/vehicleOverview';
import { addPrice, selectSetup, addVehicleOverview } from '../actions';
import { toastMessage, getVehicleById } from '../../utils/helpers';
import { Store as appState } from '../reducers';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { VehicleInformation, VehicleOverviewResponse } from '../../models/vehicle';

export default function getOverview(modelId: string): ThunkAction<Promise<void>, appState, {}, any> {
	return function (dispatch: ThunkDispatch<any, any, any>, getState) {
		return http.getOverview(modelId).then(
			(response: AxiosResponse<VehicleOverviewResponse>) => {
				const Vehicle = getVehicleById(modelId) as VehicleInformation;
				const { Setups: [firstSetup] } = response.data;
				const vehicleOverviewData: VehicleOverviewState = {
					...response.data,
					Vehicle,
					Categories: getCategoryInformation(response.data.Parts)
				};
				// selects hero price (may need rework when setups are applied)
				const configurationState=getState();
				if(configurationState.configurationState.price && configurationState.configurationState.price.Value === 0) {
					dispatch(addPrice(Vehicle.Hero.Price));
				}

				// only marks setup as selected, user has to choose and confirm
				dispatch(selectSetup(firstSetup));

				//populates vehicle overview part of the state
				dispatch(addVehicleOverview(vehicleOverviewData));
			},
			(reason: any) => {
				toastMessage('A server error occured.')
				console.error("A http error occured.", reason);
			}
		);
	}
}

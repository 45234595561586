import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Wheels: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Wheel Icon</title>
      <g id="wheel">
        <path d="M48,0A48,48,0,1,0,96,48,48,48,0,0,0,48,0Zm0,85.49A37.5,37.5,0,1,1,85.5,48,37.5,37.5,0,0,1,48,85.49Z" />
        <path d="M48,14A34,34,0,1,0,82,48,34,34,0,0,0,48,14ZM73.36,34.62,57,41.38A11.33,11.33,0,0,0,54.62,39l6.76-16.33A29,29,0,0,1,73.36,34.62ZM48,19.31a28.61,28.61,0,0,1,8.47,1.28L49.71,36.92a11.53,11.53,0,0,0-3.42,0L39.53,20.59A28.61,28.61,0,0,1,48,19.31ZM53.89,48A5.89,5.89,0,1,1,48,42.11,5.89,5.89,0,0,1,53.89,48ZM34.61,22.64h0L41.38,39A11.57,11.57,0,0,0,39,41.38L22.64,34.62A28.8,28.8,0,0,1,34.61,22.64ZM19.31,48h0a28.93,28.93,0,0,1,1.27-8.47l16.33,6.76a11.53,11.53,0,0,0,0,3.42L20.59,56.48A28.72,28.72,0,0,1,19.31,48Zm3.33,13.39h0L39,54.62A11.29,11.29,0,0,0,41.38,57L34.62,73.37A28.88,28.88,0,0,1,22.64,61.39ZM48,76.69a28.61,28.61,0,0,1-8.47-1.28l6.76-16.34a11.53,11.53,0,0,0,3.42,0L56.47,75.4A28.62,28.62,0,0,1,48,76.69Zm13.39-3.33L54.62,57A11.33,11.33,0,0,0,57,54.62l16.33,6.76A28.88,28.88,0,0,1,61.39,73.36ZM59.08,49.71a11.53,11.53,0,0,0,0-3.42l16.33-6.77a28.8,28.8,0,0,1,0,16.95Z" />
      </g>
    </SvgIcon>
  );
};

export default Wheels;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuKTM: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Menu Icon</title>
      <g id="menu">
        <rect y="8.73" width="96" height="15.71" />
        <rect y="40.15" width="96" height="15.71" />
        <rect y="71.56" width="96" height="15.71" />
      </g>
    </SvgIcon>
  );
};

export default MenuKTM;

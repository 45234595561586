import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseHQV: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 20.479 20.479">
      <title>Close Icon</title>
      <path
        d="M438.714,230.856l8.865,8.865-8.865,8.865,1.374,1.374,8.865-8.865,8.865,8.865,1.374-1.374-8.865-8.865,8.865-8.865-1.374-1.374-8.865,8.865-8.865-8.865Z"
        transform="translate(-438.714 -229.482)"
      />
    </SvgIcon>
  );
};

export default CloseHQV;

import { Store } from "./reducers/index";
import { DialogState } from "./reducers/modals";
import { Setup } from "../components/setups/SetupsList";
import { Part } from "../components/parts/PartsList";
import { CreateConfigurationResponse } from "../components/configuration/ConfigurationView";
import { SaveDetailsData } from "../components/dialogs/saveConfiguration";
import { ApplicationSettings } from "./reducers/applicationSettings";
import { ConfigurationState } from "./reducers/configuration";
import { UserGuidanceState } from "./reducers/userGuidance";
import { Price } from "../models/vehicle";

/* ApplicationSettings */
export const getApplicationSettings = (store: Store): ApplicationSettings => store.applicationSettings;

/* SETUPS*/
export const getAllSetups = (store: Store): Setup[] => store.vehicleOverviewState.Setups;

export const getSetupById = (store: Store, setupId: string): Setup => getAllSetups(store).find((setup: Setup) => setup.Name === setupId) as Setup;

export const getAppliedSetup = (store: Store) => store.setups.applied;

export const getSelectedSetup = (store: Store) => store.setups.selected;

/* MODALS */
export const getDialogState = (store: Store): DialogState => store.dialog;

export const getUserGuidanceState = (store: Store): UserGuidanceState => store.userGuidance;

/* CONFIGURATION */
export const getPerspective = (store: Store): number => store.configurationState.perspective;

export const getConfigurationState = (store: Store): ConfigurationState => store.configurationState;

export const getConfiguration = (store: Store): CreateConfigurationResponse => store.configurationState.configuration;

export const getPrice = (store: Store): Price => store.configurationState.price;

export const getImageTimeStamp = (store: Store): number => store.configurationState.imageTimestamp;

export const getPreservedSaveDetailsDataSelector = (store: Store): SaveDetailsData => store.configurationState.preservableSaveDetails;

export const getCategoryScrollPosition = (store: Store): number => store.configurationState.categoryScrollPosition;

export const getConfigurationWasRecentlyCreatedSelector = (store: Store) => store.configurationState.wasRecentlyCreated;

export const getConfigurationIsDirtySelector = (store: Store) => store.configurationState.isDirty;

export const getIS3DMode = (store: Store) => store.configurationState.is3DMode;

export const getIsXbow = (store: Store) => store.configurationState.isXBow;

export const getCdcSettings = (store: Store) => store.configurationState.cdcSettings;

export const getDealerInquirySent = (store: Store) => store.configurationState.dealerInquirySent;

export const getUserDidTimeoutInStream = (store: Store) => store.configurationState.userDidTimeoutInStream;

export const getDealerInquiryDialogOpen = (store: Store) => store.configurationState.dealerInquiryDialogOpen;

export const getVehicleSettings = (store: Store) => store.configurationState.VehicleSettings;

export const getVehicleVariation = (store: Store) => store.configurationState.currentVariation;

export const getCurrentCountry = (store: Store) => store.currentCountry;

export const getCountryArray = (store: Store) => store.countryArray;

export const getImageArray = (store: Store) => store.imageArray;

/* MOTORCYCLES */
export const getMotorcycleModels = (store: Store) => store.modelsResponse;

export const getPriceAvailability = (store: Store) => store.modelsResponse.ShowPricesInConfigurator;

export const getSelectedModel = (store: Store) => store.vehicleOverviewState.Vehicle;

export const getOverview = (store: Store) => store.vehicleOverviewState;

/* CATEGORIES */
export const getCategories = (store: Store) => store.vehicleOverviewState.Categories;

export const getConfiguredPartsCount = (store: Store) => store.parts.applied.length;

/* PARTS */
export const getSelectedPart = (store: Store) => store.parts.selected as Part;

export const getAllParts = (store: Store) => store.vehicleOverviewState.Parts;

export const getAppliedParts = (store: Store) => store.parts.applied;

/* TOAST */
export const getToastState = (store: Store) => store.toast;

/* CULTURE */
export const getCulture = (store: Store) => store.configurationState.culture;

/* MARKET */
export const getMarket = (store: Store) => store.configurationState.market;

/* VIEWS */
export const getViews = (store: Store) => store.views;

export const getPreselectedVariationCode = (store: Store) => store.preselectedVariationCode;

import React, { useEffect, useState } from 'react';
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from '../../../utils/addReduxProps';
import { connect } from "react-redux";
import ArrowHead from "../../../assets/icons/arrowHead";
import { getCulture, getMarket } from '../../../utils/helpers';
import Cookies from 'js-cookie';
import { WithNamespaces } from 'react-i18next';

export type FinanceProps = StateManagementProps & WithNamespaces;

export type PluginValues = {
    assetId: string;
    assetPrice: number;
    country: string;
    language: string;
    showButton: boolean;
    assetImage: string;
    containerId: string;
}

const FinancePlugin:React.FC<FinanceProps> = (props) => {

    var [variation, setVariation] = useState('');
    var [currentPrice, setCurrentPrice] = useState({
                                                    value: 0,
                                                    currency: ""
                                                });
    var [monthlyPrice, setMonthlyPrice] = useState(0);
    var [pluginInitialized, setpluginInitialized] = useState(false);
    var [trackingCookie, setTrackingCookies] = useState(false);
    const [PriceNote, setPriceNote] = useState('');

    const scrollToRef = React.createRef<HTMLDivElement>();
    const translation = props.t;

    useEffect(() => {
        const santanderID = props.overview.Finance.FinanceConfiguration.FinanceServiceUrl;
        const pluginPromise = makeCancelable(loadFinancePluginScript(santanderID))
        if (!pluginInitialized) {            
            if(santanderID) {
                pluginPromise.promise
                .then(()=>{
                    const plugin = (window as any).scfSimulation;
                    try {
                        let pluginValues:PluginValues|null = getFinanceValues();
                        setVariation(props.configuration.VariationCode);
                        if(props.totalPrice) {
                            setCurrentPrice({value:props.totalPrice.Value,currency:props.totalPrice.CurrencySymbol});
                        }
                        
                        if(pluginValues && plugin) {
                            plugin.configure(pluginValues);
                            plugin.openSimulator();
                            setpluginInitialized(true);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            }
        }
        
        let variation = props.configuration.VariationCode;
		let item = props.overview.Variations.find(color => color.Code === variation);
		if(item) {
			setPriceNote(item.PriceNote)
		}

        return () => {
            const plugin = (window as any).scfSimulation;
            pluginPromise.cancel();
            
            if(plugin) {
                plugin.configure(null);
                plugin.closeSimulator();
            }
            removeFinancePlugin();
            clearInterval(interval);
            setVariation('');
            setCurrentPrice({value: 0,currency: ""});
            setMonthlyPrice(0);
            setpluginInitialized(false);
            setTrackingCookies(false);
            setPriceNote('');
        }
    },[]);

    const makeCancelable = (promise:Promise<any>) => {
        let hasCanceled_ = false;
      
        const wrappedPromise = new Promise((resolve, reject) => {
          promise.then(
            val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
            error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
          );
        });
      
        return {
          promise: wrappedPromise,
          cancel() {
            hasCanceled_ = true;
          },
        };
      };

    const interval = setInterval(()=>{
        const plugin = (window as any).scfSimulation;
        if (plugin && plugin.selectedSimulation && plugin.selectedSimulation.monthlyPayment.value !== monthlyPrice) {
            setMonthlyPrice(plugin.selectedSimulation.monthlyPayment.value);
        }
        let cookie = Cookies.get('OptanonConsent');
        if(cookie && cookie !== "") {
            let cookiesArray= cookie.split('&');
            let cookieGroup = "";
            for(let i = 0; cookiesArray.length > i; i++) {
                if(cookiesArray[i].includes('groups')) {
                    cookieGroup = cookiesArray[i];
                }
                if(cookieGroup !== "") {
                    var inclusion = cookieGroup.includes('C0004:1');
                    if(inclusion !== trackingCookie) {
                        setTrackingCookies(inclusion);
                    }
                }
            }
        }
    },200);

    const loadFinancePluginScript = (clientID:string) => {
        const existingScript = document.getElementById("santander");
      
        return new Promise<void>((resolve,reject)=> {
            if(!existingScript) { 
                let script = document.createElement("script");
                script.type = "text/javascript";
                    script.src = clientID;
                    script.charset = "UTF-8";
                    script.setAttribute("id", "santander");
                    document.head.appendChild(script);
                    script.onload = () => {
                        resolve();
                    }
                    script.onerror = () => {
                        reject();
                        setpluginInitialized(false);
                    }
              }
              else {
                  resolve();
              }
        });        
      }

      const removeFinancePlugin = () => {
            const script = document.querySelector("#santander");
            if(script)
            document.head.removeChild(script);
      }

      const getFinanceValues = () => {
          if (props.totalPrice) {
            const item =  props.overview.Finance.FinanceData.find(item => item.Available && item.Variation === props.configuration.VariationCode);
            if (item) {
                
                return  {
                    assetId: item.Key,
                    assetPrice: props.totalPrice.Value,
                    country: getMarket(),
                    language: getCulture().split('-')[0],
                    showButton: false,
                    assetImage: props.selectedModel.Hero.ImageUrl, 
                    model: props.selectedModel.Name,
                    containerId: "iframe-zone",
                    cookie: 0
                }
            }
            else {
                return null;
            }
          }
          else {
              return null;
          }
      }

   const changePrice = () => {
        if(props.totalPrice) {
            (window as any).scfSimulation.configure({assetPrice: props.totalPrice.Value, deposit: 4000});
            setCurrentPrice({value: props.totalPrice.Value, currency: currentPrice.currency});
        }        
    }

    useEffect(() => {
        if(pluginInitialized && variation !== props.currentVariation && props.currentVariation != "") {
            const item =  props.overview.Finance.FinanceData.find(item => item.Available && item.Variation === props.currentVariation);
            setVariation(props.currentVariation);
            if(item) {
                (window as any).scfSimulation.configure({assetId: item.Key, assetImage:props.selectedModel.Hero.ImageUrl});
            }
        }

        return () => {
            setVariation("");
        }
    },[props.currentVariation]);

    useEffect(() => {
        if(pluginInitialized) {
            var i = trackingCookie ? 1 : 0;
            (window as any).scfSimulation.configure({cookie: i});
        }
        
    },[trackingCookie, pluginInitialized]);

    useEffect(() => {
       if(pluginInitialized && props.totalPrice && props.totalPrice.Value !== currentPrice.value) {
            changePrice();
        }
    },[(props.totalPrice ? props.totalPrice.Value : '')]);
 
    return (
        <div className={`finance-plugin-container ${!pluginInitialized ? 'd-none':''}`}>
            <div className="monthly-price-container">
                
                {monthlyPrice !== 0 &&
                    <>
                        <div className="monthly-price-title">{translation('financing.monthlyPrice')}</div>
                        <div className="monthly-price">{(Math.round(monthlyPrice * 100) / 100).toFixed(2)}&nbsp;{currentPrice.currency}</div>
                        {PriceNote !== "" &&
                            <div className="price-info" dangerouslySetInnerHTML={{__html: PriceNote}}>
                            </div>
                        }
                        {PriceNote === "" &&
                         <div className="price-info">{translation('configuration.summary.priceInfo')}</div>
                        }
                    </>
                }               
                <div className="finance-link" onClick={() => {
						if (scrollToRef && scrollToRef.current) {
							scrollToRef.current.scrollIntoView({ behavior: "smooth" });
						}
					}}>{translation('financing.header')}<ArrowHead /></div>
            </div>
        <div className="finance-plugin" ref={scrollToRef}>
            <div className="finance-image-wrapper">
                <img className="finance-image" src="/images/hero.png"/>
                <div className="image-title">What are you waiting for?</div>
                <div className="image-brand"><img src="/images/ktm-finance.png" height="112px" /></div>
            </div>
            <div id="iframe-zone"></div>
        </div>
        </div>
        
    );   
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancePlugin);
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const XBow: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 37 18">
      <title>XBow</title>
      <path
        className="stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0818 1.08927C17.1396 1.072 14.3109 1.22045 11.3731 1.3158C9.64499 2.76453 8.61622 3.57912 8.01293 4.61447C11.0125 5.12895 14.543 6.91299 18.4685 6.92387C22.3034 6.94114 25.7765 5.17503 28.7299 4.65351C28.7967 4.63623 28.861 4.63175 28.9265 4.62087C28.3213 3.5804 27.2882 2.79012 25.5488 1.33244C23.6535 1.16478 21.8467 1.09887 20.0812 1.09055V1.08927H20.0818ZM4.26532 4.14543C3.20099 4.14543 2.3781 4.28813 1.86777 4.65031C1.56831 4.86275 0.386688 5.05728 1.40797 14.4273C2.56588 15.121 2.32631 15.0282 2.47417 16.2241L9.69677 17.0003C9.92324 16.6983 10.7711 15.8377 11.5085 15.9048L14.9785 13.2633L12.3002 15.8728L18.4685 15.4614L24.3672 15.8498L21.5884 13.2435L25.4271 15.9048C26.1746 16.1205 26.7441 16.452 27.1509 16.889L34.5894 16.2241C34.7017 15.0199 34.5594 15.1664 35.5283 14.4273C36.5496 5.05792 35.368 4.86339 35.0685 4.65031C34.5582 4.28813 33.7359 4.13839 32.671 4.14543C29.478 4.16527 24.1083 7.40251 18.4678 8.68423C12.828 7.40251 7.45893 4.16463 4.26594 4.14543H4.26532ZM3.33699 6.36908C3.33699 6.36908 6.91242 6.75685 7.80643 7.20094C8.70044 7.64439 11.1267 10.3608 11.1267 10.3608C11.1267 10.3608 3.27336 11.0263 3.33699 6.36908V6.36908ZM33.6012 6.36908C33.6648 11.0263 25.8115 10.3608 25.8115 10.3608C25.8115 10.3608 28.2377 7.64439 29.1311 7.20094C30.0251 6.75749 33.6012 6.36908 33.6012 6.36908Z"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};

export default XBow;

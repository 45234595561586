import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Screenshot: React.FunctionComponent = () => {
  return (
    <SvgIcon width="31" height="31" viewBox="0 0 31 31">
      <title>Screenshot Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.41667C0 2.1148 1.8648 0.25 4.16667 0.25H8.125C8.67728 0.25 9.125 0.697715 9.125 1.25C9.125 1.80228 8.67728 2.25 8.125 2.25H4.16667C2.96937 2.25 2 3.21937 2 4.41667V8.375C2 8.92728 1.55228 9.375 1 9.375C0.447715 9.375 0 8.92728 0 8.375V4.41667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 21.625C1.55228 21.625 2 22.0727 2 22.625V26.5833C2 27.7806 2.96937 28.75 4.16667 28.75H8.125C8.67728 28.75 9.125 29.1977 9.125 29.75C9.125 30.3023 8.67728 30.75 8.125 30.75H4.16667C1.8648 30.75 0 28.8852 0 26.5833V22.625C0 22.0727 0.447715 21.625 1 21.625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 21.625C30.0523 21.625 30.5 22.0727 30.5 22.625V26.5833C30.5 28.8852 28.6352 30.75 26.3333 30.75H22.375C21.8227 30.75 21.375 30.3023 21.375 29.75C21.375 29.1977 21.8227 28.75 22.375 28.75H26.3333C27.5306 28.75 28.5 27.7806 28.5 26.5833V22.625C28.5 22.0727 28.9477 21.625 29.5 21.625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.375 1.25C21.375 0.697715 21.8227 0.25 22.375 0.25H26.3333C28.6352 0.25 30.5 2.1148 30.5 4.41667V8.375C30.5 8.92728 30.0523 9.375 29.5 9.375C28.9477 9.375 28.5 8.92728 28.5 8.375V4.41667C28.5 3.21937 27.5306 2.25 26.3333 2.25H22.375C21.8227 2.25 21.375 1.80228 21.375 1.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3713 14.3787C16.1998 13.2071 14.3003 13.2071 13.1287 14.3787L13.1287 14.3787C11.9571 15.5502 11.9571 17.4497 13.1287 18.6213L12.4289 19.3211L13.1287 18.6213C14.3002 19.7929 16.1997 19.7929 17.3713 18.6213L18.024 19.274L17.3713 18.6213C18.5429 17.4498 18.5429 15.5503 17.3713 14.3787ZM18.7855 12.9645C16.8329 11.0118 13.6671 11.0118 11.7145 12.9645L18.7855 12.9645ZM18.7855 12.9645C20.7382 14.9171 20.7382 18.0829 18.7855 20.0355C16.8329 21.9882 13.6671 21.9882 11.7145 20.0355L12.4216 19.3284L11.7145 20.0355C9.76185 18.0829 9.76184 14.9171 11.7145 12.9645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6099 8.58301L11.1656 11.0561C10.9863 11.363 10.6576 11.5518 10.3021 11.5518H8.32292C7.7827 11.5518 7.34375 11.9907 7.34375 12.5309V21.4372C7.34375 21.9774 7.7827 22.4163 8.32292 22.4163H22.1771C22.7173 22.4163 23.1562 21.9774 23.1562 21.4372V12.5309C23.1562 11.9902 22.7168 11.5518 22.1787 11.5518H20.1995C19.8487 11.5518 19.5236 11.3679 19.3427 11.0674L17.8476 8.58301H12.6099ZM10.8857 7.56952C11.2423 6.9587 11.8955 6.58301 12.6042 6.58301H17.853C18.5523 6.58301 19.1965 6.95017 19.5567 7.54425L19.5585 7.54714L19.5585 7.54714L20.7648 9.55176H22.1787C23.8224 9.55176 25.1562 10.8867 25.1562 12.5309V21.4372C25.1562 23.082 23.8219 24.4163 22.1771 24.4163H8.32292C6.67813 24.4163 5.34375 23.082 5.34375 21.4372V12.5309C5.34375 10.8861 6.67813 9.55176 8.32292 9.55176H9.72806L10.8855 7.56997C10.8855 7.56993 10.8855 7.56989 10.8855 7.56984C10.8856 7.56974 10.8857 7.56963 10.8857 7.56952Z"
      />
    </SvgIcon>
  );
};

export default Screenshot;

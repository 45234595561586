import http from '../../services/http';
import { AxiosResponse } from 'axios';
import { addMotorcycleModels } from '../actions';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { MotorcycleModelResponse } from '../../models/vehicle';

export default function getModels(): ThunkAction<Promise<void>, {}, {}, any> {
    return function (dispatch: ThunkDispatch<any, any, any>) {
        return http.getModels().then((response: AxiosResponse<MotorcycleModelResponse>) => {
            dispatch(addMotorcycleModels(response.data));
        }).catch((reason) => {
            console.error('An http error occured', JSON.stringify(reason));
        })
    }
}
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PlusIconProps } from "./plus";

const PlusGG: React.FunctionComponent<PlusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 20 20" id={props.identifyer}>
      <title>Plus Icon</title>
      <path d="M11.5118 0H8.48874V8.48816H0V11.5113H8.48814V19.9995H11.5113V11.5118H19.9994V8.4887H11.5113L11.5118 0Z" fill="#CB0D25" />
    </SvgIcon>
  );
};

export default PlusGG;

import { StreamingService } from '@monkeyway/streaming-lib/dist/streaming/streaming';
import { StreamInfo } from '@monkeyway/streaming-lib/dist/types/stream-info';
import { StreamingControlService } from '@monkeyway/streaming-control-lib/dist/control';
import { debug, error, info } from '../../utils/helpers';
import { ServiceOptions } from '@monkeyway/streaming-lib/dist/types/service-options';
import { BitrateLimitData } from '@monkeyway/streaming-lib/dist/types/stream-monitoring';
import * as Commands from './commands';

export enum StreamState {
  INITIALIZING,
  LOAD_BIKE,
  LOAD_ENV,
  LOAD_BASE,
  LOAD_COLORS,
  LOAD_PP,
  LOAD_VIDEO_SRC,
  INIT_DONE,
  BROKEN,
  ACCEPTING,
  BITRATE_DROPPED,
}

export const SOCKET = '3DcommunicationSocket';
export const STREAM_SUBSCRIPTION = 'streamSubscription';
export const STREAM_SERVICE = 'streamService';
export const STREAM_CONTROL_SERVICE = 'streamControlService';

let streamingService: StreamingService = document[STREAM_SERVICE];
let streamingControlService: StreamingControlService = document[STREAM_CONTROL_SERVICE];
let streamSubscription = document[STREAM_SUBSCRIPTION]; //Subscription | null = null;
let streamInfo: StreamInfo | null = null;
let socket: WebSocket = document[SOCKET]; //commands socket

export const socketMessagesCallbacks: { [key: string]: (message: string) => void } = {};

let messageBuffer: string[] = []; //queue for sending messages

/**
 * Send message to the web socket
 * @param message
 */
export const sendMessage = (message: string, lighting?: string) => {
  return new Promise<void>((resolve) => {
    debug('*** sending message: ', message);
    let socket: WebSocket = document[SOCKET];
    if (socket) {
      if (messageBuffer.length === 0) {
        socket.send(message);

        debug('*** message sent');
        if (lighting) {
          socketMessagesCallbacks[Commands.ENVIRONMENT_DONE] = (message: string) => {
            if (message.includes(Commands.ENVIRONMENT_DONE)) {
              debug('ENV DONE');
              delete socketMessagesCallbacks[Commands.ENVIRONMENT_DONE];
              debug('extra lighting msg sent');
              socket.send(lighting);
            }
          };
        }
      } else {
        debug('*** message added to buffer, will be send on socket response: ', message);
        messageBuffer.push(message); //will be removed on socket response
      }
    } else {
      debug('*** sending command failed, socket is null', message);
    }
  });
};

/**
 * Streaming Events
 */
const onConnected = (initializeBike: Function) => {
  debug('On connected');

  if (!streamInfo) {
    error('Fatal: streamInfo is not defined');
    return;
  }

  socket = new WebSocket(`wss://${streamInfo.specs.publicDomainName}`);
  //add socket to document to prevent closing on rerender
  document[SOCKET] = socket;

  socket.onopen = (e) => {
    initializeBike(streamInfo);
  };
  socket.onclose = (e) => {
    debug('Socket closed');
  };
  socket.onmessage = (e) => {
    debug('Socket msg: ', e);
    onSocketMessageReceived(e);
  };
  socket.onerror = (e) => {
    console.error(e);
  };
};

export const setBitRateDroppedCallback = (callback: (param: any) => void) => {
  bitRateDroppedCallback = callback;
};

let bitRateDroppedCallback: (evt: any) => void;

const onBitrateDroppedCallCallback = (evt: any) => {
  bitRateDroppedCallback && bitRateDroppedCallback(evt);
};

/**
 *
 * @param e contains object of engine response. e.data in particular is interesting
 */
const onSocketMessageReceived = (e: MessageEvent) => {
  debug('*** engine response received');
  let notSendMessage = messageBuffer.shift();
  if (notSendMessage) {
    debug('*** found unsent message, try sending again');
    sendMessage(notSendMessage);
  }

  //run all callbacks... TODO: remove them after tests
  Object.values(socketMessagesCallbacks).forEach((cb) => cb(e.data));
};

/**
 * Render part in the 3D engine by sending commands over the stream.
 * @param commandCategory
 * @param commandValue
 * @param silent true - cam will focus the part added, false - only the part will be rendered.
 * @returns
 */
export const renderParts = (commandCategory: string, commandValue: string, silent: boolean) => {
  return new Promise<void>((resolve) => {
    let categories: string[] = commandCategory.split(',').map((x) => x.trim());

    const render = (command: string) => {
      categories.forEach((category) => sendMessage(`confopt ${category} ${command}`));
      resolve();
    };

    if (silent) {
      return render(commandValue);
    }

    sendMessage(`confcam ${categories[0]}`);

    const waiting = 2500; // fallback time to send the command if the engine was too slow to response
    const timeout = setTimeout(() => {
      info(`Warning: Call render after TIMEOUT ${waiting}.`);
      render(commandValue);
    }, waiting);

    socketMessagesCallbacks[Commands.CONFCAM_DONE] = (message: string) => {
      if (message.includes(Commands.CONFCAM_DONE)) {
        clearTimeout(timeout);
        debug('CONFCAM DONE');
        delete socketMessagesCallbacks[Commands.CONFCAM_DONE];
        render(commandValue);
      }
    };
  });
};

/**
 * Controls
 */
export const startStop = (
  isStart = true,
  initializeBike: Function,
  onDisconnected: Function,
  soundEnabled: boolean,
  streamingOptions: ServiceOptions,
  videoDivRef: React.RefObject<HTMLVideoElement>,
) => {
  if (isStart) {
    debug('starting...');
    streamingService = new StreamingService(streamingOptions);
    document[STREAM_SERVICE] = streamingService;
    streamingControlService = new StreamingControlService();
    document[STREAM_CONTROL_SERVICE] = streamingControlService;
    startSession(initializeBike, onDisconnected, soundEnabled, videoDivRef);
  } else {
    debug('stopping...');
    stopSession(onDisconnected);
  }
};

const startSession = (
  initializeBike: Function,
  onDisconnected: Function,
  soundEnabled: boolean,
  videoDivRef: React.RefObject<HTMLVideoElement>,
) => {
  if (streamSubscription) {
    debug('Wont start because there is already a subscription.');
    return;
  }

  const minBitrate = 3000;

  const streamInfo$ = streamingService.start(
    {},
    {
      streamLimits: { minBitrate: minBitrate, maxBitrate: 15000, startBitrate: 5000 },
      useAudio: soundEnabled,
      cutStreamLimits: {
        bitrate: minBitrate,
        handler: (evt: any) => {
          onBitrateDroppedCallCallback(evt);
          return false;
        },
      },
    },
  );

  streamSubscription = streamInfo$.subscribe(
    (info) => {
      if (!info) {
        // session ended
        onDisconnected();
        removeStreamSubscription();
      } else if (!streamInfo || streamInfo.stream !== info.stream) {
        // session established

        const options = streamingControlService.createOptions(info);
        streamingControlService.connect(videoDivRef!.current!, options).subscribe(
          (_) => {
            streamInfo = info;
            onConnected(initializeBike);
          },
          async (err) => {
            console.error(`error connecting to renderer: ${err}`);
            streamInfo = info; // TODO
            onDisconnected(); //was setFailedToStart(true);
            //ask: why us this call here? onConnected();
            await stopSession(onDisconnected);
          },
        );
      }
    },
    async (error) => {
      // TODO: handle no available session gracefully (will follow with an updated streaming library)
      if (error instanceof Error) {
        console.error(error);
      } else {
        console.error('connection to stream failed');
      }

      onDisconnected();

      streamSubscription = null;
      document[STREAM_SUBSCRIPTION] = null;
    },
  );

  document[STREAM_SUBSCRIPTION] = streamSubscription;
};

export const stopSession = async (onDisconnected: Function) => {
  debug('Stopping...');

  streamingService = document[STREAM_SUBSCRIPTION];
  if (streamSubscription) {
    removeStreamSubscription();
  }

  streamingService = document[STREAM_SERVICE];
  if (streamingService) {
    debug('Stop streamingService');
    await streamingService.stop();
    document[STREAM_SERVICE] = null;
    streamingService = document[STREAM_SERVICE];
    onDisconnected();
  }

  streamingControlService.close();
  socket = document[SOCKET];
  if (socket) {
    debug('socket.close');
    socket.close();
  }
};

export const removeStreamSubscription = () => {
  debug('Remove streamSubscription');
  if (streamSubscription) {
    streamSubscription.unsubscribe();
  }
  streamSubscription = null;
  document[STREAM_SUBSCRIPTION] = null;
};

import React from "react";
import MinusKTM3D from "./minus_ktm3D";
import MinusHQV3D from "./minus_hqv3D";
import MinusGG3D from "./minus_gg3D";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";

export interface MinusIconProps {
  identifyer: string;
}

const Minus3D: React.FunctionComponent<StateManagementProps & MinusIconProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <MinusKTM3D identifyer={props.identifyer} />}
      {brand === "HQV" && <MinusHQV3D identifyer={props.identifyer} />}
      {brand === "GG" && <MinusGG3D identifyer={props.identifyer} />}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Minus3D);

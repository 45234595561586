import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";
import MenuHQV from "./menu_hqv";
import MenuKTM from "./menu_ktm";

const Menu: React.FunctionComponent<StateManagementProps> = props => {
  const brand = props.applicationSettings.Brand;
  const ktm = brand === "KTM" ? true : false;
  return (
    <>
      {ktm && <MenuKTM />}
      {!ktm && <MenuHQV />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

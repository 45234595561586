import React from "react";
import { DialogTypes } from "../../enums/dialogTypes";
import SetupPartsDialog from "./setupParts";
import { StateManagementProps } from "../../utils/addReduxProps";
import { DialogState } from "../../redux/reducers/modals";
import Action from "../../redux/reducers/models/action";
import WarnBeforeExit from "./warnBeforeExit";
import DeleteConfiguration from "./deleteConfiguration";
import ConflictingParts from "./conflictingParts";
import RemoveConflictingParts from "./removeConflictingParts";
import SaveConfiguration from "./saveConfiguration";
import SavedConfiguration from "./savedConfiguration";
import UserGuidance from "./UserGuidance";
import ExportConfiguration from "./exportConfiguration";
import ShareConfiguration from "./shareConfiguration";
import SendConfiguration from "./sendConfiguration";
import SendConfigurationToDealer from "./sendConfigurationToDealer";
import LanguageSelection from "./languageSelection";
import { RouteComponentProps } from "react-router";

export interface DialogProps<D = any> extends DialogState<D> {
  hideDialog: () => Action;
}

export const defaultMessage = "Something went wrong, we are working on it.";

export class DialogRouter extends React.Component<StateManagementProps & RouteComponentProps> {
  renderDialog() {
    const { contentType } = this.props.dialogState;
    const props: DialogProps = {
      ...this.props.dialogState,
      hideDialog: this.props.hideDialog,
    };

    switch (contentType) {
      case DialogTypes.setupParts:
        return <SetupPartsDialog {...props} />;
      case DialogTypes.warnBeforeExit:
        return <WarnBeforeExit {...props} />;
      case DialogTypes.deleteConfiguraion:
        return <DeleteConfiguration {...props} />;
      case DialogTypes.conflictWhileAddPart:
        return <ConflictingParts {...props} {...this.props} />;
      case DialogTypes.removeConflictingPart:
        return <RemoveConflictingParts {...props} {...this.props} />;
      case DialogTypes.saveConfiguration:
        return <SaveConfiguration {...props} setConfigurationId={this.props.setConfigurationId} configurationWasRecentlyCreated={this.props.wasRecentlyCreated} />;
      case DialogTypes.exportConfiguration:
        return <ExportConfiguration {...props} />;
      case DialogTypes.shareConfiguration:
        return <ShareConfiguration {...props} configurationWasRecentlyCreated={this.props.wasRecentlyCreated} />;
      case DialogTypes.sendConfiguration:
        return <SendConfiguration {...props} configurationWasRecentlyCreated={this.props.wasRecentlyCreated} />;
      case DialogTypes.sendConfigurationToDealer:
        return <SendConfigurationToDealer {...props} configurationWasRecentlyCreated={this.props.wasRecentlyCreated} />;
      case DialogTypes.savedConfiguration:
        return <SavedConfiguration {...props} {...this.props} />;
      case DialogTypes.userGuidance:
        return <UserGuidance {...props} {...this.props} />;
      case DialogTypes.languageSelection:
        return <LanguageSelection {...props} {...this.props} />;
      default:
        return <div className="error">{defaultMessage}</div>;
    }
  }

  render() {
    if (!this.props.dialogState.isOpen) {
      return null;
    }
    return this.renderDialog();
  }
}

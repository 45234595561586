import React from "react";
import PlusKTM3D from "./plus_ktm3D";
import PlusHQV3D from "./plus_hqv3D";
import PlusGG3D from "./plus_gg3D";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";

export interface PlusIconProps {
  identifyer: string;
}

const Plus3D: React.FunctionComponent<StateManagementProps & PlusIconProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <PlusKTM3D identifyer={props.identifyer} />}
      {brand === "HQV" && <PlusHQV3D identifyer={props.identifyer} />}
      {brand === "GG" && <PlusGG3D identifyer={props.identifyer} />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Plus3D);

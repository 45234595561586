import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import { red } from '@material-ui/core/colors';
import { I18nextProvider } from 'react-i18next';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as deLocale from 'date-fns/locale/de';
import { PersistGate } from 'redux-persist/integration/react'

const themeConfig = {
    typography: {
        fontFamily: [
            'TradeGothicLTComCondensed18',
            'sans-serif',
        ].join(','),
    },

    palette: {
        primary: {
            light: orange[300],
            main: '#ff6600',
            dark: orange[700],
        },
        secondary: yellow,
        error: red,
    },
}

const theme = createMuiTheme(themeConfig);
//TODO: request if B2C or B2B

ReactDOM.render(
    // Theme
    <MuiThemeProvider theme={theme}>
        {/* Router */}
        <BrowserRouter>
            {/* Redux */}
            {/* type 'any' mitigates current problem with types for this prop */}
            <Provider store={store as any}>
                <PersistGate loading={"LOADING"} persistor={persistor}>
                    {/* Translations */}
                    <I18nextProvider i18n={i18n}>
                        {/* Datepicker */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <App />
                        </MuiPickersUtilsProvider>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </MuiThemeProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import PlusKTM from "./plus_ktm";
import PlusHQV from "./plus_hqv";
import PlusGG from "./plus_gg";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";

export interface PlusIconProps {
  identifyer: string;
}

const Plus: React.FunctionComponent<StateManagementProps & PlusIconProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <PlusKTM identifyer={props.identifyer} />}
      {brand === "HQV" && <PlusHQV identifyer={props.identifyer} />}
      {brand === "GG" && <PlusGG identifyer={props.identifyer} />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Plus);

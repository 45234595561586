import React, { useEffect } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import ArrowHead from "../../../assets/icons/arrowHead";
import BrandLogo from "../../shared/BrandLogo";
import { RouteComponentProps } from "react-router";

export type UserProfile = {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  photoURL: string;
  thumbnailURL: string;
};

export type UserData = {
  UID: string;
  UIDSignature: string;
  profile: UserProfile;
};

export type CDCLoginProps = {
  onUserLoggedIn?(data: UserData | undefined): void | undefined;
  mode: "saveUserConfigurationCDC" | "loadAccountInfo" | "configuratorLogin";
  showSaveIcon?: boolean;
  onSaveIconClicked?(): void;
  onSaveSuccess?(): void;
  userProfileChecked?: boolean;
  removeTabIndexToMakeCDCWorking?(): void;
  mobile?: boolean;
  checkUserLogin?: () => void;
  isSummary?: boolean;
};

export type CDCProps = StateManagementProps & WithNamespaces & RouteComponentProps;

const CDCLoginContainer: React.FunctionComponent<CDCProps> = (props) => {
  const { t, applicationSettings, is3DMode, cdcSettings, history, dealerInquiryDialogOpen } = props;
  const brand = applicationSettings.Brand.toLowerCase();

  const goBackToMain = () => {
    history.push("/main");
  };

  const goBackButtonClick = () => {
    if (is3DMode && !props.dialogState.isOpen) {
      goBackToMain();

      const timer = setTimeout(() => {
        props.setCdcSettings(false);

        if ((window as any).gigya) {
          (window as any).gigya.accounts.hideScreenSet(brand + "-in-page");
        }

        clearTimeout(timer);
      }, 300);
    } else {
      props.setCdcSettings(false);

      if ((window as any).gigya) {
        (window as any).gigya.accounts.hideScreenSet(brand + "-in-page");
      }
    }
  };

  useEffect(() => {
    return () => {
      if ((window as any).gigya) {
        (window as any).gigya.accounts.hideScreenSet(brand + "-in-page");
      }
    };
  });

  return (
    <div className={`login-placeholder ${!is3DMode || props.dialogState.isOpen ? "fixed" : ""} ${cdcSettings ? "show" : "hide"}`}>
      <div className="header-wrapper header login-screen-header">
        <div onClick={goBackButtonClick}>
          <ArrowHead />
          <div className="go-back-text gtm-go-back-main" title={t("buttons.closeDialog")}>
            {t("buttons.closeDialog")}
          </div>
        </div>

        {<BrandLogo customClass={`brandlogo brandlogo-in-main-header`} {...props}></BrandLogo>}
      </div>

      <div id="cdc-login-container" className="scrollable"></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CDCLoginContainer);

import {
  EXIT_VEHICLE_CONFIGURATION,
  ADD_VEHICLE_OVERVIEW,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_GUIDANCE,
  HIDE_GUIDANCE,
  SET_PERSPECTIVE,
  ADD_MOTORYCLE_MODELS,
  SELECT_PART,
  DESELECT_PART,
  SELECT_SETUP,
  APPLY_SETUP,
  APPLY_PARTS,
  SHOW_TOAST,
  HIDE_TOAST,
  REMOVE_ALL_PARTS,
  SET_PRICE,
  SET_CREATED_CONFIGURATION,
  SET_IMAGE_TIMESTAMP,
  SET_CONFIGURATION_ID,
  SET_CONFIGURATION_IMAGES,
  TOGGLE_CONFIG_SUMMARY,
  TOGGLE_PART_DETAIL,
  PRESERVE_SAVE_DETAILS_DATA,
  CATEGORY_SCROLL_POSITION,
  SET_CONFIGURATION_WAS_RECENTLY_CREATED,
  SET_CONFIGURATION_IS_DIRTY,
  SET_VEHICLE_VARIATION,
  SET_CDC_SETTINGS,
  SET_CURRENT_COUNTRY,
  SET_COUNTY_ARRAY,
  SET_IMAGE_ARRAY,
  SET_PRESELECTED_VARIATION_CODE,
  SET_NO_CONFLICTING_PARTS,
  SET_REMOVE_NO_CONFLICTING_PARTS,
  SET_IS3D_MODE,
  SET_IS_XBOW,
  DEALER_INQUIRY_SENT,
  USER_DID_TIMEOUT_IN_STREAM,
  DEALER_INQUIRY_DIALOG_OPEN,
  SET_RUNTIME_CONFIGURATION
} from "./actionTypes";
import { Part } from "../components/parts/PartsList";
import { DialogTypes } from "../enums/dialogTypes";
import { Setup } from "../components/setups/SetupsList";
import { VehicleOverviewState } from "./reducers/vehicleOverview";
import { CreateConfigurationResponse, FullImage } from "../components/configuration/ConfigurationView";
import { SaveDetailsData } from "../components/dialogs/saveConfiguration";
import { CurrentCountryState } from "./reducers/currentCountry";
import { CountryArrayState } from "./reducers/countryArray";
import { ImageArrayState } from "./reducers/imageArray";
import { PreselectedVariationCodeState } from "./reducers/preselectedVariationCode";
import { AddPartPayload, RemovePartPayload } from "../services/http/real";
import { MotorcycleModelResponse, Price } from "../models/vehicle";
import { ApplicationSettings } from "./reducers/applicationSettings";

export const exitVehicleConfiguration = () => ({
  type: EXIT_VEHICLE_CONFIGURATION,
});

/* BIKE OVERVIEW */
export const addVehicleOverview = (payload: VehicleOverviewState) => ({
  type: ADD_VEHICLE_OVERVIEW,
  payload,
});

export interface ShowDialogProps<D = any> {
  contentType: DialogTypes;
  data: D;
  title: string;
  shareType?: string;
}

/* MODALS */
export const showDialog = ({ contentType, data, title, shareType }: ShowDialogProps) => ({
  type: SHOW_MODAL,
  payload: {
    contentType,
    data,
    title,
    shareType,
  },
});

export const setRuntimeConfiguration = (payload: ApplicationSettings) => ({
  type: SET_RUNTIME_CONFIGURATION,
  payload: payload
})

export const setIs3DMode = (payload: boolean) => ({
  type: SET_IS3D_MODE,
  payload: payload,
});

export const setIsXBow = (payload: boolean) => ({
  type: SET_IS_XBOW,
  payload: payload,
});

export const hideDialog = () => ({
  type: HIDE_MODAL,
  payload: null,
});

export const showGuidance = () => ({
  type: SHOW_GUIDANCE,
  payload: null,
});

export const hideGuidance = () => ({
  type: HIDE_GUIDANCE,
  payload: null,
});

export const setCurrentCountry = (payload: CurrentCountryState) => ({
  type: SET_CURRENT_COUNTRY,
  payload,
});

export const setCountryArray = (payload: CountryArrayState) => ({
  type: SET_COUNTY_ARRAY,
  payload,
});

export const setPreselectedVariationCode = (payload: PreselectedVariationCodeState) => ({
  type: SET_PRESELECTED_VARIATION_CODE,
  payload,
});

export const setImageArray = (payload: ImageArrayState) => ({
  type: SET_IMAGE_ARRAY,
  payload,
});
/* MODELS */
export const addMotorcycleModels = (payload: MotorcycleModelResponse) => ({
  type: ADD_MOTORYCLE_MODELS,
  payload,
});

/* CONFIGURATION */
export const setPerspective = (perspective: number) => ({
  type: SET_PERSPECTIVE,
  payload: perspective,
});

export const addCreatedConfiguration = (payload: CreateConfigurationResponse) => ({
  type: SET_CREATED_CONFIGURATION,
  payload,
});

export const setConfigurationId = (payload: number) => ({
  type: SET_CONFIGURATION_ID,
  payload,
});

export const addPrice = (payload: Price) => ({
  type: SET_PRICE,
  payload,
});

export const setImageTimestamp = () => ({
  type: SET_IMAGE_TIMESTAMP,
});

export const setConfigurationImages = (payload: FullImage[]) => ({
  type: SET_CONFIGURATION_IMAGES,
  payload,
});

export const setCategoryScrollPosition = (payload: number) => ({
  type: CATEGORY_SCROLL_POSITION,
  payload,
});

export const setConfigurationWasRecentlyCreated = (payload: boolean) => ({
  type: SET_CONFIGURATION_WAS_RECENTLY_CREATED,
  payload,
});

export const setConfigurationIsDirty = (payload: boolean) => ({
  type: SET_CONFIGURATION_IS_DIRTY,
  payload,
});

export const setVehicleVariation = (payload: string) => ({
  type: SET_VEHICLE_VARIATION,
  payload,
});

export const setCdcSettings = (payload: boolean) => ({
    type: SET_CDC_SETTINGS,
    payload,
  });

/* PARTS */
export const selectPart = (payload: Part) => ({
  type: SELECT_PART,
  payload,
});
export const deselectPart = () => ({
  type: DESELECT_PART,
});

export const applyParts = (payload: string[]) => ({
  type: APPLY_PARTS,
  payload,
});

export const removeAllParts = () => ({
  type: REMOVE_ALL_PARTS,
});

export const noConflictingParts = (payload: AddPartPayload) => ({
  type: SET_NO_CONFLICTING_PARTS,
  payload,
});

export const removeNoConflictingParts = (payload: RemovePartPayload) => ({
  type: SET_REMOVE_NO_CONFLICTING_PARTS,
  payload,
});
/* SETUPS */
export const selectSetup = (payload: Setup) => ({
  type: SELECT_SETUP,
  payload,
});

export const applySetup = (payload: Setup) => ({
  type: APPLY_SETUP,
  payload,
});

/* TOAST */
export const showToast = (payload: string) => ({
  type: SHOW_TOAST,
  payload,
});

export const hideToast = () => ({
  type: HIDE_TOAST,
});

/* VIEWS */

export const toggleSummary = (payload: boolean) => ({
  type: TOGGLE_CONFIG_SUMMARY,
  payload,
});

export const togglePartDetail = (payload: boolean) => ({
  type: TOGGLE_PART_DETAIL,
  payload,
});

export const preserveSaveDetailsData = (payload: SaveDetailsData) => ({
  type: PRESERVE_SAVE_DETAILS_DATA,
  payload,
});

export const setDealerInquirySent = (payload: boolean) => ({
  type: DEALER_INQUIRY_SENT,
  payload,
});

export const setUserDidTimeoutInStream = (payload: boolean) => ({
  type: USER_DID_TIMEOUT_IN_STREAM,
  payload,
});

export const setDealerInquiryDialogOpen = (payload: boolean) => ({
  type: DEALER_INQUIRY_DIALOG_OPEN,
  payload,
});

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Country: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Country Icon</title>
      <path
        id="country"
        d="M48,0A48,48,0,1,0,96,48,48,48,0,0,0,48,0ZM14.51,26.25A41.12,41.12,0,0,1,27.7,13.69h0A69.57,69.57,0,0,0,19,44H8.37A37.23,37.23,0,0,1,14.51,26.25ZM8.87,52.18H19A76.66,76.66,0,0,0,26.59,81C15.05,73.28,9.36,64,8.87,52.18ZM44.74,88a18.34,18.34,0,0,1-4.49-2.71c-11.5-8.88-13.48-26.44-14.21-33v0h18.7Zm0-44H26l0,0c.73-6.61,2.7-24.18,14.21-33a18,18,0,0,1,4.48-2.71h0ZM82.3,26.26A37.27,37.27,0,0,1,88.44,44H77.84A70.67,70.67,0,0,0,69.1,13.69,41.08,41.08,0,0,1,82.3,26.26ZM52.08,8.18a18.07,18.07,0,0,1,4.49,2.72c11.5,8.87,13.47,26.43,14.21,33v0H52.06Zm4.48,77.09A18.34,18.34,0,0,1,52.07,88V52.19h18.7v0C70,58.83,68.06,76.4,56.56,85.27ZM70.24,81a76.85,76.85,0,0,0,7.57-28.81H88C87.45,64,81.77,73.28,70.24,81Z"
      />
    </SvgIcon>
  );
};

export default Country;

import {
	SET_PERSPECTIVE,
	SET_CREATED_CONFIGURATION,
	SET_IMAGE_TIMESTAMP,
	SET_PRICE,
	SET_CULTURE,
	EXIT_VEHICLE_CONFIGURATION,
	SET_CONFIGURATION_ID,
	SET_CONFIGURATION_IMAGES,
	SET_COMPANY_ID,
	SET_COUNTRY,
	PRESERVE_SAVE_DETAILS_DATA,
	SET_ENTRY_POINT_URL,
	SET_CONFIGURATION_WAS_RECENTLY_CREATED,
	SET_CONFIGURATION_IS_DIRTY,
	SET_VEHICLE_VARIATION,
	SET_NO_CONFLICTING_PARTS,
	SET_REMOVE_NO_CONFLICTING_PARTS,
	SET_IS3D_MODE,
	SET_IS_XBOW,
	DEALER_INQUIRY_SENT,
	USER_DID_TIMEOUT_IN_STREAM,
	DEALER_INQUIRY_DIALOG_OPEN,
	SET_CDC_SETTINGS,
} from "../actionTypes";
import Action from "./models/action";
import { CreateConfigurationResponse } from "../../components/configuration/ConfigurationView";
import { SaveDetailsData } from "../../components/dialogs/saveConfiguration";
import { AddPartPayload, CustomColorConfigurationsPayload, RemovePartPayload, ToolsConfigurationPayload, VehicleSettingsPayload } from "../../services/http/real";
import { Price } from "../../models/vehicle";

const initialCustomerData: CustomerData = {
	name: "",
	surname: "",
	email: "@",
	comment: ""
};

const initialSaveDetailsData: SaveDetailsData = {
	Vin: "",
	OrderNumber: "",
	Firstname: "",
	Lastname: "",
	Comment: ""
};

const initialPartPayload: AddPartPayload = {
	PartId: "",
	SelectedParts: [],
	RemoveParts: [],
	DryRun: true
};

const initialRemovePartPayload: RemovePartPayload = {
	PartId: "",
	RemoveParts: [],
	DryRun: true
};

const initialToolsConfigurationPayload : ToolsConfigurationPayload = {
	Cinematics: "",
	Environments: "",
	Lighting: "",
	Perspectives: "",
	Animations: []
}
const initialCustomColorConfigurationsPayload : CustomColorConfigurationsPayload = {
	SelectedBodyColor: "",
	SelectedRimsColor: "",
	SelectedCaliperColor: "",
	SelectedStitchingColor: "",
	SelectedSuspensionAndLogoColor: "",
}

export interface ConfigurationState {
	configuration: CreateConfigurationResponse;
	perspective: number;
	price: Price;
	imageTimestamp: number;
	culture: string;
	market: string;
	companyId: string;
	customer: CustomerData;
	entryPointUrl: string;
	categoryScrollPosition: number;
	wasRecentlyCreated: boolean;
	isDirty: boolean;
	preservableSaveDetails: SaveDetailsData;
	currentVariation: string;
	noConflictingParts: AddPartPayload;
	removeNoConflictingParts: RemovePartPayload;
	is3DMode: boolean;
	cdcSettings: boolean;
	isXBow: boolean;
	dealerInquirySent: boolean;
	userDidTimeoutInStream: boolean;
	dealerInquiryDialogOpen: boolean;
	VehicleSettings: VehicleSettingsPayload;
}

export interface CustomerData {
	name?: string;
	surname?: string;
	email?: string;
	comment?: string;
}

export const initialConfigurationState: ConfigurationState = {
	configuration: {} as CreateConfigurationResponse,
	perspective: 0,
	price: {
		CurrencySymbol: "",
		Value: 0
	},
	imageTimestamp: 0,
	culture: process.env.REACT_APP_DEFAULT_CULTURE as string,
	market: "",
	companyId: "",
	customer: { ...initialCustomerData },
	entryPointUrl: "",
	categoryScrollPosition: 0,
	wasRecentlyCreated: true,
	isDirty: false,
	preservableSaveDetails: { ...initialSaveDetailsData },
	currentVariation:"",
	noConflictingParts: { ...initialPartPayload	},
	removeNoConflictingParts: { ...initialRemovePartPayload},
	is3DMode: false,
	cdcSettings: false,
	isXBow: false,
	dealerInquirySent: false,
	userDidTimeoutInStream: false,
	dealerInquiryDialogOpen: false,
	VehicleSettings: {
		CustomColorConfiguration: { ...initialCustomColorConfigurationsPayload },
		ToolsConfiguration: { ...initialToolsConfigurationPayload }
	},
};

export default function ConfigurationReducer(
	state: ConfigurationState = initialConfigurationState,
	action: Action
): ConfigurationState {
	switch (action.type) {
		case SET_PERSPECTIVE: {
			return {
				...state,
				perspective: action.payload
			};
		}
		case SET_CREATED_CONFIGURATION: {
			return {
				...state,
				configuration: action.payload
			};
		}
		case SET_CONFIGURATION_ID: {
			return {
				...state,
				configuration: {
					...state.configuration,
					ConfigurationId: action.payload
				}
			};
		}
		case SET_CONFIGURATION_IMAGES: {
			return {
				...state,
				configuration: {
					...state.configuration,
					FullImages: action.payload
				}
			};
		}
		case SET_IS3D_MODE : {
			return {
				...state,
				is3DMode: action.payload
			} 
		}
		case SET_IS_XBOW : {
			return {
				...state,
				isXBow: action.payload
			}
		}
		case DEALER_INQUIRY_SENT : {
			return {
				...state,
				dealerInquirySent: action.payload
			}
		}
		case USER_DID_TIMEOUT_IN_STREAM : {
			return {
				...state,
				userDidTimeoutInStream: action.payload
			}
		}
		case DEALER_INQUIRY_DIALOG_OPEN : {
			return {
				...state,
				dealerInquiryDialogOpen: action.payload
			}
		}
		case SET_PRICE: {
			return {
				...state,
				price: action.payload
			};
		}
		case SET_IMAGE_TIMESTAMP: {
			return {
				...state,
				imageTimestamp: Date.now()
			};
		}
		case SET_CULTURE: {
			return {
				...state,
				culture: action.payload
			};
		}
		case SET_COMPANY_ID: {
			return {
				...state,
				companyId: action.payload
			};
		}
		case SET_COUNTRY: {
			return {
				...state,
				market: action.payload
			};
		}
		case EXIT_VEHICLE_CONFIGURATION: {
			return {
				...initialConfigurationState,
				culture: state.culture,
				market: state.market,
				companyId: state.companyId
			};
		}
		case SET_ENTRY_POINT_URL: {
			return {
				...state,
				entryPointUrl: action.payload
			};
		}
		case PRESERVE_SAVE_DETAILS_DATA: {
			return {
				...state,
				preservableSaveDetails: action.payload
			};
		}
		case SET_CONFIGURATION_WAS_RECENTLY_CREATED: {
			return {
				...state,
				wasRecentlyCreated: action.payload
			};
		}
		case SET_CONFIGURATION_IS_DIRTY: {
			return {
				...state,
				isDirty: action.payload
			};
		}
		case SET_VEHICLE_VARIATION: {
			return {
				...state,
				currentVariation: action.payload
			}
		}
		case SET_NO_CONFLICTING_PARTS: {
			return {
				...state,
				noConflictingParts: action.payload 
			}
		}
		case SET_REMOVE_NO_CONFLICTING_PARTS: {
			return {
				...state,
				removeNoConflictingParts: action.payload 
			}
		}
		case SET_CDC_SETTINGS: {
			return {
				...state,
				cdcSettings: action.payload 
			}
		}
		default:
			return state;
	}
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { DialogProps } from "./dialogRouter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { RequiredParts, ConflictingPartErrorResponse } from './conflictingParts';
import { RemovePartPayload } from "../../services/http/real";
import store from '../../redux/store';
import removePartAsync from '../../redux/async-actions/removePart';
import Close from "../../assets/icons/close";
import Arrow from "../../assets/icons/arrow";
import { StateManagementProps } from "../../utils/addReduxProps";

const RemoveConflictingParts: React.FunctionComponent<DialogProps<ConflictingPartErrorResponse> & WithNamespaces & StateManagementProps> = ({ hideDialog, t, data, applicationSettings, title }) => {
	const rulesResult = data.RulesResult;
	const removePart = () => {
		const payload: RemovePartPayload = {
			PartId: rulesResult.Part.Code,
			RemoveParts: rulesResult.Parts.map((part) => part.Code),
		}
		store.dispatch(removePartAsync(payload) as any);
	}

	const renderHeadline = () => {
		if (applicationSettings.Brand === "KTM") {
			return <h2
				id="conflicting-parts-modal"
				className="conflicting-part-headline underline uppercase"
			>
				{title}
			</h2>
		}
		if (applicationSettings.Brand === "HQV") {
			return <h2
				id="conflicting-parts-modal"
				className="conflicting-part-headline underline uppercase"
			>
				<span className="headline">{title}</span>
			</h2>
		}
	}

	return (
		<Dialog
			disableBackdropClick={true}
			fullWidth={true}
			open={true}
			onClose={hideDialog}
			aria-labelledby="conflicting-parts-modal"
			id="conflicting-parts-dialog"
		>
			<div className="conflicting-parts-dialog-wrapper">
				<div className="dialog-wrapper">
					<Grid item className="close-send-mail-button-mobile show-mobile container">
						<button className="primary back-link" title={t("buttons.closeDialog")} onClick={hideDialog}>
							<Close />
						</button>
					</Grid>
					<Grid item className="close-send-mail d-none-mobile container conflicting-parts">
						<button className="primary back-link" title={t("buttons.closeDialog")} onClick={hideDialog}>
							<span className="show-mobile"><Arrow /></span>{t("navigation.goBack")}<span className="d-none-mobile"><Arrow /></span>
						</button>
					</Grid>
					<Grid item className="full-width">
						<DialogTitle id="conflicting-parts-dialog-title">
							<Grid item>
								{renderHeadline()}
							</Grid>
						</DialogTitle>
					</Grid>
					<Grid item className="full-width">
						<DialogContent>
							<RequiredParts rule={rulesResult} deleteRule={true} />
						</DialogContent>
						<DialogActions className="dialog-action-container">
							<button onClick={hideDialog} className="secondary">
								{t("buttons.cancel")}
							</button>
							<button onClick={removePart} className="primary">
								{t("buttons.confirmPartRules")}
							</button>
						</DialogActions>
					</Grid>
				</div>
			</div>
		</Dialog >
	)
}

export default withNamespaces()(RemoveConflictingParts);

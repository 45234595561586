import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Save3DConfig: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 25 30">
      <title>Save Icon</title>
      <path d="M5.75 2.58203C3.67893 2.58203 2 4.26096 2 6.33203V26.9126C1.99999 27.1311 2.12211 27.3313 2.3164 27.4313C2.5107 27.5313 2.74458 27.5143 2.92239 27.3873L11.5021 21.2589C11.8498 21.0105 12.3169 21.0105 12.6646 21.2589L21.2443 27.3873C21.4221 27.5143 21.656 27.5312 21.8503 27.4313C22.0445 27.3313 22.1667 27.1311 22.1667 26.9126V14.2487C22.1667 13.6964 22.6144 13.2487 23.1667 13.2487C23.719 13.2487 24.1667 13.6964 24.1667 14.2487V26.9126C24.1667 27.8803 23.6258 28.7668 22.7654 29.2096C21.905 29.6524 20.8692 29.5772 20.0818 29.0147L12.0833 23.3015L4.08487 29.0148C3.29742 29.5772 2.26166 29.6524 1.40122 29.2096C0.540778 28.7668 -2.6938e-05 27.8802 1.00638e-09 26.9126V6.33203C1.00638e-09 3.15639 2.57436 0.582031 5.75 0.582031H10.5C11.0523 0.582031 11.5 1.02975 11.5 1.58203C11.5 2.13432 11.0523 2.58203 10.5 2.58203H5.75ZM18.417 2.16602C18.9693 2.16602 19.417 2.61373 19.417 3.16602V5.33203H21.5833C22.1356 5.33203 22.5833 5.77975 22.5833 6.33203C22.5833 6.88432 22.1356 7.33203 21.5833 7.33203H19.417V9.49935C19.417 10.0516 18.9693 10.4993 18.417 10.4993C17.8647 10.4993 17.417 10.0516 17.417 9.49935V7.33203H15.25C14.6977 7.33203 14.25 6.88432 14.25 6.33203C14.25 5.77975 14.6977 5.33203 15.25 5.33203H17.417V3.16602C17.417 2.61373 17.8647 2.16602 18.417 2.16602Z" />
    </SvgIcon>
  );
};

export default Save3DConfig;

import http from "../../services/http";
import { AxiosResponse } from "axios";
import { Store as appState } from "../reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { LOAD_CONFIGURATION, SET_RUNTIME_CONFIGURATION } from "../actionTypes";
import { LoadConfigurationResponse } from "../reducers/load";
import { History } from "history";
import { preserveSaveDetailsData } from "../actions";

export default function loadConfiguration(configurationId: string, browserHistory: History<any>, country: string, culture: string, companyId: string): ThunkAction<Promise<void>, appState, {}, any> {
  return function (dispatch: ThunkDispatch<any, any, any>, getState: () => appState) {
    return http.getConfiguration(configurationId, country, culture, companyId).then(
      (response: AxiosResponse<LoadConfigurationResponse>) => {
        const {
          data: {
            Vehicle: { ModelId },
          },
        } = response;

        const state = getState();

        const vehicle = state.modelsResponse.Segments.flatMap(s => s.Vehicles).find(v => v.ModelId === ModelId);
        const vehicleHeroImageUrl = vehicle && vehicle.Hero.ImageUrl;

        // Load data from history into state
        dispatch({
          type: LOAD_CONFIGURATION,
          payload: {
            ...response.data,
            heroImgUrl: vehicleHeroImageUrl,
            Variations: response.data.Vehicle.Variations,
            TestRideData: response.data.TestRideData,
          },
        });

				// This is needed because otherwise the application,
				// because the initial state is loaded twice,
				// switches theme when entering over the shared link
        dispatch({
          type: SET_RUNTIME_CONFIGURATION,
          payload: state.applicationSettings,
        });

        if (response.data && response.data.Configuration) dispatch(preserveSaveDetailsData(response.data.Configuration.SaveDetails));

        // Redirect to configuration view
        browserHistory.push(`configuration/${ModelId}/categories`);
      },
      (reason: any) => {
        console.error("An error occured while trying to load a configuration:", reason);
      }
    );
  };
}

import React from "react";
import { Part } from "./PartsList";
import Grid from "@material-ui/core/Grid";
import format from "../../utils/formatter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import NoPartPreviewIcon from "@material-ui/icons/VisibilityOffOutlined";
import Minus from "../../assets/icons/minus";
import Plus from "../../assets/icons/plus";
import { getCulture } from "../../utils/helpers";
import { togglePart } from "../../utils/partsHelpers";
import Minus3D from "../../assets/icons/minus3D";
import Plus3D from "../../assets/icons/plus3D";

export interface PartProps {
  part: Part;
  fade: boolean;
  isIncluded: boolean;
  showDialog: (part: Part) => void;
  selectedFromSummary: boolean;
  itemClicked?: () => void;
  showDetails?: () => void;
  resetSubMenuState?: () => void;
  appliedParts: Part[];
  is3D: boolean | undefined;
}

export const PartItem: React.FunctionComponent<PartProps & WithNamespaces> = (props) => {
  const {
    part,
    part: { CanBeRendered, Racing, PartId },
    t,
    fade,
    isIncluded,
    showDialog,
    selectedFromSummary: selected,
    resetSubMenuState,
    is3D,
    showDetails,
    itemClicked,
    appliedParts,
  } = props;

  const faded = fade ? "faded" : "";
  const partImageStyle: React.CSSProperties = {
    background: `url(${part.Images.Main})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundColor: "#fff",
  };

  const onClick = () => {
    if (showDetails) showDetails();
    showDialog.bind(null, part)();
    if (itemClicked) itemClicked();
    document.body.classList.add("lock-scroll");
    if (resetSubMenuState !== undefined) {
      resetSubMenuState();
    }
  };

  const addOrRemovePart = () => {
    togglePart(part, appliedParts);
  };

  if (is3D) {
    return (
      <div id={`part-${PartId}`} className={`part-container ${faded} ${selected ? "selected" : ""}`}>
        <div className="part-content-wrapper" onClick={onClick}>
          <div className="part-image" style={partImageStyle}></div>
          <div className="part-description">
            <div className="part-ref ref-desktop">Ref: {part.PartId}</div>
            <div className="part-title">{part.Name}</div>
            <div className={`part-price ${(part.Price && part.Price.Value === 0) || !part.Price ? "no-price" : ""}`}>{format.currency(part.Price)}{!CanBeRendered && <NoPartPreviewIcon fontSize="inherit" className="not-visualized-icon" titleAccess={t("configuration.parts.notVisualized")} />}</div>
          </div>
          <div className="part-action">
            <div className="part-ref ref-mobile">Ref: {part.PartId}</div>

            
            <div className="mobile-button-placeholder"></div>
          </div>
        </div>

        <div className={`pp-list-bottom-content ${CanBeRendered ? "full-w-button" : ""}`}>
          <button onClick={addOrRemovePart} className={isIncluded ? "remove" : "add"}>
            {isIncluded ? (
              <>
                <span className="desktop-text">Remove</span> <Minus3D identifyer={`remove-part-${PartId}`} />
              </>
            ) : (
              <>
                <span className="desktop-text">Add</span> <Plus3D identifyer={`add-part-${PartId}`} />
              </>
            )}
          </button>
          {!CanBeRendered && <NoPartPreviewIcon fontSize="inherit" className="not-visualized-icon" titleAccess={t("configuration.parts.notVisualized")} />}
        </div>
      </div>
    );
  }

  return (
    <div id={`part-${PartId}`} className={`part-wrapper ${faded} ${selected ? "selected" : ""}`}>
      <Grid container direction="row" alignContent="center" className="part-details-container" onClick={onClick}>
        {/* LEFT: PART IMAGE */}
        <Grid item className="part-image-wrapper">
          <div className="full-height" style={partImageStyle}></div>
        </Grid>
        {/* RIGHT: PART DETAIL */}
        <Grid item className="full-width">
          <Grid container direction="column" justify="flex-start">
            <h3 className={`part-headline ${getCulture() && getCulture().substr(0, 2) === "de" ? "break-word" : ""}`}>{part.Name}</h3>

            <Grid item className="part-price-wrapper">
              <div className="part-price">{format.currency(part.Price)}</div>
              {!CanBeRendered && <NoPartPreviewIcon fontSize="inherit" className="not-visualized-icon" titleAccess={t("configuration.parts.notVisualized")} />}
            </Grid>
            <Grid item className="part-adding-wrapper">
              <div></div>
              {Racing && (
                <span className="racingComponent" title={t("configuration.parts.racing")}>
                  {t("configuration.summary.ece")}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <button onClick={addOrRemovePart} className={isIncluded ? "remove" : "add"}>
        {isIncluded ? <Minus identifyer={`remove-part-${PartId}`} /> : <Plus identifyer={`add-part-${PartId}`} />}
      </button>
    </div>
  );
};

export default withNamespaces()(PartItem);

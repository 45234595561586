import React, { useEffect } from "react";
import Preview from "./components/preview/PreviewView";
import Sandbox from "./components/sandbox/sandbox";
import ConfigurationView from "./components/configuration/ConfigurationView";
import Main from "./components/main/MainView";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BrowserRouter as Router, Route, Switch, RouteComponentProps, Redirect } from "react-router-dom";
import RestrictedRoute from "./utils/RestrictedRoute";
import { Store as appStore } from "./redux/reducers";
import { Store } from "redux";
import { TransitionState } from "./enums/transitionStates";
import { Subject } from "rxjs";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "./utils/addReduxProps";
import store from "./redux/store";
import getRuntimeConfiguration from "./redux/async-actions/getRuntimeConfiguration";
import { ApplicationSettings } from "./redux/reducers/applicationSettings";

const TransitionState$ = new Subject<TransitionState>();

export const CompanyIdContext = React.createContext("5b540245-23d2-480b-af90-ec5427e7e479"),
  CompanyIdProvider = CompanyIdContext.Provider;

export const TransititionStateValue = TransitionState$.asObservable();

const checkConfigurationID = (store: Store<appStore>) => {
  return !!store.getState().vehicleOverviewState.Parts.length;
};

const renderRoot = (props: RouteComponentProps<any>) => {
  const search = props.location.search ? props.location.search : "";
  return <Redirect to={`/main/${search}`}></Redirect>;
};

const loadOnestrust = (dataDomainScript: string) => {
  const existingScript = document.getElementById("onetrust");

  if (!existingScript) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.charset = "UTF-8";

    script.setAttribute("data-document-language", "true");
    script.setAttribute("data-domain-script", dataDomainScript);
    script.setAttribute("id", "onetrust");

    document.head.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `function OptanonWrapper() { }`;
    document.head.appendChild(script);
  }
};

const App: React.FC<StateManagementProps> = (props) => {
  const [brandInitialized, setBrandInitialized] = React.useState(false);

  // Set Brand-Class to Body
  useEffect(() => {
    if (!brandInitialized) {
      // make sure the runtime configuration callback sets the brand - otherwise it's too early/async
      store.dispatch(
        getRuntimeConfiguration((data: ApplicationSettings): void => {
          const dataDomainScript = data.OneTrust;
          loadOnestrust(dataDomainScript);

          const ENV = data.Environment === "Production" ? "live" : "stage";

          (function (sCDN, sCDNProject, sCDNWorkspace, sCDNVers) {
            if (
              window.localStorage !== null &&
              typeof window.localStorage === "object" &&
              typeof window.localStorage.getItem === "function" &&
              window.sessionStorage !== null &&
              typeof window.sessionStorage === "object" &&
              typeof window.sessionStorage.getItem === "function"
            ) {
              sCDNVers = window.sessionStorage.getItem("jts_preview_version") || window.localStorage.getItem("jts_preview_version") || sCDNVers;
            }
            //@ts-ignore
            window.jentis = window.jentis || {};
            //@ts-ignore
            window.jentis.config = window.jentis.config || {};
            //@ts-ignore
            window.jentis.config.frontend = window.jentis.config.frontend || {};
            //@ts-ignore
            window.jentis.config.frontend.cdnhost = sCDN + "/get/" + sCDNWorkspace + "/web/" + sCDNVers + "/";
            //@ts-ignore
            window.jentis.config.frontend.vers = sCDNVers;
            //@ts-ignore
            window.jentis.config.frontend.env = sCDNWorkspace;
            //@ts-ignore
            window.jentis.config.frontend.project = sCDNProject;
            //@ts-ignore
            window._jts = window._jts || [];
            var f = document.getElementsByTagName("script")[0];
            var j = document.createElement("script");
            j.async = true;
            //@ts-ignore
            j.src = window.jentis.config.frontend.cdnhost + data.JentisSettings.Id + ".js";
            //@ts-ignore
            f.parentNode.insertBefore(j, f);
          })(data.JentisSettings.Url, data.JentisSettings.Project, ENV, "_");

          const brand = data.Brand;
          document.body.classList.remove(...["KTM", "GG", "HQV"]);
          document.body.classList.add(brand.toLowerCase());
          setBrandInitialized(true);
        }) as any
      );
    }
  }, []);

  // Show nothing until useEffect ist done, to prevent default-Styles from Flashing at the start
  if (!brandInitialized) return null;

  return (
    <Router>
      <Route
        render={({ location }: RouteComponentProps<any>) => (
          <TransitionGroup className="full-height transition-group">
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={{ enter: 300, exit: 300 }}
              onEnter={() => {
                TransitionState$.next(TransitionState.Enter);
              }}
              onExited={() => {
                TransitionState$.next(TransitionState.Exit);
              }}
            >
              <section id="main-switch-wrapper" className={`full-height ${props.is3DMode ? "mode-3d" : "mode-2d"}`}>
                <Switch location={location}>
                  <Route exact path="/" render={renderRoot} />
                  <Route path="/main" component={Main} />
                  <Route path="/preview" component={Preview} />
                  <Route path="/sandbox" component={Sandbox} />
                  <RestrictedRoute checkStore={checkConfigurationID} hasParam="modelId" path="/configuration/:modelId" component={ConfigurationView} />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      ></Route>
    </Router>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Daytime: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 24 29">
      <title>Daytime Icon</title>
      <g clipPath="url(#clip0_2283_7211)">
        <path
          className="stroke"
          d="M13.7393 3.61059C14.0852 3.95367 14.3214 4.39176 14.4179 4.86928C14.5144 5.3468 14.4668 5.84222 14.2813 6.29268C14.0958 6.74315 13.7807 7.12836 13.3759 7.39942C12.9711 7.67049 12.4949 7.81521 12.0077 7.81521C11.5205 7.81521 11.0443 7.67049 10.6395 7.39942C10.2348 7.12836 9.91961 6.74315 9.73409 6.29268C9.54857 5.84222 9.50105 5.3468 9.59755 4.86928C9.69405 4.39176 9.93022 3.95367 10.2761 3.61059C10.7366 3.15378 11.359 2.89746 12.0077 2.89746C12.6564 2.89746 13.2788 3.15378 13.7393 3.61059"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path className="stroke" d="M12 1.41963V0.432617" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 10.2683V9.28125" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M15.125 2.2251L15.48 1.87012" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M8.52734 8.82288L8.87366 8.47656" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M15.9316 5.35059H16.9187" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M7.08203 5.35059H8.06904" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M15.125 8.47656L15.48 8.82288" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M8.52734 1.87012L8.87366 2.2251" strokeLinecap="round" strokeLinejoin="round" />
        <path
          className="stroke"
          d="M12.0005 24.3724C12.4138 23.9488 12.6863 23.408 12.7809 22.8239C12.8754 22.2397 12.7873 21.6405 12.5286 21.1083C12.5072 21.0731 12.4959 21.0326 12.4959 20.9914C12.4959 20.9502 12.5072 20.9097 12.5286 20.8745C12.5561 20.8408 12.5931 20.8162 12.6348 20.8038C12.6765 20.7915 12.721 20.792 12.7624 20.8053C13.3978 20.9704 13.9777 21.3022 14.442 21.7663C14.824 22.1615 15.1102 22.639 15.2787 23.1622C15.4472 23.6854 15.4933 24.2402 15.4137 24.7841C15.3341 25.3279 15.1307 25.8462 14.8193 26.2991C14.5079 26.752 14.0968 27.1274 13.6175 27.3965C13.1383 27.6656 12.6037 27.8211 12.0549 27.8511C11.5061 27.8812 10.9577 27.7849 10.4519 27.5697C9.94617 27.3546 9.49651 27.0262 9.13756 26.61C8.77861 26.1938 8.51993 25.7008 8.38142 25.1689C8.36815 25.1275 8.36766 25.083 8.38001 25.0413C8.39237 24.9996 8.417 24.9626 8.45069 24.9351C8.48592 24.9137 8.52635 24.9024 8.56757 24.9024C8.60879 24.9024 8.64922 24.9137 8.68445 24.9351C9.22626 25.2014 9.83816 25.2904 10.4334 25.1894C11.0286 25.0884 11.5769 24.8025 12.0005 24.3724Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path className="stroke" d="M2.12066 8.85742C-0.528691 13.8531 0.302478 18.6323 3.80897 23.1951" strokeMiterlimit="10" strokeLinecap="round" />
        <path className="fill" d="M0 8.26829L3.80952 6.25098L3.66234 10.5627L0 8.26829Z" />
        <path className="stroke" d="M21.8797 20.5886C24.5291 15.593 23.6979 10.8137 20.1914 6.25098" strokeMiterlimit="10" strokeLinecap="round" />
        <path className="fill" d="M24.0009 21.1772L20.1914 23.1945L20.3473 18.8828L24.0009 21.1772Z" />
      </g>
      <defs>
        <clipPath id="clip0_2283_7211">
          <rect className="fill" width="24" height="28.381" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Daytime;

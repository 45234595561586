import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MinusIconProps } from "./minus";

const MinusKTM: React.FunctionComponent<MinusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 96 96" id={props.identifyer}>
      <title>Minus Icon</title>
      <rect x="31.01" y="41.96" width="34.41" height="10.73" />
      <path d="M48,0A48,48,0,1,0,96,48,48,48,0,0,0,48,0Zm0,85.45A37.46,37.46,0,1,1,85.46,48,37.46,37.46,0,0,1,48,85.45Z" />
    </SvgIcon>
  );
};

export default MinusKTM;

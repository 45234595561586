import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { DialogContent, DialogActions, Dialog, CircularProgress } from "@material-ui/core";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import http from "../../services/http";
import { FormManager, FormControlState, createControl, DefaultValidators as Validators } from "../../utils/FormManager";
import { getConfigurationId, toastMessage, isPortraitMode, generateScreenshotForPDFGeneration, getIsMobile } from "../../utils/helpers";
import { AxiosResponse } from "axios";
import Action from "../../redux/reducers/models/action";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { DealerSearchComponent } from "ktm-dealer-search";
import "ktm-dealer-search/dist/index.css";
import CdcLogin, { UserData, UserProfile } from "../configuration/cdc/CdcLogin";
import { DealerOption } from "ktm-dealer-search/dist/lib/hooks/useDealerSearch";
import { ConfigurationSummaryProps, ShareConfigurationResponse } from "../configuration/configurationSummary/ConfigurationSummary";
import { getCurrentCountry } from "../../redux/selectors";
import store from "../../redux/store";
import formatter from "../../utils/formatter";
import MapPin from "../../assets/icons/map_pin";
import PinCircle from "../../assets/icons/pin_circle";
import Pin from "../../assets/icons/pin";
import User from "../../assets/icons/user";
import CheckOk from "../../assets/icons/check_ok";
import { DialogHeaderWithLogo } from "./dialogHeaderWithLogo";
import { shareConfiguration } from "../../services/http/real";

const EmailControl = createControl("Email", "Type Email", [Validators.email], true);

const formManager = new FormManager();

export interface Offer {
  dealer?: DealerOption;
  configurationId?: number;
  userProfileId?: string;
  userProfile?: UserProfile;
  screenshotData?: string;
}

export interface SendConfigurationProps {
  setConfigurationId: (id: number) => Action;
  configurationWasRecentlyCreated: boolean;
  isPortraitMode?: boolean;
}

export type AllSendConfigurationToDealerProps = DialogProps & SendConfigurationProps & StateManagementProps & WithNamespaces;

export const SendConfigurationToDealer: React.FunctionComponent<AllSendConfigurationToDealerProps> = (props) => {
  const { hideDialog, t, setConfigurationId, applicationSettings, data, isXBow, setDealerInquirySent, is3DMode, userDidTimeoutInStream } = props;

  const brand = applicationSettings.Brand;
  const _offer: Offer = { configurationId: getConfigurationId() };

  const configSummaryProps: ConfigurationSummaryProps = data;

  const [email, setEmail] = useState<FormControlState>(EmailControl);
  const [error, setError] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [dealerChecked, setDealerChecked] = useState<boolean>(false);
  const [configurationChecked, setConfigurationChecked] = useState<boolean>(getConfigurationId() != undefined);
  const [userProfileChecked, setUserProfileChecked] = useState<boolean>(false);
  const [dealerSearchOpen, setDealerSearchOpen] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>(_offer);
  const [selectedDealerNo, setSelectedDealerNo] = useState(0);
  const [reloadConfigurationUrl, setReloadConfigurationUrl] = useState("");

  //user starts dealer search
  const showDealerSearch = () => {
    setDealerSearchOpen(!dealerSearchOpen);
    setOffer(offer);
  };

  useEffect(() => {
    addRemoveClassToFooter(true, "send-configuration");
    props.setDealerInquiryDialogOpen(true);
    if (offer.configurationId != getConfigurationId()) {
      offer.configurationId = getConfigurationId();
      setOffer({ ...offer });
    }

    return () => {
      addRemoveClassToFooter(false, "send-configuration");
      props.setDealerInquiryDialogOpen(false);
    };
  }, []);

  useEffect(() => {
    shareConfiguration().then(
      (response: AxiosResponse<ShareConfigurationResponse>) => {
        const configurationUrl = response.data.Url;
        setReloadConfigurationUrl(configurationUrl);
      },
      (error) => {
        console.log("Cannot create shareable Link ", error);
        return "no link availiable";
      }
    );
  }, [userDidTimeoutInStream]);

  // when the dealer is selected
  const onSelectDealer = useCallback(
    (dealer: DealerOption) => {
      offer.dealer = dealer;
      setOffer({ ...offer });
      setSelectedDealerNo(dealer.DealerNo);
      setDealerChecked(true);
      setDealerSearchOpen(false);
    },
    [setSelectedDealerNo]
  );

  const changeConfiguration = () => hideDialog();

  // when the user is logged in into CDC
  const onUserLoggedIn = (userData: UserData) => {
    if (userData) {
      setUserProfileChecked(true);
      offer.userProfileId = userData.UID;
      offer.userProfile = userData.profile;
      offer.configurationId = getConfigurationId();
      setOffer({ ...offer });
    } else {
      setUserProfileChecked(false);
      offer.userProfileId = undefined;
      offer.userProfile = undefined;
      setOffer({ ...offer });
    }
  };

  const sendToDealerWithScreenShotFunction = () =>
    http
      .sendToDealerWithScreenShot(offer)
      .then((response: AxiosResponse<{ ConfigurationId: number }>) => {
        setConfigurationId(response.data.ConfigurationId);
        setGenerating(false);
        toastMessage(t("toasts.offerSent"));
        hideDialog();
        setDealerInquirySent(true);
      })
      .catch((error) => {
        setGenerating(false);
        setError(true);
        console.log(`an error occured: ${error}.`);
      });

  //Send form data to backend .. email to dealer
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(dealerChecked && configurationChecked && userProfileChecked)) return;
    setGenerating(true);

    if (is3DMode) {
      const storePdfScreenshot = sessionStorage.getItem("pdfScreenshot");

      if (storePdfScreenshot) {
        offer.screenshotData = storePdfScreenshot;
        sendToDealerWithScreenShotFunction();
      } else {
        const promise = generateScreenshotForPDFGeneration(props.isPortraitMode || isPortraitMode(), getIsMobile(), document.getElementById("stream") as HTMLVideoElement);

        promise &&
          promise.then((base64Image) => {
            offer.screenshotData = base64Image;
            sendToDealerWithScreenShotFunction();
          });
      }
    } else {
      http
        .sendToDealer(offer)
        .then((response: AxiosResponse<{ ConfigurationId: number }>) => {
          setConfigurationId(response.data.ConfigurationId);
          setGenerating(false);
          toastMessage(t("toasts.offerSent"));
          hideDialog();
          setDealerInquirySent(true);
        })
        .catch((error) => {
          setGenerating(false);
          setError(true);
          console.log(`an error occured: ${error}.`);
        });
    }
  };

  function getGoogleMapsKey(): string {
    return applicationSettings.GoogleMapsKey;
  }

  function removeTabIndexToMakeCDCWorking() {
    const element = document.getElementById("sendConfigurationToDealerDialog");
    if (element) {
      element.children[1].removeAttribute("tabindex");
    }
  }

  // update controls on every render
  formManager.addAllControls([[email, setEmail]]);

  const toDealerSearchBrand = (configuratorBrand: string) => {
    console.log("*** toDealerSearchBrand", configuratorBrand);
    switch (configuratorBrand) {
      case "KTM":
        return "ktm";
      case "HQV":
        return "husqvarna";
      case "GG":
        return "gasGas";
      default:
        return "all";
    }
  };

  const getDealerQualification = (): string | undefined => {
    if (configSummaryProps.selectedModel.Name.toLowerCase().indexOf("bow") >= 0) return "1000681369"; //X-Bow  Qualifikation-ID "1000681369"
    return undefined;
  };

  function addRemoveClassToFooter(add: boolean, className: string) {
    const footer = document.getElementById("footer");
    if (footer) {
      if (add) {
        (footer as any).classList.add(className);
      } else {
        (footer as any).classList.remove(className);
      }
    }
  }

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      onClose={hideDialog}
      fullWidth={true}
      maxWidth="xl"
      aria-labelledby="export configuration modal"
      id="sendConfigurationToDealerDialog"
      className={dealerSearchOpen ? "dealer-search-open" : "search-closed"}
    >
      <DialogHeaderWithLogo {...props} generating={generating} />

      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className="dealer-search-dialog-content">
          {dealerSearchOpen ? (
            <div className="dealer-search-component-container">
              <DealerSearchComponent
                text=""
                apiUrl="/api/"
                brand={toDealerSearchBrand(applicationSettings.Brand + "")}
                country={getCurrentCountry(store.getState()).CountryCode}
                lang={getCurrentCountry(store.getState()).LanguageCode}
                googleMapsKey={getGoogleMapsKey()}
                onSelectDealer={onSelectDealer}
                showBrandSelector={false}
                qualification={getDealerQualification()}
                isXBow={isXBow}
              />
            </div>
          ) : (
            <div className={`dealer-search-container ${generating ? "loading" : ""}`}>
              {generating && (
                <div className="generating-dealer-send-overlay">
                  <CircularProgress className="spinner" />
                </div>
              )}
              <div className={`dealer-search-step-container step-configuration ${configurationChecked ? "checked" : "unchecked"}`}>
                <div className="dealer-search-check">{configurationChecked && <CheckOk />}</div>

                <div className="dealer-search-step">
                  <div className="step-icon">
                    <img src={isXBow || is3DMode ? props.selectedModel.Hero.ImageUrl : configSummaryProps.configuration.FullImages[1].Url} />
                  </div>

                  <div>
                    <div className="info">
                      <div className="info-title">{configSummaryProps.selectedModel.Name}</div>
                      <div className="info-subtitle">
                        {configSummaryProps.totalPrice && !isXBow && (
                          <>
                            {formatter.currency(configSummaryProps.totalPrice)}
                            {brand === "KTM" ? <>{" (incl. ktm powerparts)"}</> : <>{" (incl. accessories)"}</>}
                          </>
                        )}
                      </div>
                      <div className="mobile-buttons">
                        <a
                          onClick={() => {
                            if (!userDidTimeoutInStream) {
                              changeConfiguration();
                            } else {
                              window.location.href = reloadConfigurationUrl;
                            }
                          }}
                        >
                          {!userDidTimeoutInStream ? t("configuration.offer.changeconfiguration") : t("configuration.offer.reloadconfiguration")}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="dealer-search-check-mobile">{configurationChecked && <CheckOk />}</div>

                  <div className="step-action">
                    <a
                      className={`send-config-dealer-button dealer-search-buttons ${configurationChecked ? "checked" : "unchecked"}`}
                      onClick={() => {
                        if (!userDidTimeoutInStream) {
                          changeConfiguration();
                        } else {
                          let reRoute = reloadConfigurationUrl;
                          if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
                            reRoute = reloadConfigurationUrl.replace("5000", "5001");
                          }
                          window.location.href = reRoute;
                        }
                      }}
                    >
                      {!userDidTimeoutInStream ? t("configuration.offer.changeconfiguration") : t("configuration.offer.reloadconfiguration")}
                    </a>
                  </div>
                </div>
              </div>

              <div className={`dealer-search-step-container step-dealer ${dealerChecked ? "checked" : "unchecked"}`}>
                <div className="dealer-search-check">{dealerChecked && <CheckOk />}</div>
                <div className="dealer-search-step">
                  <div className="step-icon">
                    <img src="/images/dealer-map.png" />
                    <Pin brand={brand} />
                    <PinCircle />
                    <MapPin />
                  </div>

                  <div>
                    <div className="info">
                      <div className="info-title">
                        {dealerChecked && offer && offer.dealer && <>{offer.dealer.Name}</>}
                        {!dealerChecked && <>{t("configuration.offer.nodealer")}</>}
                      </div>
                      <div className="info-subtitle">
                        {dealerChecked && offer && offer.dealer && (
                          <>
                            {offer.dealer.Street}
                            {offer.dealer.Street && offer.dealer.Street !== "" && ", "}
                            {offer.dealer.PostCode} {offer.dealer.Town}
                          </>
                        )}
                      </div>
                      <div className="mobile-buttons">
                        <a
                          onClick={() => {
                            showDealerSearch();
                          }}
                        >
                          {t("configuration.offer.finddealer")}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="dealer-search-check-mobile">{dealerChecked && <CheckOk />}</div>

                  <div className="step-action">
                    <a
                      className={`send-config-dealer-button dealer-search-buttons ${dealerChecked ? "checked" : "unchecked"}`}
                      onClick={() => {
                        showDealerSearch();
                      }}
                    >
                      {t("configuration.offer.finddealer")}
                    </a>
                  </div>
                </div>
              </div>

              <div className={`dealer-search-step-container step-login ${userProfileChecked ? "checked" : "unchecked"}`}>
                <div className="dealer-search-check">{userProfileChecked && <CheckOk />}</div>

                <div className="dealer-search-step">
                  <div className="step-icon">
                    <User />
                  </div>

                  <div>
                    <div className="info">
                      <div className="info-title">
                        {offer && offer.userProfile && (
                          <>
                            {offer.userProfile.firstName} {offer.userProfile.lastName}
                          </>
                        )}
                        {!userProfileChecked && <>{t("configuration.offer.loginhint")}</>}
                      </div>
                      <div className="info-subtitle">{offer && offer.userProfile && <>{offer.userProfile.email}</>}</div>
                      <CdcLogin onUserLoggedIn={onUserLoggedIn} mode="loadAccountInfo" {...data} mobile={true} />
                    </div>
                  </div>

                  <div className="dealer-search-check-mobile">{userProfileChecked && <CheckOk />}</div>

                  <CdcLogin
                    onUserLoggedIn={onUserLoggedIn}
                    mode="loadAccountInfo"
                    {...data}
                    userProfileChecked={userProfileChecked}
                    removeTabIndexToMakeCDCWorking={removeTabIndexToMakeCDCWorking}
                  />
                </div>
              </div>
            </div>
          )}

          {error && <p className="error margin-initial">{t("errors.sendingEmail")}</p>}
        </DialogContent>

        {!dealerSearchOpen && (
          <DialogActions className="dialog-actions">
            <button
              type="submit"
              onClick={handleSubmit}
              className="configuration-send-button send-config-dealer-button"
              disabled={generating || !userProfileChecked || !configurationChecked || !dealerChecked}
            >
              {t(`buttons.${generating ? "generating" : "send"}`)}
            </button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SendConfigurationToDealer));

import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import PDF from "../../../assets/icons/pdf";
import Share from "../../../assets/icons/share";
import ArrowHead from "../../../assets/icons/arrowHead";
import Arrow from "../../../assets/icons/arrow";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";

interface ConfigurationSummaryHeaderProps extends StateManagementProps, WithNamespaces {
  shareConfiguration?: () => void;
  downloadPdf?: (ignoreState: boolean) => void;
  updatesetShowSummary?: () => void;
  loaded: boolean;
}

export const ConfigurationSummaryHeader: React.FunctionComponent<ConfigurationSummaryHeaderProps> = props => {
  const { t, applicationSettings, shareConfiguration, downloadPdf, toggleSummary, updatesetShowSummary, is3DMode, loaded, setDealerInquirySent, dealerInquirySent, userDidTimeoutInStream } = props;

  const fixedMobileWidth = 1024;

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(windowInnerWidth < fixedMobileWidth);

  useLayoutEffect(() => setIsMobile(windowInnerWidth < fixedMobileWidth), [windowInnerWidth]);

  useEffect(() => {
    const onResize = () => setWindowInnerWidth(window.innerWidth);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      hideBackArrow(false);
      addMarginToFooter(false);
    };
  }, []);

  function backToConfiguration() {
    updatesetShowSummary && updatesetShowSummary();
    toggleSummary(false);
    setDealerInquirySent(false);
  }

  function hideBackArrow(hide: boolean) {
    var appBanners = document.getElementsByClassName("footer-content-goback-link");

    for (var i = 0; i < appBanners.length; i++) {
      (appBanners[i] as any).style.visibility = hide ? "hidden" : "visible";
    }
  }

  function addMarginToFooter(addMargin: boolean) {
    const footer = document.querySelector("#footer")
    if (footer) {
      if (addMargin) {
        footer.classList.add("summary-footer");
      } else {
        footer.classList.remove("summary-footer");
      }
    }
  }

  hideBackArrow(true);
  addMarginToFooter(true);

  return (
    <div className="config-summary-header">
      {is3DMode || dealerInquirySent ? (
        <>
          {!userDidTimeoutInStream && (
            <div
              className="back-link-top-row go-back-to-config colors-inverted"
              onClick={() => {
                backToConfiguration();
              }}
            >
              <div className="exit-summary-button 3d colors-inverted">
                {applicationSettings.Brand !== "HQV" ? <ArrowHead /> : <Arrow />}
                {!isMobile && <span>{t("configuration.summary.backLink")}</span>}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className="back-link-top-row go-back-to-config"
            onClick={() => {
              backToConfiguration();
            }}
          >
            <div className="exit-summary-button">
              {applicationSettings.Brand !== "HQV" ? <ArrowHead /> : <Arrow />}
              <span>{t("configuration.summary.backLink")}</span>
            </div>
          </div>
          <div className="header-options-wrapper">
            <a className={`pdf-download d-none-mobile ${loaded ? "" : "disabled"}`} onClick={() => downloadPdf && downloadPdf(true)} target="_blank" aria-disabled={loaded ? "false" : "true"}>
              {!loaded && <CircularProgress className="spinner d-none-mobile" />}
              {loaded && <PDF />}
              {t("configuration.summary.pdf")}
            </a>
            <div className="share-button d-none-mobile" onClick={() => shareConfiguration && shareConfiguration()}>
              <Share />
              {t("configuration.summary.share")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(ConfigurationSummaryHeader));

import React from "react";

export interface XBowCustomColorHexCodeProps {
  hexCode: string;
  display: boolean;
  isMobile: boolean;
}

const XBowCustomColorHexCode: React.FunctionComponent<XBowCustomColorHexCodeProps> = props => {
  const { hexCode, display, isMobile } = props;

  return display ? (
    <div className={`hex-code-container ${isMobile ? "mobile" : ""}`}>
      <span className="hex-code">
        <p className="text">{hexCode}</p>
      </span>
    </div>
  ) : null;
};

export default XBowCustomColorHexCode;

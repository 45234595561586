import React, { useEffect, useState } from "react";
import MotorcycleMenu from "./MotorcycleMenu";
import { RouteComponentProps } from "react-router-dom";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Action from "../../../redux/reducers/models/action";
import Models from "./Models";
import {
  StateManagementProps,
  mapStateToProps,
  mapDispatchToProps,
} from "../../../utils/addReduxProps";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { ShowDialogProps } from "../../../redux/actions";
import { DialogTypes } from "../../../enums/dialogTypes";
import { MotorcycleModelResponse, SegmentData } from "../../../models/vehicle";

export interface MotorcyclesProps {
  getMotorcycleModels: MotorcycleModelResponse;
  addMotorcycleModels: (payload: MotorcycleModelResponse) => Action;
}

export interface MotorcycleState {
  link: string;
  loaded: boolean;
}

export const classes: (theme: Theme) => Record<string, CSSProperties> = _ => ({
  motorcycles: {
    height: "calc(100% - 76px)",
    boxSizing: "border-box",
  },
  wrapper: {
    borderBottom: "1px solid #eee",
    padding: "0 10px 0 10px",
    maxWidth: 800,
  },
});

export type MotorcycleViewAllProps = MotorcyclesProps &
  RouteComponentProps<any> &
  WithStyles &
  WithNamespaces &
  StateManagementProps;

export const Motorcycles: React.FunctionComponent<
  MotorcycleViewAllProps & MotorcycleState
> = props => {
  const { models, t, classes, showDialog, userGuidanceState, hideGuidance } =
    props;
  const [showUser, setShowUser] = useState(true);
  const [link, setLink] = useState("");
  const [jtsPushed, setJtsPushed] = useState(false);

  const showUserGuidance = () => {
    if (userGuidanceState.show) {
      const dialogProps: ShowDialogProps = {
        title: "",
        data: { models },
        contentType: DialogTypes.userGuidance,
      };
      showDialog(dialogProps);
      hideGuidance();
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 767 && showUser) {
      showUserGuidance();
      setShowUser(false);
    }
  }, []);

  useEffect(() => {
    if(!jtsPushed && (window as any)._jts) {
      const jts = (window as any)._jts;
      jts.push({ track: "pageview" });
      jts.push({ track: "submit" });
      setJtsPushed(true);
    }
  });

  const { Segments } = models;
  const links = Segments.map((segment: SegmentData) => segment.Name);
  const selectedSegment = link || links[0];
  const menuProps = {
    updateState: (link: string) => {
      setLink(link);
    },
    links,
    link: selectedSegment,
    models,
  };

  const { motorcycles } = classes;
  const segmentData: SegmentData = Segments.find(
    (segment: SegmentData) => segment.Name === selectedSegment
  ) as SegmentData;

  if (segmentData) {
    return (
      <section id="motorcycles" className={`${motorcycles} full-height`}>
        <MotorcycleMenu {...menuProps}></MotorcycleMenu>
        <Models data={segmentData} />
      </section>
    );
  }
  return null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(withNamespaces()(Motorcycles)));

import React from "react";
import { StateManagementProps, mapStateToProps, mapDispatchToProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";
import ArrowHeadKTM from "./arrowHead_ktm";
import ArrowHeadHQV from "./arrowHead_hqv";

const ArrowHead: React.FunctionComponent<StateManagementProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <ArrowHeadKTM />}
      {brand === "HQV" && <ArrowHeadHQV />}
      {brand === "GG" && <ArrowHeadKTM />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArrowHead);

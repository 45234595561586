import React, { useEffect, useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import Motorcycles from "./motorcycles/MotorcycleView";
import Overview from "./overview/Overview";
import store from "../../redux/store";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { WithNamespaces, withNamespaces } from "react-i18next";
import queryString from "query-string";
import Action from "../../redux/reducers/models/action";
import { SET_CULTURE, SET_COMPANY_ID, SET_COUNTRY } from "../../redux/actionTypes";
import { getCulture, getMarket } from "../../utils/helpers";
import Footer from "../navigation/Footer";
import ConfigurationView from "../configuration/ConfigurationView";
import { CompanyIdContext, CompanyIdProvider } from "../../App";
import getModels from "../../redux/async-actions/getModels";
import loadConfiguration from "../../redux/async-actions/loadConfiguration";
import i18next from "i18next";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { CircularProgress } from "@material-ui/core";
import TagManager from "react-gtm-module";
import { DialogRouter } from "../dialogs/dialogRouter";
import { getAllMarkets, getFastlyGeoCountry } from "../../services/http/real";
import { AxiosResponse } from "axios";
import { CountryLanguageType } from "../dialogs/languageSelection";
import { CurrentCountryState } from "../../redux/reducers/currentCountry";
import { Helmet } from "react-helmet";

export const classes: (theme: Theme) => Record<string, CSSProperties> = (theme) => ({
  alignCenter: {
    fontSize: "1.3em!important",
  },
});

export interface MarketResponse {
  MarketsData: MarketDetailResponse[];
}

export interface MarketDetailResponse {
  MarketName: string;
  Languages: string[];
  Imprint: string | null;
  MarketCode: string;
}

type MainViewAllProps = StateManagementProps & WithNamespaces & WithStyles & RouteComponentProps<{ modelId: string }>;
export const MainView: React.FunctionComponent<MainViewAllProps> = (props) => {
  const {
    models,
    applicationSettings,
    classes,
    t,
    history,
    location: { pathname: string, search: Search },
    setCurrentCountry,
    countryArray,
    setCountryArray,
    setPreselectedVariationCode,
    is3DMode,
    setIs3DMode,
    overview,
    dealerInquirySent,
  } = props;

  const modelSelectionState = {
    show: "show",
    hide: "hide",
    redirect: "redirect",
  };

  const path = "/main";
  const motorcyclesUrl = path + "/motorcycles";
  const overviewUrl = path + "/overview/:modelId";
  const configUrl = path + "/configuration/:modelId";
  let CountriesArray: CountryLanguageType[] = [];
  const [openPopUp, setOpenPopUp] = useState(false);
  const [correctSettings, setCorrectSettings] = useState(modelSelectionState.hide);

  // eslint-disable-next-line no-restricted-globals
  const { search, pathname } = location;
  const parsed = queryString.parse(search);

  useEffect(() => {
    if ((pathname === "/main" || pathname === "/main/") && is3DMode) {
      setIs3DMode(false);
    } else if (pathname !== "/main" && pathname !== "/main/" && overview && overview.Vehicle.RenderingEngine === "Engine3D" && !is3DMode) {
      setIs3DMode(true);
    } else if (is3DMode && overview && overview.Vehicle.RenderingEngine !== "Engine3D" && pathname !== "/main" && pathname !== "/main/") {
      setIs3DMode(false);
    }
  }, [pathname, overview.Vehicle]);

  const redirectToExistingConfigurationPage = (configurationId: string, country: string, culture: string, companyId: string) => {
    store.dispatch(loadConfiguration(configurationId, history, country, culture, companyId) as any);
  };

  const setVariationCode = (variation: string) => {
    setPreselectedVariationCode({ code: variation });
  };

  const getMarketsList = () => {
    getAllMarkets().then((response: AxiosResponse<MarketResponse>) => {
      createCountryArray(response.data);
    });
  };

  const createCountryArray = (axiosResponse: MarketResponse) => {
    let marketsData = axiosResponse.MarketsData;
    for (let i = 0; i < marketsData.length; i++) {
      for (let j = 0; j < marketsData[i].Languages.length; j++) {
        let MarketDetails = {
          CountryName: marketsData[i].Languages.length !== 1 ? `${marketsData[i].MarketName} (${t("dialogs.languageSelection." + marketsData[i].Languages[j])})` : `${marketsData[i].MarketName}`,
          CountryCode: marketsData[i].MarketCode,
          LanguageCode: marketsData[i].Languages[j],
          ImprintCode: marketsData[i].Imprint,
        };
        CountriesArray.push(MarketDetails);
      }
    }

    CountriesArray = CountriesArray.sort((a, b) => a.CountryName.localeCompare(b.CountryName));
    setCountryArray({ currentCountryArray: CountriesArray });
  };

  const getGeoLocation = () => {
    let userLang = navigator.language || "";

    getFastlyGeoCountry()
      .then((response: AxiosResponse<any>) => {
        let validCountrySettings = checkUserSettings(response.data.Country, userLang);
        if (validCountrySettings.CountryCode === "" || !validCountrySettings.CountryCode) {
          if (!openPopUp) setOpenPopUp(true);
          store.dispatch(getModels() as any);
          store.dispatch<Action>({ type: SET_CULTURE, payload: "" });
          store.dispatch<Action>({ type: SET_COUNTRY, payload: "" });
          setCorrectSettings(modelSelectionState.show);
        } else {
          setValidSettings(validCountrySettings.CountryCode, validCountrySettings.LanguageCode);
        }
      })
      .catch((error: AxiosResponse) => {
        if (!openPopUp) setOpenPopUp(true);
        store.dispatch(getModels() as any);
        store.dispatch<Action>({ type: SET_CULTURE, payload: "" });
        store.dispatch<Action>({ type: SET_COUNTRY, payload: "" });
        setCorrectSettings(modelSelectionState.show);
      });
  };

  const setValidSettings = (country: string, culture: string) => {
    updateDocumentLanguage(culture);

    const companyId = "5b540245-23d2-480b-af90-ec5427e7e479";
    if (parsed.variation) {
      setVariationCode(parsed.variation as string);
    }

    if (parsed.configurationId) {
      redirectToExistingConfigurationPage(parsed.configurationId.toString(), country, culture, companyId);
      setCorrectSettings(modelSelectionState.redirect);
    } else {
      setCorrectSettings(modelSelectionState.show);
    }
    setGoogleAnayltics(country, culture);

    store.dispatch<Action>({ type: SET_CULTURE, payload: culture });
    store.dispatch<Action>({ type: SET_COUNTRY, payload: country });
    i18next.changeLanguage(culture);
    store.dispatch<Action>({ type: SET_COMPANY_ID, payload: companyId });
    if ((getCulture() as string) !== culture && (getMarket() as string) !== country) {
      history.push(history.location.pathname + "?culture=" + culture + "&country=" + country);
    }
  };

  const setGoogleAnayltics = (country: string, culture: string) => {
    TagManager.initialize({
      gtmId: applicationSettings.GTM,
      events: {
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      },
      dataLayer: {
        country: country,
        culture: culture,
      },
    });
  };

  const checkUserSettings = (geoLocation: string, userLang: string) => {
    let language = userLang.split("-")[0];
    let userLanguageSet = false;
    let countryCodeSet = false;
    let correctLang = "";
    let correctCode = "";
    let correctCountry = "";
    let correctImprint: string | null = null;

    countryArray.currentCountryArray.forEach((value: CountryLanguageType, index) => {
      if (value.CountryCode === geoLocation && value.LanguageCode === language) {
        correctCode = value.CountryCode;
        correctLang = value.LanguageCode + "-" + value.CountryCode;
        correctCountry = value.CountryName;
        userLanguageSet = true;
        countryCodeSet = true;
        correctImprint = value.ImprintCode;
      } else if (value.CountryCode == geoLocation && !countryCodeSet) {
        correctCode = value.CountryCode;
        correctCountry = value.CountryName;
        correctImprint = value.ImprintCode;
        if (!userLanguageSet) {
          correctLang = value.LanguageCode + "-" + value.CountryCode;
        }
      }
    });
    let validCountrySettings: CurrentCountryState = {
      CountryName: correctCountry,
      CountryCode: correctCode,
      LanguageCode: correctLang,
      ImprintCode: correctImprint,
    };
    setCurrentCountry(validCountrySettings);
    return validCountrySettings;
  };

  const setSettings = () => {
    let culture = parsed.culture || getCulture();
    let country = parsed.country || getMarket();
    let validCountrySettings = checkUserSettings(country as string, culture as string);

    if (validCountrySettings.CountryCode === "" || !validCountrySettings.CountryCode) {
      getGeoLocation();
    } else {
      setValidSettings(validCountrySettings.CountryCode, validCountrySettings.LanguageCode);
    }
  };

  const updateDocumentLanguage = (lang: string) => {
    let lng = lang.substring(0, 2);
    document.documentElement.lang = lng;
  };

  let comanyContext = useContext(CompanyIdContext);
  let parsedCompany = parsed.id_company ? parsed.id_company.toString() : null;
  let companyId = parsedCompany && parsedCompany !== comanyContext ? parsedCompany : comanyContext;

  //TODO: set 0 to false and 1 to true
  const hasModelData = () => ((models ? !!models.Segments.length : 0) && models ? 1 : 0);

  useEffect(() => {
    if (countryArray.currentCountryArray.length === 0) getMarketsList();
    else {
      setSettings();
    }
  }, [countryArray]);

  useEffect(() => {
    if (getMarket() !== "" && getCulture() !== "") {
      store.dispatch(getModels() as any);
    }
  }, [getMarket(), getCulture()]);

  if (!hasModelData() || correctSettings === modelSelectionState.hide || correctSettings === modelSelectionState.redirect) {
    return (
      <div className="no-flex-wrap main-wrapper">
        {hasModelData() == 0 || correctSettings === modelSelectionState.hide || (correctSettings === modelSelectionState.redirect && <CircularProgress className="spinner" />)}
        {hasModelData() == 1 && correctSettings === modelSelectionState.show && (
          <span id="no-models" className={classes.alignCenter}>
            {t(`motorcycles.notAvailable.${applicationSettings.Brand}`)}
          </span>
        )}
        <Footer {...props} openPopUp={openPopUp} />
      </div>
    );
  }

  return (
    <Grid className={`no-flex-wrap main-wrapper ${is3DMode ? "mode-3d" : "mode-2d"} ${dealerInquirySent ? "dealerInquirySent" : ""}`} container direction="column">
      {applicationSettings.Brand === "HQV" && (
        <Helmet
          title={t("pagetitle.HQV")}
          link={[
            {
              rel: "shortcut icon",
              href: "/favicon_hqv.ico",
            },
          ]}
        ></Helmet>
      )}
      {applicationSettings.Brand === "GG" && (
        <Helmet
          title={t("pagetitle.GG")}
          link={[
            {
              rel: "shortcut icon",
              href: "/favicon_gg.ico",
            },
          ]}
        ></Helmet>
      )}
      {applicationSettings.Brand === "KTM" && (
        <Helmet
          title={t("pagetitle.KTM")}
          link={[
            {
              rel: "shortcut icon",
              href: "/favicon-16x16.png",
            },
          ]}
        ></Helmet>
      )}
      <CompanyIdProvider value={companyId}>
        <Switch>
          <Route path={overviewUrl} component={Overview} />
          <Route exact path={motorcyclesUrl} component={Motorcycles} />
          <Route path={configUrl} component={ConfigurationView} />
          <Route component={Motorcycles} />
        </Switch>
        <Footer {...props} openPopUp={openPopUp} />
      </CompanyIdProvider>
      <DialogRouter {...props} />
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(withNamespaces()(MainView)));

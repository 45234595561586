import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Dot: React.FunctionComponent = () => {
  return (
    <SvgIcon>
      <title>Dot Icon</title>
      <ellipse ry="8" rx="8" id="svg_1" cy="8" cx="8" strokeWidth="0"/>
    </SvgIcon>
  );
};

export default Dot;
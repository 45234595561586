import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BarItemDevider: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 695 2">
      <title>BarItemDevider</title>
      <rect width="695" height="2" />
    </SvgIcon>
  );
};

export default BarItemDevider;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RotateIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <title>Rotate</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3171 2.13617C17.0242 1.84328 16.5493 1.84328 16.2564 2.13617C15.9635 2.42907 15.9635 2.90394 16.2564 3.19683L17.7852 4.72558C17.2044 4.6325 16.6086 4.58301 16 4.58301C9.69516 4.58301 4.58337 9.69479 4.58337 15.9997C4.58337 19.0855 5.81175 21.8836 7.79984 23.9373C8.08794 24.2349 8.56275 24.2426 8.86036 23.9545C9.15797 23.6664 9.16568 23.1916 8.87757 22.894C7.14833 21.1077 6.08337 18.6792 6.08337 15.9997C6.08337 10.5232 10.5236 6.08301 16 6.08301C16.518 6.08301 17.0263 6.12441 17.5232 6.20276L16.2564 7.46951C15.9635 7.7624 15.9635 8.23727 16.2564 8.53017C16.5493 8.82306 17.0242 8.82306 17.3171 8.53017L19.5451 6.30209C19.5455 6.30179 19.5458 6.30148 19.5461 6.30117L19.9837 5.8635C20.2766 5.57061 20.2766 5.09573 19.9837 4.80284L17.3171 2.13617ZM15.7437 24.5303C16.0366 24.2374 16.0366 23.7626 15.7437 23.4697C15.4509 23.1768 14.976 23.1768 14.6831 23.4697L12.4657 25.687C12.4582 25.6943 12.4508 25.7017 12.4436 25.7092L12.0164 26.1363C11.7235 26.4292 11.7235 26.9041 12.0164 27.197L14.6831 29.8637C14.976 30.1566 15.4509 30.1566 15.7437 29.8637C16.0366 29.5708 16.0366 29.0959 15.7437 28.803L14.2148 27.274C14.7956 27.3671 15.3915 27.4166 16.0001 27.4166C22.305 27.4166 27.4168 22.3048 27.4168 16C27.4168 12.8618 26.1436 10.0279 24.0978 7.96633C23.806 7.67232 23.3311 7.67051 23.0371 7.96228C22.7431 8.25405 22.7413 8.72892 23.0331 9.02293C24.8139 10.8174 25.9168 13.2768 25.9168 16C25.9168 21.4764 21.4765 25.9166 16.0001 25.9166C15.4822 25.9166 14.974 25.8752 14.4772 25.7969L15.7437 24.5303Z"
      />
    </SvgIcon>
  );
};

export default RotateIcon;

import React from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";
import CloseKTM from "./close_ktm";
import CloseHQV from "./close_hqv";

const Close: React.FunctionComponent<StateManagementProps> = props => {
  const brand = props.applicationSettings.Brand;
  const hqv = brand === "HQV" ? true : false;
  return (
    <>
      {!hqv && <CloseKTM />}
      {hqv && <CloseHQV />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Close);

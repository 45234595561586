import React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import ArrowKTM from "./arrow_ktm";
import ArrowHQV from "./arrow_hqv";
import ArrowGG from "./arrow_gg";

const Arrow: React.FunctionComponent<StateManagementProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <ArrowKTM />}
      {brand === "HQV" && <ArrowHQV />}
      {brand === "GG" && <ArrowGG />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Arrow);

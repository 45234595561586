import React, { useEffect, useState } from "react";
import { StateManagementProps } from "../../../utils/addReduxProps";
import { translate, WithNamespaces } from "react-i18next";
import formatter from "../../../utils/formatter";
import { CircularProgress, Grid } from "@material-ui/core";
import VehicleImage from "../../vehicle-overview/VehicleImage";
import { Part } from "../../parts/PartsList";
import { RouteComponentProps } from "react-router";
import { Setup } from "../../setups/SetupsList";
import { ShowDialogProps } from "../../../redux/actions";
import { DialogTypes } from "../../../enums/dialogTypes";
import { sendConfigurationToDealer, sortAscendingByProperty, hashCode, handlePDFData, getIsMobile } from "../../../utils/helpers";
import NotVisualizedIcon from "@material-ui/icons/VisibilityOffOutlined";
import PDF from "../../../assets/icons/pdf";
import Wheel from "../../../assets/icons/wheel";
import Share from "../../../assets/icons/share";
import Send from "../../../assets/icons/send";
import http from "../../../services/http";
import ConfigurationSummaryHeader from "./ConfigurationSummaryHeader";
import CdcLogin from "../cdc/CdcLogin";
import { TechnicalDetails } from "../technicalDetails";
import { isTablet } from "react-device-detect";

export interface TestRideProps {
  testRideUrl: string;
  testRideStatus: boolean;
}

export interface ShowAllPrices {
  showAllPrices: boolean;
}

export interface Is3DProp {
  updatesetShowSummary?: () => void;
  onSendToDealerSuccess?: () => void;
  onSendSuccess?: () => void;
  onShareLinkSuccess?: () => void;
  isPortraitMode?: boolean;
}

export interface ShareConfigurationResponse {
  Url: string;
  ConfigurationId: number;
}

export interface FullImage {
  Perspective: number;
  Url: string;
}

export interface PriceNotes {
  priceNote: string;
  montageNote: string | null;
}

export type ConfigurationSummaryProps = StateManagementProps & WithNamespaces & TestRideProps & ShowAllPrices & Is3DProp & RouteComponentProps<{ modelId: string }>;

export const ConfigurationSummary: React.FunctionComponent<ConfigurationSummaryProps> = (props) => {
  const {
    t,
    appliedSetup,
    appliedParts,
    selectedModel,
    totalPrice,
    models: { ShowPricesInConfigurator: hasPrices },
    hideDialog,
    showDialog,
    applicationSettings,
    updatesetShowSummary,
    onShareLinkSuccess,
    onSendSuccess,
    isPortraitMode,
    currentVariation,
    testRideUrl,
    showAllPrices,
    models,
    testRideStatus,
    overview,
    configurationState,
  } = props;

  const [loaded, setLoaded] = useState(true);
  const [isMobile, setIsMobile] = useState(() => getIsMobile());
  const [priceNotes, setPriceNotes] = useState<PriceNotes | null>(null);

  const componentContainer: HTMLElement | null = document.getElementById("componentContainer");
  const maxShownParts = 4;
  let scrollContainer: React.CSSProperties = { maxHeight: `auto` };
  let deviderHeight: React.CSSProperties = { height: `${isTablet ? "400px" : "150px"}` };
  const noStyle: React.CSSProperties = {};

  if (componentContainer && componentContainer.offsetHeight !== 0) {
    scrollContainer = {
      maxHeight: `calc(${componentContainer.offsetHeight * maxShownParts}px + 1.3rem)`,
    };
  }

  const scrollToRef = React.createRef<HTMLDivElement>();

  const handleResize = () => {
    setIsMobile(getIsMobile);
  };

  useEffect(() => {
    handleResize();

    if (!priceNotes) {
      let item = overview.Variations.find((color) => color.Code === configurationState.currentVariation);

      if (item) {
        setPriceNotes({ priceNote: item.PriceNote, montageNote: item.MontageNote });
      }
    }

    return () => {
      hideDialog();
      window.addEventListener("remove", handleResize);
    };
  }, []);

  window.addEventListener("resize", handleResize);

  const shareConfiguration = () => {
    const dialogProps: ShowDialogProps = {
      title: `${t("dialogs.shareConfiguration.title")}`,
      data: {},
      contentType: DialogTypes.shareConfiguration,
    };
    showDialog(dialogProps);
    onShareLinkSuccess && onShareLinkSuccess();
  };

  const sendConfiguration = () => {
    const dialogProps: ShowDialogProps = {
      title: `${t("dialogs.shareConfiguration.title")}`,
      data: { isPortraitMode: isPortraitMode },
      contentType: DialogTypes.sendConfiguration,
    };
    showDialog(dialogProps);
    onSendSuccess && onSendSuccess();
  };

  const parts: Part[] = appliedParts;

  /**
   * This function handles the pdf download for all the summary pages and the summary header
   * if the header is used set the ignoreState to true so that the following summary page is not messed up
   * TOOD needs more cleanup
   */
  const downloadPdf = () => {
    let partsHash: string = hashCode(selectedModel, currentVariation, parts) as string;
    setLoaded(false);
    http
      .downloadConfigurationSummaryPdf(partsHash)
      .then((response) => {
        handlePDFData(response);
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  return (
    <div className="configurationSummaryContainer flex-container column">
      <Grid id="top-area">
        <ConfigurationSummaryHeader loaded={loaded} downloadPdf={downloadPdf} shareConfiguration={shareConfiguration} updatesetShowSummary={updatesetShowSummary} />

        <Grid item container className="grow">
          <Grid item xs={isMobile ? 12 : 2} className="configuration-heading d-none-mobile">
            <h2>{t(`configuration.summary.title.${applicationSettings.Brand}`)}</h2>
            <p>{t("configuration.summary.description")}</p>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6} className="full-width">
            <div className="imageContainer">
              <VehicleImage onlyFirstImg={true} arrowStyle={{ left: "12px" }} imageClassName="initial-height" useDarkFont={true} />
            </div>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} className="vehicleData">
            <div>
              <div>
                <h2>
                  <span className={"headline"}>{selectedModel.Name}</span>
                </h2>
              </div>
            </div>
            <TechnicalDetails {...props} />
            <section>
              {appliedSetup && <h3>{(appliedSetup as Setup).Name}</h3>}

              <div className={`componentList ${parts.length === 0 ? "no-parts" : ""} ${parts.length > 4 ? "scrollable" : ""}`} style={parts.length > 4 ? scrollContainer : noStyle}>
                {parts.sort(sortAscendingByProperty<Pick<Part, "Name">>("Name")).map((part: Part, index: number) => {
                  return (
                    <div key={part.PartId} id="componentContainer" className="component-container">
                      <div className="component-wrapper-top">
                        <span className="componentName">{part.Name}</span>
                        <span className="component-id">
                          {t("configuration.parts.reference")}&nbsp;
                          {part.PartId}
                        </span>
                      </div>
                      <div className="component-wrapper-bottom">
                        <div className="component-price-wrapper">
                          <span className="componentPrice">{formatter.currency(part.Price)}</span>
                          {!part.CanBeRendered && <NotVisualizedIcon titleAccess={t("configuration.parts.notVisualized")}></NotVisualizedIcon>}
                        </div>
                        {part.Racing && <span className="racingComponent">{t("configuration.summary.ece")}</span>}
                      </div>
                    </div>
                  );
                })}
                {/*
              Mobility Service, do not delete

              <div className="component-container">
                  <div className="component-wrapper-top">
                    <span className="componentName">{t('configuration.summary.mobilityService')}</span>

                  </div>
                  <div className="component-wrapper-bottom">
                    <div className="component-price-wrapper">
                      <span className="componentPrice">{t('free')}</span>
                    </div>
                  </div>
                </div>
               */}
              </div>
            </section>
            <Grid container className="full-width send-price-wrapper">
              <Grid item xs={isMobile ? 6 : 12} className="priceContainer">
                {hasPrices && showAllPrices && (
                  <>
                    <div className="total-price">{t(`configuration.summary.total.${applicationSettings.Brand}`)}</div>
                    <div className="total-price-value">{formatter.currency(totalPrice)}</div>
                    {priceNotes && (
                      <p id="extra-cost-info">
                        <span dangerouslySetInnerHTML={{ __html: priceNotes.priceNote }}></span>
                        {priceNotes.montageNote && <span dangerouslySetInnerHTML={{ __html: priceNotes.montageNote }}></span>}
                      </p>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={isMobile ? 6 : 12} className="flex-container full-width">
                <div className="d-flex summary-buttons-wrapper">
                  <div className="summary-button-wrapper">
                    <button className="primary send-link-button" onClick={() => sendConfiguration()}>
                      {t("configuration.summary.send")}
                    </button>
                  </div>

                  {applicationSettings.DealerSearch2DEnabled && models.ShowOfferButton && (
                    <div className="summary-button-wrapper">
                      <button className="primary send-link-button" onClick={() => sendConfigurationToDealer(props)}>
                        {t("configuration.offer.sendToDealer")}
                      </button>
                    </div>
                  )}

                  {applicationSettings.CdcApplicationSettings.CdcEnabled && <CdcLogin {...props} mode="saveUserConfigurationCDC" isSummary={true} />}

                  {/*props.testRideStatus && (
                    <div className="summary-button-wrapper">
                      <button
                        className="secondary d-none-mobile bat-button"
                        onClick={() => {
                          window.open(props.testRideUrl, "_blank");
                        }}
                      >
                        {t("configuration.testride.title")}
                      </button>
                    </div>
                      )*/}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {parts.length !== 0 ? <div className="devider" style={deviderHeight}></div> : null}

      {testRideStatus && (
        <>
          <div
            id="scroll-down-button"
            className="d-none-mobile"
            onClick={() => {
              if (scrollToRef && scrollToRef.current) {
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {t("configuration.testride.ride")}
          </div>

          <div className="show-mobile summary-seperator"></div>

          <div id="scroll-down-area" className="full-width" ref={scrollToRef}>
            <h2 className={applicationSettings.Brand === "KTM" ? "uppercase show-mobile" : "show-mobile"}> {t("configuration.testride.title")}</h2>
            <Grid item container className="full-height margin">
              <Grid item xs={isMobile ? 12 : 6} className="testride-image-container">
                <div className="side-image">
                  <VehicleImage sideImage={true} arrowStyle={{ left: "12px" }} imageClassName="initial-height" useDarkFont={true} />
                </div>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6} className="text-container">
                <div className="h1 uppercase d-none-mobile">{t("configuration.testride.title")}</div>
                <div className="testride-text">{t(`configuration.testride.text.${applicationSettings.Brand}`)}</div>
                {testRideStatus && (
                  <a className="secondary" href={testRideUrl} target="_blank">
                    {t("buttons.book")}
                  </a>
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      <div className="cta-menu-mobile show-mobile full-width">
        <div className="cta-icon" onClick={() => shareConfiguration()}>
          <Share />
        </div>

        {!loaded && <CircularProgress className="spinner cta-icon-spinner" />}

        {loaded && (
          <div className={`cta-icon ${loaded ? "" : "disabled"}`} onClick={() => downloadPdf()} aria-disabled={loaded ? "false" : "true"}>
            <PDF />
          </div>
        )}

        {testRideStatus && (
          <a className="cta-icon" href={testRideUrl} target="_blank">
            <Wheel />
          </a>
        )}

        <div className="cta-icon send-link-button" onClick={() => sendConfiguration()}>
          <Send />
        </div>
      </div>
    </div>
  );
};

export default translate()(ConfigurationSummary);

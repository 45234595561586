import React, { useEffect, useMemo, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import Close from "../../../assets/icons/close";
import Movie from "../../../assets/icons/movie";
import Mountain from "../../../assets/icons/mountain";
import VideoCam from "../../../assets/icons/video_cam";
import Daytime from "../../../assets/icons/daytime";
import { Lighting } from "../../../models/vehicle";
import Moon from "../../../assets/icons/moon";
import Sun from "../../../assets/icons/sun";
import XBow from "../../../assets/icons/xbow/xBow";
import { ToolsConfigurationPayload } from "../../../services/http/real";
import { CircularInput, CircularThumb, CircularTrack } from "react-circular-input";
import ArrowHead from "../../../assets/icons/arrowHead";

export interface ToolProps {
  toolsOpen: boolean;
  setToolsOpenFunc: () => void;
  sendToolOption: (message: string, lighting?:string) => void;
  sendToolData: (toolsConfiguration: ToolsConfigurationPayload) => void;
}
interface toolCats {
  Command: string;
  List: toolCat[];
  Value?: string;
}
interface toolCat {
  Name: string;
  Value: string;
}

type AllProps = StateManagementProps & WithNamespaces & ToolProps;

const Tools: React.FunctionComponent<AllProps> = (props) => {
  const { t, overview, toolsOpen, setToolsOpenFunc, configurationState, sendToolOption, sendToolData, applicationSettings, isXBow } = props;

  const cinematics: toolCats | null = overview.RenderSettings && overview.RenderSettings.Tools.Cinematics ? overview.RenderSettings.Tools.Cinematics : null;
  const environments: toolCats | null = overview.RenderSettings && overview.RenderSettings.Tools.Environments ? overview.RenderSettings.Tools.Environments : null;
  const perspectives: toolCats | null = overview.RenderSettings && overview.RenderSettings.Tools.Perspectives ? overview.RenderSettings.Tools.Perspectives : null;
  const lighting: Lighting | null = overview.RenderSettings && overview.RenderSettings.Tools.Lighting ? overview.RenderSettings.Tools.Lighting : null;
  const animation: any | null = overview.RenderSettings && overview.RenderSettings.Tools.Views ? overview.RenderSettings.Tools.Views : null;

  const [selectedToolOptions, setSelectedToolOptions] = useState<ToolsConfigurationPayload>({
    Cinematics: "",
    Environments:
      configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Environments
        ? configurationState.VehicleSettings.ToolsConfiguration.Environments
        : "",
    Perspectives: "",
    Lighting:
      configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Lighting
        ? configurationState.VehicleSettings.ToolsConfiguration.Lighting
        : "",
    Animations:
      configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Animations
        ? configurationState.VehicleSettings.ToolsConfiguration.Animations
        : [],
  });

  const [selectedToolsCategory, setSelectedToolsCategory] = useState(cinematics ? cinematics.Command : "");

  useEffect(
    () => sendToolData(selectedToolOptions),
    [selectedToolOptions.Cinematics, selectedToolOptions.Environments, selectedToolOptions.Lighting, selectedToolOptions.Perspectives, selectedToolOptions.Animations!.length]
  );

  /**
   * Change the frontend state about the selected animations.
   * @param animation the animation to add or delete
   * @returns the changed array
   */
  const changeSelectedAnimations = (animation: string): string[] => {
    if (!selectedToolOptions.Animations!.includes(animation)) {
      selectedToolOptions.Animations!.push(animation);
    } else {
      selectedToolOptions.Animations = selectedToolOptions.Animations!.filter((x) => x !== animation);
    }
    return selectedToolOptions.Animations!;
  };

  const getKnobColor = () => {
    const brand = applicationSettings.Brand;
    if (brand === "KTM") {
      return "#ff6600";
    } else if (brand === "HQV") {
      return "#FFED00";
    } else if (brand === "GG") {
      return "#CB0D25";
    } else return "#ff6600";
  };

  const sendSelectedTool = (event: any, fallback?: string) => {
    const dataCommand = event ? ((event.target as HTMLElement).getAttribute("data-command") as string) : fallback ? fallback : "";
    if (dataCommand && dataCommand !== "") {
      const dataArray = dataCommand.split(" ");
      const newCat = dataArray[0];

      setSelectedToolOptions({
        Cinematics: cinematics ? (newCat === cinematics.Command ? dataCommand : selectedToolOptions.Cinematics) : "",
        Environments: environments ? (newCat === environments.Command ? dataCommand : selectedToolOptions.Environments) : "",
        Perspectives: perspectives ? (newCat === perspectives.Command ? dataCommand : selectedToolOptions.Perspectives) : "",
        Lighting: lighting ? (newCat === lighting.CommandSlider ? dataCommand : selectedToolOptions.Lighting) : "",
        Animations: animation ? (newCat === animation.Command ? changeSelectedAnimations(dataCommand) : selectedToolOptions.Animations) : [],
      });
      if(newCat === "envilvl") {
        sendToolOption(dataCommand, selectedToolOptions.Lighting);
      }
      sendToolOption(dataCommand);
    }
  };

  const toggleOnchangeFunction = () => {};

  const renderAnimations = (options: toolCat[], name: string) => {
    return (
      <>
        {name === selectedToolsCategory && (
          <div className="cat-list-container animation-list">
            {options.map((cat: toolCat, index: number) => {
              const dataCommand = name + " " + cat.Value;
              return (
                <div key={`tool-${index}`} className="animation-option-container default-cursor">
                  <div className={`tool-option-item tool-option-${cat.Value} default-cursor`}>{cat.Name}</div>
                  <div>
                    <label className="toggleButton">
                      <input
                        data-command={dataCommand}
                        onClick={(e) => sendSelectedTool(e)}
                        checked={selectedToolOptions.Animations!.includes(dataCommand)}
                        type="checkbox"
                        onChange={toggleOnchangeFunction}
                      ></input>
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const renderOptions = (options: toolCat[], name: string) => {
    return (
      <>
        {name === selectedToolsCategory && (
          <div className="cat-list-container">
            {options.map((cat: toolCat, index: number) => {
              const dataCommand = name + " " + cat.Value;
              return (
                <div key={`tool-${index}`} className={`tool-option-item tool-option-${cat.Value}`} data-command={dataCommand} onClick={sendSelectedTool}>
                  {cat.Name}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const renderCategories = (category: toolCats | null, lightingObject: Lighting | null) => {
    if (cinematics && category && category.Command === cinematics.Command) {
      return (
        <div className={`tool-cat ${isXBow ? "xb-tools" : ""} ${selectedToolsCategory === category.Command ? "active" : ""}`} onClick={() => setSelectedToolsCategory(category.Command)}>
          <Movie />
        </div>
      );
    } else if (environments && category && category.Command === environments.Command) {
      return (
        <div className={`tool-cat ${isXBow ? "xb-tools" : ""} ${selectedToolsCategory === category.Command ? "active" : ""}`} onClick={() => setSelectedToolsCategory(category.Command)}>
          <Mountain />
        </div>
      );
    } else if (perspectives && category && category.Command === perspectives.Command) {
      return (
        <div className={`tool-cat ${isXBow ? "xb-tools" : ""} ${selectedToolsCategory === category.Command ? "active" : ""}`} onClick={() => setSelectedToolsCategory(category.Command)}>
          <VideoCam />
        </div>
      );
    } else if (lighting && lightingObject && lightingObject.CommandSlider === lighting.CommandSlider) {
      return (
        <div
          className={`tool-cat ${isXBow ? "xb-tools" : ""} ${selectedToolsCategory === lightingObject.CommandSlider ? "active" : ""}`}
          onClick={() => setSelectedToolsCategory(lightingObject.CommandSlider)}
        >
          <Daytime />
        </div>
      );
    } else if (animation && category && animation.Command === category.Command) {
      return (
        <div className={`tool-cat ${isXBow ? "xb-tools" : ""} ${selectedToolsCategory === animation.Command ? "active" : ""}`} onClick={() => setSelectedToolsCategory(animation.Command)}>
          <XBow />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const sendCommand = () => lighting && sendSelectedTool(null, lighting.CommandSlider + " " + numericTime);

  const [sliderValue, handleSliderChange] = useState(
    configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Lighting
      ? +(+configurationState.VehicleSettings.ToolsConfiguration.Lighting.split(" ")[1] * (1 / 23)).toFixed(2)
      : lighting
      ? +(lighting.ValueTo * (1 / 23)).toFixed(2)
      : 0.25
  );

  useEffect(() => {
    sendCommand();
  }, [sliderValue]);

  const numericTime = useMemo(() => {
    const val = Math.round(sliderValue / (1 / 23));
    return val === 0 ? 24 : val;
  }, [sliderValue]);

  const displayTime = useMemo(() => {
    const morning = numericTime <= 12;
    const newValue = morning ? numericTime : numericTime - 12;
    const daytime = String(newValue).padStart(2, "0") + ":00 " + ((morning && newValue !== 12) || (!morning && newValue === 12) ? "a.m" : "p.m");
    return daytime;
  }, [numericTime]);

  const getSlider = () => {
    if (lighting && lighting.CommandSlider === selectedToolsCategory)
      return (
        <div className="cat-list-container">
          <div id="daytime-slider" className={`${!toolsOpen ? "d-none" : ""}`}>
            <Moon />
            <CircularInput value={sliderValue} onChange={handleSliderChange}>
              <CircularTrack strokeWidth={5} />
              <CircularThumb fill={`${getKnobColor()}`} />
              <text x={100} y={100} fill="white" textAnchor="middle" dy="0.3em" fontWeight="bold">
                {displayTime}
              </text>
            </CircularInput>
            <Sun />
          </div>
        </div>
      );
  };

  if (!overview.RenderSettings || (overview.RenderSettings && !overview.RenderSettings.Tools)) {
    return null;
  }

  return (
    <div className={`tools-container ${toolsOpen ? "show-tools" : "hide-tools"}`}>
      <div className={`tools-content ${toolsOpen ? "show-tools-content" : "hide-tools-content"}`}>
        <div className="tool-cats">
          <div className={`tool-tip ${toolsOpen ? "open" : "closed"}`} onClick={() => setToolsOpenFunc()}>
            <div className="tooltip-content-container">
              <>
                <Close />
                <div>
                  <ArrowHead />
                  <span>{t("configuration.tools")}</span>
                </div>
              </>
            </div>
          </div>
          <div className="tool-cats-container">
            <>
              <div className="cat-names">
                <>
                  {cinematics ? renderCategories(cinematics, null) : ""}
                  {environments ? renderCategories(environments, null) : ""}
                  {perspectives ? renderCategories(perspectives, null) : ""}
                  {lighting ? renderCategories(null, lighting) : ""}
                  {animation ? renderCategories(animation, null) : ""}
                </>
              </div>
              {cinematics && renderOptions(cinematics.List, cinematics.Command)}
              {environments && renderOptions(environments.List, environments.Command)}
              {perspectives && renderOptions(perspectives.List, perspectives.Command)}
              {lighting && getSlider()}
              {animation && renderAnimations(animation.Animations, animation.Command)}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);

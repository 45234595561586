import React from "react";
import MinusKTM from "./minus_ktm";
import MinusHQV from "./minus_hqv";
import MinusGG from "./minus_gg";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";

export interface MinusIconProps {
  identifyer: string;
  is3D?: boolean;
}

const Minus: React.FunctionComponent<StateManagementProps & MinusIconProps> = props => {
  const brand = props.applicationSettings.Brand;
  return (
    <>
      {brand === "KTM" && <MinusKTM identifyer={props.identifyer} is3D={props.is3D} />}
      {brand === "HQV" && <MinusHQV identifyer={props.identifyer} is3D={props.is3D} />}
      {brand === "GG" && <MinusGG identifyer={props.identifyer} is3D={props.is3D} />}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Minus);

import http from '../../services/http';
import { AxiosResponse } from 'axios';
import { setRuntimeConfiguration } from '../actions';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { ApplicationSettings } from '../reducers/applicationSettings';

export default function getRuntimeConfiguration(callback : (data: ApplicationSettings) => void): ThunkAction<Promise<void>, {}, {}, any> {
    return function (dispatch: ThunkDispatch<any, any, any>) {
        return http.getAppSettings().then((response: AxiosResponse<ApplicationSettings>) => {
            dispatch(setRuntimeConfiguration(response.data));
            callback(response.data);
        }).catch((reason) => {
            console.error('An http error occured', JSON.stringify(reason));
        })
    }
}
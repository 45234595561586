import {
  hideDialog,
  showDialog,
  showGuidance,
  hideGuidance,
  setPerspective,
  selectPart,
  deselectPart,
  addVehicleOverview,
  selectSetup,
  applySetup,
  applyParts,
  removeAllParts,
  showToast,
  hideToast,
  ShowDialogProps,
  setImageTimestamp,
  addCreatedConfiguration,
  addPrice,
  setConfigurationId,
  setConfigurationImages,
  setVehicleVariation,
  togglePartDetail,
  toggleSummary,
  setCurrentCountry,
  setCountryArray,
  setImageArray,
  setPreselectedVariationCode,
  setIs3DMode,
  setCdcSettings,
  setIsXBow,
  setDealerInquirySent,
  setUserDidTimeoutInStream,
  setDealerInquiryDialogOpen,
} from "../redux/actions";

import {
  getAllSetups,
  getDialogState,
  getUserGuidanceState,
  getSelectedModel,
  getCategories,
  getSelectedPart,
  getPerspective,
  getAppliedSetup,
  getSelectedSetup,
  getAllParts,
  getAppliedParts,
  getToastState,
  getMotorcycleModels,
  getConfiguration,
  getPrice,
  getImageTimeStamp,
  getOverview,
  getViews,
  getConfigurationWasRecentlyCreatedSelector,
  getApplicationSettings,
  getCulture,
  getMarket,
  getConfigurationState,
  getVehicleVariation,
  getCurrentCountry,
  getCountryArray,
  getImageArray,
  getPreselectedVariationCode,
  getIS3DMode,
  getCdcSettings,
  getIsXbow,
  getDealerInquirySent,
  getUserDidTimeoutInStream,
  getDealerInquiryDialogOpen,
} from "../redux/selectors";
import { Setup } from "../components/setups/SetupsList";
import Action from "../redux/reducers/models/action";
import { DialogState } from "../redux/reducers/modals";
import { Part } from "../components/parts/PartsList";
import { Store as AppStore } from "../redux/reducers";
import { ToastMessageState } from "../redux/reducers/toast";
import { VehicleOverviewState } from "../redux/reducers/vehicleOverview";
import { CreateConfigurationResponse, FullImage } from "../components/configuration/ConfigurationView";
import { ViewsState } from "../redux/reducers/views";
import { ApplicationSettings } from "../redux/reducers/applicationSettings";
import { ConfigurationState } from "../redux/reducers/configuration";
import { UserGuidanceState } from "../redux/reducers/userGuidance";
import { CurrentCountryState } from "../redux/reducers/currentCountry";
import { CountryArrayState } from "../redux/reducers/countryArray";
import { ImageArrayState } from "../redux/reducers/imageArray";
import { PreselectedVariationCodeState } from "../redux/reducers/preselectedVariationCode";
import { MotorcycleModelResponse, Price, VehicleInformation } from "../models/vehicle";

export interface StateToProps {
  setups: Setup[];
  applicationSettings: ApplicationSettings;
  models: MotorcycleModelResponse;
  parts: Part[];
  dialogState: DialogState;
  userGuidanceState: UserGuidanceState;
  perspective: number;
  selectedModel: VehicleInformation;
  categories: string[];
  selectedPart: Part;
  appliedSetup: Setup | undefined;
  selectedSetup: Setup;
  appliedParts: Part[];
  toast: ToastMessageState;
  configuration: CreateConfigurationResponse;
  configurationState: ConfigurationState;
  culture: string;
  country: string;
  totalPrice: Price;
  wasRecentlyCreated: boolean;
  imageTimestamp: number;
  overview: VehicleOverviewState;
  views: ViewsState;
  currentVariation: string;
  currentCountry: CurrentCountryState;
  countryArray: CountryArrayState;
  imageArray: ImageArrayState;
  preselectedVariationCode: PreselectedVariationCodeState;
  is3DMode: boolean;
  cdcSettings: boolean;
  isXBow: boolean;
  dealerInquirySent: boolean;
  userDidTimeoutInStream: boolean;
  dealerInquiryDialogOpen: boolean;
}

export interface DispatchToProps {
  addVehicleOverview: (payload: VehicleOverviewState) => Action;
  showDialog: (dialogProps: ShowDialogProps) => Action;
  hideDialog: () => Action;
  hideGuidance: () => Action;
  showGuidance: () => Action;
  setPerspective: (perspective: number) => Action;
  selectSetup: (payload: Setup) => Action;
  applySetup: (payload: Setup) => Action;
  selectPart: (part: Part) => Action;
  deselectPart: () => Action;
  removeAllParts: () => Action;
  applyParts: (payload: string[]) => Action;
  showToast: (payload: string) => Action;
  hideToast: () => Action;
  addCreatedConfiguration: (config: CreateConfigurationResponse) => Action;
  addPrice: (price: Price) => Action;
  setImageTimestamp: () => Action;
  setConfigurationId: (configId: number) => Action;
  setConfigurationImages: (images: FullImage[]) => Action;
  setVehicleVariation: (variationCode: string) => Action;
  togglePartDetail: (show: boolean) => Action;
  toggleSummary: (show: boolean) => Action;
  setCurrentCountry: (currentCountry: CurrentCountryState) => Action;
  setCountryArray: (countryArray: CountryArrayState) => Action;
  setImageArray: (imageArray: ImageArrayState) => Action;
  setPreselectedVariationCode: (code: PreselectedVariationCodeState) => Action;
  setIs3DMode: (payload: boolean) => Action;
  setIsXBow: (payload: boolean) => Action;
  setCdcSettings: (payload: boolean) => Action;
  setDealerInquirySent: (payload: boolean) => Action;
  setUserDidTimeoutInStream: (payload: boolean) => Action;
  setDealerInquiryDialogOpen: (payload: boolean) => Action;
}

export type StateManagementProps = StateToProps & DispatchToProps;

export const mapStateToProps = (store: AppStore): StateToProps => {
  return {
    applicationSettings: getApplicationSettings(store),
    setups: getAllSetups(store),
    parts: getAllParts(store),
    appliedParts: getAppliedParts(store),
    dialogState: getDialogState(store),
    userGuidanceState: getUserGuidanceState(store),
    models: getMotorcycleModels(store),
    perspective: getPerspective(store),
    selectedModel: getSelectedModel(store),
    categories: getCategories(store),
    selectedPart: getSelectedPart(store),
    appliedSetup: getAppliedSetup(store),
    selectedSetup: getSelectedSetup(store),
    toast: getToastState(store),
    configuration: getConfiguration(store),
    configurationState: getConfigurationState(store),
    culture: getCulture(store),
    country: getMarket(store),
    totalPrice: getPrice(store),
    wasRecentlyCreated: getConfigurationWasRecentlyCreatedSelector(store),
    imageTimestamp: getImageTimeStamp(store),
    overview: getOverview(store),
    views: getViews(store),
    currentVariation: getVehicleVariation(store),
    is3DMode: getIS3DMode(store),
    cdcSettings: getCdcSettings(store),
    isXBow: getIsXbow(store),
    currentCountry: getCurrentCountry(store),
    countryArray: getCountryArray(store),
    imageArray: getImageArray(store),
    preselectedVariationCode: getPreselectedVariationCode(store),
    dealerInquirySent: getDealerInquirySent(store),
    userDidTimeoutInStream: getUserDidTimeoutInStream(store),
    dealerInquiryDialogOpen: getDealerInquiryDialogOpen(store),
  };
};

export const mapDispatchToProps: DispatchToProps = {
  addVehicleOverview,
  hideDialog,
  showDialog,
  showGuidance,
  hideGuidance,
  setPerspective,
  selectPart,
  deselectPart,
  selectSetup,
  applySetup,
  applyParts,
  showToast,
  hideToast,
  removeAllParts,
  addCreatedConfiguration,
  addPrice,
  setImageTimestamp,
  setConfigurationId,
  setConfigurationImages,
  setVehicleVariation,
  togglePartDetail,
  toggleSummary,
  setCurrentCountry,
  setCountryArray,
  setImageArray,
  setPreselectedVariationCode,
  setIs3DMode,
  setIsXBow,
  setCdcSettings,
  setDealerInquirySent,
  setUserDidTimeoutInStream,
  setDealerInquiryDialogOpen,
};

import { TOGGLE_PART_DETAIL, TOGGLE_CONFIG_SUMMARY, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import Action from './models/action';

export interface ViewsState {
    showPartDetail: boolean;
    showConfigurationSummary: boolean;
}

export const viewsInitialState: ViewsState = {
    showPartDetail: false,
    showConfigurationSummary: false,
}

export default function ViewsReducer(state: ViewsState = viewsInitialState, action: Action): ViewsState {
    switch (action.type) {
        case TOGGLE_CONFIG_SUMMARY: {
            return {
                ...state,
                showConfigurationSummary: action.payload,
            }
        }
        case TOGGLE_PART_DETAIL: {
            return {
                ...state,
                showPartDetail: action.payload,
            }
        }
        case EXIT_VEHICLE_CONFIGURATION: {
            return {
                ...viewsInitialState,
            };
        }
        default:
            return state;
    }
}
import React, { useState } from "react";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Swipeable from "react-swipeable";
import ArrowHead from "../../../assets/icons/arrowHead";
import {
  mapDispatchToProps,
  mapStateToProps,
  StateManagementProps,
} from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { VehicleInformation } from "../../../models/vehicle";
import BrandLogo from "../../shared/BrandLogo";
import Indicator3d from "../../../assets/icons/3d/indicator3d";

export interface MotorcycleMenuProps {
  updateState: (link: string) => void;
  links: string[];
  link: string;
}

const classes = (theme: Theme) => ({
  typeList: {
    display: "flex",
    justifyContent: "flex-start",
    verticalAlign: "middle",
    alignItems: "center",
  },
  navLink: {
    //remove problem with safari lineHeight: 1.625,
    margin: "initial" as "initial",
    marginRight: "2.5rem",
  },
  menuWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 70px 0 140px",
  },
});

export const MotorcycleMenu: React.FunctionComponent<
  MotorcycleMenuProps & WithStyles & StateManagementProps
> = (props: MotorcycleMenuProps & WithStyles & StateManagementProps) => {
  const models = props.models;

  const { navLink, typeList, menuWrapper } = props.classes;
  const { applicationSettings } = props;
  const { Brand } = applicationSettings;
  const headlineClasses = `ktm-headline ${Brand === "KTM" ? "uppercase" : ""}`;
  const [currentlyActiveLink, setCurrentlyActiveLink] = useState(0);
  const sortedLinks = props.links;

  function swipeToLink(index: number, nextLink: number) {
    let currentLinkIndex: number = index + nextLink;
    const min = 0;
    const max = props.links.length - 1;
    if (currentLinkIndex < min) currentLinkIndex = max;
    if (currentLinkIndex > max) currentLinkIndex = min;
    let currentLink = props.links[currentLinkIndex];
    props.updateState(currentLink);
    setCurrentlyActiveLink(currentLinkIndex);
  }
  
  const has3DModels = (segmentName: string): boolean => {
    if (!models || !segmentName) return false;

    let found: VehicleInformation | null = null;
    models.Segments.forEach(s =>
      s.Vehicles.forEach(v => {
        if (s.Name === segmentName && v.RenderingEngine === "Engine3D")
          found = v;
      })
    );
    return found !== null;
  };

  return (
    <Grid
      item
      id="main-menu-wrapper"
      className={`desktop-menu ${menuWrapper}`}
    >
      <div className="main-menu">
        <ul className={`${typeList} d-none-mobile`}>
          {sortedLinks.map((link: string, idx: number) => {
            const selected = link === props.link ? "selected" : "";
            const linkClass = link.replace(/\s/g, "-").toLowerCase();
            return (
              <li
                key={idx}
                onClick={props.updateState.bind(null, link)}
                className={`${headlineClasses} ${selected} ${navLink}`}
              >
                <span className={`segment-links segment-${linkClass}`}>
                  {link}
                  {has3DModels(link) && (
                    <div className={`segment-link-indicator ${Brand === "GG" ? "gasgas-indicator":""}`}><Indicator3d {...props} useDefault={false} /></div>
                  )}
                </span>
              </li>
            );
          })}
        </ul>
        
        <BrandLogo
          customClass={"brandlogo-in-main-header"}
          {...props}
        ></BrandLogo>
      </div>
      {
        //mobile only
        sortedLinks.map((link: string, idx: number) => {
          if (currentlyActiveLink === idx) {
            return (
              <div
                className="mobile-motorcycle-menu show-mobile absolute mobile"
                key={`menu-${idx}`}
              >
                <span
                  className="prev"
                  onClick={() => {
                    swipeToLink(idx, -1);
                  }}
                >
                  <ArrowHead />
                </span>
                <Swipeable
                  trackMouse={true}
                  preventDefaultTouchmoveEvent={true}
                  onSwipedLeft={() => {
                    swipeToLink(idx, -1);
                  }}
                  onSwipedRight={() => {
                    swipeToLink(idx, 1);
                  }}
                >
                  {link}
                </Swipeable>
                <span
                  className="next"
                  onClick={() => {
                    swipeToLink(idx, 1);
                  }}
                >
                  <ArrowHead />
                </span>
              </div>
            );
          }
        })
      }
    </Grid>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(MotorcycleMenu));

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CheckOk: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Check Icon</title>
      <g id="check" fill="green">
        <polygon points="34.53 41.06 27.38 48.19 45.89 66.71 88.44 23.44 81.31 16.29 45.88 52.41 34.53 41.06" />
        <path d="M85.29,47.12V48a37.51,37.51,0,1,1-22-34.11l.74.34,5.45-9.1-.9-.43A48.38,48.38,0,0,0,47.87,0h0a48,48,0,0,0,0,96h0a48,48,0,0,0,48-48v-.91Z" />
      </g>
    </SvgIcon>
  );
};

export default CheckOk;

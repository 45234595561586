import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PlusIconProps } from "./plus";

const PlusKTM: React.FunctionComponent<PlusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 23.646 23.646" id={props.identifyer}>
      <title>Plus Icon</title>
      <g transform="translate(7.224 7.224)">
        <path d="M409.27,615.451v-3.657h-1.836v3.657h-3.657v1.837h3.657v3.658h1.836v-3.658h3.658v-1.837Z" transform="translate(-403.776 -611.794)" />
      </g>
      <path
        d="M1179.729,654.327a11.823,11.823,0,1,0,0,23.646h0a11.823,11.823,0,0,0,0-23.646Zm9.226,11.823a9.226,9.226,0,1,1-9.226-9.226h0A9.252,9.252,0,0,1,1188.955,666.15Z"
        transform="translate(-1167.906 -654.327)"
      />
    </SvgIcon>
  );
};

export default PlusKTM;

import React from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { DialogProps } from "./dialogRouter";
import Dialog from "@material-ui/core/Dialog";
import { StateManagementProps } from "../../utils/addReduxProps";
import ArrowHead from '../../assets/icons/arrowHead';
import Hand from '../../assets/icons/hand';
import { DialogContent } from "@material-ui/core";

const UserGuidance: React.FunctionComponent<DialogProps & WithNamespaces & StateManagementProps> = ({ hideDialog, t, data, applicationSettings, title }) => {
	const { ImageUrl } = data.models.Segments[0].Vehicles[0].Hero;

	return (
		<Dialog
			disableBackdropClick={true}
			fullWidth={true}
			open={true}
			onClose={hideDialog}
			onClick={hideDialog}
			aria-labelledby="user-guidance-modal"
			id="user-guidance-dialog"
		>
				<div className="part-arrows">
					<div className="part-arrow-wrapper">
						<ArrowHead/>
						<ArrowHead/>
					</div>
					<div className="part-arrow-text">
						{t("dialogs.userGuidance.arrows")}
					</div>

				</div>
				<div className="part-swipe">
					<div className="swipe-image">
						<img width="100%" src={ImageUrl}/>
					</div>
					<div className="swipe-text">
						{t("dialogs.userGuidance.swipe")}
					</div>
					<div className="swipe-icon">
						<Hand/>
					</div>

				</div>
				<div className="part-configure">
					<a className={`configure-button ${applicationSettings.Brand === "HQV" ? "secondary" : "primary"}`}>
						{t('buttons.configure')}
						{applicationSettings.Brand !== "HQV" && <ArrowHead/> }
					</a>
					<div className="configure-text">
						{t("dialogs.userGuidance.configure")}
					</div>
				</div>
				<div className="part-button">
					{t("dialogs.userGuidance.accept")}
				</div>
		</Dialog >
	)
}

export default withNamespaces()(UserGuidance);

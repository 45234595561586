import { useEffect, useState } from "react";
import { WithNamespaces } from "react-i18next";
import { generateScreenshotForPDFGeneration, getAppliedPartsCount, getIsMobile, sendConfigurationToDealer } from "../../utils/helpers";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import React from "react";
import SelectedPartsList from "./selectedPartsList/SelectedPartsList";
import ArrowHead from "../../assets/icons/arrowHead";
import formatter from "../../utils/formatter";
import { Part } from "../parts/PartsList";
import { Price } from "../../models/vehicle";
import TechnicalDetails, { TechnicalDetailsProps } from "./bottomSection/technicalDetails";
import { connect } from "react-redux";

export interface ConfigOverviewProps {
  listOpen: boolean;
  setOverviewOpen?: (open: boolean) => void;
  isPortraitMode?: boolean;
}

type AllProps = StateManagementProps & WithNamespaces & ConfigOverviewProps & TechnicalDetailsProps;

export const ConfigurationOverview3D: React.FunctionComponent<AllProps> = (props) => {
  const { t, appliedParts, listOpen, applicationSettings, setOverviewOpen, totalPrice, overview, isXBow, isPortraitMode } = props;

  const brand = applicationSettings.Brand;

  const getInnerHeight = (open) => {
    let windowInnerheight = window.innerHeight;
    if (window.innerWidth < 1024) {
      windowInnerheight = (windowInnerheight / 3) * 2.5;
    }
    if (open) {
      let summary = document.getElementById("summary-button-section");
      if (summary) {
        let summaryHeight = summary.offsetHeight;
        return windowInnerheight - summaryHeight + "px";
      }
      return windowInnerheight + "px";
    } else {
      let header = document.getElementById("overview-header");
      if (header) {
        let headerheight = header.offsetHeight;
        return headerheight + "px";
      } else {
        return 68 + "px";
      }
    }
  };

  const [innerOverviewHeight, getInnerOverviewHeight] = useState(getInnerHeight(listOpen));
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [showPPPrice, setShowPPPrice] = useState(false);

  const setInnerHeight = () => {
    getInnerOverviewHeight(getInnerHeight(listOpen));
  };

  const setIsMobileState = () => {
    setIsMobile(getIsMobile());
  };

  const handleResize = () => {
    setInnerHeight();
    setIsMobileState();
  };

  const completeConfigurationClick = () => {
    if (setOverviewOpen) {
      getInnerOverviewHeight(getInnerHeight(false));
      setOverviewOpen && setOverviewOpen(false);
    }

    sendConfigurationToDealer(props);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    getInnerOverviewHeight(getInnerHeight(listOpen));
    setIsMobileState();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowPPPrice(Boolean(totalPrice && overview.Vehicle.Hero.Price && totalPrice.Value !== overview.Vehicle.Hero.Price.Value));
  }, [totalPrice]);

  const toggleList = () => {
    if (setOverviewOpen) {
      getInnerOverviewHeight(getInnerHeight(!listOpen));
      setOverviewOpen(!listOpen);
    }
  };

  const getAppliedPowerPartsPrice = () => {
    let ppPrice = 0;
    let currency = "";
    appliedParts.forEach((part: Part) => {
      if (currency === "" && part.Price && part.Price.CurrencySymbol) {
        currency = part.Price.CurrencySymbol;
      }
      if (part.Price && part.Price.Value) {
        ppPrice += part.Price.Value;
      }
    });
    const priceElement: Price = {
      CurrencySymbol: currency,
      Value: ppPrice,
    };

    return <>{formatter.currency(priceElement)}</>;
  };

  return (
    <>
      <div className={`expandable-overview ${listOpen ? "open" : "closed"}`} style={{ height: innerOverviewHeight }}>
        <div id="overview-header" onClick={toggleList}>
          <div className="overview-header-content">
            <div className="overview-header-title">
              <ArrowHead />

              <span className="applied-parts-count">{!isXBow && <>{getAppliedPartsCount()}</>}</span>

              <span className="applied-parts-text">{!isXBow ? <>{t("configuration.summary.summary_message." + brand)}</> : <>Overview</>}</span>
            </div>
            <div className="overview-header-price">
              {!listOpen && <span className={`applied-parts-price ${(totalPrice && totalPrice.Value === 0) || !totalPrice ? "no-price" : ""}`}>{formatter.currency(totalPrice, isXBow)}</span>}
            </div>
          </div>
          <div className={listOpen ? "overview-border" : ""}></div>
        </div>
        <div id="selected-parts-list">
          <SelectedPartsList {...props} parts={appliedParts} totalPrice={totalPrice} isMobile={isMobile} />
        </div>

        {!isXBow && (
          <div id="overview-bottom-part">
            {(applicationSettings.Brand === "KTM" || isMobile) && <div className={`overview-border ${isMobile ? "withmargin" : ""}`}></div>}

            <div className="price-section">
              <div className="powerparts-price">
                <div className="pp-price-title">
                  {brand === "KTM" ? <>Powerparts</> : <>Accessories</>}
                </div>
                <div className={`pp-price-value ${showPPPrice ? "" : "no-price"}`}>{getAppliedPowerPartsPrice()}</div>
              </div>
              <div className="total-price">
                {totalPrice && (
                  <>
                    <div className="total-price-title">Total</div>
                    <div className={`total-price-value ${totalPrice && totalPrice.Value === 0 ? "no-price" : ""}`}>{formatter.currency(totalPrice)}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={`overview-border withmargin`}></div>
      </div>
      <div id="summary-button-section" className={`summary-button-section ${listOpen ? "open" : "closed"} ${!isXBow ? "" : "xbow-version"}`}>
        <div className="mobile-tec-details-wrapper">
          <TechnicalDetails {...props}></TechnicalDetails>
        </div>
        {(isXBow || applicationSettings.DealerSearch3DEnabled) && (
          <button
            className="primary go-to-summary-button-3d xbow-dealer-search gtm-dealer-search-button"
            onClick={() => {
              //Set the screenshot to session storage here
              const promise = generateScreenshotForPDFGeneration(!!isPortraitMode, getIsMobile(), document.getElementById("stream") as HTMLVideoElement);
              promise &&
                promise.then((data) => {
                  try {
                    if (sessionStorage) {
                      sessionStorage.removeItem("pdfScreenshot");
                      sessionStorage.setItem("pdfScreenshot", data);
                    }
                  } catch (e) {
                    console.log("Error while setting pdfScreenshot in session storage", e);
                  }
                });
              completeConfigurationClick();
            }}
          >
            Complete configuration
          </button>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationOverview3D);

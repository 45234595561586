import * as real from './real';
import * as mock from './mock';
import { getEntryPointUrl } from '../../utils/helpers';
import axios, { AxiosRequestConfig } from 'axios';

export enum Environment {
	PROD = 'PROD',
	DEV = 'DEV',
}
const isProd = process.env.REACT_APP_ENVIRONMENT === Environment.PROD;
const isMock = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const publicUrl = process.env.PUBLIC_URL;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(function (config: AxiosRequestConfig) {
	const { url } = config;

	// make sure to prefix url as needed
	config = {
		...config,
		url: isProd && isMock ? publicUrl + url : url,
	}
	return config;
}, function (error) {
	console.error('The following HTTP request error occured: ', error);
	return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
	return response;

}, function (error: any) {
	console.log('logging error response', error);

	if ([401, 403].includes(+error.response.status as number)) {

		window.location.replace(getEntryPointUrl());
	}

	console.log('A server error occured with the following signature:', error);
	return Promise.reject(error);
});

const data = process.env.REACT_APP_USE_MOCK_DATA === 'true' ? mock : real;

export default data;

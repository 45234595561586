import React, { useState, useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Arrow from "../../assets/icons/arrow";
import Close from "../../assets/icons/close";
import { AxiosResponse } from "axios";
import { shareConfiguration } from "../../services/http/real";
import { useClipboard } from "use-clipboard-copy";
import Action from "../../redux/reducers/models/action";
import { ShareConfigurationResponse } from "../configuration/configurationSummary/ConfigurationSummary";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";

export interface ShareConfigurationProps {
  setConfigurationId: (id: number) => Action;
  configurationWasRecentlyCreated: boolean;
}

type AllProps = DialogProps & ShareConfigurationProps & StateManagementProps & WithNamespaces;

export const ShareConfiguration: React.FunctionComponent<AllProps> = props => {
  const { applicationSettings, hideDialog, t, setConfigurationId, isXBow } = props;
  const [configurationUrl, setConfigurationUrl] = useState<string>("");
  const [hasConfig, setHasConfig] = useState(false);

  const clipboard = useClipboard({
    copiedTimeout: 0, // timeout duration in milliseconds
    onSuccess: hideDialog, //close dialog after copy
  });

  useEffect(() => {
    return () => {
      hideDialog();
    };
  }, []);

  useEffect(() => {
    if (!hasConfig) {
      shareConfiguration().then(
        (response: AxiosResponse<ShareConfigurationResponse>) => {
          setConfigurationUrl(response.data.Url);
          setConfigurationId(response.data.ConfigurationId);
          setHasConfig(true);
          return response.data.Url;
        },
        error => {
          console.log("Cannot create shareable Link ", error);
          return "no link availiable";
        }
      );
    }
  }, []);

  const renderDesktopBackButton = () => {
    if (applicationSettings.Brand === "HQV") {
      return (
        <button className="primary back-link gtm-go-back-share" title={t("buttons.closeDialog")} onClick={hideDialog}>
          <span className="d-none-mobile ">
            <Arrow />
          </span>
          {t("buttons.closeDialog")}
        </button>
      );
    }

    if (applicationSettings.Brand === "GG") {
      return (
        <a className="back-link gtm-go-back-share gg-back-link" title={t("buttons.closeDialog")} onClick={hideDialog}>
          <Close />
        </a>
      );
    }

    return (
      <button className="primary back-link gtm-go-back-share" title={t("buttons.closeDialog")} onClick={hideDialog}>
        {t("buttons.closeDialog")}
        <span className="d-none-mobile">
          <Arrow />
        </span>
      </button>
    );
  };

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      onClose={hideDialog}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="export configuration modal"
      className="send-mail-wrapper"
      id="shareConfigurationDialog"
    >
      <Grid item className="close-send-mail-button-mobile show-mobile container">
        <button className="primary gtm-go-back-share" title={t("buttons.closeDialog")} onClick={hideDialog}>
          <Close />
        </button>
      </Grid>
      <Grid item className="close-send-mail d-none-mobile container">
        {renderDesktopBackButton()}
      </Grid>
      <div className="send-mail container">
        <div className="dialog-wrapper">
          <Grid id="setup-parts-dialog-title">
            <h2 className={applicationSettings.Brand === "KTM" ? "setups-headline underline uppercase" : "setups-headline underline"}>{t("configuration.summary.shareOptions.sendLink.title")}</h2>
          </Grid>
          <div className="share-link-desc">{t(`configuration.summary.shareOptions.sendLink.description.${isXBow ? "car" : "bike"}`)}</div>
          <form noValidate id="share-link-form">
            <DialogContent className="text-field share-link-field">
              <input ref={clipboard.target} id="shareableLink" value={configurationUrl} className={clipboard.copied ? "copied" : "copy-link"} readOnly />
            </DialogContent>
            <DialogActions>
              <a onClick={clipboard.copy} className="copy-link-button uppercase">
                {clipboard.copied ? t("buttons.copied") : t("buttons.copyLink")}
              </a>
            </DialogActions>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(ShareConfiguration));

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MinusIconProps } from "./minus";

const MinusGG: React.FunctionComponent<MinusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 96 96" id={props.identifyer}>
      <title>Minus Icon</title>
      <rect id="svg_2" height="12" width="96" y="42" x="0" fill="#bf0000" stroke="#bf0000" />
    </SvgIcon>
  );
};

export default MinusGG;

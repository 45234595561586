import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const VideoCam: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 30 31">
      <title>Video Cam Icon</title>
      <path
        className="stroke"
        d="M20 13.2465L24.344 10.5688C24.7297 10.331 25.2138 10.3205 25.6093 10.5414C26.0049 10.7622 26.25 11.1798 26.25 11.6328V20.3789C26.25 20.8319 26.0049 21.2494 25.6093 21.4703C25.2138 21.6911 24.7297 21.6806 24.3441 21.4429L20 18.7652"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect className="stroke" x="3.75" y="7.88086" width="16.25" height="16.25" rx="3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default VideoCam;

import { SELECT_PART, DESELECT_PART, APPLY_PARTS, REMOVE_ALL_PARTS, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import Action from './models/action'
import { Part } from "../../components/parts/PartsList";
import { Store } from ".";
import { uniqBy } from "../../utils/helpers";

export interface PartsState {
	selected: Part | null;
	applied: Part[];
}

export const initialState: PartsState = {
	selected: {} as Part,
	applied: [],
};

export default function PartsReducer(state: PartsState = initialState, action: Action, State: Store): PartsState {
	switch (action.type) {
		case SELECT_PART: {
			const selected: Part = action.payload;
			return { ...state, selected };
		}
		case DESELECT_PART: {
			return { ...state, selected: {} as Part };
		}
		case APPLY_PARTS: {
			const partIds: string[] = action.payload;

			let parts = State.vehicleOverviewState.Parts.filter(
				(part: Part) => partIds.indexOf(part.PartId) !== -1
			);

			parts = uniqBy(parts, (p) => p.PartId);

			return { ...state, applied: [...parts] };
		}
		case REMOVE_ALL_PARTS: {

			return { ...state, applied: [] };
		}
		case EXIT_VEHICLE_CONFIGURATION: {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
}

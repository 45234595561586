import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MinusIconProps } from "./minus";

const MinusHQV3D: React.FunctionComponent<MinusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 25.5 25.5" id={props.identifyer}>
      <title>Minus Icon</title>
      <g transform="translate(0)">
        <rect width="10.492" height="1.536" transform="translate(7.504 11.982)" />
      </g>
    </SvgIcon>
  );
};

export default MinusHQV3D;

import { MotorcycleModelResponse, VehicleInformation } from "../../models/vehicle";
import { ADD_MOTORYCLE_MODELS } from "../actionTypes";
import Action from './models/action';

export const initialState: MotorcycleModelResponse = {
	Segments: [],
	ShowPricesInConfigurator: true,
	ShowOfferButton: false,
}

export function getVehicleInformation(modelResponse: MotorcycleModelResponse) {
	return modelResponse.Segments.reduce((acc, current): VehicleInformation[] => {
		return [...acc, ...current.Vehicles]
	}, [] as VehicleInformation[])
}

export default function MotorcycleReducer(state: MotorcycleModelResponse = initialState, action: Action): MotorcycleModelResponse {
	switch (action.type) {
		case ADD_MOTORYCLE_MODELS: {
			return {
				...action.payload
			}
		}
		default:
			return state;
	}
}


export const SET_RUNTIME_CONFIGURATION = "SET_RUNTIME_CONFIGURATION";

export const EXIT_VEHICLE_CONFIGURATION = "EXIT_VEHICLE_CONFIGURATION";

/* SETUPS */
export const SELECT_SETUP = "SELECT_SETUP";
export const APPLY_SETUP = "APPLY_SETUP";

/* MODALS*/
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

/* USER GUIDANCE */
export const SHOW_GUIDANCE = "SHOW_GUIDANCE";
export const HIDE_GUIDANCE = "HIDE_GUIDANCE";

/* CONFIGURATION */
export const SET_CREATED_CONFIGURATION = "SET_CREATED_CONFIGURATION_RESPONSE";
export const SET_PERSPECTIVE = "SET_BIKE_IMAGE_PERSPECTIVE";
export const SET_PRICE = "SET_PRICE";
export const SET_IMAGE_TIMESTAMP = "SET_IMAGE_TIMESTAMP";
export const SET_CONFIGURATION_ID = "SET_CONFIGURATION_ID";
export const SET_CONFIGURATION_IMAGES = "SET_CONFIGURATION_IMAGES";
export const SET_ENTRY_POINT_URL = "SET_ENTRY_POINT_URL";
export const CATEGORY_SCROLL_POSITION = "CATEGORY_SCROLL_POSITION";
export const PRESERVE_SAVE_DETAILS_DATA = "PRESERVE_SAVE_DETAILS_DATA";
export const SET_CONFIGURATION_IS_DIRTY = "SET_CONFIGURATION_IS_DIRTY";
export const SET_VEHICLE_VARIATION = "SET_VEHICLE_VARIATION";
export const SET_CDC_SETTINGS = "SET_CDC_SETTINGS";
export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";
export const SET_COUNTY_ARRAY = "SET_COUNTY_ARRAY";
export const SET_IMAGE_ARRAY = "SET_IMAGE_ARRAY";
export const SET_CONFIGURATION_WAS_RECENTLY_CREATED = "SET_CONFIGURATION_WAS_RECENTLY_CREATED";
export const SET_IS3D_MODE = "SET_IS3D_MODE";
export const SET_IS_XBOW = "SET_IS_XBOW";

/* MOTORCYCLES */
export const ADD_MOTORYCLE_MODELS = "ADD_MOTORYCLE_MODELS";
export const MODEL_SELECTED = "MODEL_SELECTED";

/* PARTS */
export const SELECT_PART = "SELECT_PART";
export const DESELECT_PART = "DESELECT_PART";
export const APPLY_PARTS = "APPLY_PARTS";
export const REMOVE_ALL_PARTS = "REMOVE_ALL_PARTS";
export const SET_NO_CONFLICTING_PARTS = "SET_NO_CONFLICTING_PARTS";
export const SET_REMOVE_NO_CONFLICTING_PARTS = "SET_REMOVE_NO_CONFLICTING_PARTS";

/* BIKE OVERVIEW */
export const ADD_VEHICLE_OVERVIEW = "ADD_VEHICLE_OVERVIEW";

/* TOAST */
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

/* CULTURE, COMPANY_ID, COUNTRY  */
export const SET_CULTURE = "SET_CULTURE";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_COUNTRY = "SET_COUNTRY";

/* VIEWS */
export const TOGGLE_CONFIG_SUMMARY = "TOGGLE_CONFIG_SUMMARY";
export const TOGGLE_PART_DETAIL = "TOGGLE_PART_DETAIL";

/* LOAD */
export const LOAD_CONFIGURATION = "LOAD_CONFIGURATION";
export const SET_PRESELECTED_VARIATION_CODE ="SET_PRESELECTED_VARIATION_CODE";

/* Dealer Inquiry */	
export const DEALER_INQUIRY_SENT = "DEALER_INQUIRY_SENT";
export const USER_DID_TIMEOUT_IN_STREAM = "USER_DID_TIMEOUT_IN_STREAM";
export const DEALER_INQUIRY_DIALOG_OPEN = "DEALER_INQUIRY_DIALOG_OPEN"
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowHQV: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 1280.000000 1096.000000">
      <title>Arrow</title>
      <g transform="translate(0.000000,1096.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M6941 10634 l-322 -327 1083 -1076 c596 -592 1588 -1578 2205 -2191
            l1122 -1115 -5515 -3 -5514 -2 0 -460 0 -460 5520 -2 5519 -3 -2171 -2185
            -2171 -2185 318 -312 c232 -229 324 -313 341 -313 17 0 674 661 2731 2750
            1489 1513 2709 2756 2711 2764 3 13 -5513 5446 -5529 5446 -4 0 -152 -147
            -328 -326z"
        />
      </g>
    </SvgIcon>
  );
};

export default ArrowHQV;

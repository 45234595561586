import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PDF: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 26 31">
      <title>PDF Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08268 0C2.78081 0 0.916016 1.8648 0.916016 4.16667V26.3333C0.916016 28.6352 2.78081 30.5 5.08268 30.5H20.916C23.2179 30.5 25.0827 28.6352 25.0827 26.3333V8.91667V8.64433C25.0827 7.53965 24.643 6.47947 23.862 5.69839L19.3843 1.22073C18.6032 0.439652 17.543 0 16.4383 0H16.166H5.08268ZM15.166 2H5.08268C3.88538 2 2.91602 2.96937 2.91602 4.16667V26.3333C2.91602 27.5306 3.88538 28.5 5.08268 28.5H20.916C22.1133 28.5 23.0827 27.5306 23.0827 26.3333V9.91667H17.7493C16.3231 9.91667 15.166 8.75962 15.166 7.33333V2ZM22.9565 7.91667H17.7493C17.4276 7.91667 17.166 7.65505 17.166 7.33333V2.12616C17.4655 2.23312 17.7409 2.40574 17.9701 2.63494L22.4477 7.11261C22.6769 7.34181 22.8496 7.61719 22.9565 7.91667Z"
      />
      <path d="M6.66871 15.8927H7.6003C7.82049 15.8927 8.00116 15.8733 8.14231 15.8345C8.2891 15.7957 8.40485 15.7403 8.48954 15.6683C8.57987 15.5907 8.64198 15.4993 8.67585 15.394C8.70973 15.2888 8.72667 15.1697 8.72667 15.0367C8.72667 14.9037 8.70973 14.7846 8.67585 14.6794C8.64198 14.5741 8.57987 14.4855 8.48954 14.4134C8.40485 14.3359 8.2891 14.2777 8.14231 14.2389C8.00116 14.2001 7.82049 14.1807 7.6003 14.1807H6.66871V15.8927ZM5.5 19.25V13.25H7.7358C8.06327 13.25 8.35968 13.286 8.62504 13.358C8.89605 13.4245 9.12471 13.5325 9.31102 13.6821C9.49734 13.8262 9.64131 14.0118 9.74294 14.2389C9.84457 14.4605 9.89538 14.7265 9.89538 15.0367C9.89538 15.347 9.84457 15.6157 9.74294 15.8428C9.64131 16.0699 9.49734 16.2555 9.31102 16.3996C9.12471 16.5436 8.89605 16.6517 8.62504 16.7237C8.35968 16.7902 8.06327 16.8234 7.7358 16.8234H6.66871V19.25H5.5Z" />
      <path d="M12.0275 18.286H12.8066C13.1397 18.286 13.4136 18.2306 13.6281 18.1198C13.8427 18.009 14.0092 17.8594 14.1278 17.6711C14.252 17.4827 14.3367 17.2666 14.3819 17.0229C14.4327 16.7735 14.4581 16.5159 14.4581 16.25C14.4581 15.9841 14.4327 15.7292 14.3819 15.4855C14.3367 15.2361 14.252 15.0173 14.1278 14.8289C14.0092 14.6406 13.8427 14.491 13.6281 14.3802C13.4136 14.2694 13.1397 14.214 12.8066 14.214H12.0275V18.286ZM10.8588 13.25H12.8829C13.2894 13.25 13.6592 13.3082 13.9923 13.4245C14.3254 13.5353 14.6133 13.7126 14.8561 13.9564C15.0989 14.2001 15.288 14.5104 15.4235 14.8871C15.559 15.2639 15.6268 15.7181 15.6268 16.25C15.6268 16.7819 15.559 17.2361 15.4235 17.6129C15.288 17.9896 15.0989 18.2999 14.8561 18.5436C14.6133 18.7874 14.3254 18.9675 13.9923 19.0838C13.6592 19.1946 13.2894 19.25 12.8829 19.25H10.8588V13.25Z" />
      <path d="M16.6043 19.25V13.25H20.5V14.214H17.773V15.6434H19.814V16.6073H17.773V19.25H16.6043Z" />
    </SvgIcon>
  );
};

export default PDF;

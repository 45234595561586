import { AxiosResponse, AxiosRequestConfig } from "axios";
import http from "../../services/http";
import { Store as AppState } from "../reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { TogglePartResponse } from "../../components/parts/PartsList";
import {
	ConflictingPartErrorResponse,
	addPartPayload$,
	selectedPartIds$,
	currentPayload$
} from "../../components/dialogs/conflictingParts";
import {
	applyParts,
	setConfigurationImages,
	addPrice,
	setImageTimestamp,
	showDialog,
	hideDialog,
	setConfigurationIsDirty,
	noConflictingParts
} from "../actions";
import { DialogTypes } from "../../enums/dialogTypes";
import {} from "react-i18next";
import { AddPartPayload } from "../../services/http/real";
import i18n from "../../i18n";
import { toastMessage } from "../../utils/helpers";
import { getPartById } from "../../utils/partsHelpers";
import Action from "../reducers/models/action";
import store from "../store";

// implement custom error interface to support data types
export interface CustormAxiosError<D> {
	config: AxiosRequestConfig;
	request: any;
	response: AxiosResponse<D>;
}

export default function addPart(
	payload: AddPartPayload
): ThunkAction<Promise<void>, AppState, {}, Action> {
	//return function(dispatch: ThunkDispatch<AppState, undefined, Action>) {
	return function(dispatch: ThunkDispatch<AppState, any, Action>) {
		return http
			.addPart(payload)
			.then(function addPartOnSuccess(
				response: AxiosResponse<TogglePartResponse>
			) {
				
				// submit payload to the conflictingDialog so that it can use it for resending the request after resolving all conflicts
				currentPayload$.next(payload);
				
				if (response.data.RulesResult) { //ruleset is existing the user should dicide how to resolve it
					hideDialog();
					const data = response.data;
					dispatch(
						showDialog({
							contentType: DialogTypes.conflictWhileAddPart,
							data,  
							title: ""
						})
					);
				}
				else if (typeof payload !== "string" && !payload.DryRun){
					dispatch(hideDialog());
					dispatch(applyParts(response.data.PartIds));
					dispatch(setConfigurationImages(response.data.FullImages));
					dispatch(addPrice(response.data.Price));
					dispatch(setConfigurationIsDirty(true));
					const partId = typeof payload === "string" ? payload : payload.PartId;
					if (getPartById(partId).CanBeRendered) {
						// force image reload
						dispatch(setImageTimestamp());
						toastMessage(i18n.t("toasts.partAdded"));
					} else {
						toastMessage(
							`${i18n.t("toasts.partAdded")} (${i18n.t(
								"configuration.parts.notVisualized"
							)})`
						);
					}
					
					let sendPayload:AddPartPayload = {
						PartId : "",
						SelectedParts : [],
						RemoveParts : [],
						DryRun : false
					} 
					store.dispatch(noConflictingParts(sendPayload));
				}
				else if(typeof payload !== "string" && payload.DryRun) {
					//now there is clean configuration that shall be persisted 				 
					let sendPayload:AddPartPayload = {
						PartId : "",
						SelectedParts : [],
						RemoveParts : [],
						DryRun : false
					} 

					sendPayload = payload;
					sendPayload.DryRun = false;
					store.dispatch(noConflictingParts(sendPayload));		
				 		
				}
				// reset streams
				addPartPayload$.next(false);
				selectedPartIds$.next(false);		
			})
			.catch(function handeErrorResponse(
				error: CustormAxiosError<ConflictingPartErrorResponse>
			) {
				console.error(error);
			});
	};
}

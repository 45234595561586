import React, { FormEvent, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { FormManager, createControl, FormControlState, DefaultValidators as Validators } from "../../utils/FormManager";
import http from "../../services/http";
import { AxiosResponse, AxiosError } from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toastMessage, getPreservedSaveDetailsData } from "../../utils/helpers";
import Action from "../../redux/reducers/models/action";
import store from "../../redux/store";
import { preserveSaveDetailsData, setConfigurationWasRecentlyCreated, setConfigurationIsDirty } from "../../redux/actions";
import FadeInLetters from "../shared/FadeInLetters";

export interface SaveDetailsData {
  Vin: string;
  OrderNumber: string;
  Firstname: string;
  Lastname: string;
  Comment: string;
}

export enum SavingState {
  none = "none",
  sending = "sending",
  success = "success",
  error = "error",
}

type SubmitButtonType = "default" | "save-new";

export interface SaveConfigurationProps {
  setConfigurationId: (id: number) => Action;
  configurationWasRecentlyCreated: boolean;
}

const VinControl = createControl("Vin", "");
const OrderNumberControl = createControl("OrderNumber", "");
const FirstNameControl = createControl("Firstname", "", [Validators.notEmpty], true);
const LastNameControl = createControl("Lastname", "", [Validators.notEmpty], true);
const CommentControl = createControl("Comment", "");
const formManager = new FormManager();

export const SaveConfiguration: React.FunctionComponent<SaveConfigurationProps & DialogProps & WithNamespaces> = ({ title, hideDialog, t, setConfigurationId, configurationWasRecentlyCreated }) => {
  const [vin, setVin] = useState<FormControlState>(VinControl);
  const [orderNumber, setOrderNumber] = useState<FormControlState>(OrderNumberControl);
  const [firstName, setFirstName] = useState<FormControlState>(FirstNameControl);
  const [lastName, setLastName] = useState<FormControlState>(LastNameControl);
  const [comment, setComment] = useState<FormControlState>(CommentControl);
  const [submitButtonSavingState, setSubmitButtonSavingState] = useState<SavingState>(SavingState.none);
  const [saveNewButtonSavingState, setSaveNewButtonSavingState] = useState<SavingState>(SavingState.none);
  const [error, setError] = useState<string>("");
  const { Vin: preservedVin, OrderNumber: preservedOrderNumber, Firstname: preservedFirstname, Lastname: preservedLastname, Comment: preservedComment } = getPreservedSaveDetailsData();

  // load form control values from redux store
  useEffect(() => {
    setVin({ ...vin, value: preservedVin });
    setOrderNumber({ ...orderNumber, value: preservedOrderNumber });
    setFirstName({ ...firstName, value: preservedFirstname });
    setLastName({ ...lastName, value: preservedLastname });
    setComment({ ...comment, value: preservedComment });
    // eslint-disable-next-line
  }, []);

  // make sure to sync controls on every render
  formManager.addAllControls([
    [vin, setVin],
    [orderNumber, setOrderNumber],
    [firstName, setFirstName],
    [lastName, setLastName],
    [comment, setComment],
  ]);

  const isFormDirty = () =>
    vin.value !== preservedVin || orderNumber.value !== preservedOrderNumber || firstName.value !== preservedFirstname || lastName.value !== preservedLastname || comment.value !== preservedComment;

  const handleSubmit = (e: FormEvent, saveAsNew: boolean = false) => {
    e.preventDefault();

    if (!formManager.formIsValid) {
      formManager.touchControls();
      return;
    }

    //inform UI and send request
    setButtonSavingState(saveAsNew ? "save-new" : "default", SavingState.sending);

    const values = formManager.getHashValues<Required<SaveDetailsData>>();

    store.dispatch(
      preserveSaveDetailsData({
        ...values,
        Vin: values.Vin || "",
        OrderNumber: values.OrderNumber || "",
      } as SaveDetailsData)
    );

    http
      .saveConfiguration(values, saveAsNew)
      .then((response: AxiosResponse<{ ConfigurationId: number }>) => {
        setButtonSavingState(saveAsNew ? "save-new" : "default", SavingState.success);
        setConfigurationId(response.data.ConfigurationId);
        hideDialog();
        toastMessage(t("toasts.configurationSaved"));
        formManager.reset();
        store.dispatch(setConfigurationWasRecentlyCreated(false));
        store.dispatch(setConfigurationIsDirty(false));
      })
      .catch((error: AxiosError) => {
        // this assumes network error
        if (!error.response) {
          setError(t("errors.networkError"));
        } else {
          // show error message if available
          if (error.response.data && error.response.data.Message) {
            setError(error.response.data.Message);
          } else {
            // log error if nothing else applies
            console.log("Error", error);
          }
        }

        // show the retry button
        setButtonSavingState(saveAsNew ? "save-new" : "default", SavingState.error);
      });
  };

  const setButtonSavingState = (buttonType: SubmitButtonType, state: SavingState) => (buttonType === "save-new" ? setSaveNewButtonSavingState(state) : setSubmitButtonSavingState(state));

  const getButtonContentFromSavingState = (state: SavingState, buttonType: SubmitButtonType) => {
    switch (state) {
      case SavingState.none:
        return buttonType === "save-new" ? t("buttons.save-as-new") : t("buttons.submit");
      case SavingState.sending:
        return (
          <div className="circular-in-button-wrapper">
            <CircularProgress size={25} className="spinner" />
          </div>
        );
      case SavingState.success:
        return t("buttons.success");
      case SavingState.error:
        return t("buttons.retry");
      default:
        return buttonType === "save-new" ? t("buttons.save-as-new") : t("buttons.submit");
    }
  };

  const onDialogClose = () => {
    formManager.reset();
    hideDialog();
  };

  return (
    <Dialog disableBackdropClick={true} fullWidth={true} maxWidth={"sm"} open={true} onClose={onDialogClose} aria-labelledby="configuration-save-modal" className="share-link-wrapper">
      <DialogTitle id="setup-parts-dialog-title">
        <Grid container justify="space-between" direction="row">
          <h2 className="setups-headline underline uppercase">
            <FadeInLetters text={title} />
          </h2>
          <IconButton className="dialog-hide-icon" onClick={onDialogClose}>
            <Clear />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={(e: FormEvent) => handleSubmit(e)} noValidate>
        <div id="configuration-save-field-wrapper">
          {formManager.formIsTouched && !formManager.formIsValid && <p className="error margin-initial">{t("errors.saveConfigurationErrorsMsg")}</p>}
          {submitButtonSavingState === SavingState.error && <p className="error margin-initial">{error}</p>}
          <TextField
            id="vin"
            label={t("dialogs.saveConfiguration.vin")}
            fullWidth={true}
            value={vin.value}
            onChange={({ target: { value } }) => setVin({ ...vin, value, pristine: false })}
            margin="normal"
            autoFocus
          />
          <TextField
            id="order-number"
            label={t("dialogs.saveConfiguration.orderNumber")}
            value={orderNumber.value}
            fullWidth={true}
            onChange={({ target: { value } }) => setOrderNumber({ ...orderNumber, value, pristine: false })}
            margin="normal"
          />
          <TextField
            id="name"
            label={t("dialogs.saveConfiguration.name")}
            value={firstName.value}
            fullWidth={true}
            onChange={({ target: { value } }) => setFirstName({ ...firstName, value, pristine: false })}
            margin="normal"
            required={true}
            error={!firstName.pristine && !formManager.controlIsValid(FirstNameControl)}
            helperText={t("errors.required")}
          />
          <TextField
            id="surname"
            label={t("dialogs.saveConfiguration.surname")}
            value={lastName.value}
            fullWidth={true}
            onChange={({ target: { value } }) => setLastName({ ...lastName, value, pristine: false })}
            margin="normal"
            required={true}
            error={!lastName.pristine && !formManager.controlIsValid(LastNameControl)}
            helperText={t("errors.required")}
          />
          <TextField
            id="comment"
            label={t("dialogs.saveConfiguration.comment")}
            value={comment.value}
            fullWidth={true}
            onChange={({ target: { value } }) => setComment({ ...comment, value, pristine: false })}
            margin="normal"
            multiline
          />
        </div>
        <DialogContent></DialogContent>
        <DialogActions>
          {!configurationWasRecentlyCreated && (
            <button
              id="configuration-save"
              disabled={[SavingState.success, SavingState.sending].includes(saveNewButtonSavingState) || !isFormDirty()}
              onClick={e => handleSubmit(e, true)}
              type="button"
              className="secondary"
            >
              {getButtonContentFromSavingState(saveNewButtonSavingState, "save-new")}
            </button>
          )}
          <button id="configuration-save" disabled={[SavingState.success, SavingState.sending].includes(submitButtonSavingState)} className="primary" type="submit">
            {getButtonContentFromSavingState(submitButtonSavingState, "default")}
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withNamespaces()(SaveConfiguration);

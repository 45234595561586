import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Mountain: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <title>Mountains Icon</title>
      <path
        className="stroke"
        d="M22 21L15.3833 8.53125C15.2099 8.20436 14.8701 8 14.5 8C14.1299 8 13.7901 8.20436 13.6167 8.53125L7 21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="stroke" d="M18.0812 13.6133L16.291 14.6133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M14.5 13.6133L16.2902 14.6133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M12.709 14.6133L14.4991 13.6133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M10.9199 13.6133L12.7101 14.6133" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M23 21H1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        className="stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43506 4.5188C9.35978 4.61493 10.0468 5.41905 9.99748 6.34744C9.94813 7.27583 9.1797 8.00257 8.25 8.00012H4.5C3.67157 8.00012 3 7.32855 3 6.50012C3 5.6717 3.67157 5.00012 4.5 5.00012C4.49826 3.9899 5.25139 3.13757 6.25414 3.01491C7.2569 2.89226 8.19325 3.53794 8.43506 4.5188Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.95966 15.4228L8.378 12.5214C8.20283 12.2 7.86604 12 7.5 12C7.13396 12 6.79717 12.2 6.622 12.5214L2 21.0001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Mountain;

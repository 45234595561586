import React, { useRef, useState, useEffect } from "react";
import VehicleOverview, { VehicleOverviewProps } from "../vehicle-overview/VehicleOverview";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { RouteComponentProps } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import Categories, { CategoryListAllProps } from "../categories/CategoryList";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Location } from "history";
import ConfigurationSummary from "./configurationSummary/ConfigurationSummary";
import http from "../../services/http";
import { AxiosResponse } from "axios";
import { store } from "../../redux/store";
import getOverview from "../../redux/async-actions/getOverview";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { StaticContext, useHistory } from "react-router";
import FinancePlugin from "./financePlugin/financePlugin";
import ArrowHead from "../../assets/icons/arrowHead";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuIcon from "../../assets/icons/menu";
import CloseIcon from "../../assets/icons/close";
import { withStyles } from "@material-ui/core/styles";
import { noConflictingParts, removeNoConflictingParts } from "../../redux/actions";
import { AddPartPayload, RemovePartPayload } from "../../services/http/real";
import { ConfigurationView3D } from "./ConfigurationView3D";
import { ConfigurationOverviewMenu } from "./ConfigurationOverviewMenu";
import BrandLogo from "../shared/BrandLogo";
import { Hero } from "../../models/vehicle";
import PartDetailDialog from "../parts/PartDetailDialog";
import { getIsMobileXS } from "../../utils/helpers";
import ConfigurationCompletion from "./configurationSummary/ConfigurationCompletion";
import CDCLoginContainer from "./cdc/CdcLoginContainer";

export interface CreateConfigurationResponse {
  ConfigurationId: number;
  FullImages: FullImage[];
  TestRideData: TestRideData;
  VariationCode: string;
}

export interface FullImage {
  Perspective: number;
  Url: string;
}

export interface TestRideData {
  Data: TestRideLink;
  Status: TestRideStatus;
}

export interface TestRideLink {
  Url: string;
}

export interface TestRideStatus {
  Success: boolean;
  Messages: [any];
}

export type ConfigurationAllProps = StateManagementProps & CreateConfigurationResponse & WithNamespaces & RouteComponentProps<{ modelId: string }, StaticContext, { hero: Hero }>;

const StyledMenu = withStyles({
  paper: {},
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    transitionDuration={0} //TODO: find better solution
    {...props}
  />
));

export const Configuration: React.FunctionComponent<ConfigurationAllProps & CategoryListAllProps> = (props) => {
  const {
    t,
    configuration,
    configurationState,
    addCreatedConfiguration,
    views,
    applicationSettings,
    hideGuidance,
    togglePartDetail,
    deselectPart,
    setDealerInquirySent,
    dealerInquirySent,
    is3DMode,
    setIs3DMode,
  } = props;

  const history = useHistory();

  const [showCategories, setShowCategories] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showSummary, setShowSummary] = useState(false);
  const [showPartDetail, setShowPartDetail] = useState(false);
  const [bookATestRideUrl, setBookATestRideUrl] = useState("");
  const scrollElement = useRef<HTMLDivElement>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [portraitModeState, setPortraitMode] = useState(false);
  const [showAllPrices, setShowAllPrices] = useState(false);
  const [createConfigurationState, setCreateConfigurationState] = useState({
    exists: configurationState.configuration.hasOwnProperty("ConfigurationId"),
    loading: false,
  });
  const [getOverViewState, setGetOverViewState] = useState({
    exists: props.overview.Vehicle.Hero.Description.length > 0,
    loading: false,
  });
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [financeAvailable, setFinanceAvailable] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [userInactive, setUserInactive] = useState(false);
  const [jtsPushed, setJtsPushed] = useState(false);

  // keep the next line for setups
  // const setuplistProps = createSetupProps(props);
  const bookATestride = configuration.TestRideData;
  const hasTestRideData = () => (bookATestride && bookATestride.Data ? true : false);
  const [isMobile, setIsMobile] = useState(() => getIsMobileXS());
  let portraitMode = Boolean(window.orientation === 0); //TODO: valentins solution

  const setIsMobileState = () => {
    setIsMobile(getIsMobileXS());
  };

  let { modelId } = props.match.params;

  let pathName = props.location.pathname;
  let pathParts = pathName.split("/");
  const urlModelId = pathParts[3];
  const scrollToRef = React.createRef<HTMLDivElement>();
  const scrollToFinance = React.createRef<HTMLDivElement>();

  const updateBATLink = (newVehicleCode?: string | undefined) => {
    if (newVehicleCode) {
      let urlArray = bookATestRideUrl.split("/");
      let oldVehicleCode = urlArray[urlArray.length - 1];
      urlArray.forEach(function (item, i) {
        if (item === oldVehicleCode) urlArray[i] = newVehicleCode;
      });
      let newUrl = urlArray.join("/");
      setBookATestRideUrl(newUrl);
    }
  };

  if (modelId === urlModelId) {
    modelId = urlModelId;
  }

  const checkFinancePlugin = () => {
    let financeArray = props.overview.Finance.FinanceData;
    if (financeArray && financeArray[0] && financeArray[0].Key !== "") {
      for (let i = 0; i < financeArray.length; i++) {
        if (financeArray[i].Available && financeArray[i].Variation === props.currentVariation) {
          setFinanceAvailable(true);
        }
      }
    }
  };

  const setUserInactiveFunction = (inactivity: boolean) => {
    setUserInactive(inactivity);
  };

  const setSubMenuState = (open: boolean) => {
    setSubMenuOpen(open);
  };
  const resetSubMenuState = () => {
    setSubMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    hideGuidance();
    handleResize();

    setIs3DMode(props.overview && props.overview.Vehicle.RenderingEngine === "Engine3D" && !is3DMode);

    return () => {
      setGetOverViewState({
        exists: props.overview.Vehicle.Hero.Description.length > 0,
        loading: false,
      });
      setCreateConfigurationState({
        exists: configurationState.configuration.hasOwnProperty("ConfigurationId"),
        loading: false,
      });
      setRedirectToMain(false);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setPortraitMode(window.innerHeight > window.innerWidth);
    setIsMobileState();
  };

  useEffect(() => {
    if (!jtsPushed && (window as any)._jts) {
      const jts = (window as any)._jts;
      jts.push({ track: "pageview" });
      jts.push({ track: "submit" });
      setJtsPushed(true);
    }
  });

  useEffect(() => {
    checkFinancePlugin();
  }, [props.overview.Finance, props.currentVariation]);

  useEffect(() => {
    if (redirectToMain) {
      history.push("/main");
    }
  }, [redirectToMain]);

  useEffect(() => {
    if (props.location.state) {
      setShowAllPrices(Boolean(props.location.state.hero.Price.Value));
    }
  }, [isMobile, props.location.state, portraitModeState]);

  useEffect(() => {
    if (configurationState && configurationState.culture && configurationState.market && configurationState.companyId) {
      if (!getOverViewState.exists && !getOverViewState.loading) {
        setGetOverViewState({ ...getOverViewState, loading: true });
        store.dispatch(getOverview(modelId) as any).then(() => {
          // Quick fix too check if loading is done, it should be done in redux
          setGetOverViewState({ ...getOverViewState, loading: false });
        });
      } else if (props.overview.Vehicle.Hero.Description.length > 0) {
        setGetOverViewState({ ...getOverViewState, loading: false, exists: true });
      }
    }
    if (props.selectedModel.Hero.Price) {
      setShowAllPrices(Boolean(props.selectedModel.Hero.Price.Value));
    }
  }, [configurationState, props.overview.Vehicle]);

  useEffect(() => {
    if (!createConfigurationState.loading && !createConfigurationState.exists && configurationState && configurationState.culture && configurationState.market && configurationState.companyId) {
      setCreateConfigurationState({ ...createConfigurationState, loading: true });
      http
        .createConfiguration(modelId)
        .then((response: AxiosResponse<CreateConfigurationResponse>) => {
          addCreatedConfiguration(response.data);
          setCreateConfigurationState({ ...createConfigurationState, loading: false, exists: true });
        })
        .catch((reason: any) => {
          console.log("no config", reason);
          setRedirectToMain(true);
        });
    }
  }, [configurationState, createConfigurationState]);

  if (hasTestRideData() && !dataLoaded && configuration) {
    setBookATestRideUrl(bookATestride.Data.Url);
    setSuccessState(bookATestride.Status.Success);
    setDataLoaded(true);
  }

  if (getOverViewState.loading || createConfigurationState.loading) {
    return <CircularProgress className="spinner" />;
  }

  if (!getOverViewState.loading && !getOverViewState.exists && !hasTestRideData()) {
    return <span id="no-models"></span>;
  }

  function handleClick() {
    setOpen(!open);
    setShowCategories(!showCategories);
  }

  function resetConflictingPartsStatus() {
    let sendPayload: AddPartPayload = {
      PartId: "",
      SelectedParts: [],
      RemoveParts: [],
      DryRun: false,
    };
    store.dispatch(noConflictingParts(sendPayload));
    let sendRemovePayload: RemovePartPayload = {
      PartId: "",
      RemoveParts: [],
      DryRun: false,
    };
    store.dispatch(removeNoConflictingParts(sendRemovePayload));
  }

  function handleSummaryClick() {
    resetConflictingPartsStatus();
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setOpen(false);
    setShowCategories(false);
    props.toggleSummary(true);
    setShowSummary(true);
  }

  const getMobileMenuClosed = () => {
    setAnchorEl(null);
  };

  function handleClose() {
    getMobileMenuClosed();
    resetSubMenuState();
  }

  function handleClickMobileMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function isRenderingEngine3D(): boolean {
    return props.overview.Vehicle.RenderingEngine === "Engine3D";
  }

  if (isRenderingEngine3D()) {
    return (
      <>
        <ConfigurationView3D
          {...props}
          isPortraitMode={portraitModeState}
          showDetails={() => setShowPartDetail(true)}
          itemClicked={() => setAnchorEl(null)}
          userInactive={userInactive}
          setUserInactiveFunction={setUserInactiveFunction}
          variationCode={configuration.VariationCode}
        ></ConfigurationView3D>
        {showPartDetail && (
          <PartDetailDialog
            closeDialog={() => {
              togglePartDetail(false);
              deselectPart();
              setShowPartDetail(false);
              document.body.classList.remove("lock-scroll");
            }}
            {...props}
            userInactive={userInactive}
          ></PartDetailDialog>
        )}
        {<CDCLoginContainer {...props} />}
      </>
    );
  }

  function closeSummary() {
    setShowSummary(false);
    setDealerInquirySent(false);
  }

  function createSummary(props: ConfigurationAllProps, testRideUrl: string, testRideStatus: boolean, showAllPrices: boolean): React.ReactNode {
    if (dealerInquirySent) {
      return <ConfigurationCompletion {...props} updatesetShowSummary={closeSummary}></ConfigurationCompletion>;
    }
    return <ConfigurationSummary {...props} testRideUrl={testRideUrl} testRideStatus={testRideStatus} showAllPrices={showAllPrices} updatesetShowSummary={closeSummary} />;
  }

  function createVehicleOverview(
    props: ConfigurationAllProps,
    location: Location<any>,
    showDescription: boolean,
    configurationExists: boolean,
    showAllPrices: boolean,
    variationCode: string,
    updateBATLink: () => void,
    financeAvailable: boolean
  ): React.ReactNode {
    // make sure not to render anything before configuration becomes available
    if (!configurationExists) {
      return null;
    }

    let vehicleOverviewProps: VehicleOverviewProps = { location, showDescription, variationCode, updateBATLink, financeAvailable };

    return <VehicleOverview {...vehicleOverviewProps} {...props} showAllPrices={showAllPrices}></VehicleOverview>;
  }

  return (
    <Grid container className={`full-height ${financeAvailable ? "finance-available" : "finance-hidden"} ${showCategories ? "show-cat" : ""}`} id="config-view-wrapper">
      <div className="full-width configuration-view-wrapper" ref={scrollToRef}>
        <Grid item className="menuWrapper">
          {!showSummary && <ConfigurationOverviewMenu {...props} handleSummaryClick={handleSummaryClick} />}

          {showSummary && <div />}

          {!showSummary && (isMobile || portraitMode) && (
            <div className="mobile-configuration-menu">
              <div>
                <Button aria-controls="customized-menu" aria-haspopup="true" onClick={handleClickMobileMenu} className="mobile-menu-button category-button gtm-categories-mobile">
                  <MenuIcon />
                </Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && !store.getState().views.showPartDetail}
                  onClose={handleClose}
                  className={subMenuOpen ? "subMenuOpen" : ""}
                >
                  <Button aria-controls="customized-menu" aria-haspopup="false" onClick={handleClose} className="mobile-menu-close-button">
                    <CloseIcon />
                  </Button>
                  <Categories {...props} showDetails={() => setShowPartDetail(true)} setSubMenuState={setSubMenuState} resetSubMenuState={resetSubMenuState} />
                </StyledMenu>
              </div>
            </div>
          )}

          <BrandLogo {...props} customClass="brandlogo-in-main-header" />
        </Grid>
      </div>

      <Grid item id="bike-config-main" container className={`${views.showConfigurationSummary ? "d-none" : ""}${showCategories ? "transform-content" : ""}`}>
        {/* Left: Bike overview: Image & Description*/}
        <Grid item xs={9} className={`configuration-vehicle-infos-wrapper ${showCategories ? "transform-content" : ""} `}>
          {createVehicleOverview(props, props.location, !showCategories, createConfigurationState.exists, showAllPrices, configuration.VariationCode, updateBATLink, financeAvailable)}
        </Grid>

        {/* Right: Category List */}
        {!isMobile && !portraitMode && (
          <Grid item xs={3} className={!showCategories ? "fit-screen collapsed category-list-wrapper d-none-mobile" : "fit-screen category-list-wrapper d-none-mobile"}>
            <List component="nav" aria-labelledby="nested-list-subheader" className="gtm-categories-button-nav">
              <div className="category-button-wrapper gtm-categories-button-wrapper" key="categ">
                <ListItem component="button" onClick={handleClick} className={`primary category-button gtm-categories-button ${open ? "show" : "hide"}`}>
                  {t(`buttons.categories.${applicationSettings.Brand}`)}
                  <ArrowHead />
                </ListItem>
                <div className={`category-line ${open ? "hide" : "show"}`}> </div>
              </div>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div ref={scrollElement} id="categories-scrollable-wrapper" className="overflow-auto full-height">
                  <Categories {...props} showDetails={() => setShowPartDetail(true)} itemClicked={() => setAnchorEl(null)} resetSubMenuState={resetSubMenuState} />
                </div>
              </Collapse>
            </List>

            {!showCategories && <p className="configure-text">{t(`configuration.summary.configureText.${applicationSettings.Brand}`)}</p>}

            <div className={`mt20 conf-summary-wrapper gtm-summary-button-wrapper ${open ? "showcat" : "hidecat"}`}>
              <button className="primary configuration-summary-button gtm-summary-button" onClick={handleSummaryClick}>
                {t(`buttons.bikeSummary.${applicationSettings.Brand}`)} <ArrowHead />
              </button>
            </div>
          </Grid>
        )}
      </Grid>

      {showSummary && views.showConfigurationSummary && (
        <div className={`full-width full-height show-configuration-summary ${successState ? "has-testride" : ""}`}>{createSummary(props, bookATestRideUrl, successState, showAllPrices)}</div>
      )}

      <div className="scroll-ref-finance" ref={scrollToFinance}></div>
      {financeAvailable && <FinancePlugin {...props} />}
      {<CDCLoginContainer {...props} />}
    </Grid>
  );
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Configuration));

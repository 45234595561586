import React, { useState, useEffect } from 'react';
import { VehicleImageAllprops } from './VehicleImage';
import { CircularProgress } from '@material-ui/core';

export interface VehicleRendererProps {
    url: string;
    brand: string;
}

export interface VehicleRendererState {
    loading: boolean;
    imagesToLoad: number;
}

export interface ImageProps {
    src: string,
    class: string,
    id: string
}

export type VehicleRendererAllProps = VehicleRendererProps & VehicleImageAllprops;

interface BikeStructureRequest {
    id: number;
    CompanyCode: string,
    Layout: BikePartImage[];
}

interface BikePartImage {
    Color: any;
    FileName: string;
    Id: number;
    IsStandardPart: boolean;
    LayerSide: number;
    Perspective: number;
    VehicleProductCode: any;
    Zindex: number;
}

export const VehicleRenderer: React.FunctionComponent<VehicleRendererAllProps> = (props: VehicleRendererAllProps) => {

    let imageArray: ImageProps[] = [];
    let dummy: BikeStructureRequest = {id: 0, CompanyCode: '', Layout: [] };
    let [previousRequest, setPreviousRequest] = useState<BikeStructureRequest>(dummy);
    const [renderedImages, setRenderedImages] = useState<ImageProps[]>(props.imageArray.currentImageArray);
    const [imagesRendered, setImagesRendered] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [imageLoadingState, setImageLoadingState] = useState<VehicleRendererState>({
        loading: false,
        imagesToLoad: 0
    });

    const [oldPerspective, setOldPerspective] = useState<number>(-1);

    const getBrand = () => {
        return props.brand;
    }

    const getImageSrc = () => {
        return props.url;
    }

    const renderFrontEnd = (isNewBikePerspective: boolean) => {
        if (!getImageSrc()) {
            return;
        }
        let url = getImageSrc();

        let bikeStructureUrl = url.replace("images", "images-fe");

        setHasError(false);

        if (!bikeStructureUrl) {
            return;
        }

        fetch(bikeStructureUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    let request = result;
                    request.CompanyCode = getBrand();
                    renderBike(request, isNewBikePerspective);
                },
                (error) => {
                    setHasError(true);
                }
            )
    }

    const renderBike = (request: BikeStructureRequest, isNewBikePerspective: boolean) => {
        // TODO: until we dont have GG images need to use KTM as image provider , todo remove later
        if (request.CompanyCode === "GG") {
            if (window.location.href.indexOf('localhost')>0)
                request.CompanyCode = "KTM";
        }
        setImagesRendered(false);

        let newParts: BikePartImage[] = [];
        if (request && previousRequest && request.Layout) {
            request.Layout.forEach(img => {
                let found = previousRequest.Layout.find(p => p.FileName === img.FileName);
                if (!found) {
                    newParts.push(img);
                }
            }); 
        } 

        if (request && request.Layout) {
            setIsLoading(isNewBikePerspective, (request.Layout.length - 1));
         
            request.Layout.forEach((img) => {
                let useEffect = newParts.find(p => p.FileName === img.FileName) === undefined;
                addImageToLayer(request, img, useEffect);
            });

            setPreviousRequest ( request ); 
        }

        setImagesRendered(true);
    }

    const setIsLoading = (loading: boolean, imagesToLoad: number) => {
        setImageLoadingState({ loading, imagesToLoad });
    }

    const onImageLoaded = () => {
        let imagesToLoad = imageLoadingState.imagesToLoad - 1;
        setIsLoading(imageLoadingState.loading, imagesToLoad);
        if (imageLoadingState.imagesToLoad <= 0) {
            setIsLoading(false, imageLoadingState.imagesToLoad);
        }
    }

    const addImageToLayer = (request: any, img: BikePartImage, useEffect: boolean) => {
        
        let host: string = request.BaseUrl;
        let imgSrc: string = `${host}/api/getblobimage?companyCode=${request.CompanyCode}&bikeSetupId=${img.VehicleProductCode.Value}&imagePath=${img.FileName}`;

        let currentImage: ImageProps = {
            src: imgSrc,
            class: useEffect ? "bike-part" : "fade-in bike-part",
            id: img.FileName
        }

        const existing = imageArray.filter(({ src: source }) => currentImage.src === source);
        if (existing.length === 0) {
            imageArray.push(currentImage);
        }

        props.setImageArray({ currentImageArray: imageArray });
        setRenderedImages(imageArray);
    }

    const deleteImages = () => {
        imageArray = [];
        setImagesRendered(false);
        setRenderedImages([]);
    }

    const loader =
        <span className="turnable-image loader-wrapper">
            <CircularProgress />
            <span className="loading-text" >{props.t('vehicleImage.loaderText')}</span>
        </span>

    const errorDiv = <div>
        <div className="error-div">Oops! Something went wrong while loading the image...</div>
    </div>

    useEffect(() => {
        if (props === undefined || props.url === undefined ||  props.url.indexOf("undefined") >= 0) {
            return; // do nothing when parent is not setting yet the url 
        }
        //on change of perspective delete first all images 
        if (oldPerspective !== props.perspective) {
            deleteImages();
        }
        setOldPerspective(props.perspective); 
        renderFrontEnd(true);
    }, [props.url /*, props.perspective*/]);

    return (
        <div id="render-container" className={`perspective-${props.perspective}`}>
            <div id="render-div">

                <div id="vehicle-background"></div>

                {imagesRendered &&
                    <>
                        {renderedImages.map((image, index) => (
                            <img id={image.id} key={`rendered-image-${index}`} src={image.src} onLoad={() => onImageLoaded()} onError={() => setHasError(true)} className={image.class} />
                        ))}
                    </>
                }
            </div>
            {hasError && !imageLoadingState.loading && errorDiv}
            {imageLoadingState.loading && loader}
            <div id="render-div-hidden"></div>
            <div id="output-parent">
                <div id="output">
                    Rendered Img
                    </div>
            </div>
            <img id="screenshot" />
        </div>
    );
}
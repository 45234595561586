import React, { useState, useEffect } from 'react';

type DotType = 1 | 2 | 3 | 4 | 5;

export const DotsLoader: React.FunctionComponent<{}> = () => {
    const [value, setValue] = useState<DotType>(1);
    useEffect(() => {
        const timer = setInterval(() => {
            setValue((value) => value === 5 ? 1 : ++value as DotType);
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    const style: React.CSSProperties = {
        display: 'inline-block',
        width: '20px',
        textAlign: 'left',
    };

    return (<span style={style}>
        {new Array(value).fill('item').map((item, idx) => <span key={idx}>.</span>)}
    </span>)
}

export default DotsLoader;
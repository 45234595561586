import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import {
  mapStateToProps,
  mapDispatchToProps,
  StateManagementProps,
} from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import Swipeable from "react-swipeable";
import ArrowHead from "../../../assets/icons/arrowHead";
import store from "../../../redux/store";

import {
  exitVehicleConfiguration,
  setImageArray,
} from "../../../redux/actions";
import { Hero, SegmentData, VehicleInformation } from "../../../models/vehicle";
import Indicator3d from "../../../assets/icons/3d/indicator3d";

export interface ModelsProps {
  data: SegmentData;
}

const classes = _ => ({
  modelLink: {
    fontSize: "14px",
    lineHeight: "22px",
  },
  modelList: {
    margin: "0 0 0 0",
  },
  spacer: {
    marginTop: "0px",
    marginBottom: "24px",
  },
  selectionWrapper: {
    marginTop: "0",
    overflowY: "auto" as "auto",
  },
  modelSelectionWrapper: {
    height: "calc(100% - 75px)",
  },
  VehicleInformation: {
    backgroundRepeat: "no-repeat",
  },
  bikePreview: {
    backgroundPosition: "right top",
    backgroundSize: "auto 93%",
  },
  heroIntro: {
    fontSize: "1.25em",
    marginBottom: "13px",
  },
  heroPrice: {
    fontSize: "1.25em",
  },
  heroDescription: {
    fontSize: "1em",
  },
});

export type AllModelProps = StateManagementProps &
  ModelsProps &
  WithNamespaces &
  WithStyles;

export const Models: React.FunctionComponent<AllModelProps> = props => {
  const { data, t, classes, applicationSettings, toggleSummary, appliedParts } = props;

  const { Hero }: SegmentData = data;

  const [hero, setHero] = useState(Hero);
  const [currentPosition, setCurrentPositon] = useState(0);
  const [selectedModel, setSelectedModel] = useState<VehicleInformation>({
    Name: "",
    ModelId: "",
    EngineType: null,
    Hero: hero,
    RenderingEngine: "",
  });
  const [hideBikeImg, setHideBikeImg] = useState(false);

  let maxPosition = 0;

  const selectHero = (Hero: Hero) => {
    setHero(Hero);
  };

  // update state on every change of props.data
  useEffect(() => {
    let segmentChanged = true;

    let sortedVehicles = Vehicles.sort(sortModels);

    for (let i = 0; sortedVehicles.length <= i; i++) {
      if (sortedVehicles[i].ModelId === selectedModel.ModelId) {
        segmentChanged = false;
      }
    }
    if (selectedModel.ModelId === "" || segmentChanged) {
      selectModel(null);
    }
    setHero(data.Hero);
    setCurrentPositon(0);
  }, [data]);

  const extract = (
    str: string
  ): { engineSize: number; name: string; year: number } => {
    let spacedModelName = str.replace(/\s/g, " ");
    spacedModelName = spacedModelName.replace(/\|/g, "");

    // if detection pattern fails,
    // provide default to let model sink to the bottom of list
    if (spacedModelName.split(" ").length < 3) {
      return {
        engineSize: 0,
        name: "",
        year: 0,
      };
    }

    let spacedArray = spacedModelName.split(" ");
    let filteredArray = spacedArray.filter(entry => {
      return entry !== "";
    });

    let year = 0;
    let engineSize = 0;

    const remainingName: string[] = [];

    filteredArray.forEach(x => {
      let maybeNumber = Number(x.replace(/[^0-9]/g, ''));
      
      if (!isNaN(maybeNumber) && maybeNumber > 0) {
        if (maybeNumber > 2000) { // let's hope that all our bikes stay below 2000cc
          year = maybeNumber;
        } else {
          engineSize = maybeNumber;
        }
      } else {
        if (x !== undefined)
          remainingName.push(x);
      }
    });

    let name = remainingName.join(' ');

    return {
      engineSize: +engineSize,
      name: name,
      year: +year,
    };
  };

  // sort 1. by year then 2. by engine size then 3. by name
  const sortModels = (prev: VehicleInformation, next: VehicleInformation) => {
    const [extractedPrevious, extractedNext] = [
      extract(prev.Name),
      extract(next.Name),
    ];
    if (
      applicationSettings.Brand === "KTM" ||
      applicationSettings.Brand === "GG"
    ) {
      return (
        -(extractedPrevious.year - extractedNext.year) ||
        -(extractedPrevious.engineSize - extractedNext.engineSize) ||
        -(extractedPrevious.name.split(' ').length - extractedNext.name.split(' ').length)
      );
    } else {
      return (
        -(extractedPrevious.year - extractedNext.year) ||
        -(extractedNext.engineSize - extractedPrevious.engineSize) ||
        -(extractedPrevious.name.split(' ').length - extractedNext.name.split(' ').length)
      );
    }
  };

  const selectModel = (v: VehicleInformation | null) => {
    if (v) {
      setSelectedModel(v);
      selectHero(v.Hero);
    } else {
      let sortedVehicles = Vehicles.sort(sortModels);
      setSelectedModel(sortedVehicles[0]);
      selectHero(sortedVehicles[0].Hero);
    }
  };

  const { Vehicles } = data;
  const {
    spacer,
    selectionWrapper,
    modelSelectionWrapper,
    heroIntro,
    modelList,
  } = classes;

  const engineTypes: Array<string | null> = Vehicles.reduce(
    (acc: Array<string | null>, vehicle: VehicleInformation) => {
      return acc.includes(vehicle.EngineType)
        ? [...acc]
        : [...acc, vehicle.EngineType];
    },
    []
  );

  const orangeUpperText = "uppercase";

  const handleSwipe = (position: number) => {
    let newPosition: number = currentPosition + position;
    const max = maxPosition - 1;
    const min = 0;
    if (newPosition < min) {
      newPosition = max;
    }
    if (newPosition > max) {
      newPosition = min;
    }
    setCurrentPositon(newPosition);
  };

  const resetConfiguration = () => {
    appliedParts.length = 0; //reseting the applied parts array to prevent XBow colors in powerparts list of other vehicles
    store.dispatch(exitVehicleConfiguration());
    store.dispatch(setImageArray({ currentImageArray: [] }));
    toggleSummary(false);
    setHideBikeImg(true);
  };

  return (
    <Grid
      className={`${modelSelectionWrapper} model-selection-container main-container`}
      container
      spacing={0}
    >
      {/* MODEL SELECTION PART */}
      <Grid
        id="model-selection-choice"
        className={`full-height d-none-mobile ${selectionWrapper}`}
        item
        xs={3}
      >
        <Grid container className="model-selection-choice-container">
          {engineTypes.map((version: string | null, index: number) => {
            const filtered = Vehicles.filter(
              (item: VehicleInformation) => item.EngineType === version
            ).sort(sortModels);
            if (!filtered.length) {
              return null;
            }
            return (
              <Grid
                item
                key={`by-tact-${index}`}
                className="model-selection-choice-container"
              >
                <ul className={modelList}>
                  {filtered.map((item: VehicleInformation, index: number) => {
                    const itemClass = item.Name.replace(/[^a-zA-Z0-9]/g, "-")
                      .replace(/---/g, "-")
                      .toLowerCase();
                    return (
                      <li key={index}>
                        <p
                          onClick={() => selectModel(item)}
                          className={`ktm-headline ${
                            applicationSettings.Brand === "KTM"
                              ? "uppercase"
                              : ""
                          } motorcycle-selection ${
                            item.ModelId === selectedModel.ModelId
                              ? "selected"
                              : ""
                          } inline-block ${spacer}`}
                        >
                          <span className={`model-title model-${itemClass}`}>
                            {item.Name}&nbsp;
                            {item.RenderingEngine === "Engine3D" && (
                              <Indicator3d {...props} useDefault={false} />
                            )}
                          </span>
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {/* PREVIEW PART */}
      <Grid id="model-selection-preview" className="d-none-mobile" item xs={9}>
        <Grid container spacing={0}>
          <Grid item xs={8} className="model-image-container">
            <div
              className={
                applicationSettings.Brand === "GG"
                  ? "background-title"
                  : "background-glow"
              }
            >
              {applicationSettings.Brand === "GG" && (
                <span className="background-name model-name">
                  {selectedModel.Name.substring(
                    0,
                    selectedModel.Name.length - 4
                  )}
                </span>
              )}
            </div>
            <img
              className={hideBikeImg ? "d-none" : ""}
              src={selectedModel.Hero.ImageUrl}
              alt={selectedModel.Name}
              title={selectedModel.Name}
            />
          </Grid>
          <Grid item xs={4} className="model-description-container">
            <h5
              className={`${heroIntro} ${
                applicationSettings.Brand === "HQV" ? "m-b" : ""
              }`}
            >
              {selectedModel.Hero.Title}
            </h5>
            <h1 className={applicationSettings.Brand === "HQV" ? "m-b" : ""}>
              {selectedModel.Name}
            </h1>
            <p
              className={`model-selection-desc ${
                applicationSettings.Brand === "HQV" ? "m-b" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: selectedModel.Hero.Description,
              }}
            ></p>
            <Link
              onClick={() => resetConfiguration()}
              to={{
                pathname: `/main/configuration/${selectedModel.ModelId}/categories`,
                state: { hero: selectedModel.Hero },
              }}
              className={`${
                applicationSettings.Brand === "HQV" ? "secondary" : "primary"
              } models-configure-button ${orangeUpperText}`}
            >
              <h3 className="models-configure-button">
                {t("buttons.configure")}
                {applicationSettings.Brand !== "HQV" && <ArrowHead />}
              </h3>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="mobile-model-view show-mobile">
        <Swipeable
          className="full-width turnable-image-wrapper flex-container centered"
          trackMouse={true}
          preventDefaultTouchmoveEvent={true}
          onSwipedLeft={() => handleSwipe(1)}
          onSwipedRight={() => handleSwipe(-1)}
        >
          {engineTypes.map((version: string | null, index: number) => {
            const filtered = Vehicles.filter(
              (item: VehicleInformation) => item.EngineType === version
            ).sort(sortModels);
            maxPosition = filtered.length;
            if (!filtered.length) {
              return null;
            }

            return (
              <Grid
                className="mobile-info-container"
                item
                key={`by-tact-mobile-${index}`}
              >
                <div className={`${modelList} mobile-info-wrapper`}>
                  {filtered.map((item: VehicleInformation, index: number) => {
                    if (index === currentPosition) {
                      return (
                        <div
                          key={`by-mobile-${index}`}
                          className={`motorcycle-selectionclickable inline-block ${spacer}`}
                        >
                          <div className="mobile-model-image-wrapper">
                            <div className="background-glow"></div>
                            <img
                              src={item.Hero.ImageUrl}
                              width="100px"
                              className={`pic-${index} ${
                                hideBikeImg ? "d-none" : ""
                              }`}
                              alt={item.Name}
                              title={item.Name}
                            />
                          </div>
                          <div className="mobile-model-description-wrapper">
                            <div>
                              <h5 className={heroIntro}>{item.Hero.Title}</h5>
                              <h1
                                className={`${
                                  applicationSettings.Brand === "HQV"
                                    ? "highlighted-hqv"
                                    : ""
                                }`}
                              >
                                <span>
                                  {item.Name}
                                  {item.RenderingEngine === "Engine3D" && (
                                    <>&nbsp;<Indicator3d
                                      {...props}
                                      useDefault={false}
                                    /></>
                                  )}
                                </span>
                              </h1>
                            </div>
                          </div>
                          <div className="mobile-config-link">
                            <Link
                              onClick={() => resetConfiguration()}
                              to={{
                                pathname: `/main/configuration/${item.ModelId}/categories`,
                                state: { hero: item.Hero },
                              }}
                              className={` models-configure-button ${orangeUpperText} ${
                                applicationSettings.Brand === "HQV"
                                  ? "secondary"
                                  : "primary"
                              }`}
                            >
                              <h3 className="models-configure-button">
                                {t("buttons.configure")}
                                {applicationSettings.Brand !== "HQV" && (
                                  <ArrowHead />
                                )}
                              </h3>
                            </Link>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Grid>
            );
          })}
        </Swipeable>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(withNamespaces()(Models)));

import React, { FormEvent, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { withNamespaces, WithNamespaces } from "react-i18next";
import http from "../../services/http";
import TextField from "@material-ui/core/TextField";
import {
	FormManager,
	FormControlState,
	createControl,
	DefaultValidators as Validators
} from "../../utils/FormManager";
import { toastMessage } from "../../utils/helpers";
import DotsLoader from "../shared/DotsLoader";
import Arrow from "../../assets/icons/arrow";
import Close from "../../assets/icons/close";
import { AxiosResponse } from "axios";
import Action from "../../redux/reducers/models/action";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
export interface SendConfigurationProps {
	setConfigurationId: (id: number) => Action;
	configurationWasRecentlyCreated: boolean;
}

const EmailControl = createControl("Email", "Type Email", [Validators.email], true);
const formManager = new FormManager();

export const SendConfiguration: React.FunctionComponent<
	DialogProps & SendConfigurationProps & StateManagementProps & WithNamespaces> = ({ title, hideDialog, t, setConfigurationId, applicationSettings }) => {
		const [email, setEmail] = useState<FormControlState>(EmailControl);
		const [error, setError] = useState<boolean>(false);
		const [generating, setGenerating] = useState<boolean>(false);


		const handleSubmit = (e: FormEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (!formManager.formIsValid) {
				formManager.touchControls();
				return;
			}
			setGenerating(true);

			http
				.sendPdfSummary(email.value)
				.then((response: AxiosResponse<{ ConfigurationId: number }>) => {
					setConfigurationId(response.data.ConfigurationId);
					setGenerating(false);
					toastMessage(t("toasts.summarySent"));
					hideDialog();
				})
				.catch(error => {
					setGenerating(false);
					setError(true);
					console.log(`an error occured: ${error}.`);
				});
		};

		// update controls on every render
		formManager.addAllControls([[email, setEmail]]);


		const emailError =
			!email.pristine && !formManager.controlIsValid(EmailControl);

		const renderDesktopBackButton = () => {
			if (applicationSettings.Brand === "HQV") {
				return (<button className="primary back-link gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}><span className="d-none-mobile "><Arrow /></span>{t("buttons.closeDialog")}</button>);
			}
			else if (applicationSettings.Brand === "GG") {
				return(<button className="gg-back-link back-link gtm-go-back-send reset-button-styles" title={t("buttons.closeDialog")} onClick={hideDialog}><Close/></button>)
			}
			return (<button className="primary back-link gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>{t("buttons.closeDialog")}<span className="d-none-mobile"><Arrow /></span></button>);
		}

		return (
			<>
				<Dialog
					open={true}
					disableBackdropClick={true}
					onClose={hideDialog}
					fullWidth={true}
					maxWidth={"sm"}
					aria-labelledby="export configuration modal"
					className="send-mail-wrapper"
					id="sendConfigurationDialog"
				>
					<Grid item className="close-send-mail-button-mobile show-mobile container">
						<button className="primary gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>
							<Close />
						</button>
					</Grid>
					<Grid item className="close-send-mail d-none-mobile container">
						{renderDesktopBackButton()}
					</Grid>
					<div className="send-mail container">
						<div className="dialog-wrapper">
							<Grid id="setup-parts-dialog-title">
								<h2 className={applicationSettings.Brand === "KTM" ? "setups-headline underline uppercase" : "setups-headline underline"}>
									{title}
								</h2>
								<p className="setups-description text-center">
									{t('configuration.summary.shareOptions.sendMail.description')}
								</p>
							</Grid>
							<form noValidate onSubmit={handleSubmit}>
								<DialogContent className="text-field">
									{error && (
										<p className="error margin-initial">{t("errors.sendingEmail")}</p>
									)}
									<TextField
										autoFocus
										placeholder="Type Email"
										id="email"
										label={t("dialogs.shareConfiguration.sendTo")}
										fullWidth={true}
										disabled={generating}
										onChange={({ target: { value } }) => setEmail({ ...email, value })}
										margin="normal"
										required={true}
										error={emailError}
										helperText={emailError ? t("errors.invalidEmail") : ""}
									/>
								</DialogContent>
								<DialogActions>
									<button
										type="submit"
										onClick={handleSubmit}
										disabled={generating}
										className="	secondary configuration-send-button"
									>
										{t(`buttons.${generating ? "generating" : "send"}`)}
										{generating && <DotsLoader></DotsLoader>}
									</button>
								</DialogActions>
							</form>
						</div>
					</div>
				</Dialog>
			</>

		);
	};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SendConfiguration)
);

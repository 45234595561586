import React, { useCallback, useEffect, useRef, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { debug } from "../../../utils/helpers";
import { removeStreamSubscription, sendMessage, socketMessagesCallbacks, startStop, stopSession, StreamState, setBitRateDroppedCallback } from "../../../lib/streaming/core";
import { ENVIRONMENT_DONE, HEROLVL_DONE, INTROGO_COMMAND, INTROIN_COMMNAD, PORTRMD_COMMAND, UERESET_DONE, TOUCHMD_COMMAND } from "../../../lib/streaming/commands";
import { ServiceOptions } from "@monkeyway/streaming-lib/dist/types/service-options";
import { StreamInfo } from "@monkeyway/streaming-lib/dist/types/stream-info";
import Arrow from "../../../assets/icons/arrow";
import { updateConfiguration } from "../updateConfiguration";
import { timer, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import useInterval from "../../../lib/hooks/useInterval";
import { config } from "process";

export interface RenderingProps {
  onStreamStateChanged: (streamState: StreamState, event?: any) => void;
  setUserInactiveFunction: (inactivity: boolean) => void;
  displaySummary: boolean;
}

const Rendering3D: React.FunctionComponent<StateManagementProps & WithNamespaces & RenderingProps & RouteComponentProps> = (props) => {
  const { t, history, setUserInactiveFunction, setUserDidTimeoutInStream, overview, configurationState, onStreamStateChanged, applicationSettings, displaySummary, dealerInquiryDialogOpen, isXBow } =
    props;

  const isDevMode = false; //to enable dev mode
  const debugMessagesON = false; //to enable debug messages

  const MAX_INACTIVE_TIME_SECONDS = !isDevMode ? 120 : 20;
  const WAIT_TO_PRESS_OK_BUTTON = !isDevMode ? 30 : 10;

  const NO_STREAM_AVAILABLE_WAIT = 20000;
  const NO_STREAM_AVAILABLE_TICK_INTERVAL = 1000;

  const fixedMobileWidth = 1024;

  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(windowInnerWidth < fixedMobileWidth);

  const landscape = window.matchMedia("(orientation: landscape)");
  const [isLandscapeMode, setIsLandscapeMode] = useState(landscape.matches);
  landscape.addEventListener("change", function (e) {
    if (e.matches) {
      setIsLandscapeMode(true);
    } else {
      setIsLandscapeMode(false);
    }
  });

  useEffect(() => {
    setIsMobile(windowInnerWidth < fixedMobileWidth);
  }, [windowInnerHeight, windowInnerWidth]);

  let renderSettings = overview.RenderSettings;
  let appSettings = renderSettings!.AppSettings;

  let videoDivRef = useRef<HTMLVideoElement>(null);
  let bike3Ref = useRef<HTMLButtonElement>(null);
  let resetRef = useRef<HTMLButtonElement>(null);

  const [failedToStart, setFailedToStart] = useState(false);
  const [busy, setBusy] = useState(true);
  const [bikeRenderingLoaded, setBikeRenderingLoaded] = useState(false);
  const [lastUserActivity, setLastUserActivity] = useState(Date.now());
  const [userInactive, setUserInactive] = useState(false);
  const [inactiveTime, setInactiveTime] = useState(0);
  const [showDebugTools, setShowDebugTools] = useState(false);
  const [tryAgainDisabled, setTryAgainDisabled] = useState(true);
  const [noStreamAvailableTimeLeft, setNoStreamAvailableTimeLeft] = useState(0);
  const [listenerForUserInactivityAdded, setListenerForUserInactivityAdded] = useState(false);

  let noStreamAvailableTimer: Subscription;

  const streamingOptions: ServiceOptions = {
    baseUrl: appSettings.URL,
    appEnvId: appSettings.ENV,
    apiKey: appSettings.KEY,
  };

  const RESET_COMMAND = renderSettings!.Reset.Command + " " + renderSettings!.Reset.Value;
  const ENVIRONMENT_LEVEL = isDevMode ? "envilvl debug" : renderSettings!.Tools.Environments.Command + " " + renderSettings!.Tools.Environments.List[0].Value; // take first environment in list as default;
  const DEFAULT_LIGHTING_COMMAND = renderSettings!.Tools.Lighting.CommandInitial + " " + renderSettings!.Tools.Lighting.ValueFrom;
  const DEFAULT_LIGHTING_SLIDE_TO_COMMAND = renderSettings!.Tools.Lighting.CommandSlider + " " + renderSettings!.Tools.Lighting.ValueTo;

  //sound for bike per configuration enabled -
  let soundEnabled: boolean = renderSettings!.Sound.Enabled;

  // US Enduro 2023 FIX
  const REMAP: any = { F7475X3: "F7475X3", F0475X7: "F0475X7" };

  /**
   * Fetch the bike variation from the configuration state and check if it is part of the remap dictionary. if so use the mapped value - otherwise use
   * settings from database
   * @returns vehicle loading command
   */
  const GetHeroLevel = (): string => {
    if (configurationState && configurationState && configurationState.configuration.VariationCode && REMAP[configurationState.configuration.VariationCode]) {
      const remapped = REMAP[configurationState.configuration.VariationCode];
      console.log("FORCING VEHICLE REMAP :::", remapped);
      return renderSettings!.Data.Command + " " + remapped;
    }
    else if(configurationState && configurationState && configurationState.configuration.VariationCode && overview.Variations.length > 1) {
      return isDevMode ? "herolvl dummy" : renderSettings!.Data.Command + " " +configurationState.configuration.VariationCode;
    }

    return isDevMode ? "herolvl dummy" : renderSettings!.Data.Command + " " + renderSettings!.Data.Value;
  };

  const goBackToMain = () => {
    history.push("/main");
  };

  /**
   * Bike Initialization Procedure
   */
  const initializeBike = (streamInfo: StreamInfo) => {
    const waiting = 30000; // close one
    const timeout = setTimeout(() => {
      if (debugMessagesON) debug("CALL VIA TIMEOUT - FALLBACK");
      loadBike();
    }, waiting); // this might load the bike without the env now.
    setBusy(true);

    /**
     *  UE RESET
     *  LOAD BIKE
     *  LOAD ENVI
     *
     *  SET LIGHTIN 0
     *  2sec
     *  SHOW STREAM
     *  SPAWN GO spawngo 1
     *  SET LIGHTSC 22
     * FALLBACK -> x sekunden -> LOAD BIKE ENTRY
     */

    // FIRST STEP
    if (renderSettings) {
      sendMessage("ueversn 1"); // Version Number Response
      sendMessage(RESET_COMMAND);
      socketMessagesCallbacks[UERESET_DONE] = (message: string) => {
        if (message === UERESET_DONE) {
          // INTENTIONAL
          if (debugMessagesON) debug("INIT UERESET DONE");
          sendMessage("soundfx 0");
          delete socketMessagesCallbacks[UERESET_DONE];
          loadBike();
        }
      };
    }

    // SECOND STEP
    const loadBike = () => {
      onStreamStateChanged(StreamState.LOAD_BIKE);
      sendMessage(GetHeroLevel());
      socketMessagesCallbacks[HEROLVL_DONE] = (message: string) => {
        if (message === HEROLVL_DONE) {
          clearTimeout(timeout);
          if (debugMessagesON) debug("INIT HEROLVL DONE");
          delete socketMessagesCallbacks[HEROLVL_DONE];

          if (isDevMode) {
            setInitialEnvironmentValues();
          } else {
            loadEnvironment();
          }
        }
      };
    };

    const loadEnvironment = () => {
      /**
       * Load Environment according to load/store or default from constants
       */
      onStreamStateChanged(StreamState.LOAD_ENV);
      if (configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Environments) {
        renderRestoredStringCommand(configurationState.VehicleSettings.ToolsConfiguration.Environments);
      } else {
        sendMessage(ENVIRONMENT_LEVEL);
      }
      socketMessagesCallbacks[ENVIRONMENT_DONE] = (message: string) => {
        if (message === ENVIRONMENT_DONE) {
          if (debugMessagesON) debug("INIT ENVILVL DONE");
          delete socketMessagesCallbacks[ENVIRONMENT_DONE];
          setInitialEnvironmentValues();
        }
      };
    };

    const setInitialEnvironmentValues = () => {
      onStreamStateChanged(StreamState.LOAD_BASE);
      sendMessage(DEFAULT_LIGHTING_COMMAND);
      sendMessage(`${PORTRMD_COMMAND}${+isMobile}`); //TODO determine wether we are on mobile and implement a switch, this is for mobile to rotate the camera
      sendMessage(`${TOUCHMD_COMMAND}${+isMobile}`);
      sendMessage(INTROIN_COMMNAD);
      if (isXBow) {
        onStreamStateChanged(StreamState.LOAD_COLORS);
      }

      /*** Render Color Settings that were saved previously before video is shown*/
      if (configurationState.VehicleSettings && configurationState.VehicleSettings.CustomColorConfiguration) {
        Object.values(configurationState.VehicleSettings.CustomColorConfiguration).forEach((elem) => {
          renderRestoredStringCommand(elem);
        });
      }

      setTimeout(() => {
        bikeInitializationComplete();
      }, 3000);
    };

    // INIT COMPLETE
    const bikeInitializationComplete = () => {
      if (videoDivRef && videoDivRef.current && streamInfo) {
        onStreamStateChanged(StreamState.LOAD_VIDEO_SRC);
        videoDivRef.current.srcObject = streamInfo.stream;
        videoDivRef.current.muted = true; // iOS Fix?
        videoDivRef.current.play();
      }

      showVideo();
      showDetails(streamInfo);
      setBusy(false);
      /**
       * Set Lighting
       */
      if (configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Lighting) {
        // TODO make sure to use the lighting props in tools.tsx accordingly to set the frontend slider state. the value probably has to be parsed
        renderRestoredStringCommand(configurationState.VehicleSettings.ToolsConfiguration.Lighting);
      } else {
        sendMessage(DEFAULT_LIGHTING_SLIDE_TO_COMMAND);
      }

      sendMessage(INTROGO_COMMAND);
      onStreamStateChanged(StreamState.ACCEPTING);
      /**
       * Send all things that should happen after IntroGo
       */
      setTimeout(() => {
        if (configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Perspectives) {
          // TODO make sure to use the lighting props in tools.tsx accordingly to set the frontend slider state. the value probably has to be parsed
          renderRestoredStringCommand(configurationState.VehicleSettings.ToolsConfiguration.Perspectives);
        }
        // restore animations (open hood, doors, etc)
        if (configurationState.VehicleSettings && configurationState.VehicleSettings.ToolsConfiguration && configurationState.VehicleSettings.ToolsConfiguration.Animations) {
          configurationState.VehicleSettings.ToolsConfiguration.Animations.forEach((x) => {
            renderRestoredStringCommand(x);
          });
        }
      }, 2000);

      setBikeRenderingLoaded(true);
      if (debugMessagesON) debug("INIT COMPLETE");
      onStreamStateChanged(StreamState.INIT_DONE);
      setLastUserActivity(Date.now()); // reset activity because it's running in the background although the user is waiting (in case of being in the waiting room)
      startInactivityChecks();
      setBitRateDroppedCallback(bitRateDropCallback);
    };
  };

  const bitRateDropCallback = (evt) => {
    //console.log("DROPPED");
    //console.log(evt);
    onStreamStateChanged(StreamState.BITRATE_DROPPED, evt);
  };

  useEffect(() => {
    sendMessage(`${PORTRMD_COMMAND}${+isMobile}`); //TODO determine wether we are on mobile and implement a switch, this is for mobile to rotate the camera
    sendMessage(`${TOUCHMD_COMMAND}${+isMobile}`);
  }, [isMobile]);

  const renderRestoredStringCommand = (stringCommand: string) => {
    if (stringCommand) {
      let commands: string[] = stringCommand.split(";");
      commands.forEach((command) => sendMessage(command));
    }
  };

  const onConnecting = () => {
    if (debugMessagesON) debug("connecting");
    setBusy(true);
    onStreamStateChanged(StreamState.INITIALIZING);
  };

  const onDisconnected = () => {
    if (debugMessagesON) debug("disconnected");
    hideVideo();
    hideDetails();
    setFailedToStart(true);
    setBikeRenderingLoaded(false);

    if (debugMessagesON) debug("videoDivRef.pause");
    if (videoDivRef && videoDivRef!.current) {
      videoDivRef!.current!.pause();
      videoDivRef!.current!.srcObject = null;
    }

    onStreamStateChanged(StreamState.BROKEN);
  };

  const control = (event: any /*MouseEvent*/) => {
    let command: string = (event.target as HTMLButtonElement).getAttribute("data-command") as string;
    sendMessage(command);
  };

  const showDetails = (streamInfo: StreamInfo) => {
    if (debugMessagesON) debug(streamInfo!.specs.publicDomainName);
  };

  const hideDetails = () => {
    if (debugMessagesON) debug("hide details");
  };

  const showVideo = () => {
    if (videoDivRef && videoDivRef!.current) {
      videoDivRef!.current!.style.display = "block";
    }
  };

  const hideVideo = () => {
    if (videoDivRef && videoDivRef!.current) {
      videoDivRef!.current!.style.display = "none";
    }
  };

  /**
   * Start this hot mess
   */
  useEffect(() => {
    setFailedToStart(false);
    removeStreamSubscription();
    setTimeout(() => {
      // Tries start 3d Rendering when backend rendering settings available
      if (debugMessagesON) debug("WARNING: THIS LINE SHALL BE SHOWN ONLY ONCE. OTHERWISE SEEMS THE COMPONENT IS RENDERED MULTIPLE TIMES. THIS IS A PROBLEM!");

      updateConfiguration(props);
      tryStart();
    }, 1000);

    return () => {
      window.removeEventListener("resize", onResize);
      if (debugMessagesON) debug("Unmount: Anything in here is fired on component unmount.");
      sendCommand();
      stopSession(onDisconnected);
      noStreamAvailableTimer && noStreamAvailableTimer.unsubscribe && noStreamAvailableTimer.unsubscribe();
    };
  }, []);

  useCallback(() => {
    if (bikeRenderingLoaded) {
      setInactiveTime(0);
      onVideoMouseMove();
      setUserDidTimeoutInStream(false);
    }
  }, [bikeRenderingLoaded]);

  const onResize = () => {
    setWindowInnerHeight(window.innerHeight);
    setWindowInnerWidth(window.innerWidth);
  };

  window.addEventListener("resize", onResize);

  const tryStart = () => {
    if (renderSettings) {
      if (debugMessagesON) debug("Start Rendering for:", renderSettings.Data);
      setFailedToStart(false);
      onConnecting();
      startStop(true, initializeBike, onDisconnected, soundEnabled, streamingOptions, videoDivRef);
    } else {
      if (debugMessagesON) debug("Missing rendering settings!", null);
    }
  };

  const sendCommand = () => {
    let el: HTMLInputElement | null = document.getElementById("addCommand") as HTMLInputElement;
    if (el && el.value) {
      sendMessage(el.value);
    }
  };

  const onVideoLoaded = () => {
    if (debugMessagesON) debug("onVideoLoaded called");
  };

  const onVideoMouseMove = () => {
    if (debugMessagesON) debug("onVideoMouseMove called");
    setLastUserActivity(Date.now());
    setInactiveTime(0);
    setUserInactive(false);
    setUserInactiveFunction(false);
  };

  useInterval(() => {
    //THIS HANDLES THE TIMEOUT
    if (!lastUserActivity) {
      onVideoMouseMove();
    }

    const inactiveForSeconds = Math.round((Date.now() - lastUserActivity) / 1000);
    setInactiveTime(inactiveForSeconds);

    if (inactiveForSeconds > MAX_INACTIVE_TIME_SECONDS) {
      setUserInactive(true);
      setUserInactiveFunction(true);
    }

    if (inactiveForSeconds > MAX_INACTIVE_TIME_SECONDS + WAIT_TO_PRESS_OK_BUTTON) {
      stopSession(onDisconnected);
      setUserDidTimeoutInStream(true);
    }
  }, 1000);

  const startInactivityChecks = () => {
    addEventListenerForUserInactivity();
  };

  const addEventListenerForUserInactivity = () => {
    //add listener to the cats browsing for user inactivity
    if (listenerForUserInactivityAdded) {
      return;
    }
    var catsContainer = document.getElementsByClassName("category-container");
    if (catsContainer.length > 0) {
      if (debugMessagesON) debug("Add event listeners for user inactivity", null);
      catsContainer[0].removeEventListener("mousemove", onVideoMouseMove);
      catsContainer[0].removeEventListener("touchstart", onVideoMouseMove);
      catsContainer[0].addEventListener("mousemove", onVideoMouseMove);
      catsContainer[0].addEventListener("touchstart", onVideoMouseMove);
      setListenerForUserInactivityAdded(true);
    }
  };

  /**
   * This part handles user inactivity
   */
  const confirmActive = () => {
    setInactiveTime(0);
    setUserInactive(false);
    setUserInactiveFunction(false);
    setLastUserActivity(Date.now());
    onVideoMouseMove();
    if (failedToStart) {
      tryStart(); //try to start again
    }
  };

  /**
   * This part handles no streams available in the waiting room.
   */
  const noStreamAvailableRetryClick = useCallback(() => {
    setTryAgainDisabled(true);
    tryStart();
  }, [tryAgainDisabled]);

  useEffect(() => {
    if (failedToStart && !userInactive && tryAgainDisabled) {
      const countdown = timer(0, NO_STREAM_AVAILABLE_TICK_INTERVAL).pipe(take(NO_STREAM_AVAILABLE_WAIT / NO_STREAM_AVAILABLE_TICK_INTERVAL + 1));
      noStreamAvailableTimer = countdown.subscribe({
        next: (val) => {
          setNoStreamAvailableTimeLeft(NO_STREAM_AVAILABLE_WAIT / NO_STREAM_AVAILABLE_TICK_INTERVAL - val);
        },
        error: (err) => {
          setTryAgainDisabled(false);
        },
        complete: () => {
          setTryAgainDisabled(false);
        },
      });
    }
  }, [failedToStart, userInactive, tryAgainDisabled]);

  return (
    <>
      {isLandscapeMode &&
        isMobile &&
        !busy && ( //Hint for mobile users to turn to landscape mode
          <div className="rendering-hints">
            {t("rendering3d.useLandscape")}
            <div>
              <img id="img-turn-to-portrait-mode" alt="turn to landscape" src="/images/rotate-to-landscape-mode.png" />
            </div>
            <div className="go-back-hint" onClick={goBackToMain}>
              <span>
                <Arrow />
              </span>
              {t("navigation.goBack")}
            </div>
          </div>
        )}

      {failedToStart &&
        !userInactive && ( //Hint for failed to start
          <div className="failed-to-start-hint rendering-hints">
            <p>{t("rendering3d.engineBusy")}</p>
            {noStreamAvailableTimeLeft > 0 && (
              <p className="hint-timer" style={{ textTransform: "lowercase" }}>
                {`${noStreamAvailableTimeLeft}s`}
              </p>
            )}
            <button disabled={tryAgainDisabled} className="secondary button-try-again" onClick={noStreamAvailableRetryClick} style={{ cursor: tryAgainDisabled ? "default" : "pointer" }}>
              {t("rendering3d.tryAgain")}
            </button>
            <div className="go-back-hint" onClick={goBackToMain}>
              <span>
                <Arrow />
              </span>
              {t("navigation.goBack")}
            </div>
          </div>
        )}

      {userInactive &&
        bikeRenderingLoaded &&
        !displaySummary &&
        !dealerInquiryDialogOpen && ( //Hint for user inactivity
          <div className="rendering-hints">
            <h3> {t("rendering3d.inactiveTooLong")}</h3>
            <p>
              {t("rendering3d.inactiveTime").replace("{inactiveTime}", "" + inactiveTime)}
              <br />
              {t("rendering3d.inactiveConfirm")}
            </p>

            <button className="secondary" onClick={confirmActive}>
              OK
            </button>
            <br />

            <div className="go-back-hint" onClick={goBackToMain}>
              <span>
                <Arrow />
              </span>
              {t("navigation.goBack")}
            </div>
          </div>
        )}

      <div className={`rendering-container-3d ${busy || userInactive ? "busy" : ""}`}>
        <div className="centered-container">
          <div className="aspect-ratio">
            <video className="styled-video" ref={videoDivRef} id="stream" playsInline muted autoPlay onLoad={onVideoLoaded} onMouseMove={onVideoMouseMove} onTouchStart={onVideoMouseMove} />
          </div>
        </div>

        {applicationSettings.Show3DDebugTools && (
          <div className="test-controls-container" id="testing3D">
            <button
              title="debug tools"
              onClick={() => {
                setShowDebugTools(!showDebugTools);
              }}
            >
              D
            </button>
            <div className="centered-container" style={{ visibility: showDebugTools ? "inherit" : "hidden" }}>
              <button className="styled-button" ref={bike3Ref} data-command="herolvl F8401W5" onClick={control}>
                LoadBike F8401W5
              </button>
              <br />
              <button className="styled-button" ref={resetRef} data-command="uereset 1" onClick={control}>
                Reset
              </button>
              <br />
              <button className="styled-button" ref={resetRef} data-command="debugui 1" onClick={control}>
                Debug UI On
              </button>
              <button className="styled-button" ref={resetRef} data-command="debugui 0" onClick={control}>
                Debug UI Off
              </button>
              <br />
              <div>
                <input placeholder="addComand" id="addCommand" style={{ display: "block" }} />
                <input type="submit" value="Submit" onClick={sendCommand} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Rendering3D);

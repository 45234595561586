import { SET_CURRENT_COUNTRY} from "../actionTypes";
import Action from './models/action';

export interface CurrentCountryState  {
    CountryName: string;
    CountryCode: string;
    LanguageCode: any;
    ImprintCode: string|null;
}

export const initialState: CurrentCountryState = {
	CountryName: "",
    CountryCode: "",
    LanguageCode: "",
    ImprintCode: null
};

export default function CurrentCountryReducer(state: CurrentCountryState = initialState, action: Action): CurrentCountryState {
	switch (action.type) {
		case SET_CURRENT_COUNTRY: {
			return {
				...action.payload,  
			};
		}    
		default: 
			return state;
	}
}
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AddCustomColorIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Add custom color</title>
      <rect x="0.5" y="0.5" width="56" height="56" rx="1.5" stroke="#D9D9D9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.2599 20.8999H27.7399V27.7399H20.8999L20.8999 29.2599H27.7399V36.0999H29.2599V29.2599H36.0999V27.7399H29.2599V20.8999Z" fill="#D9D9D9" />
    </SvgIcon>
  );
};

export default AddCustomColorIcon;

import React, { useEffect, useState } from "react";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { connect } from "react-redux";
import { WithNamespaces } from "react-i18next";
import Close from "../../assets/icons/close";
import { RouteComponentProps } from "react-router";
import XBowColors from "../configuration/colorSelection/xBowColors";
import { CustomColorConfigurationsPayload } from "../../services/http/real";
import { CategoryListComponent } from "./CategoryList";

export interface CatContainerProps {
  setCatsOpenFunc: () => void;
  is3D?: boolean;
  renderingCommands?: string[] | null;
  updateRenderingCommands?: (renderingCommandList) => void;
  catsOpen: boolean;
  isMobile: boolean;
  sendColorData: (colorData: CustomColorConfigurationsPayload) => void;
}

export type CatContainerAllProps = StateManagementProps & WithNamespaces & CatContainerProps & RouteComponentProps;

const CategoryContainer: React.FunctionComponent<CatContainerAllProps> = props => {
  const { isXBow, setCatsOpenFunc, applicationSettings, catsOpen, t, sendColorData, isMobile, appliedParts } = props;

  const [isColorSelectorOpen, setColorSelectorOpen] = useState(false);

  const updateIsColorSelectorOpen = (open: boolean) => setColorSelectorOpen(open);

  useEffect(()=>{
    if(isMobile && catsOpen) {
      setCatsOpenFunc();
    }
  },[appliedParts])

  return (
    <div
      id={isXBow ? "xb-container" : "pp-container"}
      className={`category-container ${catsOpen ? "show-category" : "hide-category"} 
    ${isMobile && isColorSelectorOpen ? "show-mobile-colors" : ""}`}
    >
      <div className={`category-content ${catsOpen ? "show-category-content" : "hide-category-content"}`}>
        <div className="cats">
          <div className={`tool-tip ${catsOpen ? "open" : "closed"}`} onClick={() => setCatsOpenFunc()}>
            <div className="tooltip-content-container">
              <>
                <Close />
                {!isXBow ? t(`buttons.categories.${applicationSettings.Brand}`) : "Customization"}
              </>
            </div>
          </div>
          <div className={`cats-container ${isXBow ? "colors-cats-container" : ""}`}>
            <div className="mobile-close" onClick={() => setCatsOpenFunc()}>
              <Close />
            </div>
            {isXBow ? (
              <XBowColors {...props} sendColorData={sendColorData} isMobile={isMobile} updateIsColorSelectorOpen={updateIsColorSelectorOpen} isColorSelectorOpen={isColorSelectorOpen} />
            ) : (
              <CategoryListComponent {...props} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);

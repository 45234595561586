import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { TogglePartResponse } from "../../components/parts/PartsList";
import { CreateConfigurationResponse } from "../../components/configuration/ConfigurationView";
import { debug, getConfigurationId, getLocaleQuery } from "../../utils/helpers";
import * as uuid from "uuid";
import { SaveDetailsData } from "../../components/dialogs/saveConfiguration";
import { ExportConfigurationValueNames } from "../../components/dialogs/exportConfiguration";
import { LoadConfigurationResponse } from "../../redux/reducers/load";
import { ShareConfigurationResponse } from "../../components/configuration/configurationSummary/ConfigurationSummary";
import { ApplicationSettings } from "../../redux/reducers/applicationSettings";
import { MarketResponse } from "../../components/main/MainView";
import { RulesResult } from "../../components/dialogs/conflictingParts";
import { Offer } from "../../components/dialogs/sendConfigurationToDealer";
import { MotorcycleModelResponse, Price, VehicleOverviewResponse } from "../../models/vehicle";

interface HistoryResponseItem {
  Configurations: Configuration[];
}

interface BitrateDroppedTelemetryPayload {
  UserId: string,
  Payload: BitratePayload,
  IsMobile: boolean
}

interface BitratePayload {
  Bitrate: string
}

interface Configuration {
  Id: number;
  Model: Model;
  TotalPrice: Price;
  Customer: Customer;
  Comment: string;
  SavedOn: number;
}

interface Customer {
  Lastname: string;
  Firstname: string;
  Fullname?: string;
}

interface Model {
  HeroImageUrl: string;
  Id: string;
  Name: string;
}

export interface AddPartPayload {
  PartId: string;
  SelectedParts: string[];
  RemoveParts: string[];
  DryRun?: boolean; //if true then the part will not be added only check for rules will be done.
  ValidationResultType?: number; // 0 invalid input,  1 rule exception
  InvalidInputMessage?: any; // if defined show error in a diferrent toast
  RulesResult?: RulesResult;
}

export interface ColorOptionPayload {
  FrontendColorCode: string | null;
  RenderColorCode: string | null;
  ColorName: string | null;
}

/**
 * This is used for 3D Vehicles, motorcycles and XBow
 */
export interface ToolsConfigurationPayload {
  Cinematics?: string;
  Environments?: string;
  Perspectives?: string;
  Lighting?: string;
  Animations?: string[];
}

/**
 * This is used for the XBow
 */
export interface CustomColorConfigurationsPayload {
  SelectedBodyColor?: string;
  SelectedRimsColor?: string;
  SelectedCaliperColor?: string;
  SelectedStitchingColor?: string;
  SelectedSuspensionAndLogoColor?: string;
}

/**
 * Container object for payload transport of color and tools to the backend.
 */
export interface VehicleSettingsPayload {
  ToolsConfiguration: ToolsConfigurationPayload;
  CustomColorConfiguration: CustomColorConfigurationsPayload;
}

export interface UpdateConfigurationPayload {
  VariationCode: string;
  ColorCode: string;
  newVariation?: string;
  VehicleSettings?: VehicleSettingsPayload;
}

export interface RemovePartPayload {
  PartId: string;
  RemoveParts: string[];
  DryRun?: boolean; //if true then the part will not be added only check for rules will be done.
  ValidationResultType?: number; // 0 invalid input,  1 rule exception
  InvalidInputMessage?: any; // if defined show error in a diferrent toast
  RulesResult?: RulesResult;
}

export function getAppSettings(): AxiosPromise<ApplicationSettings> {
  return axios.get<ApplicationSettings>('/api/posconfigurator/appsettings');
}

export function getModels(): AxiosPromise<MotorcycleModelResponse> {
  return axios.get<MotorcycleModelResponse>(`/api/posconfigurator/vehicles${getLocaleQuery()}`);
}

export function getOverview(modelId: string): AxiosPromise<VehicleOverviewResponse> {
  return axios.get<VehicleOverviewResponse>(`/api/posconfigurator/vehicles/${modelId}${getLocaleQuery()}`);
}

export function addPart(payload: string | AddPartPayload): AxiosPromise<TogglePartResponse> {
  let data: AddPartPayload;

  if (typeof payload === "string") {
    data = {
      PartId: payload,
      SelectedParts: [],
      RemoveParts: [],
    };
  } else {
    data = { ...payload };
  }
  return axios.post<TogglePartResponse>(`/api/posconfigurator/configuration/${getConfigurationId()}/add-part${getLocaleQuery()}`, data);
}

export function removePart(payload: string | RemovePartPayload): AxiosPromise<TogglePartResponse> {
  let data: RemovePartPayload;
  if (typeof payload === "string") {
    data = {
      PartId: payload,
      RemoveParts: [],
    };
  } else {
    data = { ...payload };
  }
  return axios.post<TogglePartResponse>(`/api/posconfigurator/configuration/${getConfigurationId()}/remove-part${getLocaleQuery()}`, data);
}

export function createConfiguration(modelId: string): AxiosPromise<CreateConfigurationResponse> {
  const data = {
    ModelId: modelId,
  };
  const config: AxiosRequestConfig = {
    headers: {
      AnonymousID: uuid.v4(),
    },
  };
  return axios.post<CreateConfigurationResponse>(`/api/posconfigurator/configuration/create${getLocaleQuery()}`, data, config);
}

export function updateConfiguration(payload: UpdateConfigurationPayload): AxiosPromise<any> {
  let id = getConfigurationId();
  let stringID = "" + id;
  return axios.put(`/api/posconfigurator/configuration/update/${stringID}${getLocaleQuery()}`, payload);
}

export function sendBitrateDroppedTelemetryData(user: string, bitrate: string, isMobile: boolean): AxiosPromise<any> {
  const data : BitrateDroppedTelemetryPayload = {
    Payload: {Bitrate: bitrate} as BitratePayload,
    UserId:user,
    IsMobile: isMobile
  };

  return axios.post<any>(`/api/posconfigurator/health/telemetrydata`, data)
}

export function updateConfigurationForUser(payload: UpdateConfigurationPayload, userId: string): AxiosPromise<any> {
  return axios.put(`/api/posconfigurator/configuration/update/${getConfigurationId()}/${userId}${getLocaleQuery()}`, payload);
}

export function deleteConfiguration(id: number | undefined = undefined): AxiosPromise<any> {
  id = id || getConfigurationId();
  return axios.delete(`/api/posconfigurator/configuration/${id}${getLocaleQuery()}`);
}

export function dismissUnsavedChanges(id: number | undefined = undefined): AxiosPromise<any> {
  id = id || getConfigurationId();
  return axios.post(`/api/posconfigurator/configuration/${id}/dismiss-unsaved-changes${getLocaleQuery()}`);
}

export function saveConfiguration(data: SaveDetailsData, saveAsNew: boolean): AxiosPromise<any> {
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/save-configuration${getLocaleQuery()}`, { ...data, SaveAsNew: saveAsNew });
}

export function saveUserConfigurationCDC(id: string, parts: any, header: string): AxiosPromise<any> {
  const data = {
    id: id,
    parts: parts,
  };
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: header,
    },
  };
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/save-user-configuration-cdc${getLocaleQuery()}`, data, config);
}

export function sendExport(data: ExportConfigurationValueNames): AxiosPromise<any> {
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/send-export${getLocaleQuery()}`, data);
}

export function sendToDealer(data: Offer): AxiosPromise<any> {
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/send-dealer${getLocaleQuery()}`, data);
}

export function sendToDealerWithScreenShot(data: Offer): AxiosPromise<any> {
  console.log(data);
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/send-dealer${getLocaleQuery()}`, data);
}

export function sendPdfSummary(email: string): AxiosPromise<any> {
  return axios.post<any>(`/api/posconfigurator/configuration/${getConfigurationId()}/send${getLocaleQuery()}`, {
    Email: email,
  });
}

export function downloadConfigurationSummaryPdf(partsHash: string): AxiosPromise<any> {
  console.log("downloadConfigurationSummaryPdf");
  let url = `/api/posconfigurator/configuration/${getConfigurationId()}/pdf${getLocaleQuery()}&stamp=${partsHash}`;
  // url = `http://localhost:3000/api/posconfigurator/configuration/YUq3qxnIP0O8p3MVqp30uA/pdfwithscreenshot?cid=5b540245-23d2-480b-af90-ec5427e7e479&c=de-AT&m=AT&stamp=-1874297231`
  return axios.get<any>(url, { responseType: "blob" });
}

export function downloadConfigurationSummaryPdfWithScreenshot(partsHash: string, screenshotData: string): AxiosPromise<any> {
  console.log("downloadConfigurationSummaryPdfWithScreenshot");
  debug("downloadConfigurationSummaryPdfWithScreenshot", screenshotData);
  let url = `/api/posconfigurator/configuration/${getConfigurationId()}/pdfwithscreenshot${getLocaleQuery()}&stamp=${partsHash}`;
  // url = `http://localhost:3000/api/posconfigurator/configuration/YUq3qxnIP0O8p3MVqp30uA/pdfwithscreenshot?cid=5b540245-23d2-480b-af90-ec5427e7e479&c=de-AT&m=AT&stamp=-1874297231`;
  return axios.post<any>(url, { screnshotdataaspng: screenshotData }, { responseType: "blob" });
}

export function shareConfiguration(): AxiosPromise<ShareConfigurationResponse> {
  return axios.get<ShareConfigurationResponse>(`/api/posconfigurator/configuration/${getConfigurationId()}/share${getLocaleQuery()}`);
}

export function getConfiguration(configId: string | number, country: string, culture: string, companyId: string): AxiosPromise<LoadConfigurationResponse> {
  let localeQuery = country !== "" && culture !== "" && companyId !== "" ? "?cid=" + companyId + "&c=" + culture + "&m=" + country : getLocaleQuery();
  return axios.get<LoadConfigurationResponse>(`/api/posconfigurator/configuration/${configId}${localeQuery}`);
}

export function getHistory(): AxiosPromise<HistoryResponseItem> {
  return axios.get<HistoryResponseItem>(`/api/posconfigurator/dealer/history${getLocaleQuery()}`);
}

export function getAllMarkets(): AxiosPromise<MarketResponse> {
  return axios.get<MarketResponse>("/api/posconfigurator/market");
}
export function getFastlyGeoCountry(): AxiosPromise<any> {
  return axios.get<any>("/api/posconfigurator/market/geolocation");
}

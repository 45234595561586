import { useEffect, useState } from "react";
import { WithNamespaces } from "react-i18next";
import { StateManagementProps } from "../../utils/addReduxProps";
import React from "react";

export const TechnicalDetails: React.FunctionComponent<StateManagementProps & WithNamespaces> = (props: any) => {

    const {
        overview,
    } = props;

    const [displacementData, setDisplacementData] = useState({
        title: "",
        value: "",
        unit: "CM³"
    });
    const [dryWeightData, setDryWeightData] = useState({
        title: "",
        value: "",
        unit: "KG"
    });
    const [powerData, setPowerData] = useState({
        title: "",
        value: "",
        unit: "KW"
    });
    const [torqueData, setTorqueData] = useState({
        title: "",
        value: "",
        unit: "NM"
    });
    
    const [showTechnicalDetails, setShowTechnicalDetails] = useState(false);

    useEffect(() => {
        getTechnicalDetails();
    }, [])

    const getTechnicalDetails = () => {
        if (overview.Attributes) {
            const attributes = overview.Attributes.find(item => item.ProductCode === props.configuration.VariationCode);
            if (attributes) {
                const displacement = attributes.Attributes.find(item => item.Key === "DISPLACEMENT");
                if (displacement && displacement.Description !== "" && displacement.Values[0].Key !== "") {
                    setDisplacementData({ title: displacement.Description, value: displacement.Values[0].Key, unit: displacementData.unit });
                    setShowTechnicalDetails(true);
                }
                const dryWeight = attributes.Attributes.find(item => item.Key === "DRYWEIGHT");
                if (dryWeight && dryWeight.Description !== "" && dryWeight.Values[0].Key !== "") {
                    setDryWeightData({ title: dryWeight.Description, value: dryWeight.Values[0].Key, unit: dryWeightData.unit });
                    setShowTechnicalDetails(true);
                }
                const power = attributes.Attributes.find(item => item.Key === "POWER_KW");
                if (power && power.Description !== "" && power.Values[0].Key !== "") {
                    setPowerData({ title: power.Description, value: power.Values[0].Key, unit: powerData.unit });
                    setShowTechnicalDetails(true);
                }
                const torque = attributes.Attributes.find(item => item.Key === "TORQUE");
                if (torque && torque.Description !== "" && torque.Values[0].Key !== "") {
                    setTorqueData({ title: torque.Description, value: torque.Values[0].Key, unit: torqueData.unit });
                    setShowTechnicalDetails(true);
                }
            }
        }
    }

    return (<div className="bike-details">
        {displacementData.title !== "" &&
            <div>
                <div className="detail-heading">{displacementData.title}</div>
                <div className="detail-value">{displacementData.value}&nbsp;{displacementData.unit}</div>
            </div>
        }
        {torqueData.title !== "" &&
            <div>
                <div className="detail-heading">{torqueData.title}</div>
                <div className="detail-value">{torqueData.value}&nbsp;{torqueData.unit}</div>
            </div>
        }
        {powerData.title !== "" &&
            <div>
                <div className="detail-heading">{powerData.title}</div>
                <div className="detail-value">{powerData.value}&nbsp;{powerData.unit}</div>
            </div>
        }
        {dryWeightData.title !== "" &&
            <div>
                <div className="detail-heading">{dryWeightData.title}</div>
                <div className="detail-value">{dryWeightData.value}&nbsp;{dryWeightData.unit}</div>
            </div>
        }
    </div>);
}
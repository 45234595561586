import { SELECT_SETUP, APPLY_SETUP, EXIT_VEHICLE_CONFIGURATION } from "../actionTypes";
import { Setup } from '../../components/setups/SetupsList'
import Action from './models/action'

export interface SetupsState {
	selected: Setup;
	applied: Setup | undefined;
}

export const initialState: SetupsState = {
	selected: {} as Setup,
	applied: undefined,
};

export default function SetupsReducer(state: SetupsState = initialState, action: Action): SetupsState {
	switch (action.type) {
		case SELECT_SETUP: {
			const selected: Setup = action.payload;
			return { ...state, selected };
		}
		case APPLY_SETUP: {
			const applied: Setup = action.payload;
			return { ...state, applied };
		}
		case EXIT_VEHICLE_CONFIGURATION: {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
}
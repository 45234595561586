import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowHeadHQV: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 8.657 19.15">
      <title>Arrow</title>
      <path d="M192.82,244.439l-1.062-.841,6.928-8.734-6.928-8.733,1.062-.841,7.6,9.575Z" transform="translate(-191.759 -225.289)" />
    </SvgIcon>
  );
};

export default ArrowHeadHQV;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SoundOff: React.FunctionComponent = () => {
  return (
    <SvgIcon width="38" height="29" viewBox="0 0 38 29">
      <title>Sound Icon Off</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3759 9.28507C27.9463 8.8555 27.2498 8.8555 26.8202 9.28507C26.3907 9.71465 26.3907 10.4111 26.8202 10.8407L30.2836 14.304L26.8202 17.7674C26.3907 18.197 26.3907 18.8934 26.8202 19.323C27.2498 19.7526 27.9463 19.7526 28.3759 19.323L31.8392 15.8597L35.3025 19.323C35.7321 19.7526 36.4286 19.7526 36.8582 19.323C37.2878 18.8934 37.2878 18.197 36.8582 17.7674L33.3948 14.304L36.8582 10.8407C37.2878 10.4111 37.2878 9.71465 36.8582 9.28507C36.4286 8.8555 35.7321 8.8555 35.3025 9.28507L31.8392 12.7484L28.3759 9.28507Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5733 2.5405C17.0829 2.10851 17.8646 2.46943 17.8646 3.13963V25.6932C17.8646 26.3638 17.0812 26.7235 16.5739 26.2928L9.07279 19.9379C8.85092 19.7499 8.57622 19.6651 8.30704 19.6785H3.88535C3.45236 19.6785 3.10039 19.3266 3.10039 18.8936V9.94004C3.10039 9.50705 3.45236 9.15508 3.88535 9.15508H8.32745C8.59007 9.16335 8.85653 9.07811 9.07279 8.8949L16.5733 2.5405ZM7.95904 6.95508L15.1507 0.862322C17.0915 -0.782593 20.0646 0.59797 20.0646 3.13963V25.6932C20.0646 28.2341 17.0903 29.6161 15.1509 27.9706L15.1501 27.9699L7.96003 21.8785H3.88535C2.23734 21.8785 0.900391 20.5416 0.900391 18.8936V9.94004C0.900391 8.29203 2.23734 6.95508 3.88535 6.95508H7.95904Z"
      />
    </SvgIcon>
  );
};

export default SoundOff;

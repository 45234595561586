import React from "react";
import { getBrandLogoSvgComponent } from "../../redux/reducers/applicationSettings";
import { StateManagementProps } from "../../utils/addReduxProps";

export interface BrandLogoProps {
  customClass?: string;
}

export const BrandLogo: React.FunctionComponent<StateManagementProps & BrandLogoProps> = (props) => {
  const { culture, applicationSettings, customClass } = props;
  const brand = applicationSettings.Brand;

  const brandLink = applicationSettings.BrandLink + culture.toLowerCase() + ".html";
  const brandSvg = getBrandLogoSvgComponent(brand);

  return (
    <a href={brandLink} target="_blank" className={`go-to-ktm-link ${customClass}`}>
      {brandSvg}
    </a>
  );
};

export default BrandLogo;

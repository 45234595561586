import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export interface PinIconProps {
  brand?: string;
}

const Pin: React.FunctionComponent<PinIconProps> = props => {
  const { brand } = props;
  return (
    <SvgIcon viewBox="0 0 28 28" fill="none" className="icon-pin">
      <title>Pin Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98242 11.7192V11.5185C5.98242 7.08983 9.57225 3.5 14.0009 3.5C18.4296 3.5 22.0194 7.08983 22.0194 11.5185V11.7192C22.0194 15.757 16.9071 21.8353 14.8468 24.122C14.3929 24.626 13.6089 24.626 13.1551 24.122C11.0948 21.8353 5.98242 15.757 5.98242 11.7192Z"
        fill={brand === "KTM" ? "#FF6600" : brand === "HQV" ? "#002550" : "#CB0D25"}
        stroke={brand === "KTM" ? "#FF6600" : brand === "HQV" ? "#002550" : "#CB0D25"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.666 11.6138C11.666 12.903 12.7102 13.9471 13.9993 13.9471C15.2885 13.9471 16.3327 12.903 16.3327 11.6138V11.5706C16.3327 10.2815 15.2885 9.2373 13.9993 9.2373C12.7102 9.2373 11.666 10.2815 11.666 11.5706"
        fill="white"
      />
      <path
        d="M11.666 11.6138C11.666 12.903 12.7102 13.9471 13.9993 13.9471V13.9471C15.2885 13.9471 16.3327 12.903 16.3327 11.6138V11.5706C16.3327 10.2815 15.2885 9.2373 13.9993 9.2373V9.2373C12.7102 9.2373 11.666 10.2815 11.666 11.5706"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Pin;

import React, { useEffect, useState } from "react";
import { DialogProps } from "./dialogRouter";
import { WithNamespaces } from "react-i18next";
import { StateManagementProps } from "../../utils/addReduxProps";
import BrandLogo from "../shared/BrandLogo";
import ArrowHead from "../../assets/icons/arrowHead";

export interface DialogHeaderProps {
  generating?: boolean;
}

export type AllSendConfigurationToDealerProps = DialogProps & StateManagementProps & WithNamespaces & DialogHeaderProps;

export const DialogHeaderWithLogo: React.FunctionComponent<AllSendConfigurationToDealerProps> = (props) => {
  const { hideDialog, t, userDidTimeoutInStream, generating } = props;

  return (
    <>
      <div className="dealer-search-header-mobile">
        {!userDidTimeoutInStream && !generating ? (
          <button className="show-mobile primary gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>
            <ArrowHead />
          </button>
        ) : (
          <div></div>
        )}

        <BrandLogo customClass={`brandlogo-in-main-header ${generating ? " generating-logo" : ""}`} {...props} />
      </div>

      <div className="d-none-mobile dealer-search-header">
        {!userDidTimeoutInStream && !generating ? (
          <button className="primary back-link gtm-go-back-send" title={t("buttons.closeDialog")} onClick={hideDialog}>
            <ArrowHead />
            {t("buttons.closeDialog")}
          </button>
        ) : (
          <div></div>
        )}
        <BrandLogo customClass={`brandlogo-in-main-header ${generating ? " generating-logo" : ""}`} {...props} />
      </div>
    </>
  );
};

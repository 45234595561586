import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import SoundOn from "../../assets/icons/sound_on";
import SoundOff from "../../assets/icons/sound_off";
import PDF from '../../assets/icons/pdf';
import html2canvas from '../../../node_modules/html2canvas';
import { debug } from '../../utils/helpers';
import http from "../../services/http";
import contentDisposition from "../../../node_modules/content-disposition";
import { saveAs } from "../../../node_modules/file-saver";
import Wheels from '../../assets/icons/wheel';

const SandboxComponent: React.FunctionComponent<any> = (props: any) => {

    const {
        t
    } = props;

    const [active, setActive] = useState(true);
    let [tryAgainDisabled, setTryAgainDisabled] = useState(false);

    useEffect(() => {

    }, []);

    const activated = {
        fill: 'red'
    }
    const deactivated = {
        fill: 'grey'
    }

    //when the user clicks on try again, disable the button for 10 secs.
    const tryAgainClick = () => {
        setTryAgainDisabled(true);
        // startStop();
        setTimeout(() => { setTryAgainDisabled(false) }, 10000);
    }

    const takeScreenshotAndDownloadPDF = () => {
        const screnshotDivRef = document.getElementById("sandbox");
        if (screnshotDivRef) {
            html2canvas(screnshotDivRef, { allowTaint: true }).then(canvas => {
                const data1 = canvas.toDataURL();
                downloadPdfWithScreenshot(data1);
            });
        }
    }

    const downloadPdfWithScreenshot = (screenshot: string) => {
        debug('downloadPdfWithScreenshot');
        let partsHash: string = "XXX";
        http.downloadConfigurationSummaryPdfWithScreenshot(partsHash, screenshot).then(response => {
            var headers = response.headers;
            var headersArray = Object.entries(headers);
            var contentDispos: any;
            headersArray.forEach(([key, value]) => {
                if (key === "content-disposition") {
                    contentDispos = value;
                }
            });

            var blob = response.data;
            var disposition = contentDisposition.parse(contentDispos);
            var fileName = disposition.parameters.filename
                ? disposition.parameters.filename
                : "configuration.pdf";

            fileName =  "configuration.pdf";
            console.log("SAVE PDF", fileName);
            saveAs(blob, fileName);
        });
    };

    const downloadPdf = () => {
        debug('downloadPdf');
        let partsHash: string = "XXX";
        http.downloadConfigurationSummaryPdf(partsHash).then(response => {
            var headers = response.headers;
            var headersArray = Object.entries(headers);
            var contentDispos: any;
            headersArray.forEach(([key, value]) => {
                if (key === "content-disposition") {
                    contentDispos = value;
                }
            });

            var blob = response.data;
            var disposition = contentDisposition.parse(contentDispos);
            var fileName = disposition.parameters.filename
                ? disposition.parameters.filename
                : "configuration.pdf";

            fileName =  "configuration.pdf";
            console.log("SAVE PDF", fileName);
            saveAs(blob, fileName);
        });
    };

    return (
        <section className="sandbox" id="sandbox">
            <h1>KTM Configurator Sandbox</h1>
            <img alt="sound" id="btn-sound" src="/icons/soundOn.svg" width={32} className={active ? 'active' : 'inactive'} style={active ? activated : deactivated} onClick={(e) => setActive(!active)} />

            {/* <img alt="sound" src="/icons/soundOn.svg" width={32}></img>
            <img alt="sound" src="/icons/soundOff.svg" width={32}></img> */}

            <SoundOn />
            <SoundOff />

            <button disabled={tryAgainDisabled} className='secondary button-try-again' onClick={tryAgainClick}> {t('rendering3d.tryAgain')}</button>
            <hr />
            <a className="pdf-button" onClick={() => downloadPdf()} target="_blank">
                GET <PDF />
            </a>
            <a className="pdf-button" onClick={() => takeScreenshotAndDownloadPDF()} target="_blank">
                POST <PDF />
            </a>
            Wheels: <Wheels /> 
        </section >
    );
}

export default withNamespaces()(SandboxComponent);


import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withNamespaces, WithNamespaces } from "react-i18next";
import format from "../../utils/formatter";
import { DialogProps } from "./dialogRouter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { AddPartPayload } from "../../services/http/real";
import store from "../../redux/store";
import addPartAsync from "../../redux/async-actions/addPart";
import i18n from "../../i18n";
import Done from "@material-ui/icons/Done";
import { getPartById } from "../../utils/partsHelpers";
import Close from "../../assets/icons/close";
import Arrow from "../../assets/icons/arrow";
import { StateManagementProps } from "../../utils/addReduxProps";
import { Price } from "../../models/vehicle";

export interface ConflictingPartErrorResponse {
  ValidationResultType: number; // 0 invalid input,  1 rule exception
  InvalidInputMessage?: any; // if defined show error in a diferrent toast
  RulesResult: RulesResult;
}

export interface RulesResult {
  RuleType: RuleTypes;
  Part: ResponsePart;
  Parts: ResponsePart[];
  ChoosePart: boolean;
}

export enum RuleTypes {
  Required = 1,
  Conflicting = 0,
}

export interface ResponsePart {
  Code: string;
  Title: string;
  Price: Price;
  PreviewImageId: number;
  PreviewImage: string;
}

export interface RequiredPartsProps {
  rule: RulesResult;
  deleteRule?: true;
}

export interface ConflictingPartsProps {
  rule: RulesResult;
}

export interface RequiredSelectablePartsProps {
  rule: RulesResult;
  partKey: string;
}

type selectedPartIdsType = { [key: string]: string };

export const selectedPartIds$ = new BehaviorSubject<selectedPartIdsType | false>(false);

//changes on this variable triggers server call!
export const addPartPayload$ = new BehaviorSubject<AddPartPayload | false>(false);

//the last requested payload to the backend, separate variable because addPartPayload$ emits server calls
export const currentPayload$ = new BehaviorSubject<AddPartPayload | false>(false);

export const ConflictingParts: React.FunctionComponent<DialogProps<ConflictingPartErrorResponse & AddPartPayload> & WithNamespaces & StateManagementProps> = ({
  hideDialog,
  t,
  data,
  applicationSettings,
}) => {
  // @todo add or remove selector
  const removeParts: boolean = data.RulesResult.RuleType === 0;
  const title = t(`dialogs.conflictingParts.${removeParts ? "conflicting" : "required"}.title`);
  const cancelButton = t(`buttons.${removeParts ? "keep" : "remove"}`);
  const addButton = t(`buttons.${removeParts ? "change" : "add"}`);
  const {
    Part: { Code },
  } = data.RulesResult;

  //use effect to subscribe and unsubsrcibe observable of selectable required parts
  useEffect(() => {
    // subscribe to the required streams
    const partIdsSub = selectedPartIds$.subscribe();

    // when this stream emits send values to the server
    const partPayloadSub = addPartPayload$.pipe(filter((val): val is AddPartPayload => !!val)).subscribe((val) => {
      store.dispatch(addPartAsync(val) as any);
    });

    return function cleanup() {
      // values in this stream need reset during unmounting
      selectedPartIds$.next(false);

      // cancel subscriptions
      partIdsSub.unsubscribe();
      partPayloadSub.unsubscribe();
    };
  }, []);

  const addPart = () => {
    console.log("Try add part to the current configuration.");
    // initialize the value of the payload with the value of observable (when not false) or the default
    let payload: AddPartPayload = currentPayload$.getValue() || {
      PartId: Code,
      SelectedParts: [],
      RemoveParts: [],
    };

    payload.DryRun = true; //allways send dry run, until all conflicts are resolved

    if (data.RulesResult.RuleType === RuleTypes.Required) {
      // get all required selectable parts from the last value of the stream
      const selectedRequiredValues = selectedPartIds$.getValue();

      // if required selectable parts are not falsy add them otherwise provide empty array
      const requiredSelectedPartIds = selectedRequiredValues ? getRequiredPartsIds(selectedRequiredValues) : [];

      // if required non selectable parts are inside rules result add them otherwise provide empty array
      const requiredPreselectedIds = !data.RulesResult.ChoosePart ? getPartsIdsFromRulesResult(data.RulesResult) : [];

      // accumulates: required preselected, required selected and previously selected parts
      const SelectedParts = requiredPreselectedIds
        .concat(requiredSelectedPartIds)
        .concat(payload.SelectedParts)
        .filter((value, index, self) => self.indexOf(value) === index); // Contained duplicated Part codes which caused bug KPC-248; TODO: is most efficient solution?

      if (SelectedParts.length) {
        payload = {
          ...payload,
          SelectedParts,
        };
      }
    } else {
      // assumes RuleType.Conflicting

      // combines Remove part ids from this call and the previous one if any
      const RemoveParts = getPartsIdsFromRulesResult(data.RulesResult).concat(payload.RemoveParts);
      if (RemoveParts.length) {
        payload = {
          ...payload,
          RemoveParts,
        };
      }
    }

    // emit next value to trigger server call
    addPartPayload$.next(payload);
  };

  const closeDialog = () => {
    // when user chooses to close dialog reset streams
    addPartPayload$.next(false);
    selectedPartIds$.next(false);
    hideDialog();
  };

  const ConflictingContent = withNamespaces()(ConflictingPartsContent);

  const renderDesktopBackButton = () => {
    if (applicationSettings.Brand === "HQV") {
      return (
        <>
          <span>
            <Arrow />
          </span>
          {t("navigation.goBack")}
        </>
      );
    }
    return (
      <>
        <span className="show-mobile">
          <Arrow />
        </span>
        {t("navigation.goBack")}
        <span className="d-none-mobile">
          <Arrow />
        </span>
      </>
    );
  };

  const renderHeadline = () => {
    if (applicationSettings.Brand === "KTM") {
      return (
        <h2 id="conflicting-parts-modal" className="conflicting-part-headline underline uppercase">
          {title}
        </h2>
      );
    }
    if (applicationSettings.Brand === "HQV") {
      return (
        <h2 id="conflicting-parts-modal" className="conflicting-part-headline underline uppercase">
          <span className="headline">{title}</span>
        </h2>
      );
    }
  };

  return (
    <Dialog disableBackdropClick={true} fullWidth={true} open={true} onClose={closeDialog} aria-labelledby="conflicting-parts-modal" id="conflicting-parts-dialog">
      <div className="conflicting-parts-dialog-wrapper">
        <div className="dialog-wrapper">
          <Grid item className="close-send-mail-button-mobile show-mobile container">
            <button className="primary back-link" title={t("buttons.closeDialog")} onClick={closeDialog}>
              <Close />
            </button>
          </Grid>
          <Grid item className="close-send-mail d-none-mobile container conflicting-parts">
            <button className="primary back-link" title={t("buttons.closeDialog")} onClick={closeDialog}>
              {renderDesktopBackButton()}
            </button>
          </Grid>
          <Grid item className="full-width">
            <DialogTitle id="conflicting-parts-dialog-title">
              <Grid item>{renderHeadline()}</Grid>
            </DialogTitle>
          </Grid>
          <Grid item className="full-width">
            <DialogContent>
              <ConflictingContent {...data} />
            </DialogContent>
            <DialogActions className="dialog-action-container">
              <button onClick={closeDialog} className="secondary">
                {cancelButton}
              </button>
              <button onClick={addPart} className="primary">
                {addButton}
              </button>
            </DialogActions>
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};

export const ConflictingPartsContent: React.FunctionComponent<ConflictingPartErrorResponse & WithNamespaces> = ({ RulesResult, t }) => {
  const remove = RulesResult.RuleType === RuleTypes.Conflicting;
  return remove ? <PartsToRemove rule={RulesResult} /> : <RequiredParts rule={RulesResult} />;
};

export const RequiredParts: React.FunctionComponent<RequiredPartsProps> = ({ rule, deleteRule }) => {
  const {
    Part: { Title, PreviewImage, Price },
  } = rule;
  const { ChoosePart: isSelectable } = rule;
  let requiredPartIds = "";
  for (let i = 0; i < rule.Parts.length; i++) {
    if (requiredPartIds !== "") {
      requiredPartIds += "-";
    }
    requiredPartIds += rule.Parts[i].Code;
  }
  const selectable = (
    <>
      <span className={`gtm-add-required-part-selectable`}>{i18n.t("dialogs.conflictingParts.required.selectRequired")}</span>
      <RequiredSelectableParts rule={rule} partKey={`selectable`} />
    </>
  );

  const nonSelectable = (
    <>
      <span className={`required-part-info`}>{i18n.t(`dialogs.conflictingParts.required.${deleteRule ? "delete" : "requiredNonSelectable"}`)}</span>
      <RequiredNonSelectableParts parts={rule.Parts} />
    </>
  );
  return (
    <Grid
      container
      className={`conflicting-parts-items-container gtm-base-part-${rule.Part.Code} gtm-required-part-${requiredPartIds}  ${isSelectable ? "gtm-selectable-parts" : "gtm-none-selectable-parts"} ${
        deleteRule ? "gtm-remove-parts" : "gtm-add-parts"
      }`}
    >
      <Grid item md={6} xs={12} className="item-left">
        <div className="dialog-image-wrapper">
          <Grid container justify="space-between" alignItems="center" className="part-item part-item-big">
            <Grid item xs={4} className="big-image-container">
              <img className="fitting-image big-image" src={PreviewImage} alt={Title} />
            </Grid>
            <Grid className="part-item-big-title-wrapper" item xs={8}>
              <h3>{Title}</h3>
              <div className="part-item-price">{format.currency(Price)}</div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={6} xs={12} className="item-right">
        {isSelectable ? selectable : nonSelectable}
      </Grid>
    </Grid>
  );
};

export const RequiredSelectableParts: React.FunctionComponent<RequiredSelectablePartsProps> = ({ rule, partKey }) => {
  const { Parts } = rule;
  const [{ Code }] = Parts;
  const [selected, setSelected] = useState<false | string>(false);

  useEffect(() => {
    selectedPartIds$.next({ [partKey]: Code });
    const subscription = selectedPartIds$.subscribe((value) => {
      // when stream emits make sure to refresh component
      setSelected((value as selectedPartIdsType)[partKey]);
    });
    return function cleanup() {
      // unsubscribe on component destruction
      subscription.unsubscribe();
    };
  }, [partKey, Code]);

  const selectPart = (Code: string) => {
    const values = selectedPartIds$.getValue();
    selectedPartIds$.next({
      ...values,
      [partKey]: Code,
    });
  };

  const partsInGrid = Parts.map(({ PreviewImage, Title, Price, Code }: ResponsePart, idx: number) => {
    const higlighted = Code === selected;

    return (
      <Grid key={idx} onClick={selectPart.bind(null, Code)} container alignItems="center" className={`part-item clickable ${higlighted ? "orange-border" : "transparent-border"}`}>
        <Grid item xs={1} style={{ visibility: higlighted ? "visible" : "hidden" }}>
          <div className="selected-icon-wrapper">
            <Done color="primary" />
          </div>
        </Grid>
        <Grid item md={2} xs={5}>
          <div className="small-image-container">
            <img className="fitting-image" src={PreviewImage} alt={Title} />
          </div>
        </Grid>
        <Grid item className="part-item-title" md={6} xs={3}>
          <h3>{Title}</h3>
        </Grid>
        <Grid item className="part-item-price text-center" xs={3}>
          {format.currency(Price)}
        </Grid>
      </Grid>
    );
  });

  return <>{partsInGrid}</>;
};

export const RequiredNonSelectableParts: React.FunctionComponent<{ parts: ResponsePart[] }> = ({ parts }) => {
  return (
    <>
      {parts.map((part: ResponsePart, idx: number) => {
        const { PreviewImage, Title, Price } = part;

        return (
          <Grid container key={idx} justify="space-around" alignItems="center" className="required-parts-container">
            <Grid item md={2} xs={5} className="image-grid-item">
              <div className="small-image-container">
                <img alt="part" className="fitting-image" src={PreviewImage} />
              </div>
            </Grid>
            <Grid item md={10} xs={7} className="text-grid-item">
              <h3>{Title}</h3>
              <div className="part-item-price">{format.currency(Price)}</div>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default withNamespaces()(ConflictingParts);

export const PartsToRemove: React.FunctionComponent<ConflictingPartsProps> = ({ rule }) => {
  //old parts
  const { Parts }: RulesResult = rule;

  // the part to replace old parts
  const { Part } = rule;

  return (
    <Grid container justify="space-between" spacing={16}>
      <Grid item md={6} xs={12}>
        <ReplacePart replacing={true} part={Part} />
      </Grid>
      <Grid item md={6} xs={12}>
        {Parts.map((part: ResponsePart, idx: number) => (
          <ReplacePart replacing={false} part={part} key={idx} />
        ))}
      </Grid>
    </Grid>
  );
};

export interface ReplacePartsProps {
  part: ResponsePart;
  replacing: boolean;
}

export const ReplacePart: React.FunctionComponent<ReplacePartsProps> = ({ part: { Title, PreviewImage, Price, Code }, replacing }) => {
  return (
    <Grid container className={`replace-part-wrapper ${replacing ? "orange-lines" : "grey-lines"}`}>
      <div className="dialog-image-wrapper full-width">
        <Grid container justify="space-between" alignItems="center" className="part-item part-item-big">
          <Grid item xs={4} className="big-image-container">
            <img className="fitting-image big-image" src={PreviewImage} alt={Title} />
          </Grid>
          <Grid className="part-item-big-title-wrapper" item xs={8}>
            <h3>{Title}</h3>
            <div className="part-item-price">{format.currency(Price ? Price : getPartById(Code).Price)}</div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

function getPartsIdsFromRulesResult(rulesResult: RulesResult): string[] {
  return rulesResult.Parts.reduce((acc: string[], part: ResponsePart) => [...acc, part.Code], []);
}

function getRequiredPartsIds(selectedRequiredValues: selectedPartIdsType | false) {
  if (!selectedRequiredValues) {
    return [];
  }
  return Object.keys(selectedRequiredValues).reduce((acc: string[], selectionKeys: string) => [...acc, selectedRequiredValues[selectionKeys]], []);
}

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Movie: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 24 25">
      <title>Movie Icon</title>
      <path className="stroke" d="M7 3.38086V21.3809" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M17 21.3809V3.38086" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        className="stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21.3809H5C3.895 21.3809 3 20.4859 3 19.3809V5.38086C3 4.27586 3.895 3.38086 5 3.38086H19C20.105 3.38086 21 4.27586 21 5.38086V19.3809C21 20.4859 20.105 21.3809 19 21.3809Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="stroke" d="M7 7.38086H3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M7 17.3809H3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M17 17.3809H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M17 7.38086H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M21 12.3809H3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default Movie;

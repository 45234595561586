import React, { useState, useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

interface FadeInLettersProps {
	text: string;
	speed?: number
}

export const FadeInLetters: React.FunctionComponent<FadeInLettersProps> = ({ text, speed = 25 }) => {
	const textLength = text.length;
	const [substringArray, setSubstringArray] = useState<Array<string>>([]);

	useEffect(() => {
		const timer = setInterval(() => {
			setSubstringArray((substringArray) => {
				if (substringArray.length === textLength) {
					clearInterval(timer)
					return [...substringArray];
				}
				return [...substringArray, text[substringArray.length]];
			});
		}, speed);
	}, [speed, text, textLength]);

	return (<span>
		<ReactCSSTransitionGroup
			transitionName="fade"
			transitionEnterTimeout={200}
			transitionLeaveTimeout={200}
		>
			{substringArray.map((letter, idx) => <span key={idx}>{letter}</span>)}
		</ReactCSSTransitionGroup>

	</span>)
}

export default FadeInLetters;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Moon: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 23 23">
      <title>Moon Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7136 11.5705C14.1507 9.06822 14.753 5.30462 13.2185 2.16674C13.1225 1.9383 13.1663 1.67502 13.3311 1.48994C13.4958 1.30486 13.7523 1.23089 13.9903 1.29981C15.8231 1.77224 17.4961 2.72665 18.8356 4.06396C22.9814 8.10275 23.0689 14.7374 19.0311 18.8841C14.8847 22.9223 8.25009 22.8353 4.21093 18.6899C2.87285 17.3513 1.91792 15.6787 1.4455 13.8459C1.37623 13.6076 1.45029 13.3506 1.6358 13.1857C1.82131 13.0208 2.08514 12.9774 2.31371 13.0741C5.45021 14.6079 9.21209 14.0062 11.7136 11.5705Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Moon;

import { SHOW_GUIDANCE, HIDE_GUIDANCE} from "../actionTypes";
import Action from './models/action';

export interface UserGuidanceState {
	show: boolean;
}

export const initialState: UserGuidanceState = {
	show: true,
};

export default function ToastMessageReducer(state: UserGuidanceState = initialState, action: Action): UserGuidanceState {
	switch (action.type) {
		case SHOW_GUIDANCE: {
			return {
				...initialState,  
			};
		}
		case HIDE_GUIDANCE: {
			return {
				show: false,  
			};
		}    
		default: 
			return state;
	}
}
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowKTM: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Arrow</title>
      <polygon points="57.16 8.97 35.4 8.97 66.6 40.19 0 40.19 0 56.19 66.71 56.19 35.92 87.03 56.83 87.03 96 47.81 57.16 8.97" />
    </SvgIcon>
  );
};

export default ArrowKTM;

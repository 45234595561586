import React, { useEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { StaticContext } from "react-router";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
	mapStateToProps,
	mapDispatchToProps,
	StateManagementProps
} from "../../../utils/addReduxProps";
import { WithNamespaces, withNamespaces } from "react-i18next";
import store from "../../../redux/store";
import getOverview from '../../../redux/async-actions/getOverview';
import { exitVehicleConfiguration } from "../../../redux/actions";
import Format from '../../../utils/formatter';
import { Hero } from "../../../models/vehicle";

export type OverviewAllProps = RouteComponentProps<{ modelId: string }, StaticContext, { hero: Hero }> &
	StateManagementProps &
	WithNamespaces;

export const Overview: React.FunctionComponent<OverviewAllProps> = props => {
	const {
		match: {
			path,
			params //: { modelId }
		},
		location: { state }
	} = props;

	useEffect(() => {

		if (!hasOverviewData()) {
			// @todo: better types when available
			store.dispatch(getOverview(props.match.params.modelId) as any)
		}
	}, [props.overview.Vehicle.ModelId])

	const resetAndGoToModels = () => {
		store.dispatch(exitVehicleConfiguration());
		props.history.goBack();
	}


	const hasOverviewData = () => (props.overview.Vehicle.ModelId.length);

	if (!hasOverviewData()) {
		return <CircularProgress className="spinner" />;
	}

	const { hero } = props.location.state;
	const {
		Vehicle: {
			Hero: { Description }
		},
		FastFacts
	} = props.overview;
	const { modelId } = props.match.params;
	const imageProps = {
		src: process.env.REACT_APP_SERVER_ROOT + hero.ImageUrl
	};
	const { t } = props;

	const { Intro, Title, Price } = hero;
	const orangeUpperText = 'uppercase arrow-link';

	return (
		<Grid container>

			<Grid className="full-width" id="back-to-selection-wrapper">
				<div onClick={resetAndGoToModels}>
					<p className="clickable">
						<span style={{ width: 5, display: "inline-block" }} />
						{t('configuration.summary.overview')}
					</p>
				</div>
			</Grid>

			<Grid item id="bike-overview-main" container>

				{/* CONTENT Body */}
				<Grid item id="bike-overview-info-wrapper" className="full-width">
					{/* Bike Intro */}
					<Grid container className="full-height">
						<Grid item xs={3} className="full-height">
							<h3>{Title}</h3>
							<h1>{Intro}</h1>
							<p className="text overflow-auto max-height">{hero.Description}</p>
							<p><strong className="uppercase">{t('configuration.summary.total')}</strong></p>
							<p>{Format.currency(Price)}</p>
							<p>{t('configuration.summary.priceInfo')}</p>

						</Grid>
						<Grid item xs={6} className="full-height">
							{/* BIKE IMAGE */}
							<Grid container
								justify="center"
								alignContent="center"
								className="full-height"
							>
								<img
									alt="overview"
									id="overview-main-image"
									{...imageProps}
								/>
							</Grid>
						</Grid>
						{/* BIKE DESCRIPTION */}
						<Grid item xs={3} className="full-height">

							<div id="bike-overview-button-wrapper">
								{/* <button className="secondary">{t('buttons.testDrive')}</button> */}
								{/* @todo: REMOVE '/categores' from the next when setups become active */}
								<Link
									to={{ pathname: `/configuration/${modelId}/categories`, state: { hero: hero } }}
									className={orangeUpperText}>
									<h3>Go to Configuration-Page</h3>
								</Link>
								<p>{t('configuration.summary.configureText')}</p>
								<p>note: this component/page is only temporary</p>

							</div>
						</Grid>
					</Grid>
				</Grid>

			</Grid>

			{/* GO BACK item   */}
			<Grid className="full-width" id="overview-footer">
				<div onClick={resetAndGoToModels}>
					<p className="clickable">
						<span style={{ width: 5, display: "inline-block" }} />
						{t('navigation.goBack')}
					</p>
				</div>
			</Grid>

		</Grid>
	);

}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Overview));

import React, { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { exitVehicleConfiguration, ShowDialogProps } from "../../redux/actions";
import { History } from "history";
import Arrow from "../../assets/icons/arrow";
import Country from "../../assets/icons/country";
import { WithNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, StateManagementProps } from "../../utils/addReduxProps";
import { DialogTypes } from "../../enums/dialogTypes";
import { CountryLanguageType } from "../dialogs/languageSelection";
import { getCopyright } from "./copyright";

export interface FooterProps {
  history: History;
  closePrev?: string;
  className?: string;
  currentCountryArray?: CountryLanguageType[];
  openPopUp?: boolean;
}

export type FooterAllProps = FooterProps & WithNamespaces & StateManagementProps;

const Footer: React.FunctionComponent<FooterAllProps> = props => {
  const { t, applicationSettings, culture, showDialog, currentCountry, openPopUp, history, toggleSummary, is3DMode, dealerInquirySent } = props;

  useEffect(() => {
    if (openPopUp) {
      showLanguageMenu();
    }
  }, [openPopUp]);

  let closePrev = props.closePrev;
  let countryName = currentCountry.CountryName;
  let copyright = getCopyright(applicationSettings.Brand);

  function goBackToList() {
    if (!goToKtm) {
      store.dispatch(exitVehicleConfiguration());
      history.push("/main");
      return false;
    } else {
      window.location.href = backLink;
    }
  }

  function showLanguageMenu() {
    const dialogProps: ShowDialogProps = {
      title: "",
      data: {},
      contentType: DialogTypes.languageSelection,
    };
    showDialog(dialogProps);
  }

  function historyGoBack() {
    history.goBack();
  }

  let className: string = "";
  if (props.className) {
    className = props.className;
    closePrev = undefined;
  }

  const goToKtm = history.location.pathname === "/main/" || history.location.pathname === "/main";
  const rootLink = applicationSettings.BrandLink;
  let linkCulture = culture.toLowerCase();

  if (currentCountry.ImprintCode) {
    linkCulture = currentCountry.ImprintCode;
  }

  const legalLink = rootLink + linkCulture + "/legal-notices.html";
  const imprintLink = rootLink + linkCulture + "/imprint.html";
  const privacyLink = rootLink + linkCulture + "/privacy-policy.html";
  const backLink = rootLink + linkCulture + ".html";

  return (
    <footer id="footer" className={`footer-container ${className} ${is3DMode || dealerInquirySent ? "is3D" : ""} `}>
      {!closePrev && countryName && (
        <div className="language-selection-footer-link show-mobile" onClick={() => showLanguageMenu()}>
          <span>
            <Country />
          </span>
          {countryName}
        </div>
      )}
      <div className={`footer-content ${is3DMode ? "is3D" : ""}`}>
        {!closePrev && className !== "summary-footer" && !is3DMode && (
          <div className="footer-content-goback-link">
            <a className="go-back-link" onClick={goBackToList}>
              <>
                <span>
                  <Arrow />
                </span>
                {t("navigation.goBack")}
              </>
            </a>
          </div>
        )}
        {className === "summary-footer" && (
          <div>
            <a className="go-back-link go-back-to-config" onClick={() => toggleSummary(false)}>
              <>
                <span>
                  <Arrow />
                </span>
                {t("navigation.goBack")}
              </>
            </a>
          </div>
        )}
        {closePrev && (
          <div>
            <a className="go-back-link go-back-to-config" onClick={historyGoBack}>
              <>
                <span>
                  <Arrow />
                </span>
                {t("navigation.goBack")}
              </>
            </a>
          </div>
        )}

        <div className={`footer-navigation ${is3DMode ? "is3D" : ""}`}>
          {applicationSettings.Brand === "HQV" && (
            <div className="copyright-container">
              <div className="copyright">{copyright}</div>
              <span className="d-none-mobile">|</span>
            </div>
          )}
          <div className="footer-navigation-links">
            <a target="_blank" href={imprintLink}>
              {t("footer.imprint")}
            </a>
            <a target="_blank" href={privacyLink}>
              {t("footer.privacy")}
            </a>
            <a target="_blank" href={legalLink}>
              {t("footer.legalNotices")}
            </a>
          </div>

          {applicationSettings.Brand !== "HQV" && (
            <div className="copyright-container">
              <span className="d-none-mobile footer-pipe">|</span>
              <div className="copyright ktm">{copyright}</div>
            </div>
          )}
        </div>

        {!closePrev && countryName && !is3DMode && (
          <div className="language-selection-footer-link d-none-mobile" onClick={() => showLanguageMenu()}>
            <Country />
            {countryName}
          </div>
        )}
      </div>
    </footer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

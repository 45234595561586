import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Sun: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 32 31">
      <title>Sun Icon</title>
      <path
        d="M20.8317 11.0255C23.3422 13.536 23.3422 17.6063 20.8317 20.1168C18.3212 22.6273 14.2508 22.6273 11.7403 20.1168C9.22979 17.6063 9.22979 13.536 11.7403 11.0255C14.2508 8.51495 18.3212 8.51495 20.8317 11.0255"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.2874 5.2853V2.71387" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.2874 28.4279V25.8564" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.4638 7.39421L25.3766 6.48135" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.19619 24.6608L8.10905 23.748" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.5723 15.5706H29.1437" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.42969 15.5706H6.00112" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.4638 23.748L25.3766 24.6608" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.19619 6.48135L8.10905 7.39421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default Sun;

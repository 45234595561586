import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseKTM: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Close Icon</title>
      <polygon points="94.99 15.54 78.96 -0.49 46.99 31.47 15.04 -0.48 -0.99 15.55 30.96 47.51 -0.99 79.46 15.04 95.49 46.99 63.54 78.96 95.5 94.99 79.47 63.03 47.51 94.99 15.54" />
    </SvgIcon>
  );
};

export default CloseKTM;

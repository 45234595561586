import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PlusIconProps } from "./plus";

const PlusHQV3D: React.FunctionComponent<PlusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 25.5 25.5" id={props.identifyer}>
      <title>Plus Icon</title>
      <g transform="translate(-1845 -840)">
        <g transform="translate(1852.374 847.433)">
          <path d="M540.542,117.277v5.034h-5.034v.78h5.034v5.034h.78v-5.034h5.034v-.78h-5.034v-5.034Z" transform="translate(-535.508 -117.277)" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PlusHQV3D;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SoundOn: React.FunctionComponent = () => {
  return (
    <SvgIcon width="38" height="30" viewBox="0 0 38 30">
      <title>Sound Icon On</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5733 3.2905C17.0829 2.85851 17.8646 3.21943 17.8646 3.88963V26.4432C17.8646 27.1138 17.0812 27.4735 16.5739 27.0428L9.07279 20.6879C8.85092 20.4999 8.57622 20.4151 8.30704 20.4285H3.88535C3.45236 20.4285 3.10039 20.0766 3.10039 19.6436V10.69C3.10039 10.2571 3.45236 9.90508 3.88535 9.90508H8.32745C8.59007 9.91335 8.85653 9.82811 9.07279 9.6449L16.5733 3.2905ZM7.95904 7.70508L15.1507 1.61232C17.0915 -0.0325927 20.0646 1.34797 20.0646 3.88963V26.4432C20.0646 28.9841 17.0903 30.3661 15.1509 28.7206L15.1501 28.7199L7.96003 22.6285H3.88535C2.23734 22.6285 0.900391 21.2916 0.900391 19.6436V10.69C0.900391 9.04203 2.23734 7.70508 3.88535 7.70508H7.95904Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4955 10.9323C28.9765 10.5612 29.6672 10.6502 30.0383 11.1312C31.9576 13.6187 31.9576 17.0877 30.0383 19.5752C29.6672 20.0562 28.9765 20.1453 28.4955 19.7742C28.0145 19.403 27.9254 18.7123 28.2965 18.2313C29.6049 16.5356 29.6049 14.1708 28.2965 12.4751C27.9254 11.9942 28.0145 11.3034 28.4955 10.9323Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2497 8.39369C33.7305 8.02224 34.4213 8.11081 34.7927 8.59153C37.8692 12.5729 37.8692 18.1294 34.7927 22.1107C34.4213 22.5914 33.7305 22.68 33.2497 22.3085C32.769 21.9371 32.6804 21.2462 33.0519 20.7655C35.5161 17.5765 35.5161 13.1257 33.0519 9.9367C32.6804 9.45598 32.769 8.76515 33.2497 8.39369Z"
      />
    </SvgIcon>
  );
};

export default SoundOn;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowHeadKTM: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 96 96">
      <title>Arrow</title>
      <polygon points="38.91 0 9.32 0 56.79 48.23 9.96 96 38.51 96 86.68 48.23 38.91 0" />
    </SvgIcon>
  );
};

export default ArrowHeadKTM;

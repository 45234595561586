import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ZoomIcon: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <title>Zoom</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19543 7.97299C11.0116 5.15679 15.5776 5.15679 18.3938 7.97299C21.2006 10.7797 21.21 15.3245 18.422 18.143C18.3934 18.1641 18.366 18.1875 18.34 18.2132C18.3147 18.238 18.2916 18.2643 18.2707 18.2916C15.446 20.987 10.9709 20.9469 8.19543 18.1714C5.37923 15.3552 5.37923 10.7892 8.19543 7.97299ZM18.8418 19.7898C15.4196 22.6226 10.3394 22.4366 7.13477 19.232C3.73279 15.83 3.73279 10.3143 7.13477 6.91233C10.5368 3.51034 16.0525 3.51034 19.4545 6.91233C22.6965 10.1544 22.8489 15.316 19.9118 18.7385L27.2006 26.127C27.4915 26.4219 27.4883 26.8968 27.1934 27.1877C26.8985 27.4786 26.4236 27.4753 26.1327 27.1805L18.8418 19.7898ZM9.91666 13.3335C9.91666 12.9193 10.2524 12.5835 10.6667 12.5835H12.5833V10.667C12.5833 10.2528 12.9191 9.91699 13.3333 9.91699C13.7476 9.91699 14.0833 10.2528 14.0833 10.667V12.5835H16C16.4142 12.5835 16.75 12.9193 16.75 13.3335C16.75 13.7477 16.4142 14.0835 16 14.0835H14.0833V16.0003C14.0833 16.4145 13.7476 16.7503 13.3333 16.7503C12.9191 16.7503 12.5833 16.4145 12.5833 16.0003V14.0835H10.6667C10.2524 14.0835 9.91666 13.7477 9.91666 13.3335Z"
      />
    </SvgIcon>
  );
};

export default ZoomIcon;

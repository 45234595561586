import React, { useEffect } from "react";
import { Part } from "../../parts/PartsList";
import Minus from "../../../assets/icons/minus";
import { ConfigurationState } from "../../../redux/reducers/configuration";
import formatter from "../../../utils/formatter";
import { Price } from "../../../models/vehicle";
import Minus3D from "../../../assets/icons/minus3D";
import { StateManagementProps } from "../../../utils/addReduxProps";
import { WithNamespaces } from "react-i18next";
import { removePart } from "../../../utils/partsHelpers";

export interface SelectedPartsListProps {
  parts: Part[];
  configurationState: ConfigurationState;
  totalPrice?: Price;
  isMobile?: boolean;
}

type AllProps = SelectedPartsListProps & StateManagementProps & WithNamespaces;

export const SelectedPartsList: React.FunctionComponent<AllProps> = (props) => {
  const { parts, isXBow, is3DMode, isMobile } = props;

  return is3DMode ? (
    <>
      {parts.map((part) => {
        return (
          <>
            <div key={"selected-3d-part"+part.PartId} className="parts-list-configurated">
              <div className="part-name">{part.Name}</div>
              {part.IsColorPart && (<div className="part-price">{part.Description}</div>)}
              {!part.IsColorPart && (
                <>
                  <div className="part-ref">Ref: {part.PartId}</div>
                  <div className="part-price">
                    {formatter.currency(part.Price, isXBow)}
                  </div>
                  <div className="remove-button" onClick={() => removePart(part)}>
                    <span>
                      Remove
                      <Minus3D identifyer={`remove-part-list-${part.PartId}`} />
                    </span>
                  </div>
                </>
              )}
            </div>
            {parts.length !== 1 && !isMobile && (
              <div className="overview-border"></div>
            )}
          </>
        );
      })}
    </>
  ) : (
    <div className={`selected-parts-list`}>
      {parts.map((part) => {
        return (
          <div key={"selected-part-"+part.PartId} className="parts-list-configurated">
            <div>{part.Name}</div>
            <div className="icon-container" onClick={() => removePart(part)}>
              <Minus identifyer={`remove-part-list-${part.PartId}`} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SelectedPartsList;

import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import VehicleSummary from './VehicleSummary';
import VehicleImage from './VehicleImage';
import { Location } from 'history';
import { mapStateToProps, mapDispatchToProps, StateManagementProps } from '../../utils/addReduxProps';
import { connect } from 'react-redux';
import PartDetailDialog from '../parts/PartDetailDialog';
import { RouteComponentProps } from 'react-router';
import { updateConfiguration, UpdateConfigurationPayload } from '../../services/http/real';

export interface VehicleOverviewProps {
  location: Location;
  showDescription: boolean;
  variationCode: string;
  updateBATLink: (newLink: string|undefined) => void;
  financeAvailable: boolean;
};

export interface ShowAllPrices {
	showAllPrices: boolean;
}

export type VehicleOverviewAllProps = VehicleOverviewProps & ShowAllPrices & WithNamespaces & StateManagementProps & RouteComponentProps;

export const VehicleOverview: React.FunctionComponent<VehicleOverviewAllProps> =
  (props) => {

    const {
      t,
      selectedModel,
      views: { showPartDetail },
      location: { pathname },
      models,
      toggleSummary,
      togglePartDetail,
      deselectPart,
      showDescription,
      variationCode,
      updateBATLink,
      currentVariation,
      setVehicleVariation,
      preselectedVariationCode,
      overview:{Variations},
      financeAvailable
    } = props;

    const [changeRenderedPicture, setChangeRenderedPicture] = React.useState(false);

    const resetRenderedPictureChange = () => {
      setChangeRenderedPicture(false);
    }

    useEffect(() => {
      if(preselectedVariationCode.code !== "") {
        Variations.forEach(Variation => {
          if(Variation.Code === preselectedVariationCode.code) {
            changeBikeColor(Variation.Code, Variation.ColorCode);
          }
        });
      }
    }, []);

    if (!selectedModel) {
      //@todo return page not found
      return null;
    }
    
    const changeBikeColor = (Code:string, ColorCode:string) => {
      const data: UpdateConfigurationPayload = {
        ColorCode: ColorCode,
        VariationCode: Code
      }
      setVehicleVariation(Code);
      updateConfiguration(data).then(() => {
        setChangeRenderedPicture(true);
      });
      updateBATLink(Code);
    }

    return (
      <Grid container id="vehicle-overview-wrapper" className={`vehicle-image-wrapper ${showDescription ? 'category-closed' : 'category-open'} ${showPartDetail ? 'detail-open' : 'detail-closed'}`}>
        {/* summary section */}
        <Grid item xs={!showDescription ? 2 : 3} className="summary-section-wrapper mobile row-back-mobile">
          {!showPartDetail && <VehicleSummary
            model={selectedModel}
            hasPrices={models.ShowPricesInConfigurator && props.showAllPrices}
            {...props}
            showDescription={showDescription}
            changeBikeColor={changeBikeColor}
            variationCode={variationCode}
            financeAvailable={financeAvailable}
          >
            {/* REVERT BUTTON'S DISABLED PROP WHEN SETUPS BECOME ACTIVE */}
            {/*  disabled={!appliedSetup} */}
            <button className="primary" disabled={false} onClick={() => toggleSummary(true)}>{t('buttons.viewConfiguration')}</button>
          </VehicleSummary>}
        </Grid>

        {/* central component section */}
            <Grid item xs={!showDescription ? 10 : 9} className={`${showPartDetail ? '' : 'text-center'} grow summary-section-wrapper mobile vehicle-images-wrapper`}>
                <VehicleImage pathname={pathname} changeRenderedImage={changeRenderedPicture} resetRenderedPictureChange={resetRenderedPictureChange} />
          {showPartDetail ? (
            <PartDetailDialog
              closeDialog={() => {
                togglePartDetail(false);
                deselectPart();
                document.body.classList.remove("lock-scroll");
              }}
              {...props}
            ></PartDetailDialog>
          ) : <></>}
        </Grid>
      </Grid>
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(VehicleOverview));

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PlusIconProps } from "./plus";

const PlusKTM3D: React.FunctionComponent<PlusIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 23.646 23.646" id={props.identifyer}>
      <title>Plus Icon</title>
      <g transform="translate(7.224 7.224)">
        <path d="M409.27,615.451v-3.657h-1.836v3.657h-3.657v1.837h3.657v3.658h1.836v-3.658h3.658v-1.837Z" transform="translate(-403.776 -611.794)" />
      </g>
    </SvgIcon>
  );
};

export default PlusKTM3D;

import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import * as Redux from "redux";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

let middleware: Redux.Middleware[] = [thunkMiddleware];

/*if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
	middleware = [...middleware, loggerMiddleware];
}*/

const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'root',
	whitelist: [""], // Disables redux persist
	storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default store;

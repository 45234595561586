
const year = new Date().getFullYear();

export const getCopyright = (brand?: string) => {
    if (brand === "KTM") {
      return `Copyright ${year} KTM Sportmotorcycle GmbH, all rights reserved`;
    } else if (brand === "HQV") {
      return ` ${year} Husqvarna Motorcycles All rights reserved. Husqvarna and Husqvarna Motorcycles are used under license from Husqvarna AB, Sweden.`;
    } else if (brand === "GG") {
      return `GASGAS Copyright ${year}, all rights reserved`;
    }
  };
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DialogProps } from "./dialogRouter";
import { Link } from "react-router-dom";
import { withNamespaces, WithNamespaces } from "react-i18next";
import store from "../../redux/store";
import { exitVehicleConfiguration } from "../../redux/actions";
import http from "../../services/http";
import FadeInLetters from "../shared/FadeInLetters";

export const WarnBeforeExit: React.FunctionComponent<DialogProps &
	WithNamespaces> = ({ title, hideDialog, t, data }) => {
	const handleConfigurationExit = () => {
		// Since configurations are stored in the database when starting the configuration
		// we need to delete from the database again if the user decides to leave without saving.
		http.dismissUnsavedChanges().then(() => {
			console.log("configuration reset");
		});

		store.dispatch(exitVehicleConfiguration());
	};
	return (
		<Dialog
			open={true}
			onClose={hideDialog}
			aria-labelledby="setup-parts-modal"
		>
			<DialogTitle id="setup-parts-dialog-title">
				<Grid container justify="space-between" direction="row">
					<h2 className="setups-headline underline uppercase">
						<FadeInLetters text={title} />
					</h2>
					<IconButton className="dialog-hide-icon" onClick={hideDialog}>
						<Clear />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent>{data}</DialogContent>
			<DialogActions>
				<button onClick={hideDialog} className="primary m-r-5">
					{t("buttons.no")}
				</button>
				<Link to={`/main`} onClick={handleConfigurationExit}>
					<button className="secondary">{t("buttons.yes")}</button>
				</Link>
			</DialogActions>
		</Dialog>
	);
};

export default withNamespaces()(WarnBeforeExit);

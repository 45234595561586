import React from "react";
import Action from "./models/action";
import HQV_LOGO from "../../assets/icons/hqv_logo";
import KTM_LOGO from "../../assets/icons/ktm_logo";
import GG_LOGO from "../../assets/icons/gg_logo";
import { SET_RUNTIME_CONFIGURATION } from "../actionTypes";

export type ApplicationSettings = {
  Brand: string;
  SupportedBrands: string[];
  BrandLink: string;
  IsB2C: boolean;
  Env: string;
  OneTrust: string;
  GTM: string;
  Santander: string;
  CdcApplicationSettings: CDCApplicationSettings;
  DealerSearch2DEnabled: boolean;
  DealerSearch3DEnabled: boolean;
  GoogleMapsKey: string;
  //GMAPSKEY_local: string;
  Show3DDebugTools: boolean;
  versioninfo?: string;
  Environment: string;
  JentisSettings: JentisSettings;
};

export type JentisSettings = {
  Url: string;
  Project: string;
  Id: string;
};

export type CDCApplicationSettings = {
  Url: string;
  Api: string;
  CdcEnabled: boolean;
};

export const initialState: ApplicationSettings = {
  Brand: "HQV",
  SupportedBrands: ["KTM", "HQV", "GG"],
  BrandLink: "https://www.ktm.com/",
  IsB2C: true,
  Env: "Development",
  OneTrust: "4ceffab1-4567-4a2f-afd8-13c433741ab5-test",
  GTM: "GTM-K5NTPVP",
  Santander: "https://dev.appgile.com/plugin/scf-plugin.js?clientId=505ea096-635b-4b59-aebe-519e644c4029",
  CdcApplicationSettings: {
    Url: "https://login-dev.ktm.com/js/gigya.js?apikey=",
    Api: "4_eVs1NDxVqiV4FGHp1F8lcA",
    CdcEnabled: true,
  },
  DealerSearch2DEnabled: true,
  DealerSearch3DEnabled: true,
  GoogleMapsKey: "AIzaSyCPEuL6iv1R2hkyAdZa-Udaf8nYMt5904E",
  Show3DDebugTools: true,
  versioninfo: "1.0.0",
  Environment: "Development",
  JentisSettings: {
    Url: "https://8b2pf4.configurator.ktm.com",
    Project: "configurator-ktm-com",
    Id: "ggq058",
  },
};

export default function ApplicationSettingsReducer(state: ApplicationSettings = initialState, action: Action): ApplicationSettings {
  switch (action.type) {
    case SET_RUNTIME_CONFIGURATION:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export const getBrandLogo = (brand: string) => {
  return "/images/" + brand.toLowerCase() + "_Logo.png";
};

export const getBrandLogoSvgComponent = (brand: string | undefined) => {
  switch (brand) {
    case "KTM":
      return <KTM_LOGO />;
    case "HQV":
      return <HQV_LOGO />;
    case "GG":
      return <GG_LOGO />;
    default:
      return <KTM_LOGO />;
  }
};

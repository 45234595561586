import React from "react";
import { StateManagementProps, mapStateToProps, mapDispatchToProps } from "../../../utils/addReduxProps";
import { connect } from "react-redux";
import Indicator3DKTM from "./indicator3d_ktm";
import Indicator3DDefault from "./indicator3d_default";

export interface Indicator3DProps {
  useDefault: boolean;
}

export type Indicator3DPropsAll = Indicator3DProps & StateManagementProps;

const Indicator3D: React.FunctionComponent<Indicator3DPropsAll> = props => {
  const { applicationSettings, useDefault } = props;
  const brand = applicationSettings.Brand;

  return (
    <>
      {brand === "KTM" && !useDefault && <Indicator3DKTM color="#FF6600" />}
      {brand === "HQV" && !useDefault && <Indicator3DKTM color="#022550" />}
      {brand === "GG" && !useDefault && <Indicator3DKTM color="#cb0d25" />}
      {useDefault && <Indicator3DDefault />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Indicator3D);

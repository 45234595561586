import { VehicleSettingsPayload, CustomColorConfigurationsPayload, updateConfigurationForUser, ToolsConfigurationPayload, UpdateConfigurationPayload } from "../../services/http/real";

 /**
   * this method is used to store the user id to the configuration within the database for identification purposes.
   * @param props state props
   */
  export const updateConfiguration = (props, toolsData?: ToolsConfigurationPayload, colorData?: CustomColorConfigurationsPayload) => {
    (window as any).gigya &&  (window as any).gigya.accounts && (window as any).gigya.accounts.getAccountInfo && (window as any).gigya.accounts.getAccountInfo({
      callback: (callbackObject) => {
        updateConfigurationForUser(
          { ColorCode: "", VariationCode: props.newVariation ? props.newVariation : props.configuration.VariationCode, VehicleSettings: { ToolsConfiguration: toolsData, CustomColorConfiguration: colorData } as VehicleSettingsPayload } as UpdateConfigurationPayload,
          callbackObject.UID
        );
      },
    });
  };
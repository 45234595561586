import React, { useState , useEffect } from 'react';
import { mapStateToProps, mapDispatchToProps, StateManagementProps } from '../../utils/addReduxProps';
import { connect } from 'react-redux';
import { withStyles, WithStyles, Theme } from '@material-ui/core';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Grid } from '@material-ui/core';
import VehicleImage from '../vehicle-overview/VehicleImage';
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Footer from '../navigation/Footer';
import { ConfigurationAllProps } from '../configuration/ConfigurationView';

export interface PreviewComponentProps {

}

export interface PreviewComponentState {
	background: number;
}

export const classes: (theme: Theme) => Record<string, CSSProperties> = (theme: Theme) => {
	return {
		wrapper: {
			height: '100%',
			background: 'white',
		},

		bikeWrapper: {
			height: '80%',
			position: 'relative' as "relative",
		},

		background: {
			width: '100%',
		},
		contentWrapper: {
			width: '100%',
			position: 'absolute' as "absolute",
			boxSizing: 'border-box' as "border-box",
			height: 'calc(100vh - 74px)',
		},
	}
};

type PreviewProps = PreviewComponentProps & StateManagementProps & WithStyles & WithNamespaces & RouteProps & RouteComponentProps & ConfigurationAllProps;

const PreviewComponent: React.FunctionComponent<PreviewProps & PreviewComponentState> = (props: PreviewProps & PreviewComponentState) => {

	const {
		classes,
		t,
		history,
		location,
		appliedSetup,
		appliedParts,
		totalPrice,
		models: { ShowPricesInConfigurator: hasPrices },
		selectedModel,
		addCreatedConfiguration,
		configuration,
	} = props;

	const { state } : any = location as Location;
	const closePreviewUrl = state && state.from ? state.from : '/page-not-found';
	let closePreview: string;
	closePreview = closePreviewUrl;
	const exitConfigView = React.createRef()
	const [escClicked,setEscClicked] = useState(false);

	useEffect(() => {
		window.addEventListener('keydown', function(event) {
				var key = event.key || event.keyCode;
				if(key === 27 || key === 'Escape' || key === 'Esc' && !escClicked){
					setEscClicked(true);
					if(history) {
						history.goBack();
					}
				}
			},{once: true})
	}, []);
	
	const { wrapper, headerGrid, rootIcons, bikeWrapper, background, contentWrapper, closePreviewBtn, selected, iconWrapper } = classes;

	return (
		<section className="" id="bike-preview">
			<Grid container direction='column' className="full-height">
				{/* BODY WITH PICTURE */}
				<Grid item className={`${bikeWrapper} grow`}>
					<div className={`full-height ${background}`}>
						<div className={contentWrapper}>
							<VehicleImage arrowStyle={{ left: '12px' }} closePreview={closePreview}/>							
						</div>
					</div>				
				</Grid>
				<Footer closePrev={closePreview} {...props}/>
			</Grid>
		</section >
	);
}

export default withNamespaces()(withStyles(classes)(connect(mapStateToProps, mapDispatchToProps)(PreviewComponent)));

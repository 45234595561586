import { Store } from "./index";
import Action from "./models/action";
import { Part } from "../../components/parts/PartsList";
import { FullImage, TestRideData, TestRideStatus } from "../../components/configuration/ConfigurationView";
import { getCategoryInformation } from "./vehicleOverview";
import { initialReduxState as emptyState } from "./index";
import { SaveDetailsData } from "../../components/dialogs/saveConfiguration";
import { Price } from "../../models/vehicle";
import { uniqBy } from "../../utils/helpers";

export interface LoadConfigurationResponse {
	Vehicle: Vehicle;
	Configuration: Configuration;
	TestRideData: TestRideData;
}

interface Configuration {
	ClonedConfigurationId: number;
	SaveDetails: SaveDetailsData;
	SelectedPartIds: string[];
	TotalPrice: Price;
	VariationCode: string;
	Is3DModel: boolean;
	VehicleSettings: VehicleSettings;
}

interface Vehicle {
	ModelId: string;
	Name: string;
	AvailableParts: Part[];
	FullImages: FullImage[];
	TestRideData: TestRideData;
	Variations: [];
}

/**
 * 3D only
 */
export interface VehicleSettings {
	ToolsConfiguration: ToolsConfiguration,
	CustomColorConfiguration: CustomColorConfiguration
}

/**
 * 3D only
 */
export interface ToolsConfiguration {
	Lighting: string;
	Environments: string;
	Perspectives: string;
	Cinematics: string
}

/**
 * 3D only
 */
export interface CustomColorConfiguration {
	SelectedBodyColor: string;
	SelectedRimsColor: string;
	SelectedCaliperColor: string;
	SelectedStitchingColor: string;
	SelectedSuspensionAndLogoColor: string;
}

export default function LoadReducer(
	state: Store,
	action: Action<LoadConfigurationResponse>
): Store {
	const {
		Vehicle: { AvailableParts: Parts, FullImages, Name, ModelId, Variations },
		TestRideData: {Data, Status},
		Configuration: {
			ClonedConfigurationId,
			TotalPrice: price,
			SelectedPartIds,
			VariationCode,
			Is3DModel,
			VehicleSettings
		},
		heroImgUrl
	} = action.payload as LoadConfigurationResponse & { heroImgUrl: string } & {VariationCode: string};

	const { culture, market, companyId } = state.configurationState;

	return {
		...emptyState,
		parts: {
			...emptyState.parts,
			applied: getAppliedParts(Parts, SelectedPartIds)
		},
		vehicleOverviewState: {
			...emptyState.vehicleOverviewState,
			Parts,
			Categories: getCategoryInformation(Parts),
			Vehicle: {
				...state.vehicleOverviewState.Vehicle,
				Name,
				ModelId,
				Hero: {
					...state.vehicleOverviewState.Vehicle.Hero,
					ImageUrl: heroImgUrl
				}
			},
			Variations:Variations
		},
		configurationState: {
			...emptyState.configurationState,
			configuration: {
				ConfigurationId: ClonedConfigurationId,
				FullImages,
				TestRideData:{
					Data: Data,
					Status: Status
				},
				VariationCode,
			},
			price,
			culture,
			market,
			companyId,
			wasRecentlyCreated: false,
			is3DMode: false,
			isXBow: false,
			VehicleSettings
		},
		views: {
			...emptyState.views,
			// this will take user to the summary
			showConfigurationSummary: false
		}
	};
}

function getAppliedParts(Parts: Part[], PartIds: string[]) {
	let parts = Parts.filter(
		(part: Part) => PartIds.indexOf(part.PartId) !== -1
	);
	
	const appliedParts = uniqBy(parts, 'PartId');
	return appliedParts;
}

import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { DialogProps } from './dialogRouter';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import FadeInLetters from '../shared/FadeInLetters';

export type DeleteConfigurationProps = DialogProps<
	{ text: string, deleteAndReloadHistory: (id: number) => void, id: number }
> & WithNamespaces

export const DeleteConfiguration: React.FunctionComponent<DeleteConfigurationProps> = (
	{
		title,
		hideDialog,
		t,
		data: {
			text,
			deleteAndReloadHistory,
			id,
		}
	}
) => {
	return (
		<Dialog open={true} onClose={hideDialog} aria-labelledby="setup-parts-modal">
			<DialogTitle id="setup-parts-dialog-title">
				<Grid container justify="space-between" direction="row">
					<h2 className="setups-headline underline uppercase"><FadeInLetters text={title} /></h2>
					<IconButton className="dialog-hide-icon" onClick={hideDialog}>
						<Clear />
					</IconButton>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{text}
			</DialogContent>
			<DialogActions>
				<button onClick={hideDialog} className="primary m-r-5">{t('buttons.no')}</button>
				<button className="secondary" onClick={() => {
					deleteAndReloadHistory(id);
					hideDialog();
				}}>{t('buttons.yes')}</button>
			</DialogActions>
		</Dialog>
	);
}

export default withNamespaces()(DeleteConfiguration);

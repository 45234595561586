import React from "react";
import { RouteComponentProps } from "react-router";
import ArrowHead from "../../assets/icons/arrowHead";
import { StateManagementProps } from "../../utils/addReduxProps";
import BrandLogo from "./BrandLogo";
import { WithNamespaces } from "react-i18next";

export interface HeaderProps {
  loggedIn?: boolean;
  engineReady?: boolean;
  cdcLoaded?: boolean;
  selectedModelName?: string;
  displayWaitingroom?: boolean;
}

export const Header: React.FunctionComponent<StateManagementProps & RouteComponentProps & HeaderProps & WithNamespaces> = props => {
  const { applicationSettings, loggedIn, cdcLoaded, history, t, engineReady, selectedModelName, displayWaitingroom, dealerInquirySent } = props;
  const brand = applicationSettings.Brand;

  const backToMain = () => {
    (window as any).gigya.accounts.hideScreenSet(brand + "-overlay-3d");
    history.push("/main");
  };

  return (
    <div id="header" className={`header ${!loggedIn && cdcLoaded ? "login-screen-header" : ""} ${displayWaitingroom ? "waiting-room-header" : ""}`}>
      {!(loggedIn && !engineReady) && (
        <div className="go-back-button" onClick={backToMain}>
          <ArrowHead />
          <span className={engineReady && brand === "HQV" ? "white-text" : ""}>{t("navigation.goBack")}</span>
        </div>
      )}

      {selectedModelName && !engineReady && (
        <div className={`mobile-model-name ${loggedIn && !engineReady ? "waiting-room-model-name" : ""} ${!loggedIn ? "hide-model-name" : ""}`}>
          <h1>
            <span className={`${brand === "HQV" ? "headline" : ""}`}>{!dealerInquirySent ? selectedModelName : ""}</span>
          </h1>
        </div>
      )}

      <BrandLogo
        customClass={`brandlogo ${(loggedIn && !engineReady) || !loggedIn ? "brandlogo-in-signupheader" : "brandlogo-in-configheader"} ${!loggedIn || !engineReady ? "hqv" : ""}`}
        {...props}
      ></BrandLogo>
    </div>
  );
};

export default Header;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ShowFullImage: React.FunctionComponent = () => {
  return (
    <SvgIcon viewBox="0 0 20.432 20.432">
      <title>Show Full Image Icon</title>
      <path
        d="M739.528,231.739H736.6v7.3h7.3v-2.914h-4.375ZM736.6,225.9h2.923v-4.375H743.9V218.6h-7.3Zm17.519,10.22h-4.384v2.914h7.3v-7.3h-2.914ZM749.739,218.6v2.923h4.384V225.9h2.914v-7.3Zm0,0"
        transform="translate(-736.605 -218.605)"
      />
    </SvgIcon>
  );
};

export default ShowFullImage;
